import { OptionType } from "components/Combobox";
import {
  STRIPE_PRICE_METADATA_TYPE_TEAM,
  STUDENT_DISCOUNT_1_YEAR,
  STUDENT_DISCOUNT_2_YEAR,
  STUDENT_DISCOUNT_3_YEAR,
  STUDENT_DISCOUNT_4_YEAR,
  STUDENT_DISCOUNT_PERCENT,
} from "constants/config";

const extractAmount = (price: any) => {
  if (!price?.recurring) return { amount: 0.0, interval: null };

  const interval = price?.recurring.interval;
  let amount;

  if (price.metadata.type === STRIPE_PRICE_METADATA_TYPE_TEAM) {
    amount = price.tiers[0].unit_amount;
  } else {
    amount = price?.unit_amount;
  }

  return { amount, interval };
};

export const getMonthlyCost = (price: any, discountPercent?: number) => {
  const { amount, interval } = extractAmount(price);
  if (!interval) return 0.0;

  const value = interval === "year" ? amount / 12.0 : amount;
  return discountPercent ? (value * (100 - discountPercent)) / 100 : value;
};

export const getAnnualCost = (price: any, discountPercent?: number) => {
  const { amount, interval } = extractAmount(price);
  if (!interval) return 0.0;

  const value = interval === "year" ? amount : amount * 12;
  return discountPercent ? (value * (100 - discountPercent)) / 100 : value;
};

export const getStudentPricingDiscountAttr = (graduationYear: string) => {
  let discountId = STUDENT_DISCOUNT_1_YEAR;
  let discountPercent = STUDENT_DISCOUNT_PERCENT;

  if (graduationYear && graduationYear.length >= 4) {
    const thisYear = new Date().getFullYear();
    const diffYears = Number(graduationYear.substring(0, 4)) - thisYear;

    if (diffYears == 1) {
      discountId = STUDENT_DISCOUNT_1_YEAR;
    } else if (diffYears == 2) {
      discountId = STUDENT_DISCOUNT_2_YEAR;
    } else if (diffYears == 3) {
      discountId = STUDENT_DISCOUNT_3_YEAR;
    } else if (diffYears == 4) {
      discountId = STUDENT_DISCOUNT_4_YEAR;
    }
  }

  return {
    discountId,
    discountPercent,
  };
};

export const getGraduationYearsOptions = () => {
  const today = new Date();
  const nextYear = today.getFullYear() + 1;
  const graduationYearsOptions: OptionType[] = [];
  for (let i = 0; i < 4; i++) {
    graduationYearsOptions.push({
      label: `${nextYear + i} graduation`,
      value: `${nextYear + i}`,
    });
  }
  return graduationYearsOptions;
};

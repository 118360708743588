import { MixpanelNavigationSource } from "helpers/services/mixpanel/events";
import useAnalytics from "hooks/useAnalytics";
import { useAppSelector } from "hooks/useStore";
import { useRouter } from "next/router";
import React, { useEffect } from "react";

function HandleAnalytic() {
  const { route, query } = useRouter();
  const navigationSource = useAppSelector(
    (state) => state.analytic.navigationSource
  );

  const { pageViewEvent, setAnalyticNavigationSource } = useAnalytics();

  useEffect(() => {
    if (
      query.utm_source === "chatgpt" &&
      navigationSource !== MixpanelNavigationSource.ChatGPT
    ) {
      setAnalyticNavigationSource(MixpanelNavigationSource.ChatGPT);
    }

    pageViewEvent({ route, pageTitle: document.title });
  }, [route, pageViewEvent]);

  return <div />;
}

export default HandleAnalytic;

import { useEffect, useRef } from "react";

export const ESCAPE_HOTKEY = "Escape";

/**
 * Custom hook to listen for a specific key combination and execute a callback.
 *
 * @param {string[]} keys - The keys to listen for (e.g., ["Control", "K"]).
 * @param {() => void} callback - The function to execute when the key combination is pressed.
 * @param {boolean} [allowInInputs=false] - Whether the hotkey should work when an input field is focused.
 */
function useHotkey(
  keys: string[],
  callback: () => void,
  allowInInputs = false
) {
  const pressedKeysRef = useRef(new Set<string>());

  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      const activeElement = document.activeElement;

      // Skip if an input, textarea, or contenteditable element is focused unless explicitly allowed
      if (
        !allowInInputs &&
        (activeElement instanceof HTMLInputElement ||
          activeElement instanceof HTMLTextAreaElement ||
          (activeElement &&
            activeElement.getAttribute("contenteditable") === "true"))
      ) {
        return;
      }

      // Add pressed key to the set
      pressedKeysRef.current.add(event.key);

      // Check if all required keys are currently pressed
      if (keys.every((key) => pressedKeysRef.current.has(key))) {
        event.preventDefault();
        callback();
      }
    }

    function handleKeyUp(event: KeyboardEvent) {
      // Remove released key from the set
      pressedKeysRef.current.delete(event.key);

      // If a modifier key is released, clear all keys (to avoid "stuck" keys)
      if (["Meta", "Control", "Alt", "Shift"].includes(event.key)) {
        pressedKeysRef.current.clear();
      }
    }

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
      pressedKeysRef.current.clear();
    };
  }, [keys, callback, allowInInputs]);

  return;
}

export default useHotkey;

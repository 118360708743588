import { ButtonVariant, CoButton } from "components/CoButton";
import { usePremiumFeatureModal } from "components/Subscription";
import useLabels from "hooks/useLabels";
import React, { useCallback } from "react";
import { CreditedFeature } from "store/slices/subscription";

/**
 * @component OutOfFreeBookmarkListsTooltip
 * @description Tooltip that tells the user that they're out of lists
 * @example
 * return (
 *   <OutOfFreeBookmarkListsTooltip />
 * )
 */

function OutOfFreeBookmarkListsTooltip() {
  const [tooltipLabels] = useLabels("tooltips.out-of-free-bookmark-lists");

  const { openPremiumFeatureModal } = usePremiumFeatureModal();
  const handleClickUpgradePremium = useCallback(() => {
    openPremiumFeatureModal(CreditedFeature.BOOKMARKS);
  }, [openPremiumFeatureModal]);

  return (
    <div className="flex flex-col gap-3 text-fg-base">
      <h3 className="base-bold">{tooltipLabels["title"]}</h3>
      <p className="sm-normal">{tooltipLabels["message"]}</p>
      <CoButton
        variant={ButtonVariant.Primary}
        label={tooltipLabels["button-text"]}
        icon="/icons/premium.svg"
        size="sm"
        onClick={handleClickUpgradePremium}
      />
    </div>
  );
}

export default OutOfFreeBookmarkListsTooltip;

import { ButtonVariant, CoButton } from "components/CoButton";
import {
  ASK_PAPER_CREDITS_PER_MONTH,
  PRO_ANALYSIS_CREDITS_PER_MONTH,
  SNAPSHOT_CREDITS_PER_MONTH,
} from "constants/config";
import { SubscriptionPlan } from "enums/subscription-plans";
import { useBookmarks } from "features/Bookmarks";
import pluralize from "helpers/pluralize";
import {
  isBillingAdminSubscription,
  ISubscriptionData,
} from "helpers/subscription";
import useCredits from "hooks/useCredits";
import useLabels from "hooks/useLabels";
import useOrg from "hooks/useOrg";
import LoadingSubscriptionCard from "../LoadingSubscriptionCard/LoadingSubscriptionCard";
import UsageListItem from "./UsageItem";

type SubscriptionUsageCardProps = {
  isLoading?: boolean;
  subscription: ISubscriptionData;
  currentPlan?: SubscriptionPlan;
};

/**
 * @component SubscriptionUsageCard
 * @description describes credits used for free and premium users on the subscription page.
 */
const SubscriptionUsageCard = ({
  currentPlan,
  isLoading,
  subscription,
}: SubscriptionUsageCardProps) => {
  const {
    loading: isOrgInfoLoading,
    availableSeats,
    purchasedSeats,
  } = useOrg();
  const [subscriptionUsageLabels] = useLabels("subscription-usage");
  const {
    isSubscriptionLoaded,
    isUsageSet,
    proAnalysisCreditsRemaining,
    askPaperCreditsRemaining,
    snapshotCreditsRemaining,
    creditRefreshDate,
  } = useCredits();

  const { bookmarkListsLeft, bookmarkItemsLeft } = useBookmarks();

  if (isLoading || isOrgInfoLoading || !isSubscriptionLoaded || !isUsageSet) {
    return <LoadingSubscriptionCard />;
  }

  const isFreePlan = !currentPlan || currentPlan === SubscriptionPlan.FREE;
  const isTeamsAdmin =
    subscription.user && isBillingAdminSubscription(subscription.user);

  return (
    <div className="flex flex-col gap-4">
      <h3 className="lg-bold">{subscriptionUsageLabels["title"]}</h3>

      {isTeamsAdmin && (
        <div className="flex items-center gap-3 subscription-usage-card-summaries-left">
          <i className="m-0 text-[22px] leading-none tracking-tight icon-users-round text-accent-base" />
          <div className="flex-1">
            <h4 className="base-md">
              {pluralize(subscriptionUsageLabels["seats-in-your-teams-plan"], {
                seats: purchasedSeats,
              })}
            </h4>
            <span className="sm-normal text-fg-muted">
              {availableSeats === 0
                ? subscriptionUsageLabels["all-seats-used"]
                : pluralize(subscriptionUsageLabels["seats-used"], {
                    used: Math.max(purchasedSeats - availableSeats, 0),
                    available: purchasedSeats,
                  })}
            </span>
          </div>
        </div>
      )}

      <div className="subscription-usage-card-summaries-left">
        {isFreePlan ? (
          <ul className="flex flex-col gap-4">
            <UsageListItem
              iconClassName="icon-toggle-right"
              title={subscriptionUsageLabels["pro-analysis"]}
              description={pluralize(subscriptionUsageLabels["credits-left"], {
                count: Math.max(proAnalysisCreditsRemaining, 0),
                maxCredits: PRO_ANALYSIS_CREDITS_PER_MONTH,
                creditRefreshDate,
              })}
            />
            <UsageListItem
              iconClassName="icon-table-2"
              title={subscriptionUsageLabels["study-snapshots"]}
              description={pluralize(subscriptionUsageLabels["credits-left"], {
                count: Math.max(snapshotCreditsRemaining, 0),
                maxCredits: SNAPSHOT_CREDITS_PER_MONTH,
                creditRefreshDate,
              })}
            />
            <UsageListItem
              iconClassName="icon-message-circle"
              title={subscriptionUsageLabels["ask-paper-messages"]}
              description={pluralize(subscriptionUsageLabels["credits-left"], {
                count: Math.max(askPaperCreditsRemaining, 0),
                maxCredits: ASK_PAPER_CREDITS_PER_MONTH,
                creditRefreshDate,
              })}
            />
            <UsageListItem
              iconClassName="icon-bookmark"
              title={subscriptionUsageLabels["bookmarks-and-lists"]}
              description={pluralize(
                subscriptionUsageLabels["bookmarks-and-lists-left"],
                {
                  bookmarks: Math.max(bookmarkItemsLeft, 0),
                  lists: Math.max(bookmarkListsLeft, 0),
                }
              )}
            />
          </ul>
        ) : (
          <ul className="flex flex-col gap-4">
            <UsageListItem
              iconClassName="icon-toggle-right"
              title={subscriptionUsageLabels["unlimited-pro-analysis"]}
            />
            <UsageListItem
              iconClassName="icon-table-2"
              title={subscriptionUsageLabels["unlimited-study-snapshots"]}
            />
            <UsageListItem
              iconClassName="icon-message-circle"
              title={subscriptionUsageLabels["unlimited-ask-paper-messages"]}
            />
            <UsageListItem
              iconClassName="icon-bookmark"
              title={subscriptionUsageLabels["unlimited-bookmarks-and-lists"]}
            />
          </ul>
        )}
      </div>

      {isTeamsAdmin && (
        <CoButton
          className="mt-2"
          href="/organization"
          label={subscriptionUsageLabels["manage-seats"]}
          size="sm"
          variant={ButtonVariant.Secondary}
        />
      )}
    </div>
  );
};

export default SubscriptionUsageCard;

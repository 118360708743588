import classNames from "classnames";
import { IconLoader } from "components/Icon";
import ProToggle from "components/ProToggle";
import { FilterButton } from "features/FilterDrawer";
import useFilterDrawer from "features/FilterDrawer/hooks/useFilterDrawer";
import { getAutocompleteQuerySuggestions } from "helpers/api";
import { getAutocompleteEndpointParams } from "helpers/endpointQueryParams";
import useIsScrolledDown from "hooks/useIsScrollledDown";
import useLabels from "hooks/useLabels";
import { useSearch } from "hooks/useSearch";
import { useAppSelector } from "hooks/useStore";
import throttle from "lodash/throttle";
import { useRouter } from "next/router";
import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Autosuggest from "react-autosuggest";
import TextArea, { TextareaProps } from "react-expanding-textarea";

const WAIT_BETWEEN_AUTOSUGGEST_REQUESTS_MSEC = 1000;
const ONE_LINER_MAX_TEXT_HEIGHT = 24;

/**
 * @component SearchInput
 * @description Component for search input.
 */
const SearchInput = () => {
  const router = useRouter();
  const [generalLabels] = useLabels("general");
  const [searchInputValue, setSearchInputValue] = useState<string>("");
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const isSearching = useAppSelector((state) => state.search.isSearching);
  const { handleSearch } = useSearch();
  const [focused, setFocused] = React.useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [selectedItemIndex, setSelectedItemIndex] = useState(-1);

  const isScrolledDown = useIsScrolledDown();

  const { openFilterDrawer } = useFilterDrawer();

  const isHomePage = router.pathname === "/" || router.pathname === "/search";

  const onSubmit = useCallback(
    (e: any) => {
      e.preventDefault();
      inputRef.current?.blur();
      handleSearch(searchInputValue);
    },
    [searchInputValue, handleSearch, inputRef]
  );

  const onChangeSearchInput = useCallback((e: any, data?: any) => {
    setSearchInputValue(e.target.value);
  }, []);

  const onSuggestionSelected = useCallback(
    (_: any, { suggestion }: { suggestion: string }) => {
      inputRef.current?.blur();
      handleSearch(suggestion);
      setSearchInputValue(suggestion);
      setSelectedItemIndex(-1);
    },
    [handleSearch]
  );

  useEffect(() => {
    if (router?.query?.q) {
      setSearchInputValue(router?.query?.q as string);
    }
  }, [router?.query?.q]);

  useEffect(() => {
    if (isHomePage) {
      setFocused(true);
      inputRef.current?.focus();
    }
  }, [isHomePage]);

  const onSuggestionsRequested = useMemo(() => {
    const loadSuggestions = async ({
      value,
    }: {
      value: string;
    }): Promise<void> => {
      let suggestions: string[] = [];
      try {
        const params = getAutocompleteEndpointParams(router.query);
        const result = await getAutocompleteQuerySuggestions(value, params);
        suggestions = result.queries;
      } catch (error) {
        // do nothing
      }
      setSuggestions(suggestions);
    };
    return throttle(loadSuggestions, WAIT_BETWEEN_AUTOSUGGEST_REQUESTS_MSEC);
  }, [router?.query]);

  const onSuggestionsCleared = useCallback(() => {
    setSuggestions([]);
  }, []);

  const handleTextAreaKeydown = (
    event: React.KeyboardEvent<HTMLTextAreaElement>
  ) => {
    if (event.key === "ArrowDown") {
      event.preventDefault();
      setSelectedItemIndex((prevIndex) => (prevIndex + 1) % suggestions.length);
    } else if (event.key === "ArrowUp") {
      event.preventDefault();
      setSelectedItemIndex(
        (prevIndex) => (prevIndex - 1 + suggestions.length) % suggestions.length
      );
    }
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      if (selectedItemIndex === -1) {
        // Enter pressed on input field
        handleSearch(inputRef.current?.value as string);
        return false;
      }
    }
  };

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  const enterKeyHintStaticallyTypedToPassTypeCheck: "go" = "go";
  const inputProps = {
    value: searchInputValue,
    placeholder: generalLabels["search-input-placeholder"],
    onChange: onChangeSearchInput,
    ref: inputRef,
    type: "text",
    enterKeyHint: enterKeyHintStaticallyTypedToPassTypeCheck,
    "data-testid": "search-input",
    onFocus: handleFocus,
    onBlur: handleBlur,
    onKeyDown: handleTextAreaKeydown,
  };

  const handleOpenFilterDrawer = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    openFilterDrawer();
  };

  const renderSearchInput = (inputProps: TextareaProps) => {
    const inputHeight = inputRef.current?.offsetHeight as number;
    const isLongQuery = inputHeight > ONE_LINER_MAX_TEXT_HEIGHT;

    const { key, ...theRest } = inputProps as TextareaProps & { key: string };

    const isOutlineExpanded = !isHomePage && isScrolledDown;

    return (
      <div
        data-test-id="search-input-box"
        id="search-input-box"
        className={classNames(
          "w-full px-3 md:px-4 bg-white rounded-2xl transition",
          "border border-base",
          "outline transition-[outline-width] duration-150 ease-out",
          isHomePage
            ? "pt-4 pb-2 hover:border-border-emphasis"
            : "py-1.5 hover:border-accent-base outline-border-base",
          isHomePage && focused
            ? "outline-offset-2 outline-2 outline-accent-base border-border-emphasis"
            : "",
          !isHomePage && isOutlineExpanded ? "outline-8" : "outline-0",
          "co-bottom-shadow"
        )}
      >
        <div className={`flex ${isLongQuery ? "items-start" : "items-center"}`}>
          <TextArea
            key={key}
            {...theRest}
            className="block w-full overflow-hidden outline-none resize-none"
          />
          {!isHomePage && (
            <div className={`grid ${!isHomePage ? "grid-cols-2" : ""}`}>
              <div className={`flex items-center ml-1`}>
                {searchInputValue && (
                  <button
                    data-testid="close-input"
                    onClick={() => setSearchInputValue("")}
                    type="button"
                    className="inline-flex items-center justify-center w-10 h-10 rounded-full hover:bg-bgr-faint"
                  >
                    <i className="text-xl icon-x" />
                  </button>
                )}
              </div>
              <button
                data-testid="search-button"
                id="search-button"
                className="inline-flex justify-center items-center w-10 h-10 rounded-full ml-[6px] hover:bg-bgr-faint"
                onClick={onSubmit}
              >
                {isSearching ? (
                  <div className="w-6 h-6 -mr-3">
                    <IconLoader />
                  </div>
                ) : (
                  <i className="text-xl icon-search" />
                )}
              </button>
            </div>
          )}
        </div>
        {isHomePage ? (
          <div className="flex justify-between pt-4">
            <div className="flex items-center gap-2">
              <span className="flex items-center">
                <ProToggle />
              </span>
              <FilterButton />
            </div>
            <button
              data-testid="search-button"
              id="search-button"
              className="flex items-center w-10 h-10"
              onClick={onSubmit}
            >
              {isSearching ? (
                <div className="w-5 h-5 -mr-3">
                  <IconLoader />
                </div>
              ) : (
                <span
                  className={classNames(
                    "w-10 h-10 inline-flex items-center justify-center rounded-full bg-accent-base",
                    searchInputValue ? "bg-opacity-100" : "bg-opacity-60"
                  )}
                >
                  <i className="text-xl text-white icon-arrow-right" />
                </span>
              )}
            </button>
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <>
      <form
        data-testid="search-input-form"
        className="relative mx-auto leading"
      >
        <Autosuggest
          suggestions={inputProps.value ? suggestions : []}
          onSuggestionsFetchRequested={onSuggestionsRequested}
          onSuggestionsClearRequested={onSuggestionsCleared}
          getSuggestionValue={(suggestion: string) => suggestion}
          renderSuggestion={(suggestion: string) => (
            <span className="px-0">{suggestion}</span>
          )}
          onSuggestionSelected={onSuggestionSelected}
          shouldRenderSuggestions={(value, reason) => true}
          inputProps={inputProps}
          renderInputComponent={renderSearchInput}
          renderSuggestionsContainer={({ containerProps, children }) => (
            <div
              {...containerProps}
              key={containerProps.key}
              className={`${containerProps.className} ${
                isHomePage ? "border-t border-t-border-base" : "border-t-0"
              }`}
            >
              {children}
            </div>
          )}
        />
      </form>
    </>
  );
};

export default SearchInput;

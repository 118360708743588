import { influenceLabels, QuartileBar } from "components/QuartileBar";
import useLabels from "hooks/useLabels";
import React from "react";

/**
 * @component SjrQuartilesTooltip
 * @description Tooltip for case study
 * @example
 * return (
 *   <SjrQuartilesTooltip />
 * )
 */
const SjrQuartilesTooltip = () => {
  const [tooltipsLabels, quartileLabels] = useLabels(
    "tooltips.sjr-quartiles",
    "quartile"
  );

  return (
    <div className="flex flex-col gap-3" data-testid="tooltip-sjr-quartiles">
      <p className="sm-bold">{tooltipsLabels["title"]}</p>
      <p
        className="sm-normal"
        dangerouslySetInnerHTML={{ __html: tooltipsLabels["content"] }}
      ></p>
      <p className="tiny-normal text-fg-muted">{tooltipsLabels["footer"]}</p>
      <div className="border-t border-t-border-base pt-3">
        {[1, 2, 3, 4].map((item) => (
          <div key={item} className="flex justify-between items-center mb-1">
            <QuartileBar quartile={item} />
            <p className="tiny-normal text-fg-faint">
              Q{item}, {quartileLabels[influenceLabels[`Q${item}`]]}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SjrQuartilesTooltip;

import COLORS from "constants/colors.json";
import { MeterFilterParams } from "types/MeterFilterParams";
import { YesNoType } from "types/YesNoType";
import { CategoryAggregations, MeterCategories } from "./types";

export const orderedMeterCategories: MeterCategories[] = [
  "yes",
  "possibly",
  "mixed",
  "no",
];

export const categoriesToColors: Record<MeterCategories, string> = {
  yes: COLORS.green.base,
  no: COLORS.red.base,
  possibly: COLORS.yellow.base,
  mixed: COLORS.orange.base,
};

export const orderedCategoryAggregations: CategoryAggregations[] = [
  "recency",
  "tier_one_studies",
  "journal_score",
  "citations",
];

export const categoryAggregationsToIcons: Record<CategoryAggregations, string> =
  {
    recency: "icon-calendar",
    tier_one_studies: "icon-flask-conical",
    journal_score: "icon-book-open",
    citations: "icon-quote",
  };

export const defaultMeterFilter: MeterFilterParams = {
  no: true,
  possibly: true,
  yes: true,
  unknown: true,
  mixed: true,
};

export function getIsMeterFilterActive(
  meterFilter: MeterFilterParams
): boolean {
  return !(
    meterFilter.yes &&
    meterFilter.possibly &&
    meterFilter.no &&
    meterFilter.mixed
  );
}

export function removedByYesNoMeterFilter(
  meterFilter: MeterFilterParams,
  yesNoType?: YesNoType
): boolean {
  if (!meterFilter.yes && yesNoType === "YES") {
    return false;
  }
  if (!meterFilter.no && yesNoType === "NO") {
    return false;
  }
  if (!meterFilter.possibly && yesNoType === "POSSIBLY") {
    return false;
  }
  if (!meterFilter.mixed && yesNoType === "MIXED") {
    return false;
  }
  if (yesNoType === "UNKNOWN") {
    return false;
  }
  if (!yesNoType) {
    return false;
  }
  return true;
}

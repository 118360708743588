import { IconProps } from ".";

/**
 * @component VenetianMask
 * @description Component for showing a venetian mask SVG icon. Used instead of an <img> tag so that
 * we are able to change the color with css, e.g. on active. Use in conjunction with the <Icon>
 * component.
 */
function VenetianMask({ className }: IconProps) {
  return (
    <svg
      aria-label="Venetian Mask"
      xmlns="http://www.w3.org/2000/svg"
      className={` ${className} ${className.includes("h-") ? "" : "w-5 h-5"}`}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M1.66663 9.99992C1.66663 11.105 2.10561 12.1648 2.88701 12.9462C3.66842 13.7276 4.72822 14.1666 5.83329 14.1666C7.37305 14.2239 8.84541 14.8129 9.99996 15.8333C11.1545 14.8129 12.6269 14.2239 14.1666 14.1666C15.2717 14.1666 16.3315 13.7276 17.1129 12.9462C17.8943 12.1648 18.3333 11.105 18.3333 9.99992V5.83325H14.1666C12.6269 5.89061 11.1545 6.47955 9.99996 7.49992C8.84541 6.47955 7.37305 5.89061 5.83329 5.83325H1.66663V9.99992Z"
        className="stroke-current"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.99996 9.16659C6.24996 9.16659 7.49996 9.58325 7.49996 10.8333C5.83329 10.8333 4.99996 10.8333 4.99996 9.16659Z"
        className="stroke-current"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 9.16659C13.75 9.16659 12.5 9.58325 12.5 10.8333C14.1666 10.8333 15 10.8333 15 9.16659Z"
        className="stroke-current"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default VenetianMask;

import { ButtonVariant, CoButton } from "components/CoButton";
import useLabels from "hooks/useLabels";
import React from "react";

type FreeSubscriptionUpgradeCardProps = {
  price?: string;
  onClick: () => void;
};

function FreeSubscriptionUpgradeCard({
  price,
  onClick,
}: FreeSubscriptionUpgradeCardProps) {
  const [upgradePlanLabels] = useLabels("upgrade-plan-card");

  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-col flex-1 gap-4">
        <h3 className="lg-bold">{upgradePlanLabels["title-free"]}</h3>
        <span className="text-fg-muted">
          {upgradePlanLabels["subtitle-free"]}
        </span>
        {!!price && (
          <div>
            <span className="tiny-normal text-fg-muted">
              {upgradePlanLabels["as-low-as"]}
            </span>
            <div>
              <span
                data-testid="upgrade-plan-card-amount"
                className="mt-1 mr-1 text-xl h2-md"
              >
                {price}
              </span>
              <span
                data-testid="current-plan-card-interval"
                className="tiny-normal text-fg-muted"
              >
                {upgradePlanLabels["per-month"]}
              </span>
            </div>
          </div>
        )}
      </div>
      <div className="flex gap-1 mt-6">
        <CoButton
          className="flex-1"
          label={upgradePlanLabels["upgrade-to-premium"]}
          onClick={onClick}
          size="sm"
          variant={ButtonVariant.Secondary}
        />
        <CoButton
          href="/pricing"
          label={upgradePlanLabels["see-plans"]}
          size="sm"
          variant={ButtonVariant.Flat}
        />
      </div>
    </div>
  );
}

export default FreeSubscriptionUpgradeCard;

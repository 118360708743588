import { useCallback } from "react";
import { toogleSidebarOpen } from "store/slices/setting";
import { useAppDispatch, useAppSelector } from "./useStore";

export const useSidebar = () => {
  const dispatch = useAppDispatch();
  const isSidebarOpen = useAppSelector((state) => state.setting.isSidebarOpen);

  const toggleSidebar = useCallback(() => {
    /**
     * This is the css based solution to hide/show nav as it enables
     * nav column(sidebar) sitting in the grid and not floating over
     * the main column while maintaining body scroll. There are
     * many advantages of having content scroll over the window rather that it's own
     * container including relying on the default nextjs app router scroll behaviour
     * (The default behavior of the Next.js App Router is to scroll to the top of a
     * new route or to maintain the scroll position
     * for backwards and forwards navigation.)
     */
    const body = document.body;
    body.classList.toggle("sidebar-expanded");
    dispatch(toogleSidebarOpen());
  }, [dispatch]);

  return { toggleSidebar, isSidebarOpen };
};

import {
  ASK_PAPER_CREDITS_PER_MONTH,
  PRO_ANALYSIS_CREDITS_PER_MONTH,
  SNAPSHOT_CREDITS_PER_MONTH,
} from "constants/config";
import { AskPaperThreadMessage } from "helpers/api";
import {
  calculateAskPaperMessageCountForPaper,
  getCreditRefreshDate,
  getSubscriptionType,
  hashQuery,
  isSubscriptionPremium,
  ISubscriptionType,
} from "helpers/subscription";
import { useAppDispatch, useAppSelector } from "hooks/useStore";
import { useRouter } from "next/router";
import { useCallback, useMemo } from "react";
import { CreditedFeature } from "store/slices/subscription";
import {
  appendConsensusSnapshotUsedQuery,
  appendProAnalysisUsedQuery,
  appendStudyDetailsUsedPaperId,
  updateAskPaperMessageCountForPaper,
} from "store/slices/user";

const useCredits = (threadedSearchId?: string) => {
  const router = useRouter();

  const searchId = useMemo(() => {
    if (threadedSearchId) return threadedSearchId;
    if (router.query.q === undefined) return undefined;
    return hashQuery(router.query);
  }, [router.query, threadedSearchId]);

  const dispatch = useAppDispatch();
  const subscriptionUsageData = useAppSelector((state) => state.user.usage);
  const subscription = useAppSelector(
    (state) => state.subscription.subscription
  );

  const isSubscriptionLoaded = subscriptionUsageData.isSet;
  const isPremium = isSubscriptionPremium(subscription);
  const subscriptionType = getSubscriptionType(subscription);

  const spendCreditsOnProAnalysis = useCallback(() => {
    if (searchId === undefined)
      throw new Error("Error spending credit: query not set");
    dispatch(appendProAnalysisUsedQuery(searchId));
  }, [dispatch, searchId]);

  const spendCreditsOnConsensusMeterSnapshot = useCallback(() => {
    if (searchId === undefined)
      throw new Error("Error spending credit: query not set");
    dispatch(appendConsensusSnapshotUsedQuery(searchId));
  }, [dispatch, searchId]);

  const spendCreditsOnAskPaper = useCallback(
    (paperId: string, messages: AskPaperThreadMessage[]) => {
      const previousMessagesForPaper = calculateAskPaperMessageCountForPaper(
        messages,
        subscriptionUsageData.userLastResetDate
      );
      // The function is triggered upon receiving a new message, which doesn't exist in the list.
      // So, add 1 to the usage data to account for it.
      dispatch(
        updateAskPaperMessageCountForPaper({
          [paperId]: previousMessagesForPaper + 1,
        })
      );
    },
    [dispatch, subscriptionUsageData.userLastResetDate]
  );

  const spendCreditOnStudyDetails = useCallback(
    (paperId: string) => {
      dispatch(appendStudyDetailsUsedPaperId(paperId));
    },
    [dispatch]
  );

  const proAnalysisWasPreviouslyRunOnQuery = useMemo(() => {
    return (
      searchId !== undefined &&
      subscriptionUsageData.proAnalysisUsedQueries.includes(searchId)
    );
  }, [subscriptionUsageData.proAnalysisUsedQueries, searchId]);

  const consensusSnapshotWasPreviouslyRunOnQuery = useMemo(() => {
    return (
      searchId !== undefined &&
      subscriptionUsageData.consensusSnapshotUsedQueries.includes(searchId)
    );
  }, [subscriptionUsageData.consensusSnapshotUsedQueries, searchId]);

  const wasStudySnapshotPreviouslyRunOnPaperId = useCallback(
    (paperId: string) => {
      return subscriptionUsageData.studySnapshotUsedPaperIds.includes(paperId);
    },
    [subscriptionUsageData.studySnapshotUsedPaperIds]
  );

  const proAnalysisCreditsRemaining = useMemo(() => {
    if (!subscriptionUsageData.isSet) return 0;

    return (
      PRO_ANALYSIS_CREDITS_PER_MONTH -
      subscriptionUsageData.proAnalysisUsedQueries.length
    );
  }, [subscriptionUsageData]);

  const isOutOfProAnalysisCredits = useMemo(() => {
    return proAnalysisCreditsRemaining <= 0;
  }, [proAnalysisCreditsRemaining]);

  // Snapshot credits combine the number of study snapshots and consensus snapshots
  const snapshotCreditsRemaining = useMemo(() => {
    if (!subscriptionUsageData.isSet) return 0;

    return (
      SNAPSHOT_CREDITS_PER_MONTH -
      subscriptionUsageData.studySnapshotUsedPaperIds.length -
      subscriptionUsageData.consensusSnapshotUsedQueries.length
    );
  }, [subscriptionUsageData]);

  const askPaperCreditsRemaining = useMemo(() => {
    if (!subscriptionUsageData.isSet) return 0;

    // Sum up all the values in the askPaperMessagesPerPaperCount map
    const askPaperCreditsUsed = Object.values(
      subscriptionUsageData.askPaperMessagesPerPaperCount
    ).reduce((total, count) => total + count, 0);

    return ASK_PAPER_CREDITS_PER_MONTH - askPaperCreditsUsed;
  }, [subscriptionUsageData]);

  const creditRefreshDate = getCreditRefreshDate(subscriptionUsageData);

  let subscriptionImgSrc = "";
  switch (subscriptionType) {
    case ISubscriptionType.Premium:
      subscriptionImgSrc = "/icons/premium.svg";
      break;
    case ISubscriptionType.Enterprise:
      subscriptionImgSrc = "/icons/enterprise.svg";
      break;
    case ISubscriptionType.Team:
      subscriptionImgSrc = "/icons/team.svg";
      break;
  }

  const featureToCreditsRemainingMap: Record<
    | CreditedFeature.ASK_PAPER
    | CreditedFeature.STUDY_SNAPSHOT
    | CreditedFeature.PRO_ANALYSIS
    | CreditedFeature.CONSENSUS_SNAPSHOT,
    number
  > = {
    [CreditedFeature.ASK_PAPER]: askPaperCreditsRemaining,
    [CreditedFeature.STUDY_SNAPSHOT]: snapshotCreditsRemaining,
    [CreditedFeature.PRO_ANALYSIS]: proAnalysisCreditsRemaining,
    [CreditedFeature.CONSENSUS_SNAPSHOT]: snapshotCreditsRemaining,
  };

  return {
    askPaperCreditsRemaining,
    creditRefreshDate,
    isPremium,
    isSubscriptionLoaded,
    isUsageSet: subscriptionUsageData.isSet,
    proAnalysisCreditsRemaining,
    isOutOfProAnalysisCredits,
    proAnalysisWasPreviouslyRunOnQuery,
    spendCreditOnStudyDetails,
    spendCreditsOnAskPaper,
    spendCreditsOnProAnalysis,
    spendCreditsOnConsensusMeterSnapshot,
    consensusSnapshotWasPreviouslyRunOnQuery,
    snapshotCreditsRemaining,
    wasStudySnapshotPreviouslyRunOnPaperId,
    subscriptionImgSrc,
    subscriptionType,
    featureToCreditsRemainingMap,
  };
};

export default useCredits;

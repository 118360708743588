import ArrowLeft from "components/Icon/local/ArrowLeftIcon";
import Copied from "components/Icon/local/CopiedIcon";
import CopyLink from "components/Icon/local/CopyLinkIcon";
import CopyText from "components/Icon/local/CopyTextIcon";
import Filter from "components/Icon/local/FilterIcon";
import SearchHistory from "components/Icon/local/SearchHistoryIcon";
import Share from "components/Icon/local/ShareIcon";
import Twitter from "components/Icon/local/TwitterIcon";
import VenetianMask from "./VenetianMaskIcon";

export type IconProps = {
  className: string;
};

export {
  ArrowLeft,
  Copied,
  CopyLink,
  CopyText,
  Filter,
  SearchHistory,
  Share,
  Twitter,
  VenetianMask,
};

type PropsType = {
  quartile: number | null;
  w?: string;
};

export const colorMap = [
  "", // Index starts at 1
  "bg-green-base",
  "bg-yellow-base",
  "bg-yellow-base",
  "bg-red-base",
];

export const influenceLabels: { [key: string]: string } = {
  Q1: "most-influential",
  Q2: "middle-influential",
  Q3: "middle-influential",
  Q4: "least-influential",
  UNKNOWN: "unknown-influential",
};

export const QuartileBar = ({ quartile, w = "w-1/4" }: PropsType) => {
  return (
    <div className="flex w-full gap-x-1">
      {[4, 3, 2, 1].map((q) => (
        <div
          key={q}
          role="img"
          aria-label={`Quartile ${q} bubble`}
          className={`${w} h-2 rounded-full max-w-[40px] ${
            quartile && quartile <= q ? colorMap[quartile] : "bg-bgr-subtle"
          }`}
        />
      ))}
    </div>
  );
};

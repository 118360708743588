import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PricingPlanResponse } from "helpers/api";
import { IStripeInvoiceOrUpcomingInvoice } from "helpers/stripe";
import { ISubscriptionData } from "helpers/subscription";

export interface SubscriptionState {
  subscription: ISubscriptionData;
  upcomingInvoice: IStripeInvoiceOrUpcomingInvoice | null;
  isSubscriptionLoaded: boolean;
  upgradeToPremiumModalFeature?: CreditedFeature;
  disableProToggleOnPremiumModalClose?: boolean;
  products: any[];
  pricingText: PricingPlanResponse;
}

export enum CreditedFeature {
  ASK_PAPER = "ASK_PAPER",
  BOOKMARKS = "BOOKMARKS",
  PRO_ANALYSIS = "PRO_ANALYSIS",
  STUDY_SNAPSHOT = "STUDY_SNAPSHOT",
  CONSENSUS_SNAPSHOT = "CONSENSUS_SNAPSHOT",
}

const initialStateTypePricingText = {
  features: [],
  monthly_pricing: "",
  yearly_pricing: "",
  monthly: {
    unit_amount: 0,
    description: "",
  },
  yearly: {
    unit_amount: 0,
    description: "",
  },
};

export const initialState: SubscriptionState = {
  subscription: { user: null, org: null },
  isSubscriptionLoaded: false,
  upgradeToPremiumModalFeature: undefined,
  products: [],
  pricingText: {
    enterprise: initialStateTypePricingText,
    free: initialStateTypePricingText,
    premium: initialStateTypePricingText,
    team: initialStateTypePricingText,
  },
  upcomingInvoice: null,
} as SubscriptionState;

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    setSubscription(state: SubscriptionState, { payload }: PayloadAction<any>) {
      state.subscription = payload;
    },
    setIsSubscriptionLoaded(
      state: SubscriptionState,
      { payload }: PayloadAction<boolean>
    ) {
      state.isSubscriptionLoaded = payload;
    },
    setPremiumModalFeature(
      state: SubscriptionState,
      { payload }: PayloadAction<CreditedFeature>
    ) {
      state.upgradeToPremiumModalFeature = payload;
    },
    setProducts(state: SubscriptionState, { payload }: PayloadAction<any[]>) {
      state.products = payload;
    },
    setPricingText(
      state: SubscriptionState,
      { payload }: PayloadAction<PricingPlanResponse>
    ) {
      state.pricingText = payload;
    },
    setUpcomingInvoice(state: SubscriptionState, { payload }) {
      state.upcomingInvoice = payload;
    },
    setDisableProToggleOnPremiumModalClose(
      state: SubscriptionState,
      { payload }: PayloadAction<boolean>
    ) {
      state.disableProToggleOnPremiumModalClose = payload;
    },
  },
});

export const {
  setIsSubscriptionLoaded,
  setPremiumModalFeature,
  setPricingText,
  setProducts,
  setSubscription,
  setUpcomingInvoice,
  setDisableProToggleOnPremiumModalClose,
} = subscriptionSlice.actions;

export default subscriptionSlice.reducer;

import classNames from "classnames";
import { useSidebar } from "hooks/useSidebar";
import { useAppSelector } from "hooks/useStore";

// Used to gray out the main content when the sidebar is open on mobile screens.
export const SidebarOverlay = () => {
  const is898WideScreen = useAppSelector(
    (state) => state.setting.is898WideScreen
  );

  const { toggleSidebar, isSidebarOpen } = useSidebar();

  const closeSidebar = () => {
    toggleSidebar();
  };

  return (
    <div
      className={classNames(
        "absolute top-0 right-0 z-30 opacity-0 bg-[rgba(0,0,0,0.5)]",
        {
          "w-full h-full opacity-100": isSidebarOpen && is898WideScreen,
        }
      )}
      onClick={closeSidebar}
      style={{ transition: "opacity 0.5s ease" }}
    ></div>
  );
};

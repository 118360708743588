import axios, { AxiosRequestHeaders, AxiosResponse } from "axios";
import { PER_PAGE } from "constants/config";
import path from "constants/path";
import { IncomingHttpHeaders } from "http";
import { Redirect } from "next";
import requestIp from "request-ip";
import { YesNoType } from "types/YesNoType";
import api, { BASE_URL, isServer } from "./apiInstance";
import {
  IBookmarkCreateItemData,
  IBookmarkCreateItemsResponse,
  IBookmarkCreateListResponse,
  IBookmarkItemsResponse,
  IBookmarkListResponse,
  IBookmarkUpdateListResponse,
} from "./bookmark";
import { IClerkPublicMetaData } from "./clerk";
import { IEmailRequest } from "./mailchimp";
import { FilterParams } from "./pageUrl";
import { rewriteSearchQueryParamsForServerEndpoint } from "./search";
import { TrackEventData } from "./services/events/events";
import {
  IStripeInvoiceOrUpcomingInvoice,
  IStripeMeterEventSummary,
  PaymentDetailResponse,
} from "./stripe";

const REQUEST_TIMEOUT_OPENAI_ENDPOINTS = 400000;

interface RequestHeaderData {
  authToken?: string | null;
  ipAddress?: string;
  headers?: IncomingHttpHeaders;
}

function createRequestHeaders(data?: RequestHeaderData): AxiosRequestHeaders {
  const headers: AxiosRequestHeaders = {};
  if (!data) return headers;

  if (data.authToken) {
    headers["Authorization"] = `Bearer ${data.authToken}`;
  }
  if (data.ipAddress) {
    headers["X-Forwarded-For"] = data.ipAddress;
  }
  if (data.headers) {
    const xApiKey = data.headers["x-api-key"];
    if (xApiKey) {
      headers["X-Api-Key"] = xApiKey.toString();
    }
  }
  return headers;
}

export interface DisputedBadge {
  reason: string;
  url: string;
}

export enum StudyType {
  ANIMAL_STUDY = "animal",
  CASE_STUDY = "case report",
  IN_VITRO_TRIAL = "non-rct in vitro",
  LITERATURE_REVIEW = "literature review",
  META_ANALYSIS = "meta-analysis",
  NON_RCT_TRIAL = "non-rct experimental",
  OBSERVATIONAL_STUDY = "non-rct observational study",
  RCT = "rct",
  SYSTEMATIC_REVIEW = "systematic review",
}

export interface Badges {
  very_rigorous_journal: boolean;
  rigorous_journal: boolean;
  highly_cited_paper: boolean;
  study_type: StudyType | undefined;
  sample_size: number | undefined;
  study_count: number | undefined;
  animal_trial: boolean | undefined;
  large_human_trial: boolean | undefined;
  disputed: DisputedBadge | undefined;
  enhanced: boolean;
  preprint?: boolean;
}

export interface PaperDetails {
  abstract?: string;
  abstract_takeaway: string;
  authors: string[];
  badges?: Badges;
  citation_count: number;
  doi: string;
  id: string;
  influential_citation_count?: number;
  publish_date: string;
  journal: {
    title: string;
    scimago_quartile: number | undefined;
  };
  open_access_pdf_url?: string;
  pages: string;
  provider_url: string;
  title: string;
  volume: string;
  url_slug: string | null;
  year: number;
}

export type PaperDetailsResponse = PaperDetails;

/** Returns details on the given paper id. */
export async function getPaperDetails(
  id: string,
  headerData?: RequestHeaderData,
  queryParams?: string
): Promise<PaperDetailsResponse> {
  let endpoint = `/papers/details/${id}`;
  if (queryParams) {
    endpoint = `${endpoint}?${queryParams}`;
  }

  const { data } = await api.get(endpoint, {
    headers: createRequestHeaders(headerData),
  });

  return data;
}

export function convertPaperDetailsToPaper(paper: PaperDetails): Paper {
  return {
    authors: paper.authors,
    badges: paper.badges ?? {
      very_rigorous_journal: false,
      rigorous_journal: false,
      highly_cited_paper: false,
      study_type: null,
      sample_size: null,
      study_count: null,
      animal_trial: null,
      large_human_trial: null,
      disputed: null,
      enhanced: false,
    },
    citation_count: paper.citation_count,
    has_valid_chat_pdf: false,
    display_text: paper.abstract_takeaway,
    doc_id: "",
    doi: paper.doi,
    journal: paper.journal.title,
    pages: paper.pages,
    paper_id: paper.id,
    primary_author: paper.authors[0],
    title: paper.title,
    url_slug: paper.url_slug ?? "",
    volume: paper.volume,
    year: paper.year,
  };
}
export function getPaperPdfUrl(id: string): string {
  return `/api/papers/pdf/${id}`;
}

/** Download the PDF located at paper.open_access_pdf_url. */
export async function getPaperPdf(
  id: string,
  headerData?: RequestHeaderData
): Promise<AxiosResponse<Buffer>> {
  let endpoint = `/papers/pdf/${id}`;

  const response = await api.get(endpoint, {
    headers: createRequestHeaders(headerData),
    responseType: "arraybuffer",
  });

  return response;
}

export type RelatedPapersResponse = {
  papers: PaperDetails[];
  nextOffset: number;
  isEnd: boolean;
};

export enum RelatedPapersType {
  CITATIONS = "cites",
  REFERENCES = "refs",
}

/** Returns related papers for the given paper id. */
export async function getRelatedPapers(
  papersType: RelatedPapersType,
  id: string,
  offset: number,
  headerData?: RequestHeaderData
): Promise<RelatedPapersResponse> {
  const params = new URLSearchParams();

  // Add parameters if they are defined
  params.append("type", papersType);
  params.append("offset", offset.toString());

  let endpoint = `/papers/related/${id}`;
  if (params.toString()) {
    endpoint += `?${params}`;
  }

  const { data } = await api.get(endpoint, {
    headers: createRequestHeaders(headerData),
  });

  return data;
}

export interface PapersListRequest {
  paper_ids: string[];
  options: PapersListRequestOptions;
}

export interface PapersListResponse {
  paperDetailsListByPaperId: { [key: string]: PaperDetails };
}

export interface PapersListRequestOptions {
  include_abstract?: boolean;
  include_journal?: boolean;
  include_badges?: boolean;
}

/** Returns papers list on the given paper ids. */
export async function getPapersList(
  body: PapersListRequest,
  headerData?: RequestHeaderData
): Promise<PapersListResponse> {
  let endpoint = `/papers/details/`;

  const { data } = await api.post(endpoint, body, {
    headers: createRequestHeaders(headerData),
  });

  return data;
}

export interface ClaimDetailsResponse {
  paperIdForRedirect: string | undefined;
}

/** Returns details on the given claim id. */
export async function getClaimDetails(
  id: string,
  headerData?: RequestHeaderData,
  queryParams?: string
): Promise<ClaimDetailsResponse> {
  let endpoint = `/claims/details/${id}`;
  if (queryParams) {
    endpoint = `${endpoint}?${queryParams}`;
  }

  const { data } = await api.get(endpoint, {
    headers: createRequestHeaders(headerData),
  });

  return data;
}

export interface Paper {
  authors: string[];
  badges: any;
  citation_count: number;
  debug_info?: any;
  display_text: string;
  doc_id: string;
  doi: string;
  has_valid_chat_pdf: boolean;
  journal: string;
  originalIndex?: number; // This is client based and it's used to keep hold of paper position from the backend before it's client filtered
  pages: string;
  paper_id: string;
  primary_author: string;
  title: string;
  url_slug: string;
  volume: string;
  year: number;
}

export interface QueryFeatures {
  is_offensive?: boolean;
  is_yes_no_question?: boolean;
  is_translated?: boolean;
  is_adjusted?: boolean;
  query_class?: number;
  is_long_query?: boolean;
  fields_of_study?: string[];
  query_specificity?: number;
  query_specificity_score?: number;
  typo_prob?: number;
  authors?: string[];
  phrases?: string[];
  doi?: string;
  years?: number[];
  bool_query?: string;
}

export interface SynthesisFeatures {
  can_pro_analysis_succeed: boolean;
  not_enough_results_for_pro_analysis: boolean;
}

export interface PaperSearchResponse {
  search_id: string;
  papers: Paper[];
  is_end: boolean;
  query_rewrite?: string;
  isExtractedAnswers?: boolean;
  num_top_results?: number;
  num_relevant_results?: number;
  query_features: QueryFeatures;
  synthesis_features?: SynthesisFeatures;
  filters?: FilterParams;
}

/** Returns results from a user's paper search. */
export let searchController = new AbortController();
export async function getNewPaperSearch(
  query: string,
  pageOffset?: number,
  searchTestingQueryParams?: string,
  headerData?: RequestHeaderData
): Promise<PaperSearchResponse> {
  searchController = new AbortController();
  const page = pageOffset || 0;
  let endpoint = `/paper_search/?query=${encodeURIComponent(
    query
  )}&page=${page}&size=${PER_PAGE}`;

  if (searchTestingQueryParams) {
    endpoint = `${endpoint}${
      isServer()
        ? rewriteSearchQueryParamsForServerEndpoint(searchTestingQueryParams)
        : searchTestingQueryParams
    }`;
  }

  const { data } = await api.get(endpoint, {
    signal: searchController.signal,
    headers: createRequestHeaders(headerData),
  });
  return data;
}

/** Returns results from a user's paper search. */
export async function getPaperSearch(
  searchId: number,
  pageOffset?: number,
  headerData?: RequestHeaderData
): Promise<PaperSearchResponse> {
  const params = new URLSearchParams({
    page: String(pageOffset || 0),
    size: String(PER_PAGE),
  });

  const endpoint = `/paper_search/${searchId}?${params.toString()}`;

  const { data } = await api.get(endpoint, {
    headers: createRequestHeaders(headerData),
  });

  return data;
}

export interface ExtractedAnswersResponse {
  idToAnswers: { [id: string]: string };
}

/**
 * Get the extracted answers for a non-threaded search.
 * To be used for thread interactions where search results are
 * stored in the cache and not the db.
 */
export async function getExtractedAnswersResponse(
  searchId: string,
  queryParams?: string,
  headerData?: RequestHeaderData
): Promise<ExtractedAnswersResponse> {
  let endpoint = `/extracted_answers/?search_id=${searchId}`;
  if (queryParams) {
    endpoint = `${endpoint}&${queryParams}`;
  }
  const { data } = await api.get(endpoint, {
    headers: createRequestHeaders(headerData),
    timeout: REQUEST_TIMEOUT_OPENAI_ENDPOINTS,
  });
  return data;
}

/**
 * Returns extracted answers from a user's paper search.
 * To be used for thread interactions where search results are
 * stored in the db.
 */
export async function getPaperSearchExtractedAnswers(
  searchId: number,
  queryParams?: string,
  headerData?: RequestHeaderData
): Promise<ExtractedAnswersResponse> {
  let endpoint = `/paper_search/${searchId}/extracted_answers`;
  if (queryParams) {
    endpoint = `${endpoint}?${queryParams}`;
  }
  const { data } = await api.get(endpoint, {
    headers: createRequestHeaders(headerData),
    timeout: REQUEST_TIMEOUT_OPENAI_ENDPOINTS,
  });
  return data;
}

export interface ThreadInteractionResponse {
  id: number;
  user_message: string;
  search_id?: number;
  analysis?: string;
  results?: ThreadInteractionResultsResponse;
  is_pro_enabled: boolean;
  created_at: string;
  not_enough_relevant_results?: boolean;
  num_results_analyzed?: number;
}

export interface ThreadResponse {
  interactions: Array<ThreadInteractionResponse>;
  is_shared: boolean;
  thread_id: number;
  title: string;
}

export async function getThread(
  threadId: number,
  headerData?: RequestHeaderData
): Promise<ThreadResponse> {
  let endpoint = `/threads/${threadId}`;

  const { data } = await api.get(endpoint, {
    headers: createRequestHeaders(headerData),
  });
  return data;
}

export interface ThreadInteractionResultsResponse extends PaperSearchResponse {}

const OPENAI_MODELS = [
  "gpt-4.5-preview",
  "gpt-4o",
  "gpt-4o-alpha-2025-02-27",
  "gpt-4o-mini",
] as const;
const GEMINI_MODELS = ["gemini-2.0-flash", "gemini-2.0-flash-lite"] as const;
export const LLM_MODELS: readonly LLMModel[] = [
  ...OPENAI_MODELS,
  ...GEMINI_MODELS,
];

type OpenAIModel = typeof OPENAI_MODELS[number];
type GeminiModel = typeof GEMINI_MODELS[number];

export type LLMModel = OpenAIModel | GeminiModel;

export type ThreadInteractionOverrides = {
  analysis_prompt_override?: string;
  analysis_max_interactions_override?: number;
  analysis_model_override?: LLMModel;
  rewriter_max_interactions_override?: number;
  rewriter_model_override?: LLMModel;
  rewriter_prompt_override?: string;
  include_usage?: boolean;
};

export async function addInteractionToThread({
  threadId,
  userMessage,
  isProEnabled,
  filters,
  overrides,
  lang,
  headerData,
}: {
  threadId: number;
  userMessage: string;
  isProEnabled: boolean;
  filters: FilterParams;
  overrides?: ThreadInteractionOverrides;
  lang?: string;
  headerData?: RequestHeaderData;
}): Promise<ThreadInteractionResponse> {
  let endpoint = `/threads/${threadId}`;

  const { data } = await api.post(
    endpoint,
    {
      user_message: userMessage,
      is_pro_enabled: isProEnabled,
      filters,
      size: PER_PAGE,
      overrides: overrides,
      lang,
    },
    {
      headers: createRequestHeaders(headerData),
    }
  );

  return data;
}

export async function deleteThreadInteraction({
  threadId,
  threadInteractionId,
  headerData,
}: {
  threadId: number;
  threadInteractionId: number;
  headerData?: RequestHeaderData;
}) {
  let endpoint = `/threads/${threadId}/interactions/${threadInteractionId}`;

  await api.delete(endpoint, {
    headers: createRequestHeaders(headerData),
  });

  return null;
}

// A partial thread returned by the threads list endpoint, that omits the interactions but includes a created_at date for sorting.
export type ThreadHistory = Omit<ThreadResponse, "interactions"> & {
  created_at: string;
};

export interface ThreadsResponse {
  // Interactions are not populated when loading a list of threads for efficiency's
  threads: Array<ThreadHistory>;
}

export const THREADS_PAGE_SIZE = 20;

export async function getThreads(
  limit = THREADS_PAGE_SIZE,
  offset = 0,
  headerData?: RequestHeaderData
): Promise<ThreadsResponse> {
  const params = new URLSearchParams({
    limit: String(limit),
    offset: String(offset),
  });

  const endpoint = `/threads/?${params.toString()}`;

  const { data } = await api.get(endpoint, {
    headers: createRequestHeaders(headerData),
  });

  return data;
}

export async function createThread({
  userMessage,
  isProEnabled,
  filters,
  lang,
  headerData,
}: {
  userMessage: string;
  isProEnabled: boolean;
  filters: FilterParams;
  lang?: string;
  headerData?: RequestHeaderData;
}): Promise<ThreadResponse> {
  let endpoint = `/threads/`;

  const { data } = await api.post(
    endpoint,
    {
      user_message: userMessage,
      is_pro_enabled: isProEnabled,
      size: 10,
      filters,
      lang,
    },
    {
      headers: createRequestHeaders(headerData),
    }
  );

  return data;
}

type UpdateThreadResponse = Omit<ThreadResponse, "interactions">;

export async function updateThread(
  threadId: number,
  {
    is_shared,
    title,
    headerData,
  }: {
    is_shared?: boolean;
    title?: string;
    headerData?: RequestHeaderData;
  }
): Promise<UpdateThreadResponse> {
  let endpoint = `/threads/${threadId}`;

  const { data } = await api.put(
    endpoint,
    {
      is_shared,
      title,
    },
    {
      headers: createRequestHeaders(headerData),
    }
  );

  return data;
}

export interface GetOrganizationResponse {
  owner_stripe_id: string;
  clerk_organization_id?: string;
}

export async function getOrganizationByOwnerStripeId(
  owner_stripe_id: string,
  headerData?: RequestHeaderData,
  queryParams?: string
): Promise<GetOrganizationResponse> {
  let endpoint = `/teams/get_organization_by_owner_stripe_id/${owner_stripe_id}`;
  if (queryParams) {
    endpoint = `${endpoint}?${queryParams}`;
  }

  const { data } = await api.get(endpoint, {
    headers: createRequestHeaders(headerData),
  });

  return data;
}

export interface CreateOrganizationResponse {
  clerk_organization_id: string;
  owner_clerk_id: string;
  owner_stripe_id: string;
}

/** Creates organization entry in DB for the given clerk organization id. */
export async function createOrganizationDetails(
  clerk_organization_id: string,
  owner_clerk_id: string,
  owner_stripe_id: string,
  headerData?: RequestHeaderData
): Promise<CreateOrganizationResponse> {
  let endpoint = `/teams/create_organization`;

  const { data } = await api.post(
    endpoint,
    {
      clerk_organization_id: clerk_organization_id,
      owner_clerk_id: owner_clerk_id,
      owner_stripe_id: owner_stripe_id,
    },
    {
      headers: createRequestHeaders(headerData),
    }
  );

  return data;
}

export interface SetOrganizationDeletedResponse {
  clerk_organization_id: string;
  is_deleted: boolean;
}

/** Updates organization deleted_at for a given clerk organization id with current timestamp. */
export async function setOrganizationDeleted(
  clerk_organization_id: string,
  headerData?: RequestHeaderData,
  queryParams?: string
): Promise<SetOrganizationDeletedResponse> {
  let endpoint = `/teams/set_organization_deleted`;
  if (queryParams) {
    endpoint = `${endpoint}?${queryParams}`;
  }

  const { data } = await api.post(
    endpoint,
    {
      clerk_organization_id: clerk_organization_id,
    },
    {
      headers: createRequestHeaders(headerData),
    }
  );

  return data;
}

export interface UpdateOrganizationAdminsResponse {
  clerk_organization_id: string;
  admin_clerk_ids: string[];
}

/** Updates organization metadata for a given clerk organization id with new admin data. */
export async function updateOrganizationAdmins(
  clerk_organization_id: string,
  admin_clerk_id: string,
  headerData?: RequestHeaderData
): Promise<UpdateOrganizationAdminsResponse> {
  let endpoint = `/teams/update_organization_admins`;

  const { data } = await api.post(
    endpoint,
    {
      clerk_organization_id: clerk_organization_id,
      admin_clerk_id: admin_clerk_id,
    },
    {
      headers: createRequestHeaders(headerData),
    }
  );

  return data;
}

export interface DeleteOrganizationAdminResponse {
  clerk_organization_id: string;
  admin_clerk_ids: string[];
}

/** Deletes admin from organization metadata for a given clerk organization id. */
export async function removeOrganizationAdmin(
  clerk_organization_id: string,
  admin_clerk_id: string,
  headerData?: RequestHeaderData
): Promise<DeleteOrganizationAdminResponse> {
  let endpoint = `/teams/remove_organization_admin`;

  const { data } = await api.post(
    endpoint,
    {
      clerk_organization_id: clerk_organization_id,
      admin_clerk_id: admin_clerk_id,
    },
    {
      headers: createRequestHeaders(headerData),
    }
  );

  return data;
}

export interface ConsensusMeterCategory {
  paper_count: number;
  recency: number;
  tier_one_studies: number;
  journal_score: number;
  citations: number;
}
export interface ConsensusMeterResults {
  num_results_analyzed: number;
  result_id_to_answer: Record<string, YesNoType>;
  yes?: ConsensusMeterCategory;
  no?: ConsensusMeterCategory;
  possibly?: ConsensusMeterCategory;
  mixed?: ConsensusMeterCategory;
  is_incomplete: boolean;
  is_disputed: boolean;
  is_skipped: boolean;
}

export interface YesNoAnswerPercents {
  YES: number;
  NO: number;
  POSSIBLY: number;
  UNKNOWN: number;
}

export interface YesNoResponse {
  resultsAnalyzedCount: number;
  yesNoAnswerPercents: YesNoAnswerPercents;
  resultIdToYesNoAnswer: { [resultId: string]: YesNoType };
  isIncomplete: boolean;
  isDisputed: boolean;
}

/**
 * Object containing either threadSearchId or nonThreadSearchId
 */
export type SearchIdParams =
  | { threadSearchId: number; nonThreadSearchId?: never }
  | { threadSearchId?: never; nonThreadSearchId: string };

/**
 * Returns yes/no results for either a thread search or a non-threaded search. When a non-threaded
 * search is used, the GET yes_no/?search_id=... endpoint is used. When a thread search is used, the
 * GET paper_search/{threadSearchId}/yes_no/ endpoint is used.
 *
 * @param searchIdParams - Object containing either threadSearchId or nonThreadSearchId
 * @param testingQueryParams - Optional query params for testing.
 * @param headerData - Optional header data.
 * @returns The yes/no results.
 */
export async function getYesNoResults(
  searchIdParams: SearchIdParams,
  queryParams?: string,
  headerData?: RequestHeaderData
): Promise<ConsensusMeterResults> {
  let endpoint: string;

  if (searchIdParams.threadSearchId !== undefined) {
    // Thread search endpoint
    endpoint = `/paper_search/${searchIdParams.threadSearchId}/yes_no/`;

    // Add testing parameters if present
    if (queryParams) {
      endpoint += `?${queryParams}`;
    }
  } else {
    // Non-thread search endpoint with search_id
    endpoint = `/yes_no/?search_id=${searchIdParams.nonThreadSearchId}`;

    // Add testing parameters if present
    if (queryParams) {
      endpoint += `&${queryParams}`;
    }
  }

  // Make the API request
  const { data } = await api.get(endpoint, {
    headers: createRequestHeaders(headerData),
  });

  return data;
}

export interface ConsensusMeterAnalysisResponse {
  yes?: string | null;
  no?: string | null;
  possibly?: string | null;
  mixed?: string | null;
}

/**
 * Returns yes/no analysis for either a thread search or a non-threaded search. When a non-threaded
 * search is used, the GET yes_no/analysis/?search_id=... endpoint is used. When a thread search is
 * used, the GET paper_search/{threadSearchId}/yes_no/analysis/ endpoint is used.
 *
 * @param searchIdParams - Object containing either threadSearchId or nonThreadSearchId
 * @param testingQueryParams - Optional query params for testing.
 * @param headerData - Optional header data.
 * @returns The yes/no analysis.
 */
export async function getYesNoAnalysis(
  searchIdParams: SearchIdParams,
  queryParams?: string,
  headerData?: RequestHeaderData
): Promise<ConsensusMeterAnalysisResponse> {
  let endpoint: string;

  if (searchIdParams.threadSearchId !== undefined) {
    // Thread search endpoint
    endpoint = `/paper_search/${searchIdParams.threadSearchId}/yes_no/analysis/`;

    // Add testing parameters if present
    if (queryParams) {
      endpoint += `?${queryParams}`;
    }
  } else {
    // Non-thread search endpoint with search_id
    endpoint = `/yes_no/analysis/?search_id=${searchIdParams.nonThreadSearchId}`;

    // Add testing parameters if present
    if (queryParams) {
      endpoint += `&${queryParams}`;
    }
  }

  // Make the API request
  const { data } = await api.get(endpoint, {
    headers: createRequestHeaders(headerData),
  });

  return data;
}

export interface YesNoSuggestedQueryResponse {
  suggested: string;
}

export interface GetYesNoSuggestedQueryOptions {
  cacheOff: boolean;
}

/** Returns yes/no results for a search id returned from a getSearch response. */
export async function getYesNoSuggestedQueryResponse(
  searchId: string,
  options?: GetYesNoSuggestedQueryOptions | null,
  headerData?: RequestHeaderData
): Promise<YesNoSuggestedQueryResponse> {
  let endpoint = `/suggest/yes_no_query/?search_id=${searchId}`;

  if (options) {
    endpoint += `&cache_off=${options.cacheOff}`;
  }

  const { data } = await api.get(endpoint, {
    headers: createRequestHeaders(headerData),
  });
  return data;
}

export interface RelatedSearchQuery {
  query: string;
  is_yes_no_query?: boolean;
}

export interface RelatedSearchQueriesResponse {
  related_search_queries: RelatedSearchQuery[];
}

export interface GetRelatedSearchQueriesOptions {
  cacheOff?: boolean;
  lang?: string;
}

/** Returns related search queries for a search id returned from a getSearch response. */
export async function getRelatedSearchQueriesResponse(
  searchId: string,
  options?: GetRelatedSearchQueriesOptions | null,
  headerData?: RequestHeaderData
): Promise<RelatedSearchQueriesResponse> {
  let endpoint = `/suggest/related_search_queries/?search_id=${searchId}`;

  if (options && options.cacheOff) {
    endpoint += `&cache_off=${options.cacheOff}`;
  }
  if (options && options.lang) {
    endpoint += `&lang=${options.lang}`;
  }

  const { data } = await api.get(endpoint, {
    headers: createRequestHeaders(headerData),
  });
  return data;
}

export interface GeneralizedYesNoQueryResponse {
  generalized_yes_no_query: string;
}

export interface GetGeneralizedYesNoQueryOptions {
  cacheOff: boolean;
}

/** Returns generalized yes/no query for a search id returned from a getSearch response. */
export async function getGeneralizedYesNoQueryResponse(
  searchId: string,
  options?: GetGeneralizedYesNoQueryOptions | null,
  headerData?: RequestHeaderData
): Promise<GeneralizedYesNoQueryResponse> {
  let endpoint = `/suggest/generalized_yes_no_query/?search_id=${searchId}`;

  if (options) {
    endpoint += `&cache_off=${options.cacheOff}`;
  }

  const { data } = await api.get(endpoint, {
    headers: createRequestHeaders(headerData),
  });
  return data;
}

export interface SummaryResponse {
  resultsAnalyzedCount: number;
  summary?: string;
  isIncomplete: boolean;
  isDisputed: boolean;
  dailyLimitReached: boolean;
}

/** Returns summary results for a search id returned from a getSearch response. */
export async function getSummaryResponse(
  searchId: string,
  testingQueryParams?: string,
  headerData?: RequestHeaderData
): Promise<SummaryResponse> {
  let endpoint = `/summary/?search_id=${searchId}`;
  if (testingQueryParams) {
    endpoint = `${endpoint}${testingQueryParams}`;
  }
  const { data } = await api.get(endpoint, {
    headers: createRequestHeaders(headerData),
    timeout: REQUEST_TIMEOUT_OPENAI_ENDPOINTS,
  });
  return data;
}

export interface ProAnalysisResponse {
  isDisputed: boolean;
  isIncomplete: boolean;
  response?: string;
  resultsAnalyzedCount: number;
  dailyLimitReached: boolean;
}

export enum AskPaperThreadRole {
  ASSISTANT = "assistant",
  USER = "user",
}

export interface AskPaperThreadMessage {
  role: AskPaperThreadRole;
  content: string;
  created_at: string;
}

export interface AskPaperThreadResponse {
  messages: AskPaperThreadMessage[];
}

const ASK_PAPER_THREAD_SIZE = 50;

/** Returns previous ask paper messages for a paper */
export async function getAskPaperThread(
  paperId: string,
  limit = ASK_PAPER_THREAD_SIZE,
  offset = 0,
  headerData?: RequestHeaderData
): Promise<AskPaperThreadResponse> {
  const params = new URLSearchParams({
    limit: String(limit),
    offset: String(offset),
  });

  const endpoint = `/copilot/papers/${paperId}/?${params.toString()}`;

  const { data } = await api.get(endpoint, {
    headers: createRequestHeaders(headerData),
  });

  return data;
}

export function getEventStreamForProAnalysis(
  searchId: string,
  queryParams?: string
) {
  let endpoint = `/api/copilot/search/stream?search_id=${encodeURIComponent(
    searchId
  )}`;

  if (queryParams) {
    endpoint = `${endpoint}&${queryParams}`;
  }

  return new EventSource(endpoint);
}

export async function forwardEventStreamForProAnalysis(
  searchId: string,
  writer: WritableStreamDefaultWriter,
  headers: Record<string, string>,
  queryParams?: string
) {
  try {
    let endpoint = `${process.env.HOST}/copilot/search/stream/?search_id=${searchId}`;

    if (queryParams) {
      endpoint = `${endpoint}&${queryParams}`;
    }

    // Note: Just use fetch because axios was causing adapter errors.
    const response = await fetch(endpoint, { headers });
    const reader = response.body!.getReader();
    let done, value;

    while (!done) {
      ({ done, value } = await reader.read());
      if (!done) {
        writer.write(value);
      }
    }
    writer.close();
  } catch (error) {
    console.error("Error fetching external data:", error);
    writer.abort(error);
  }
}

export function getEventStreamForThreadInteractionAnalysis(
  threadId: number,
  threadInteractionId: number,
  queryParams?: string
) {
  let endpoint = `/api/threads/${threadId}/interactions/${threadInteractionId}/analysis/stream`;

  if (queryParams) {
    endpoint = `${endpoint}?${queryParams}`;
  }

  return new EventSource(endpoint);
}

export async function forwardEventStreamForThreadInteractionAnalysis(
  threadId: number,
  threadInteractionId: number,
  writer: WritableStreamDefaultWriter,
  headers: Record<string, string>,
  queryParams?: string
) {
  try {
    let endpoint = `${process.env.HOST}/threads/${threadId}/interactions/${threadInteractionId}/analysis/stream`;

    if (queryParams) {
      endpoint = `${endpoint}?${queryParams}`;
    }

    // Note: Just use fetch because axios was causing adapter errors.
    const response = await fetch(endpoint, { headers });
    const reader = response.body!.getReader();
    let done, value;

    while (!done) {
      ({ done, value } = await reader.read());
      if (!done) {
        writer.write(value);
      }
    }
    writer.close();
  } catch (error) {
    console.error("Error fetching external data:", error);
    writer.abort(error);
  }
}

export function getEventStreamForAskPaper(
  paperId: string,
  message: string,
  queryParams?: string
) {
  let endpoint = `/api/copilot/papers/${paperId}/stream/?message=${encodeURIComponent(
    message
  )}`;

  if (queryParams) {
    endpoint = `${endpoint}&${queryParams}`;
  }

  return new EventSource(endpoint);
}

export async function forwardStreamForAskPaper(
  paperId: string,
  message: string,
  writer: WritableStreamDefaultWriter,
  headers: Record<string, string>,
  queryParams?: string
) {
  try {
    let endpoint = `${
      process.env.HOST
    }/copilot/papers/${paperId}/stream/?message=${encodeURIComponent(message)}`;

    if (queryParams) {
      endpoint = `${endpoint}&${queryParams}`;
    }

    // Note: Just use fetch because axios was causing adapter errors.
    const response = await fetch(endpoint, { headers });
    const reader = response.body!.getReader();
    let done, value;

    while (!done) {
      ({ done, value } = await reader.read());
      if (!done) {
        writer.write(value);
      }
    }
    writer.close();
  } catch (error) {
    console.error("Error fetching external data:", error);
    writer.abort(error);
  }
}

export interface SeoQuestionItem {
  questionId: string;
  text: string;
}

export interface SeoQuestionsResponse {
  question: SeoQuestionItem;
  relatedQuestions: SeoQuestionItem[];
  papers: Paper[];
  copilotResponse: ProAnalysisResponse | null;
  summaryResponse: SummaryResponse | null;
}

export async function getSeoQuestionsData(
  question: string,
  headerData?: RequestHeaderData
): Promise<SeoQuestionsResponse> {
  let endpoint = `/seo_questions/data/?question_id=${question}`;
  const { data } = await api.get(endpoint, {
    headers: createRequestHeaders(headerData),
  });
  return data;
}

export async function getSeoQuestionWithNativeFetch(
  question: string
): Promise<SeoQuestionsResponse> {
  const endpoint = `/seo_questions/data/?question_id=${question}`;

  const response = await fetch(BASE_URL + endpoint).then(
    async (res) => await res.json()
  );

  return response;
}

export interface SeoQuestionsListResponse {
  questions: SeoQuestionItem[];
  isEnd: boolean;
}

export async function getSeoQuestionsList(
  page: number,
  headerData?: RequestHeaderData
): Promise<SeoQuestionsListResponse> {
  let endpoint = `/seo_questions/list/?page=${page}`;
  const { data } = await api.get(endpoint, {
    headers: createRequestHeaders(headerData),
  });
  return data;
}

export interface StudyDetailsResponse {
  population: string | null;
  method: string | null;
  outcome: string | null;
  result: string | null;
  country: string[] | null;
  duration: string | null;
  dailyLimitReached: boolean;
}

/** Returns study details results for a search id returned from a getSearch response. */
export async function getStudyDetailsResponse(
  id: string,
  testingQueryParams?: string,
  headerData?: RequestHeaderData
): Promise<StudyDetailsResponse> {
  let endpoint = `/study_details/${id}`;
  if (testingQueryParams) {
    endpoint = `${endpoint}?${testingQueryParams}`;
  }
  const { data } = await api.get(endpoint, {
    headers: createRequestHeaders(headerData),
    timeout: REQUEST_TIMEOUT_OPENAI_ENDPOINTS,
  });
  return data;
}

export interface AutocompleteResponse {
  queries: string[];
}

/** Returns autocomplete suggestions for the given query. */
export async function getAutocompleteQuerySuggestions(
  query: string,
  testingQueryParams?: string,
  headerData?: RequestHeaderData
): Promise<AutocompleteResponse> {
  let endpoint = `/autocomplete/?query=${encodeURIComponent(query)}`;
  if (testingQueryParams) {
    endpoint = `${endpoint}&${testingQueryParams}`;
  }
  const { data } = await api.get(endpoint, {
    headers: createRequestHeaders(headerData),
  });
  return data;
}

export interface TrendingItem {
  key: string;
  title: string;
  queries: string[];
}

interface TrendingResponse {
  queries: string[];
  questionTypes: TrendingItem[];
  topics: TrendingItem[];
  emptyAutocompleteQueries: string[];
}
/** Returns trending queries. */
export async function getTrending(
  headerData?: RequestHeaderData
): Promise<TrendingResponse> {
  const { data } = await api.get(`/trending/queries`, {
    headers: createRequestHeaders(headerData),
  });
  return data;
}

/** Returns the sitemap for the given path. */
export async function getSitemap(
  path: string,
  headerData?: RequestHeaderData
): Promise<string> {
  const { data } = await api.get(`/sitemap?path=${path}`, {
    headers: createRequestHeaders(headerData),
  });
  return data;
}

/** Returns ip */
export async function getIp(request?: any): Promise<string | undefined> {
  if (!request && isServer()) {
    throw new Error("Calling getIp from server requires passing in request.");
  }
  if (request) {
    return requestIp.getClientIp(request);
  } else {
    const { data } = await api.get("/ip/");
    return data.ip;
  }
}

export function handleRedirect(error: unknown): { redirect: Redirect } {
  let destination = path.INTERNAL_SERVER_ERROR;
  if (axios.isAxiosError(error) && error.response?.status === 429) {
    destination = path.TOO_MANY_REQUESTS;
  }
  return {
    redirect: {
      destination,
      permanent: false,
    },
  };
}

export async function getSubscriptionProductsAPI(
  product_id: string,
  headerData?: RequestHeaderData
): Promise<any> {
  let endpoint = `/subscription_products/`;
  if (product_id) {
    endpoint = `${endpoint}?product_id=${product_id}`;
  }

  const { data } = await api.get(endpoint, {
    headers: createRequestHeaders(headerData),
  });
  return data;
}

export interface StripeCustomerSubscriptionRequest {
  stripe_customer_id?: string | null;
  email_addresses?: string[] | null;
}

export interface StripeCustomerSubscriptionResponse {
  stripe_customer_id: string;
  user_subscription: string;
  org_name: string | null;
  team: boolean;
}

export async function getCustomerSubscriptionAPI(
  requestData: StripeCustomerSubscriptionRequest,
  headerData?: RequestHeaderData
): Promise<StripeCustomerSubscriptionResponse> {
  let endpoint = `/get_stripe_subscription/`;
  const { data } = await api.post(endpoint, requestData, {
    headers: createRequestHeaders(headerData),
  });
  return data;
}

export async function getCustomerUpcomingInvoiceAPI(
  customerId: string,
  headerData?: RequestHeaderData
): Promise<IStripeInvoiceOrUpcomingInvoice | null> {
  let endpoint = `/get_customer_upcoming_invoice/?customerId=${customerId}`;
  const { data } = await api.get(endpoint, {
    headers: createRequestHeaders(headerData),
  });
  return data;
}

export interface StripeTeamSubscriptionResponse {
  subscription: string | null;
}

export async function getTeamSubscriptionAPI(
  clerk_organization_id: string,
  headerData?: RequestHeaderData
): Promise<StripeTeamSubscriptionResponse> {
  let endpoint = `/get_stripe_subscription/team/${clerk_organization_id}`;
  const { data } = await api.get(endpoint, {
    headers: createRequestHeaders(headerData),
  });
  return data;
}

export async function getTeamApiSubscriptionAPI(
  clerkOrgId: string,
  headerData?: RequestHeaderData
): Promise<StripeTeamSubscriptionResponse> {
  let endpoint = `/api_stripe_subscription/team/${clerkOrgId}`;
  const { data } = await api.get(endpoint, {
    headers: createRequestHeaders(headerData),
  });
  return data;
}

export async function enableApiSubscriptionAPI(
  clerkOrgId: string,
  headerData?: RequestHeaderData
): Promise<StripeTeamSubscriptionResponse> {
  let endpoint = `/api_stripe_subscription/enable`;
  const { data } = await api.post(
    endpoint,
    {
      clerkOrgId: clerkOrgId,
    },
    {
      headers: createRequestHeaders(headerData),
    }
  );
  return data;
}

export interface GetApiInvoicesResponse {
  cardLast4: string;
  invoices: IStripeInvoiceOrUpcomingInvoice[];
}

export async function getApiInvoicesAPI(
  clerkOrgId: string,
  limit: number,
  headerData?: RequestHeaderData
): Promise<GetApiInvoicesResponse> {
  let endpoint = `/api_stripe_subscription/invoices/team/${clerkOrgId}`;
  endpoint += `?limit=${limit}`;
  const { data } = await api.get(endpoint, {
    headers: createRequestHeaders(headerData),
  });
  return data;
}

export interface GetApiUsageResponse {
  summaries: IStripeMeterEventSummary[];
}

export async function getApiUsageAPI({
  clerkOrgId,
  periodStart,
  periodEnd,
  aggregateAll,
  headerData,
}: {
  clerkOrgId: string;
  periodStart: number;
  periodEnd: number;
  aggregateAll: boolean;
  headerData?: RequestHeaderData;
}): Promise<GetApiUsageResponse> {
  let endpoint = `/api_stripe_subscription/usage/${clerkOrgId}`;
  endpoint += `?start=${periodStart}&end=${periodEnd}`;
  if (aggregateAll) {
    endpoint += "&aggregate=true";
  }
  const { data } = await api.get(endpoint, {
    headers: createRequestHeaders(headerData),
  });
  return data;
}

export interface StripeAddApiSubscriptionResponse {
  subscription_added: boolean;
}

export async function addApiSubscriptionAPI(
  stripe_customer_id: string,
  user_subscription: string,
  headerData?: RequestHeaderData
): Promise<StripeAddApiSubscriptionResponse> {
  if (isServer()) {
    let endpoint = `/api_stripe_subscription/`;
    const { data } = await api.post(
      endpoint,
      {
        stripe_customer_id: stripe_customer_id,
        user_subscription: user_subscription,
      },
      {
        headers: createRequestHeaders(headerData),
      }
    );
    return data;
  } else {
    throw new Error("Calling addApiSubscriptionAPI from client requires.");
  }
}

export async function deleteApiSubscriptionAPI(
  stripe_customer_id: string,
  headerData?: RequestHeaderData
): Promise<boolean> {
  if (isServer()) {
    let endpoint = `/api_stripe_subscription/${stripe_customer_id}`;
    const { data } = await api.delete(endpoint, {
      headers: createRequestHeaders(headerData),
    });
    return data;
  } else {
    throw new Error("Calling deleteApiSubscriptionAPI from client requires.");
  }
}

export interface StripeAddCustomerSubscriptionResponse {
  subscription_added: boolean;
}

export async function addCustomerSubscriptionAPI(
  stripe_customer_id: string,
  user_subscription: string,
  headerData?: RequestHeaderData
): Promise<StripeAddCustomerSubscriptionResponse> {
  if (isServer()) {
    let endpoint = `/add_stripe_subscription/`;
    const { data } = await api.post(
      endpoint,
      {
        stripe_customer_id: stripe_customer_id,
        user_subscription: user_subscription,
      },
      {
        headers: createRequestHeaders(headerData),
      }
    );
    return data;
  } else {
    throw new Error("Calling addCustomerSubscriptionAPI from client requires.");
  }
}

export async function deleteCustomerSubscriptionAPI(
  stripe_customer_id: string,
  headerData?: RequestHeaderData
): Promise<boolean> {
  if (isServer()) {
    let endpoint = `/delete_stripe_subscription/${stripe_customer_id}`;
    const { data } = await api.delete(endpoint, {
      headers: createRequestHeaders(headerData),
    });
    return data;
  } else {
    throw new Error(
      "Calling deleteCustomerSubscriptionAPI from client requires."
    );
  }
}

export async function updateStripeSubscriptionAPI(
  subscriptionObjectId: string,
  subscriptionItemId: string,
  quantity: number,
  newPriceId?: string
): Promise<boolean> {
  let endpoint = `/subscription_update/?subscriptionObjectId=${subscriptionObjectId}&subscriptionItemId=${subscriptionItemId}&quantity=${quantity}`;
  if (newPriceId) {
    endpoint += `&newPriceId=${newPriceId}`;
  }
  const { data } = await api.get(endpoint);
  return data;
}

export async function getPaymentMethodDetailAPI(
  payment_method_id: string
): Promise<PaymentDetailResponse> {
  const { data } = await api.get(
    `/get_payment_method/?id=${payment_method_id}`
  );
  return data;
}

export async function updateClerkPublicMetaDataAPI(
  inputData: Partial<IClerkPublicMetaData>
): Promise<IClerkPublicMetaData> {
  let endpoint = `/update_clerk_public_meta_data/`;
  const { data } = await api.post(endpoint, {
    data: inputData,
  });
  return data;
}

export async function updateMixpanelUserProfileAPI(): Promise<IClerkPublicMetaData> {
  let endpoint = `/update_mixpanel_user_profile/`;
  const { data } = await api.post(endpoint);
  return data;
}

export async function getCustomerPortalLinkAPI(email: string): Promise<string> {
  let endpoint = `/get_customer_portal_link/?email=${email}`;
  const { data } = await api.get(endpoint);
  return data;
}

export async function sendEmailAPI(inputData: IEmailRequest): Promise<boolean> {
  let endpoint = `/send_email/`;
  const { data } = await api.post(endpoint, {
    data: inputData,
  });
  return data;
}

export async function getSearchWithNativeFetch(
  query: string,
  searchTestingQueryParams?: string
): Promise<PaperSearchResponse> {
  let endpoint = `/paper_search/?query=${encodeURIComponent(
    query
  )}&page=0&size=${PER_PAGE}`;

  if (searchTestingQueryParams) {
    endpoint = `${endpoint}${rewriteSearchQueryParamsForServerEndpoint(
      searchTestingQueryParams
    )}`;
  }

  const response = await fetch(BASE_URL + endpoint).then(
    async (res) => await res.json()
  );

  return response;
}

export async function getYesNoResultsWithNativeFetch(
  searchId: string,
  testingQueryParams?: string
): Promise<ConsensusMeterResults> {
  let endpoint = `/yes_no/?search_id=${searchId}`;
  if (testingQueryParams) {
    endpoint = `${endpoint}&${testingQueryParams}`;
  }

  const response = await fetch(BASE_URL + endpoint).then(
    async (res) => await res.json()
  );

  return response;
}

export async function getSummaryResponseWithNativeFetch(
  searchId: string
): Promise<SummaryResponse> {
  const endpoint = `/summary/?search_id=${searchId}`;

  const response = await fetch(BASE_URL + endpoint).then(
    async (res) => await res.json()
  );

  return response;
}

/** Returns details on the given paper id. */
export async function getPaperDetailsWithNativeFetch(
  id: string
): Promise<PaperDetailsResponse> {
  let endpoint = `/papers/details/${id}`;

  const response = await fetch(BASE_URL + endpoint).then((res) => res.json());

  return response;
}

/** Track event from NextJS server */
export async function trackEventServerSide(
  data: TrackEventData
): Promise<void> {
  await api.post(`/track_event/`, data);
}

/** Get Bookmark Lists */
export async function getBookmarkLists(
  favoriteListName: string,
  headerData?: RequestHeaderData
): Promise<IBookmarkListResponse> {
  let endpoint = `/bookmarks/lists/?favorite_list_name=${favoriteListName}`;
  const { data } = await api.get(endpoint, {
    headers: createRequestHeaders(headerData),
  });
  return data;
}

/** Create Bookmark List */
export async function createBookmarkList(
  text_label: string,
  headerData?: RequestHeaderData
): Promise<IBookmarkCreateListResponse> {
  let endpoint = `/bookmarks/lists/`;
  const { data } = await api.post(
    endpoint,
    {
      text_label: text_label,
    },
    {
      headers: createRequestHeaders(headerData),
    }
  );
  return data;
}

export async function updateBookmarkList(
  list_id: string,
  text_label: string,
  headerData?: RequestHeaderData
): Promise<IBookmarkUpdateListResponse> {
  let endpoint = `/bookmarks/lists/${list_id}`;
  const { data } = await api.put(
    endpoint,
    {
      text_label: text_label,
    },
    {
      headers: createRequestHeaders(headerData),
    }
  );
  return data;
}

/** Delete Bookmark List */
export async function deleteBookmarkList(
  id: string,
  headerData?: RequestHeaderData
) {
  let endpoint = `/bookmarks/lists/${id}`;
  await api.delete(endpoint, {
    headers: createRequestHeaders(headerData),
  });
  return null;
}

/** Get Bookmark Items */
export async function getBookmarkItems(
  headerData?: RequestHeaderData
): Promise<IBookmarkItemsResponse> {
  let endpoint = `/bookmarks/items/`;
  const { data } = await api.get(endpoint, {
    headers: createRequestHeaders(headerData),
  });
  return data;
}

/** Create Bookmark Item */
export async function createBookmarkItemsAPI(
  itemsData: IBookmarkCreateItemData[],
  headerData?: RequestHeaderData
): Promise<IBookmarkCreateItemsResponse> {
  let endpoint = `/bookmarks/items/`;
  const { data } = await api.post(
    endpoint,
    {
      items: itemsData,
    },
    {
      headers: createRequestHeaders(headerData),
    }
  );
  return data;
}

/** Delete Bookmark Item */
export async function deleteBookmarkItem(
  id: number,
  headerData?: RequestHeaderData
) {
  let endpoint = `/bookmarks/items/${id}`;

  await api.delete(endpoint, {
    headers: createRequestHeaders(headerData),
  });

  return null;
}

export interface SearchHistory {
  clerk_user_id: string;
  full_url: string;
  id: string;
  last_searched_at: string;
  query: string;
}

export interface SearchHistoriesResponse {
  search_histories: SearchHistory[];
}

export const SEARCH_HISTORY_PAGE_SIZE = 20;

export async function getSearchHistories(
  limit = SEARCH_HISTORY_PAGE_SIZE,
  offset = 0,
  headerData?: RequestHeaderData
): Promise<SearchHistoriesResponse> {
  const params = new URLSearchParams({
    limit: String(limit),
    offset: String(offset),
  });

  const endpoint = `/search_histories/?${params.toString()}`;

  const { data } = await api.get(endpoint, {
    headers: createRequestHeaders(headerData),
  });

  return data;
}

export async function deleteAllSearchHistory(headerData?: RequestHeaderData) {
  let endpoint = `/search_histories/all`;

  await api.delete(endpoint, {
    headers: createRequestHeaders(headerData),
  });

  return null;
}

export async function deleteSearchHistory(
  id: string,
  headerData?: RequestHeaderData
) {
  let endpoint = `/search_histories/${id}`;

  await api.delete(endpoint, {
    headers: createRequestHeaders(headerData),
  });

  return null;
}

export interface ApiKey {
  id: number;
  label: string;
  lastFourChars: string;
  createdBy: string;
  createdAt: string;
  lastUsedAt?: string;
}

export interface GetApiKeysResponse {
  apiKeys: ApiKey[];
}

export async function getOrganizationApiKeys(
  clerkOrganizationId: string,
  headerData?: RequestHeaderData
): Promise<GetApiKeysResponse> {
  let endpoint = `/api_keys/org/${clerkOrganizationId}`;
  const { data } = await api.get(endpoint, {
    headers: createRequestHeaders(headerData),
  });
  return data;
}

export interface CreateApiKeyResponse {
  apiKeyRaw: string;
  apiKey: ApiKey;
}

export async function createApiKey(
  clerkOrgId: string,
  apiKeyLabel?: string,
  headerData?: RequestHeaderData
): Promise<CreateApiKeyResponse> {
  let endpoint = `/api_keys/`;
  const { data } = await api.post(
    endpoint,
    {
      label: apiKeyLabel,
      clerkOrgId: clerkOrgId,
    },
    {
      headers: createRequestHeaders(headerData),
    }
  );
  return data;
}

export async function deleteApiKey(
  apiKeyId: number,
  headerData?: RequestHeaderData
): Promise<void> {
  let endpoint = `/api_keys/${apiKeyId}`;
  await api.delete(endpoint, {
    headers: createRequestHeaders(headerData),
  });
}

export interface RenameApiKeyResponse {
  apiKey: ApiKey;
}

export async function renameApiKey(
  apiKeyId: number,
  apiKeyLabel: string,
  headerData?: RequestHeaderData
): Promise<RenameApiKeyResponse> {
  let endpoint = `/api_keys/${apiKeyId}`;
  const { data } = await api.patch(
    endpoint,
    {
      label: apiKeyLabel,
    },
    {
      headers: createRequestHeaders(headerData),
    }
  );
  return data;
}

/** Check if any email address has .edu domain */
export async function hasEduEmailDomain(): Promise<boolean> {
  let endpoint = `/has_edu_email_domain/`;
  const { data } = await api.get(endpoint);
  return data;
}

export enum QueryType {
  COMMAND = "chat command",
  QUESTION = "question",
}
export interface SearchFeedQueries {
  query: string;
  emoticon: string;
  query_type: QueryType;
  yn_question: boolean;
}

export interface SearchFeedData {
  category: string;
  queries: SearchFeedQueries[];
}

export interface SearchFeedQueriesResponse {
  searchFeed: SearchFeedData[];
}

export async function getSearchFeedQueriesAPI(
  headerData?: RequestHeaderData
): Promise<SearchFeedQueriesResponse> {
  const { data } = await api.get(`/search_feed/queries`, {
    headers: createRequestHeaders(headerData),
  });
  return data;
}

export interface PricingPlan {
  monthly_pricing: string;
  yearly_pricing: string;
  features: string[];
  monthly?: {
    unit_amount: number;
    description: string;
  };
  yearly?: {
    unit_amount: number;
    description: string;
  };
}

export interface PricingPlanResponse {
  free: PricingPlan;
  premium: PricingPlan;
  team: PricingPlan;
  enterprise: PricingPlan;
}

export async function getPricingTextAPI(
  headerData?: RequestHeaderData
): Promise<PricingPlanResponse> {
  const { data } = await api.get(`/pricing/plans`, {
    headers: createRequestHeaders(headerData),
  });
  return data;
}

export async function validatePdfLink(
  url: string
): Promise<{ isPdf: boolean; error?: string }> {
  const { data } = await axios.get(
    `/api/validate_pdf_link?url=${encodeURIComponent(url)}`
  );
  return data;
}

export interface WriteFeedbackResponse {
  feedbackId: number;
  message: string;
}

export interface Feedback {
  original: string;
  corrected?: string;
  description?: string;
  query?: string;
}

export interface PaperDetailsFeedback {
  keyTakeaway?: Feedback;
  answerFromSearch?: Feedback;
  authors?: Feedback;
  studyType?: Feedback;
}

export interface StudySnapshotFeedback {
  population?: Feedback;
  sampleSize?: Feedback;
  methods?: Feedback;
  outcomes?: Feedback;
  results?: Feedback;
  location?: Feedback;
  duration?: Feedback;
  studyCount?: Feedback;
}

export interface JournalDetailsFeedback {
  journalName?: Feedback;
  sjrScore?: Feedback;
}

export interface FeedbackData {
  clerkUserId: string;
  hashPaperId: string;
  paperDetails?: PaperDetailsFeedback;
  studySnapshot?: StudySnapshotFeedback;
  journalDetails?: JournalDetailsFeedback;
}

export async function writeFeedback(
  feedbackData: FeedbackData,
  headerData?: RequestHeaderData
): Promise<WriteFeedbackResponse> {
  let endpoint = `/feedback`;

  const { data } = await api.post(endpoint, feedbackData, {
    headers: createRequestHeaders(headerData),
  });

  return data;
}

export interface UploadPaperResponse {
  url: string;
  full_text_url: string;
}

export interface GetUploadedPapersResponse {
  papers: Paper[];
}

export interface DeleteUploadedPaperResponse {
  success: boolean;
}

export async function uploadPaper(
  file: File,
  headerData?: RequestHeaderData,
  force_hash_paper_id?: string
): Promise<UploadPaperResponse> {
  const formData = new FormData();
  formData.append("file", file);

  // Add hash_paper_id to the form data if provided
  if (force_hash_paper_id) {
    formData.append("force_hash_paper_id", force_hash_paper_id);
  }

  const { data } = await api.post("/papers/uploaded/", formData, {
    headers: {
      ...createRequestHeaders(headerData),
      "Content-Type": "multipart/form-data",
    },
  });
  return data;
}

export async function getUploadedPapers(
  headerData?: RequestHeaderData
): Promise<GetUploadedPapersResponse> {
  const { data } = await api.get("/papers/uploaded/", {
    headers: createRequestHeaders(headerData),
  });
  return data;
}

export async function getUploadedPaper(
  paperId: string,
  headerData?: RequestHeaderData
): Promise<PaperDetails> {
  const { data } = await api.get(`/papers/uploaded/${paperId}`, {
    headers: createRequestHeaders(headerData),
  });
  return data;
}

export async function deleteUploadedPaper(
  paperId: string,
  headerData?: RequestHeaderData
): Promise<DeleteUploadedPaperResponse> {
  const { data } = await api.delete(`/papers/uploaded/${paperId}`, {
    headers: createRequestHeaders(headerData),
  });
  return data;
}

export async function downloadUploadedPaper(
  paperId: string,
  headerData?: RequestHeaderData
): Promise<Blob> {
  const response = await api.get(`/papers/uploaded/${paperId}/download`, {
    headers: createRequestHeaders(headerData),
    responseType: "blob",
  });
  return response.data;
}

import { useAuth } from "@clerk/nextjs";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  BOOKMARK_LISTNAME_FAVORITE,
  BOOKMARK_MAX_CUSTOM_LIST_NUM,
  BOOKMARK_MAX_ITEM_NUM,
} from "constants/config";
import path from "constants/path";
import {
  createBookmarkList,
  deleteBookmarkItem as deleteBookmarkItemAPI,
  deleteBookmarkList as deleteBookmarkListAPI,
  getBookmarkItems,
  getBookmarkLists,
  updateBookmarkList,
} from "helpers/api";
import {
  getBookmarkItemsCount,
  IBookmarkCreateListResponse,
  IBookmarkItem,
  IBookmarkUpdateListResponse,
} from "helpers/bookmark";
import { isSubscriptionPremium } from "helpers/subscription";
import useAnalytics from "hooks/useAnalytics";
import { useAppDispatch, useAppSelector } from "hooks/useStore";
import { useRouter } from "next/router";
import { useMemo } from "react";
import {
  addListTargetForNewBookmarkItem as addListTargetForNewBookmarkItemAction,
  clearListTargetsForNewBookmarkItem as clearListTargetsForNewBookmarkItemAction,
  SaveBookmarkState,
  setSaveBookmarkState as setSaveBookmarkStateAction,
} from "store/slices/bookmark";

const QUERY_KEYS = {
  BOOKMARK_LISTS: ["get-bookmark-lists"],
  BOOKMARK_ITEMS: ["get-bookmark-items"],
};

const useBookmarks = (loadBookmarks = true) => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const queryClient = useQueryClient();

  const { isSignedIn, isLoaded } = useAuth();

  const subscription = useAppSelector(
    (state) => state.subscription.subscription
  );
  const isSubscriptionLoaded = useAppSelector(
    (state) => state.subscription.isSubscriptionLoaded
  );

  const { createListEvent } = useAnalytics();

  const saveBookmarkState = useAppSelector(
    (state) => state.bookmark.saveBookmarkState
  );
  const targetedListsForNewBookmarkItem = useAppSelector(
    (state) => state.bookmark.targetedListsForNewBookmarkItem
  );

  // Load bookmark lists using react-query
  const { data: bookmarkLists = [], isFetched: isBookmarkListLoaded } =
    useQuery(
      QUERY_KEYS.BOOKMARK_LISTS,
      async () => {
        try {
          const listResponse = await getBookmarkLists(
            BOOKMARK_LISTNAME_FAVORITE
          );
          return listResponse?.bookmark_lists || [];
        } catch (error) {
          console.error("Failed to fetch bookmark lists:", error);
          return [];
        }
      },
      {
        enabled: isSignedIn && isLoaded && loadBookmarks,
      }
    );

  // Load bookmark items using react-query
  const { data: bookmarkItems = {}, isFetched: isBookmarkItemsLoaded } =
    useQuery(
      QUERY_KEYS.BOOKMARK_ITEMS,
      async () => {
        try {
          const itemsResponse = await getBookmarkItems();
          return itemsResponse?.bookmark_items || {};
        } catch (error) {
          console.error("Failed to fetch bookmark items:", error);
          return {};
        }
      },
      {
        enabled: isSignedIn && isLoaded && loadBookmarks,
      }
    );

  const reloadBookmarkItems = () => {
    queryClient.invalidateQueries(QUERY_KEYS.BOOKMARK_ITEMS);
  };

  // Derived counts
  const bookmarkItemNum = getBookmarkItemsCount(bookmarkItems);
  const bookmarkListsLeft =
    BOOKMARK_MAX_CUSTOM_LIST_NUM - bookmarkLists.length + 1;
  const bookmarkItemsLeft = BOOKMARK_MAX_ITEM_NUM - bookmarkItemNum;

  // Derived "out of free bookmarks" states
  const isOutOfFreeBookmarkLists = useMemo(() => {
    return (
      isSubscriptionLoaded &&
      !isSubscriptionPremium(subscription) &&
      bookmarkLists.length > BOOKMARK_MAX_CUSTOM_LIST_NUM
    );
  }, [isSubscriptionLoaded, subscription, bookmarkLists]);

  const isOutOfFreeBookmarkItems = useMemo(() => {
    return (
      isSubscriptionLoaded &&
      !isSubscriptionPremium(subscription) &&
      bookmarkItemNum >= BOOKMARK_MAX_ITEM_NUM
    );
  }, [isSubscriptionLoaded, subscription, bookmarkItemNum]);

  const createNewBookmarkList = async (listName: string) => {
    if (!isLoaded) {
      router?.push(path.INTERNAL_SERVER_ERROR);
      return;
    }

    if (!isSignedIn) {
      router.push(
        `${path.SIGN_UP}#/?redirect_url=${encodeURIComponent(router?.asPath)}`
      );
      return;
    }

    if (listName.trim() == BOOKMARK_LISTNAME_FAVORITE) return;

    if (listName.trim().length > 0 && !isOutOfFreeBookmarkLists) {
      try {
        const ret: IBookmarkCreateListResponse = await createBookmarkList(
          listName.trim()
        );
        if (ret.success) {
          queryClient.setQueryData(
            QUERY_KEYS.BOOKMARK_LISTS,
            (oldLists: any) => [...(oldLists || []), ret.created_item]
          );
          createListEvent(ret.created_item.text_label);
        }
      } catch (error) {}
    }
  };

  const updateBookmarkListName = async (
    bookmarkListId: string,
    newLabel: string
  ) => {
    if (newLabel.length !== 0 && newLabel !== BOOKMARK_LISTNAME_FAVORITE) {
      try {
        const ret: IBookmarkUpdateListResponse = await updateBookmarkList(
          bookmarkListId,
          newLabel
        );
        if (ret.success) {
          queryClient.setQueryData(QUERY_KEYS.BOOKMARK_LISTS, (oldLists: any) =>
            oldLists.map((list: any) =>
              list.id === bookmarkListId ? ret.updated_item : list
            )
          );
        }
      } catch (error) {}
    }
  };

  const deleteBookmarkItem = async (bookmarkItem: IBookmarkItem) => {
    await deleteBookmarkItemAPI(bookmarkItem.id);
    queryClient.setQueryData(QUERY_KEYS.BOOKMARK_ITEMS, (oldItems: any) => {
      const updatedItems = { ...oldItems };
      Object.keys(updatedItems).forEach((listId) => {
        updatedItems[listId] = updatedItems[listId].filter(
          (item: IBookmarkItem) => item.id !== bookmarkItem.id
        );
      });
      return updatedItems;
    });
  };

  const deleteBookmarkList = async (bookmarkListId: string) => {
    await deleteBookmarkListAPI(bookmarkListId);
    queryClient.setQueryData(QUERY_KEYS.BOOKMARK_LISTS, (oldLists: any) =>
      oldLists.filter((list: any) => list.id !== bookmarkListId)
    );
    queryClient.setQueryData(QUERY_KEYS.BOOKMARK_ITEMS, (oldItems: any) => {
      const updatedItems = { ...oldItems };
      delete updatedItems[bookmarkListId];
      return updatedItems;
    });
  };

  const clearListTargetsForNewBookmarkItem = () => {
    dispatch(clearListTargetsForNewBookmarkItemAction());
  };

  const addListTargetForNewBookmarkItem = (bookmarkListId: string) => {
    dispatch(addListTargetForNewBookmarkItemAction(bookmarkListId));
  };

  const setSaveBookmarkState = (saveBookmarkState: SaveBookmarkState) => {
    dispatch(setSaveBookmarkStateAction(saveBookmarkState));
  };

  return {
    addListTargetForNewBookmarkItem,
    bookmarkItems,
    bookmarkItemsLeft,
    bookmarkLists,
    bookmarkListsLeft,
    saveBookmarkState,
    clearListTargetsForNewBookmarkItem,
    createNewBookmarkList,
    deleteBookmarkItem,
    deleteBookmarkList,
    isBookmarkItemsLoaded,
    isBookmarkListLoaded,
    isOutOfFreeBookmarkItems,
    isOutOfFreeBookmarkLists,
    reloadBookmarkItems,
    setSaveBookmarkState,
    targetedListsForNewBookmarkItem,
    updateBookmarkListName,
  };
};

export default useBookmarks;

import { Disclosure } from "@headlessui/react";
import Checkbox from "components/Checkbox";
import { ButtonVariant, CoButton } from "components/CoButton";
import Drawer from "components/Drawer";
import Popover from "components/Popover";
import AskPaperFilterTooltip from "components/Popover/AskPaperFilterTooltip/AskPaperFilterTooltip";
import SjrQuartilesTooltip from "components/Popover/SjrQuartilesTooltip/SjrQuartilesTooltip";
import StudyDetailsFilterTooltip from "components/Popover/StudyDetailsFilterTooltip/StudyDetailsFilterTooltip";
import StudyTypesFilterTooltip from "components/Popover/StudyTypesFilterTooltip/StudyTypesFilterTooltip";
import { QuartileBar } from "components/QuartileBar";
import Switch from "components/Switch";
import { BetaTag } from "components/Tag/BetaTag";
import { YEAR_FILTER_OPTIONS } from "constants/filters";
import { StudyType } from "helpers/api";
import { StudyTypesFilterOptions } from "helpers/studyTypes";
import useLabels from "hooks/useLabels";
import { useAppSelector } from "hooks/useStore";
import { useEffect, useState } from "react";
import { Filter, Filters } from "store/slices/search";
import CountryInput from "./components/CountryInput";
import DomainInput from "./components/DomainInput";
import DurationInput from "./components/DurationInput";
import NumberInput from "./components/NumberInput";
import useFilterDrawer from "./hooks/useFilterDrawer";
import useFilters from "./hooks/useFilters";

function FilterDrawer() {
  const { isFilterDrawerOpen, closeFilterDrawer } = useFilterDrawer();
  const [filterLabels] = useLabels("filter");
  const isMobile = useAppSelector((state) => state.setting.isMobile);

  const { appliedFilterCount, appliedFilters, applyFilters, removeAllFilters } =
    useFilters();

  const [pendingFilters, setPendingFilters] = useState<Filters>(appliedFilters);

  useEffect(() => {
    setPendingFilters(appliedFilters);
  }, [appliedFilters]);

  const changePendingFilter = <T extends Filter>(
    filter: T,
    value: Filters[T]
  ) => {
    setPendingFilters((prev) => ({
      ...prev,
      [filter]: value,
    }));
  };

  const toggleStudyType = (studyType: StudyType) => {
    const updatedStudyTypes = pendingFilters.studyTypes ?? [];

    changePendingFilter(
      "studyTypes",
      updatedStudyTypes.includes(studyType)
        ? updatedStudyTypes.filter((type) => type !== studyType) // Remove if already selected
        : [...updatedStudyTypes, studyType] // Add if not selected
    );
  };

  const handleApplyPendingFilters = () => {
    applyFilters(pendingFilters);
    closeFilterDrawer();
  };

  const handleRemoveAllFitlers = () => {
    removeAllFilters();
    closeFilterDrawer();
  };

  return (
    <Drawer
      open={isFilterDrawerOpen}
      onClose={() => closeFilterDrawer()}
      fillY={true}
    >
      <div data-testid="filter-drawer" className="w-full mb-[150px]">
        <div className="flex items-center justify-between mt-[30px] ml-4 mr-1.5">
          <h2 className="lg-bold">{`${filterLabels["title"]}${
            appliedFilterCount > 0 ? ` (${appliedFilterCount})` : ""
          }`}</h2>
          <CoButton
            onClick={() => closeFilterDrawer()}
            variant={ButtonVariant.Flat}
            icon="icon-x"
            size="sm"
          />
        </div>

        <Disclosure defaultOpen={!isMobile}>
          {({ open }) => (
            <section className="border-b border-b-border-base">
              <Disclosure.Button className="flex justify-between w-full px-4 my-4">
                <h3 className="font-bold">{filterLabels["papers"]}</h3>
                {open ? (
                  <i className="text-xl icon-chevron-up text-fg-muted" />
                ) : (
                  <i className="text-xl icon-chevron-down text-fg-muted" />
                )}
              </Disclosure.Button>
              <Disclosure.Panel className="px-4">
                <span className="mb-2 sm-md">
                  {filterLabels["published-since"]}
                </span>
                <div className="grid grid-cols-3 gap-2 mt-2">
                  <button
                    onClick={() => changePendingFilter("yearMin", undefined)}
                    key="all years"
                    className={`w-full py-2 px-3 rounded-lg sm-md ${
                      pendingFilters.yearMin === undefined
                        ? "bg-accent-faint text-accent-emphasis"
                        : "bg-white border border-border-base"
                    }`}
                  >
                    <span className="leading-[24px]">
                      {filterLabels["all-years"]}
                    </span>
                  </button>
                  {YEAR_FILTER_OPTIONS.map((year, index) => (
                    <button
                      onClick={() => changePendingFilter("yearMin", year)}
                      key={index}
                      className={`w-full py-2 px-3 rounded-lg sm-md ${
                        pendingFilters.yearMin === year
                          ? "bg-accent-faint text-accent-emphasis"
                          : "bg-white border border-border-base"
                      }`}
                    >
                      <span className="leading-[24px]">{year}</span>
                    </button>
                  ))}
                </div>
              </Disclosure.Panel>
              <Disclosure.Panel className="flex flex-col items-start px-4 mt-4">
                <div className="flex flex-row items-center max-w-[250px] sm:max-w-none w-full justify-between">
                  <div className="flex flex-col">
                    <span className="sm-md">
                      {filterLabels["exclude-preprints"]}
                    </span>
                    <span className="tiny-normal text-fg-muted">
                      {filterLabels["exclude-preprints-desc"]}
                    </span>
                  </div>
                  <Switch
                    active={Boolean(pendingFilters.excludePreprints)}
                    onChange={(value) =>
                      changePendingFilter(
                        "excludePreprints",
                        value ? true : undefined
                      )
                    }
                  />
                </div>
                <div className="mt-4 flex flex-row items-center max-w-[250px] sm:max-w-none w-full justify-between">
                  <span className="sm-md">
                    {filterLabels["has-open-access-pdf"]}
                  </span>
                  <Switch
                    active={Boolean(pendingFilters.openAccess)}
                    onChange={(value) =>
                      changePendingFilter(
                        "openAccess",
                        value ? true : undefined
                      )
                    }
                  />
                </div>
                <div className="mt-4 flex flex-row items-start max-w-[250px] sm:max-w-none w-full justify-between">
                  <div className="flex gap-2">
                    <span className="flex items-center gap-2 sm-md">
                      {filterLabels["ask-paper-available"]}
                      <Popover tooltipContent={<AskPaperFilterTooltip />}>
                        <BetaTag />
                      </Popover>
                    </span>
                  </div>
                  <Switch
                    active={Boolean(pendingFilters.hasValidChatPdf)}
                    onChange={(value) =>
                      changePendingFilter(
                        "hasValidChatPdf",
                        value ? true : undefined
                      )
                    }
                  />
                </div>
                <div className="w-full my-4">
                  <span className="block mb-2 sm-md">
                    {filterLabels["citations"]}&nbsp;&nbsp;&nbsp;≥
                  </span>
                  <NumberInput
                    value={pendingFilters.citationsMin}
                    onChange={(value) =>
                      changePendingFilter("citationsMin", value)
                    }
                    placeholder="Min 1"
                  />
                </div>
              </Disclosure.Panel>
            </section>
          )}
        </Disclosure>
        <Disclosure
          defaultOpen={
            !isMobile &&
            (pendingFilters.filterControlledStudies ||
              pendingFilters.filterHumanStudies ||
              pendingFilters.sampleSizeMin !== undefined ||
              pendingFilters.studyTypes !== undefined)
          }
        >
          {({ open }) => (
            <section className="border-b border-b-border-base">
              <Disclosure.Button
                className={`flex w-full justify-between text-base my-4 px-4`}
              >
                <div className="flex flex-row items-center">
                  <img
                    className="min-w-[24px] max-w-[24px] h-6 w-6 mr-[6px]"
                    alt="Sparkler"
                    src="/icons/sparkler.svg"
                  />
                  <h3 className="font-bold">{filterLabels["methods"]}</h3>
                </div>
                {open ? (
                  <i className="text-xl icon-chevron-up text-fg-muted" />
                ) : (
                  <i className="text-xl icon-chevron-down text-fg-muted" />
                )}
              </Disclosure.Button>
              <Disclosure.Panel className="flex flex-col items-start px-4 mt-6 mb-4">
                <div className="flex flex-row items-center gap-2">
                  <span className="sm-bold">{filterLabels["study-types"]}</span>
                  <Popover
                    interactive
                    maxWidth={334}
                    tooltipContent={<StudyTypesFilterTooltip />}
                  >
                    <i className="icon-info text-fg-muted" />
                  </Popover>
                </div>
                <div className="pt-4">
                  {StudyTypesFilterOptions.map((option, optionIdx) => {
                    const isSelected = Boolean(
                      pendingFilters.studyTypes?.includes(option.value)
                    );
                    return (
                      <div key={optionIdx} className="flex gap-3 mb-3">
                        <Checkbox
                          isChecked={isSelected}
                          onToggle={() => toggleStudyType(option.value)}
                        />
                        {option.elementTag}
                      </div>
                    );
                  })}
                </div>
                <div className="flex flex-row items-center gap-2 pt-4">
                  <span className="sm-bold">
                    {filterLabels["study-details"]}
                  </span>
                  <Popover
                    interactive
                    maxWidth={334}
                    tooltipContent={<StudyDetailsFilterTooltip />}
                  >
                    <i className="icon-info text-fg-muted" />
                  </Popover>
                </div>
                <div className="min-w-[265px] w-full mt-4 flex flex-col gap-y-4">
                  <div className="flex flex-row items-center max-w-[172px] sm:max-w-none w-full justify-between">
                    <span className="sm-md">
                      {filterLabels["controlled-studies"]}
                    </span>
                    <Switch
                      active={Boolean(pendingFilters.filterControlledStudies)}
                      onChange={(value) =>
                        changePendingFilter(
                          "filterControlledStudies",
                          value ? true : undefined
                        )
                      }
                    />
                  </div>
                  <div className="flex flex-row items-center max-w-[172px] sm:max-w-none w-full justify-between">
                    <span className="sm-md">
                      {filterLabels["human-studies"]}
                    </span>
                    <Switch
                      active={Boolean(pendingFilters.filterHumanStudies)}
                      onChange={(value) =>
                        changePendingFilter(
                          "filterHumanStudies",
                          value ? true : undefined
                        )
                      }
                    />
                  </div>
                  <div className="w-full">
                    <span className="block mb-2 sm-md">
                      {filterLabels["sample-size"]}&nbsp;&nbsp;&nbsp;≥
                    </span>
                    <NumberInput
                      value={pendingFilters.sampleSizeMin}
                      onChange={(value) =>
                        changePendingFilter("sampleSizeMin", value)
                      }
                      placeholder="Min 1"
                    />
                  </div>
                  <DurationInput
                    pendingFilters={pendingFilters}
                    changePendingFilter={changePendingFilter}
                  />
                </div>
              </Disclosure.Panel>
            </section>
          )}
        </Disclosure>
        <Disclosure
          defaultOpen={
            !isMobile && pendingFilters.sjrBestQuartileMax !== undefined
          }
        >
          {({ open }) => (
            <section className="border-b border-b-border-base">
              <Disclosure.Button
                className={`flex w-full justify-between my-4 px-4`}
              >
                <h3 className="font-bold">{filterLabels["journals"]}</h3>
                {open ? (
                  <i className="text-xl icon-chevron-up text-fg-muted" />
                ) : (
                  <i className="text-xl icon-chevron-down text-fg-muted" />
                )}
              </Disclosure.Button>
              <Disclosure.Panel className="flex flex-col items-start px-4 pb-4">
                <div className="flex flex-row items-center gap-x-2">
                  <span className="sm-md">
                    {filterLabels["sjr-quartile-rating"]}
                  </span>
                  <Popover
                    interactive
                    maxWidth={334}
                    tooltipContent={<SjrQuartilesTooltip />}
                  >
                    <i className="icon-info text-fg-muted" />
                  </Popover>
                </div>
                <div className="flex flex-col w-full pt-4 gap-y-2">
                  {[1, 2, 3, 4].map((quartile) => (
                    <button
                      onClick={() => {
                        changePendingFilter("sjrBestQuartileMax", quartile);
                      }}
                      key={quartile}
                      className={
                        quartile <=
                        (pendingFilters.sjrBestQuartileMax as number)
                          ? `bg-accent-faint border border-border-base px-3 py-2 rounded-lg`
                          : `bg-white border border-border-base px-3 py-2 rounded-lg`
                      }
                    >
                      <div className="flex items-center gap-3 sm-body">
                        Q{quartile}{" "}
                        <QuartileBar quartile={quartile} w="w-[46px]" />
                      </div>
                    </button>
                  ))}
                </div>
              </Disclosure.Panel>
            </section>
          )}
        </Disclosure>
        <Disclosure
          defaultOpen={!isMobile && pendingFilters.domains !== undefined}
        >
          {({ open }) => (
            <section className="border-b border-b-border-base">
              <Disclosure.Button
                className={`flex w-full justify-between my-4 px-4
                `}
              >
                <h3 className="font-bold">{filterLabels["domains"]}</h3>
                {open ? (
                  <i className="text-xl icon-chevron-up text-fg-muted" />
                ) : (
                  <i className="text-xl icon-chevron-down text-fg-muted" />
                )}
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 my-4">
                <DomainInput
                  changePendingFilter={changePendingFilter}
                  pendingFilters={pendingFilters}
                />
              </Disclosure.Panel>
            </section>
          )}
        </Disclosure>
        <Disclosure
          defaultOpen={!isMobile && pendingFilters.countries !== undefined}
        >
          {({ open }) => (
            <section className="border-b border-b-border-base">
              <Disclosure.Button
                className={`flex w-full justify-between my-4 px-4
                `}
              >
                <div className="flex flex-row items-center">
                  <img
                    className="min-w-[24px] max-w-[24px] h-6 w-6 mr-[6px]"
                    alt="Sparkler"
                    src="/icons/sparkler.svg"
                  />
                  <h3 className="font-bold">{filterLabels["country"]}</h3>
                </div>
                {open ? (
                  <i className="text-xl icon-chevron-up text-fg-muted" />
                ) : (
                  <i className="text-xl icon-chevron-down text-fg-muted" />
                )}
              </Disclosure.Button>
              <Disclosure.Panel className="flex flex-col items-start gap-3 px-4 my-4">
                <p className="sm-normal text-fg-muted">
                  {filterLabels["country-filter-desc"]}
                </p>
                <CountryInput
                  changePendingFilter={changePendingFilter}
                  pendingFilters={pendingFilters}
                />
              </Disclosure.Panel>
            </section>
          )}
        </Disclosure>
        <div className="fixed bottom-0 left-0 right-0 flex flex-col-reverse justify-between p-4 border-t bg-bgr-faint border-t-border-base sm:flex-row">
          <CoButton
            onClick={handleRemoveAllFitlers}
            variant={ButtonVariant.Flat}
            className="px-0"
            size="sm"
            label={filterLabels["reset-filters"]}
          />
          <CoButton
            onClick={handleApplyPendingFilters}
            size="sm"
            label={filterLabels["apply"]}
          />
        </div>
      </div>
    </Drawer>
  );
}

export default FilterDrawer;

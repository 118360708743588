import { getUploadedPapers } from "helpers/api";

async function verifyIsUploadedPaper(paperId: string) {
  const response = await getUploadedPapers();
  const paper = response.papers.find(
    (uploadedPaper) => uploadedPaper.paper_id === paperId
  );
  return paper !== undefined;
}

export default verifyIsUploadedPaper;

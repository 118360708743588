import { store } from "../../../store";
import {
  MixpanelActionSource,
  MixpanelNavigationSource,
  PaperEventSearchProperties,
  PaperProperties,
  ResultProperties,
  SearchResultsProperties,
  SearchSettingsProperties,
} from "./events";

export function buildPaperProperties(): PaperProperties {
  const state = store.getState().analytic;
  const paperItem = state?.paper;
  const resultItem = state?.result.item;

  return {
    paperId: paperItem?.id ?? resultItem?.paper_id ?? null,
    journalName: paperItem?.journal.title ?? resultItem?.journal ?? null,
    publishYear: paperItem?.year ?? resultItem?.year ?? null,
    paperTitle: paperItem?.title ?? resultItem?.title ?? null,
    firstAuthor: paperItem?.authors[0] ?? resultItem?.primary_author ?? null,
    doi: paperItem?.doi ?? resultItem?.doi ?? null,
    resultText:
      paperItem?.abstract_takeaway ?? resultItem?.display_text ?? null,
    resultType:
      paperItem !== undefined
        ? "takeaway"
        : resultItem !== null
        ? "answer"
        : null,
  };
}

export function buildResultProperties(): ResultProperties {
  const state = store.getState().analytic;
  const resultIndex = state?.result?.index;

  // Note: resultNumber rank is 1-indexed by convention.
  // See https://github.com/Consensus-NLP/common/pull/2231
  return {
    pageNumber: state?.result?.page,
    resultNumber: resultIndex === null ? null : resultIndex + 1,
  };
}

export function buildSearchSettingsProperties(): SearchSettingsProperties {
  const state = store.getState();

  return {
    proAnalysisOn: state.analytic.search.proAnalysisOn,
    filters: state.analytic.search.filters,
    filtersOn: state.analytic.search.filtersOn,
    query: state.analytic.search.query,
  };
}

export function buildSearchResultsProperties(): SearchResultsProperties {
  const state = store.getState();

  return {
    adjustedQuery: state.analytic.search.adjustedQuery,
    error: state.analytic.search.error,
    isNullResults: state.analytic.search.isNullResults,
    summarySuccess: state.search.summarySuccess,
    queryFeatures: state.analytic.search.queryFeatures,
  };
}

export function buildPaperEventSearchProperties(): PaperEventSearchProperties {
  const state = store.getState();
  return {
    filters: state.analytic.search.filters,
    query: state.analytic.search.query,
    adjustedQuery: state.analytic.search.adjustedQuery,
  };
}

export function getActionSource(): MixpanelActionSource {
  const state = store.getState();
  return state.analytic.actionSource;
}

export function getNavigationSource(): MixpanelNavigationSource {
  const state = store.getState();
  return state.analytic.navigationSource;
}

import { VenetianMask } from "components/Icon/local";
import useLabels from "hooks/useLabels";

export const IncognitoModeBanner = () => {
  const [incognitoModeLabels] = useLabels("incognito-mode");
  const incognitoMode = incognitoModeLabels["incognito-mode"];
  const incognitoModeDescription =
    incognitoModeLabels["incognito-mode-description"];

  return (
    <div className="bg-gray-200 p-4 mb-4 mx-4 rounded-lg mt-4">
      <div className="flex items-center gap-2">
        <VenetianMask className="text-fg-base" />
        <span className="sm-md">{incognitoMode}</span>
      </div>
      <p className="sm-normal mt-1">{incognitoModeDescription}</p>
    </div>
  );
};

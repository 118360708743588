type UsageListItemProps = {
  iconClassName: string;
  title: string;
  description?: string;
};

const UsageListItem = ({
  iconClassName,
  title,
  description,
}: UsageListItemProps) => (
  <li className="flex items-center gap-3">
    <i className={`text-2xl ${iconClassName} text-fg-muted`} />
    <div className="flex flex-col flex-1 gap-1">
      <h4 className="base-md">{title}</h4>
      {description && (
        <span className="text-fg-muted sm-normal">{description}</span>
      )}
    </div>
  </li>
);

export default UsageListItem;

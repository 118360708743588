import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import { ButtonVariant, CoButton } from "components/CoButton";
import { useSaveBookmarkModal } from "features/Bookmarks";
import {
  deleteSearchHistory,
  getSearchHistories,
  SearchHistory as SearchHistoryType,
  SEARCH_HISTORY_PAGE_SIZE,
} from "helpers/api";
import {
  getSearchPathFromSearchHistory,
  groupSearchHistoriesByDate,
} from "helpers/searchHistories";
import { MixpanelActionSource } from "helpers/services/mixpanel/events";
import useAnalytics from "hooks/useAnalytics";
import { useIncognitoMode } from "hooks/useIncognitoMode";
import useLabels from "hooks/useLabels";
import React, { useMemo } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import SidebarContentLoading from "../Sidebar/components/SidebarContentLoading";
import SearchHistoryEmpty from "./components/SearchHistoryEmpty";
import SearchHistoryGroup from "./components/SearchHistoryGroup";

export const SEARCH_HISTORIES_QUERY_KEY = ["get_search_histories"];

type SearchHistoryProps = {
  onNavigate: () => void;
};

function SearchHistory({ onNavigate }: SearchHistoryProps) {
  const queryClient = useQueryClient();
  const [searchHistoryLabels] = useLabels("screens.sidebar.search-history");
  const { setAnalyticActionSource } = useAnalytics();
  const { isIncognitoModeActive } = useIncognitoMode();

  const { openSaveBookmarkModalForSearch } = useSaveBookmarkModal();

  const {
    data,
    isLoading,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    SEARCH_HISTORIES_QUERY_KEY,
    async ({ pageParam = 0 }) => {
      const data = await getSearchHistories(
        SEARCH_HISTORY_PAGE_SIZE,
        pageParam
      );
      return data?.search_histories;
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage?.length === SEARCH_HISTORY_PAGE_SIZE)
          return allPages.length * SEARCH_HISTORY_PAGE_SIZE;
        return undefined;
      },
    }
  );

  // Concatenate all searches into a single array
  const allData = useMemo(
    () => data?.pages.flatMap((page) => page),
    [data?.pages]
  );
  const groupedData = useMemo(
    () => groupSearchHistoriesByDate(allData || []),
    [allData]
  );

  const [infiniteRef] = useInfiniteScroll({
    loading: isFetchingNextPage,
    hasNextPage: !!hasNextPage,
    onLoadMore: fetchNextPage,
    disabled: isError,
    rootMargin: "0px 0px 400px 0px",
  });

  const handleDeleteSearchHistory = async (id: string) => {
    await deleteSearchHistory(id);
    // Refetch the search histories to remove the deleted item from the list
    queryClient.invalidateQueries({
      queryKey: SEARCH_HISTORIES_QUERY_KEY,
      exact: true,
    });
  };

  const handleSaveSearchHistory = (searchHistory: SearchHistoryType) => {
    setAnalyticActionSource(MixpanelActionSource.Sidebar);
    const searchUrl = getSearchPathFromSearchHistory(searchHistory);
    openSaveBookmarkModalForSearch(searchUrl);
  };

  if (isError || groupedData === undefined) {
    return (
      <div className="flex flex-col items-center gap-2 m-5 mx-8 text-center">
        {searchHistoryLabels["error"]}
        <CoButton
          variant={ButtonVariant.Secondary}
          onClick={() => fetchNextPage()}
          label={searchHistoryLabels["retry"]}
        />
      </div>
    );
  }

  if (isLoading) {
    return <SidebarContentLoading />;
  }

  return (
    <div
      data-testid="search-history-container"
      className={classNames(
        "flex-1 pb-[104px] bottom-fade-out max-h-[100%] scrollbar-hide",
        isIncognitoModeActive ? "overflow-hidden" : "overflow-auto"
      )}
    >
      {groupedData.count === 0 ? (
        <div className="flex items-center justify-center w-full h-full pt-4">
          <SearchHistoryEmpty onNavigate={onNavigate} />
        </div>
      ) : (
        <div className="flex flex-col w-full gap-6">
          {groupedData.today.length > 0 && (
            <SearchHistoryGroup
              label={searchHistoryLabels["today"]}
              searchHistories={groupedData.today}
              onNavigate={onNavigate}
              onDelete={handleDeleteSearchHistory}
              onSave={handleSaveSearchHistory}
            />
          )}
          {groupedData.lastSevenDays.length > 0 && (
            <SearchHistoryGroup
              label={searchHistoryLabels["last-seven-days"]}
              searchHistories={groupedData.lastSevenDays}
              onNavigate={onNavigate}
              onDelete={handleDeleteSearchHistory}
              onSave={handleSaveSearchHistory}
            />
          )}
          {groupedData.lastThirtyDays.length > 0 && (
            <SearchHistoryGroup
              label={searchHistoryLabels["last-thirty-days"]}
              searchHistories={groupedData.lastThirtyDays}
              onNavigate={onNavigate}
              onDelete={handleDeleteSearchHistory}
              onSave={handleSaveSearchHistory}
            />
          )}
          {groupedData.lastYear.length > 0 && (
            <SearchHistoryGroup
              label={searchHistoryLabels["last-year"]}
              searchHistories={groupedData.lastYear}
              onNavigate={onNavigate}
              onDelete={handleDeleteSearchHistory}
              onSave={handleSaveSearchHistory}
            />
          )}
        </div>
      )}
      {hasNextPage && (
        <div ref={infiniteRef} className="text-center">
          <SidebarContentLoading />
        </div>
      )}
    </div>
  );
}

export default SearchHistory;

import classNames from "classnames";
import { ButtonVariant, CoButton } from "components/CoButton";
import useHotkey from "hooks/useHotkey";
import useIsMac from "hooks/useIsMac";
import useLabels from "hooks/useLabels";
import useModalStack from "hooks/useModalStack/useModalStack";
import NewThreadModal, {
  NEW_THREAD_MODAL_ID,
} from "./components/NewThreadModal";

type NewThreadButtonProps = {
  isCollapsed?: boolean;
};

function NewThreadButton({ isCollapsed = false }: NewThreadButtonProps) {
  const [labels] = useLabels("features.new-thread-button");
  const { openModal } = useModalStack(NEW_THREAD_MODAL_ID);

  // Configure hotkey to open the modal
  const isMac = useIsMac();
  const hotkeys = isMac ? ["Meta", "k"] : ["Control", "k"];
  useHotkey(hotkeys, openModal);

  return (
    <>
      <CoButton
        onClick={openModal}
        icon="icon-message-circle-plus"
        label={isCollapsed ? null : labels["new-thread"]}
        size="sm"
        className={classNames(isCollapsed ? "" : "w-full ml-4 mr-4")}
        variant={ButtonVariant.Secondary}
      />
      <NewThreadModal />
    </>
  );
}

export default NewThreadButton;

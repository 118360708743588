import { ButtonVariant, CoButton } from "components/CoButton";
import { LanguageSelectBox, modes } from "components/LanguageSelectBox";
import Modal from "components/Modal";
import { resultsPagePath } from "helpers/pageUrl";
import { queryToResultPageParams } from "helpers/search";
import { LangCodeType, LanguageCode } from "helpers/sharedDataConstants";
import useLabels from "hooks/useLabels";
import { useAppDispatch, useAppSelector } from "hooks/useStore";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { setSearchLanguage } from "store/slices/user";

export const QueryLanguageModal = () => {
  const router = useRouter();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedLang, setSelectedLang] = useState<LanguageCode>();
  const { queryFeatures } = useAppSelector((state) => state.search.queryData);

  const { lang } = useAppSelector((state) => state.user.settings);
  const dispatch = useAppDispatch();

  const [labels] = useLabels("query-language-modal");

  useEffect(() => {
    if (queryFeatures?.is_translated && !lang && !router.query.lang) {
      setIsOpen(true);
    }
  }, [queryFeatures?.is_translated, lang]);

  const dismissModal = () => {
    setIsOpen(false);
  };

  const handleLangChange = (item: LanguageCode) => {
    setSelectedLang(item);
  };

  const handleApply = async () => {
    const langCode = selectedLang?.code as LangCodeType;
    dispatch(setSearchLanguage(langCode || "en"));
    setIsOpen(false);
    await router.push(
      resultsPagePath(router.query.q as string, {
        ...queryToResultPageParams(router.query),
        lang: langCode,
      })
    );
    router.reload();
  };

  const handleCancel = () => {
    dispatch(setSearchLanguage("en"));
    setIsOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      onClose={dismissModal}
      title=""
      contentClassName="m-0"
      headerClassName="border-b-0"
    >
      <div>
        <section className="px-4 pb-4">
          <div className="flex justify-center mb-3">
            <img src="/icons/languages.svg" alt="languages" />
          </div>
          <h1
            className="text-center lg-bold"
            dangerouslySetInnerHTML={{ __html: labels["title"] }}
          ></h1>
          <div className="mt-8 mb-1">
            <h3 className="sm-md">{labels["subtitle"]}</h3>
            <p className="sm-normal text-fg-muted">{labels["description"]}</p>
          </div>
          <div>
            <LanguageSelectBox
              onChange={handleLangChange}
              mode={modes.insideModalMode}
            />
          </div>
        </section>
        <section className="flex justify-end gap-2 p-4 border-t border-t-border-base">
          <CoButton
            label={labels["cancel-btn-text"]}
            variant={ButtonVariant.Tertiary}
            size="sm"
            onClick={handleCancel}
          />
          <CoButton
            label={labels["apply-btn-text"]}
            size="sm"
            onClick={handleApply}
            disabled={!selectedLang}
          />
        </section>
      </div>
    </Modal>
  );
};

import { Dialog, Transition } from "@headlessui/react";
import classNames from "classnames";
import { useAppSelector } from "hooks/useStore";
import { Fragment, ReactNode, useEffect, useRef } from "react";

type EmptyModalProps = {
  blurBackground?: boolean;
  children?: ReactNode;
  onClose: () => void;
  open?: boolean;
};

/**
 * @component EmptyModal
 * @description Renders a modal with no content or styling, so that any body content can be
 *   rendered as children. Centers the content on the screen with a dark overlay in the background.
 *   Renders as a full-width drawer on mobile.
 */
function EmptyModal({
  onClose,
  open,
  children,
  blurBackground = false,
}: EmptyModalProps) {
  // Fix for scroll freezing, see: https://github.com/tailwindlabs/headlessui/issues/1199
  useEffect(() => {
    if (!open) {
      document.documentElement.style.overflow = "auto";
    }
  }, [open]);
  let refDiv = useRef(null);

  const isMobile = useAppSelector((state) => state.setting.isMobile);

  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog
          as="div"
          open={open}
          className="relative z-[999]"
          onClose={() => onClose()}
          static
          initialFocus={refDiv}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className={classNames(
                "fixed inset-0 bg-black bg-opacity-60",
                blurBackground && "backdrop-blur-sm"
              )}
            />
          </Transition.Child>

          <div data-testid="modal" className="fixed inset-0 overflow-y-auto">
            <div className="flex items-end justify-center w-full h-full sm:items-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-150"
                enterFrom={
                  isMobile
                    ? "opacity-0 translate-y-[200px]"
                    : "opacity-0 scale-95"
                }
                enterTo={
                  isMobile
                    ? "opacity-100 translate-y-0"
                    : "opacity-100 scale-100"
                }
                leave="ease-in duration-150"
                leaveFrom={
                  isMobile
                    ? "opacity-100 translate-y-0"
                    : "opacity-100 scale-100"
                }
                leaveTo={
                  isMobile ? "opacity-0 translate-y-full" : "opacity-0 scale-95"
                }
              >
                <Dialog.Panel className="w-full sm:w-fit" ref={refDiv}>
                  {children}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default EmptyModal;

import Modal from "components/Modal";
import { BOOKMARK_LISTNAME_MAX_LENGTH } from "constants/config";
import useLabels from "hooks/useLabels";
import React, { useCallback, useEffect, useRef, useState } from "react";
import useBookmarks from "../hooks/useBookmarks";

type CreateNewBookmarkListModalProps = {
  initialValue?: string;
  onClose: (value: boolean) => void;
  open?: boolean;
};

/**
 * @component CreateNewBookmarkListModal
 * @description Component for save search modal
 * @example
 * return (
 *   <CreateNewBookmarkListModal
 *    open={true}
 *    onClose={fn}
 *   />
 * )
 */
function CreateNewBookmarkListModal({
  open,
  onClose,
  initialValue = "",
}: CreateNewBookmarkListModalProps) {
  const [createNewListLabels] = useLabels("create-new-list-modal");
  const [newListInputValue, setNewListInputValue] =
    useState<string>(initialValue);
  const [isCreatingList, setIsCreatingList] = useState<Boolean>(false);
  const inputRef = useRef<any>(null);

  const { isOutOfFreeBookmarkLists, createNewBookmarkList } = useBookmarks();

  useEffect(() => {
    setNewListInputValue(initialValue);
  }, [initialValue]);

  const handleClickCancel = useCallback(async () => {
    onClose(true);
  }, [onClose]);

  const handleClickCreateNewList = async () => {
    if (isCreatingList || isOutOfFreeBookmarkLists) return;

    setIsCreatingList(true);
    try {
      await createNewBookmarkList(newListInputValue.trim());
      onClose(true);
    } catch (error) {}
    setIsCreatingList(false);
  };

  const onChangeNewListLabel = async (e: any) => {
    setNewListInputValue(e.target.value);
  };

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        if (inputRef && inputRef.current) {
          inputRef.current.focus();
        }
      }, 500);
    }
  }, [open, inputRef]);

  return (
    <Modal
      open={open}
      onClose={handleClickCancel}
      title={createNewListLabels["title"]}
      onButtonClicked={handleClickCreateNewList}
      buttonDisabled={newListInputValue.trim().length === 0}
      buttonText={createNewListLabels["button-create"]}
    >
      <div className="flex flex-col gap-2 text-fg-base">
        <h2 className="base-bold">{createNewListLabels["list-name"]}</h2>
        <input
          ref={inputRef}
          type="text"
          className="p-4 border rounded-lg"
          placeholder={createNewListLabels["name"]}
          value={newListInputValue}
          maxLength={BOOKMARK_LISTNAME_MAX_LENGTH}
          onChange={onChangeNewListLabel}
          onKeyUp={(e) => {
            if (e.keyCode == 13) {
              handleClickCreateNewList();
            }
          }}
          enterKeyHint="go"
        />
        <span className="tiny-normal text-fg-muted">
          {createNewListLabels["maximum-characters"].replace(
            "{max-value}",
            BOOKMARK_LISTNAME_MAX_LENGTH
          )}
        </span>
      </div>
    </Modal>
  );
}

export default CreateNewBookmarkListModal;

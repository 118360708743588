import Popover from "components/Popover";
import { usePremiumFeatureModal } from "components/Subscription";
import Switch from "components/Switch/Switch";
import Tooltip from "components/Tooltip";
import useCredits from "hooks/useCredits";
import useLabels from "hooks/useLabels";
import useProToggle from "hooks/useProToggle";
import { useAppDispatch } from "hooks/useStore";
import React, { useState } from "react";
import { CreditedFeature } from "store/slices/subscription";
import ProToggleTooltip from "./ProToggleTooltip";

interface ProToggleProps {
  isLoading?: boolean;
}

function ProToggle({ isLoading }: ProToggleProps) {
  const dispatch = useAppDispatch();
  const [proToggleLabels] = useLabels("pro-toggle");
  const [tooltipInstance, setTooltipInstance] = useState<any>(null);

  const { openPremiumFeatureModal } = usePremiumFeatureModal();
  const handleClickUpgradePremium = async () => {
    openPremiumFeatureModal(CreditedFeature.PRO_ANALYSIS);
    tooltipInstance?.hide();
  };

  const {
    isProToggleOn,
    changeProToggle,
    isNotEnoughResults,
    isNuanceRequired,
    canEnableProToggle,
  } = useProToggle();

  const { isPremium } = useCredits();

  // Always allow the switch to be turned back off, even when the pro feature should be disabled.
  const isSwitchDisabled = isLoading || (!isProToggleOn && !canEnableProToggle);

  const renderSwitch = () => {
    return (
      <div className="flex items-center gap-x-1.5">
        <Switch
          disabled={isSwitchDisabled}
          active={isProToggleOn}
          onChange={(state) => changeProToggle(state)}
        />
        <span className="text-fg-base sm-md">{proToggleLabels["pro"]}</span>
      </div>
    );
  };

  if (isPremium) {
    return (
      <Tooltip
        content={proToggleLabels["enable-pro-analysis"]}
        placement="bottom"
      >
        {renderSwitch()}
      </Tooltip>
    );
  }

  return (
    <div
      data-intercom-target="Pro toggle"
      className="flex items-center gap-x-2"
    >
      <Popover
        disabled={isLoading}
        interactive={canEnableProToggle}
        touch={false}
        maxWidth={340}
        onShown={(instance: any) => {
          setTooltipInstance(instance);
        }}
        onHidden={(instance: any) => {
          setTooltipInstance(instance);
        }}
        tooltipContent={
          <ProToggleTooltip
            isNotEnoughResults={isNotEnoughResults}
            isNuanceRequired={isNuanceRequired}
            onClickUpgradeToPremium={handleClickUpgradePremium}
          />
        }
      >
        {renderSwitch()}
      </Popover>
    </div>
  );
}

export default ProToggle;

import classNames from "classnames";
import { FeatureFlag } from "enums/feature-flag";
import NewThreadButton from "features/NewThreadButton";
import NewSearchButton from "features/SearchHistory/components/NewSearchButton";
import SidebarNavigation, {
  NavigationTab,
} from "features/SearchHistory/components/SidebarNavigation";
import ThreadHistory from "features/ThreadHistory";
import { useIncognitoMode } from "hooks/useIncognitoMode";
import useIsFeatureEnabled from "hooks/useIsFeatureEnabled";
import useLabels from "hooks/useLabels";
import { useSidebar } from "hooks/useSidebar";
import { useState } from "react";
import SearchHistory from "../../SearchHistory";
import { SidebarTab } from "../Sidebar";
import { IncognitoModeBanner } from "./IncognitoModeBanner";
import SidebarBookmarksList from "./SidebarBookmarksList";

type SignedInContentProps = {
  selectedTab: SidebarTab;
  setSelectedTab: (selectedTab: SidebarTab) => void;
};

function SignedInContent({
  selectedTab,
  setSelectedTab,
}: SignedInContentProps) {
  const { toggleSidebar } = useSidebar();
  const [sidebarLabels] = useLabels("screens.sidebar");
  const isResearchHubEnabled = useIsFeatureEnabled(FeatureFlag.RESEARCH_HUB);
  const [navigationTab, setNavigationTab] = useState<NavigationTab>(
    NavigationTab.Home
  );

  const handleOnNavigate = () => {
    toggleSidebar();
  };

  const isThreadsEnabled = useIsFeatureEnabled(FeatureFlag.THREADS);

  const { isIncognitoModeActive } = useIncognitoMode();

  return (
    <div className="h-full overflow-hidden">
      {isThreadsEnabled && !isResearchHubEnabled && (
        <div className="flex justify-center mb-4">
          <NewThreadButton />
        </div>
      )}
      {isResearchHubEnabled && (
        <>
          <div className="flex justify-center mb-4">
            {isThreadsEnabled ? <NewThreadButton /> : <NewSearchButton />}
          </div>
          <SidebarNavigation onTabChange={setNavigationTab} />
          {isIncognitoModeActive && <IncognitoModeBanner />}
          <SearchHistory onNavigate={handleOnNavigate} />
        </>
      )}
      {!isResearchHubEnabled && (
        <>
          <div className="flex w-full justify-items-stretch">
            <button
              style={{ fontVariantLigatures: "none" }}
              className={classNames(
                "p-4 md:w-auto w-full flex flex-1 gap-3 items-center justify-center",
                "ring-transparent ring-opacity-60 focus:outline-none focus:ring-2",
                "hover:bg-bgr-faint",
                selectedTab == SidebarTab.History
                  ? "text-fg-base sm-md border-b-2 border-b-fg-base"
                  : "text-fg-muted sm-normal border-b-2"
              )}
              onClick={() => {
                setSelectedTab(SidebarTab.History);
              }}
            >
              <i
                className={classNames(
                  "icon-history text-xl",
                  selectedTab == SidebarTab.History
                    ? "text-fg-base"
                    : "text-fg-muted"
                )}
              />
              <span>{sidebarLabels["search-history"]["history"]}</span>
            </button>
            <button
              style={{ fontVariantLigatures: "none" }}
              className={classNames(
                "p-4 leading-5 md:w-auto w-full flex flex-1 gap-2 items-center justify-center",
                "ring-transparent ring-opacity-60 focus:outline-none focus:ring-2",
                "hover:bg-bgr-faint",
                selectedTab == SidebarTab.Bookmarks
                  ? "text-fg-base sm-md border-b-2 border-b-fg-base"
                  : "text-fg-muted sm-normal border-b-2"
              )}
              onClick={() => {
                setSelectedTab(SidebarTab.Bookmarks);
              }}
            >
              <i
                className={classNames(
                  "icon-bookmark text-xl",
                  selectedTab == SidebarTab.Bookmarks
                    ? "text-fg-base"
                    : "text-fg-muted"
                )}
              />
              {sidebarLabels["lists"]}
            </button>
          </div>
          {selectedTab === SidebarTab.Bookmarks && (
            <SidebarBookmarksList onNavigate={handleOnNavigate} />
          )}
          {selectedTab === SidebarTab.History && (
            <>
              {isIncognitoModeActive && <IncognitoModeBanner />}
              {isThreadsEnabled ? (
                <ThreadHistory onNavigate={handleOnNavigate} />
              ) : (
                <SearchHistory onNavigate={handleOnNavigate} />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default SignedInContent;

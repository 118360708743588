import { LangCodeType } from "./language";

export interface IClerkPublicMetaData {
  stripeCustomerId: string;
  created_date: string;
  // The last time the user's credits were set back to full. Reset every CREDIT_RESET_INTERVAL_DAYS days.
  last_reset_date: string;
  // A list of paper_ids that the user has opened the study snapshot for in this period
  used_study_paper_ids: string[];
  // A list of search_ids for queries that the user ran with pro analysis enabled in this period
  used_pro_analysis_queries: string[];
  // A dictionary that maps paperIds to the number of ask paper responses generated for that paper in this period.
  ask_paper_messages_per_paper_count: {
    [key: string]: number;
  };
  // A list of search_ids for queries that the user has opened the consensus meter snapshot for in this period
  used_consensus_snapshot_queries: string[];
  is_premium: boolean;
  settings: {
    lang?: LangCodeType;
  };
  hasUsedStudentTrial?: boolean;
  is_incognito_mode_active?: boolean;
  is_research_hub_banner_hidden?: boolean;
}

export function convertUserPublicMetadata(publicMetadata: {
  [k: string]: unknown;
}): IClerkPublicMetaData {
  let userPublicMetadata: IClerkPublicMetaData = {
    stripeCustomerId: publicMetadata.stripeCustomerId as string,
    created_date: publicMetadata.created_date as string,
    last_reset_date: publicMetadata.last_reset_date as string,
    used_study_paper_ids: publicMetadata.used_study_paper_ids as string[],
    used_pro_analysis_queries:
      publicMetadata.used_pro_analysis_queries as string[],
    ask_paper_messages_per_paper_count:
      publicMetadata.ask_paper_messages_per_paper_count as {
        [key: string]: number;
      },
    used_consensus_snapshot_queries:
      publicMetadata.used_consensus_snapshot_queries as string[],
    is_premium: publicMetadata.is_premium as boolean,
    settings: publicMetadata.settings as { lang?: LangCodeType },
    hasUsedStudentTrial:
      (publicMetadata.hasUsedStudentTrial as boolean) || false, // Initialize to false if undefined,
    is_incognito_mode_active:
      publicMetadata.is_incognito_mode_active as boolean,
    is_research_hub_banner_hidden:
      publicMetadata.is_research_hub_banner_hidden as boolean,
  };

  return userPublicMetadata;
}

export interface IClerkOrganizationPublicMetadata {
  accepted_into_api_beta: boolean;
}

export function convertOrganizationPublicMetadata(publicMetadata: {
  [k: string]: unknown;
}): IClerkOrganizationPublicMetadata {
  let orgPublicMetadata: IClerkOrganizationPublicMetadata = {
    accepted_into_api_beta: publicMetadata.accepted_into_api_beta as boolean,
  };
  return orgPublicMetadata;
}

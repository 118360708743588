import { useUser } from "@clerk/nextjs";
import Popover from "components/Popover";
import IncognitoModeUserPicture from "components/TopBar/IncognitoModeUserPicture";
import UserMenuContents from "components/TopBar/UserMenuContents";
import useCredits from "hooks/useCredits";
import { useIncognitoMode } from "hooks/useIncognitoMode";
import useLabels from "hooks/useLabels";

export const SidebarContentAccountMenu = () => {
  const { user } = useUser();
  const { subscriptionImgSrc, isPremium } = useCredits();
  const { isIncognitoModeActive } = useIncognitoMode();
  const [incognitoLabel] = useLabels("incognito-mode.incognito");

  if (!user) return null;

  return (
    <div className="mx-2 mb-2">
      <Popover
        interactive
        tooltipContent={<UserMenuContents />}
        className="p-0 w-full"
        placement="bottom-end"
        trigger="click"
        contentWidthFull
      >
        <button className="flex items-center w-full gap-3 px-2 py-3 rounded-lg hover:bg-bgr-faint">
          <div className="relative flex items-center justify-center w-6 h-6 rounded-full bg-bgr-faint">
            {isIncognitoModeActive ? (
              <IncognitoModeUserPicture />
            ) : user?.imageUrl ? (
              <img
                src={user.imageUrl}
                className="rounded-full"
                alt="user profile"
              />
            ) : (
              <span>
                <i className="icon-user" />
              </span>
            )}
            {isPremium && (
              <span className="absolute flex items-center justify-center w-4 h-4 bg-white rounded-full -top-1 -right-1">
                <img
                  src={subscriptionImgSrc}
                  alt="premium"
                  className="w-3 h-3"
                />
              </span>
            )}
          </div>
          <span className="sm-normal text-fg-muted">
            {isIncognitoModeActive
              ? incognitoLabel
              : `${user?.firstName} ${user?.lastName}`}
          </span>
        </button>
      </Popover>
    </div>
  );
};

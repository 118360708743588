import { ButtonVariant, CoButton } from "components/CoButton";
import { usePremiumFeatureModal } from "components/Subscription";
import { pricingPageUrl } from "helpers/pageUrl";
import { isSubscriptionPremium } from "helpers/subscription";
import useLabels from "hooks/useLabels";
import { useAppSelector } from "hooks/useStore";
import React, { useCallback } from "react";
import { CreditedFeature } from "store/slices/subscription";

/**
 * @component CreateBookmarkItemTooltip
 * @description Tooltip for Create bookmark item
 */

interface CreateBookmarkItemTooltipProps {
  onClick?: () => void;
}

function CreateBookmarkItemTooltip({
  onClick,
}: CreateBookmarkItemTooltipProps) {
  const [bookmarkPremiumTooltipLabels] = useLabels(
    "tooltips.bookmark-premium-tooltip"
  );
  const subscription = useAppSelector(
    (state) => state.subscription.subscription
  );
  const { openPremiumFeatureModal } = usePremiumFeatureModal();

  const handleClickUpgradePremium = useCallback(async () => {
    if (onClick != null) {
      onClick();
    }

    openPremiumFeatureModal(CreditedFeature.BOOKMARKS);
  }, [openPremiumFeatureModal, onClick]);

  const isPremium = isSubscriptionPremium(subscription);

  return (
    <div
      className="p-2 leading-tight text-fg-base"
      data-testid="tooltip-create-bookmark-list"
    >
      <div>
        {!isPremium && (
          <>
            <h2 className="text-lg base-bold">
              {bookmarkPremiumTooltipLabels["free-plan"]}
            </h2>
            <p
              className="mt-2 text-fg-base sm-normal"
              dangerouslySetInnerHTML={{
                __html:
                  bookmarkPremiumTooltipLabels["limited-items-description"],
              }}
            ></p>

            <div className="flex justify-between gap-2 mt-5">
              <CoButton
                size="sm"
                variant={ButtonVariant.Tertiary}
                href={pricingPageUrl()}
                label={bookmarkPremiumTooltipLabels["view-pricing"]}
              />
              <CoButton
                size="sm"
                variant={ButtonVariant.Primary}
                onClick={handleClickUpgradePremium}
                icon="/icons/premium.svg"
                label={bookmarkPremiumTooltipLabels["upgrade-to-premium"]}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default CreateBookmarkItemTooltip;

import { ButtonVariant, CoButton } from "components/CoButton";
import Popover from "components/Popover";
import { ANNUAL_PRICE_DISCOUNT } from "constants/config";
import { formatCurrency } from "helpers/format";
import { OrganizationRole } from "helpers/organization";
import pluralize from "helpers/pluralize";
import { PaymentDetailResponse } from "helpers/stripe";
import {
  getCancellationExpiryDate,
  UserSubscription,
} from "helpers/subscription";
import useLabels from "hooks/useLabels";
import useOrg from "hooks/useOrg";
import React, { useMemo } from "react";
import LoadingSubscriptionCard from "../LoadingSubscriptionCard/LoadingSubscriptionCard";

type Props = {
  interval: string;
  isLifeTimePremium: boolean;
  isLoading?: boolean;
  onCancelClick: () => void;
  onManagePaymentMethod: () => void;
  onResume: () => void;
  onUpgradeToAnnualClick: () => void;
  paymentMethodDetail?: PaymentDetailResponse;
  unitAmount: number;
  userSubscription: UserSubscription;
};

/**
 * @component SubscriptionBillingCard
 * @description designed for subscription billing card
 * @example
 * return (
 *   <SubscriptionBillingCard
 *      interval="year"
 *      isLifeTimePremium={false}
 *      isLoading={isPageLoading}
 *      onCancelClick={onCancelClick}
 *      onManagePaymentMethod={clickedManagePaymentMethod}
 *      onResume={onResume}
 *      onUpgradeToAnnualClick={onUpgradeToAnnualClick}
 *      paymentMethodDetail={paymentMethodDetail}
 *      unitAmount={11.99}
 *      userSubscription={subscription.user}
 *    />
 * )
 */
const SubscriptionBillingCard = ({
  interval,
  isLifeTimePremium,
  isLoading,
  onCancelClick,
  onManagePaymentMethod,
  onResume,
  onUpgradeToAnnualClick,
  paymentMethodDetail,
  unitAmount,
  userSubscription,
}: Props) => {
  const [billingLabels] = useLabels("billing-card");
  const dateOptions: Intl.DateTimeFormatOptions = {
    month: "long",
    day: "2-digit",
    year: "numeric",
  };
  const dateFormatPeriodEnd = new Date(
    userSubscription.current_period_end * 1000
  );

  const { orgRole, purchasedSeats } = useOrg();

  const price = useMemo(() => {
    return interval === "year" ? unitAmount / 12 : unitAmount;
  }, [interval, unitAmount]);

  let futureExpirationDate = null;
  const cancellationDate = getCancellationExpiryDate(userSubscription);
  if (cancellationDate && Date.now() <= cancellationDate.getTime()) {
    futureExpirationDate = cancellationDate;
  }

  if (isLoading) {
    return <LoadingSubscriptionCard />;
  }

  let priceText = (
    <>
      {(orgRole as OrganizationRole) === OrganizationRole.OWNER ? (
        <span>
          {pluralize(billingLabels["per-month-x-seats"], {
            price: formatCurrency((price / 100) * purchasedSeats),
            perSeatPrice: formatCurrency(price / 100),
            purchasedSeats: purchasedSeats,
          })}
        </span>
      ) : (
        <span>
          {formatCurrency(price / 100)} {billingLabels["per-month"]}
        </span>
      )}{" "}
      · <span className="mr-1">{billingLabels["renews-on"]}</span>
      {dateFormatPeriodEnd.toLocaleString("en-US", dateOptions)}
    </>
  );

  let subtitle = isLifeTimePremium
    ? billingLabels["lifetime-membership"]
    : !!futureExpirationDate
    ? billingLabels["cancelled"].replace(
        "{date}",
        futureExpirationDate.toLocaleString(undefined, {
          month: "long",
          day: "2-digit",
          year: "numeric",
        })
      )
    : priceText;

  return (
    <div className="flex flex-col items-start h-full gap-4">
      <h3 className="lg-bold">{billingLabels["title"]}</h3>
      <div className="flex-1 w-full">
        <div className="text-fg-muted">{subtitle}</div>
        {paymentMethodDetail && paymentMethodDetail.type == "card" && (
          <div className="flex items-center mt-4">
            <div className="flex-1">
              <h4 className="capitalize base-md">
                {billingLabels["card"].replace(
                  "{brand}",
                  paymentMethodDetail.card.brand
                )}
              </h4>
              <div className="mt-1 sm-normal text-fg-muted">
                ****{paymentMethodDetail.card.last4} {billingLabels["expiring"]}{" "}
                {paymentMethodDetail.card.exp_month}/
                {paymentMethodDetail.card.exp_year}
              </div>
            </div>
            <Popover
              interactive
              arrow={false}
              trigger="click"
              placement="bottom"
              tooltipContent={
                <button
                  className="flex items-center gap-3 p-2 tiny-md"
                  onClick={onManagePaymentMethod}
                >
                  <i className="text-xl icon-credit-card" />
                  {billingLabels["manage-payment-method"]}
                </button>
              }
            >
              <CoButton
                variant={ButtonVariant.Flat}
                icon="icon-ellipsis"
                size="sm"
              />
            </Popover>
          </div>
        )}
      </div>
      {!!futureExpirationDate ? (
        <CoButton
          label={billingLabels["renew"]}
          onClick={onResume}
          size="sm"
          variant={ButtonVariant.Primary}
        />
      ) : !isLifeTimePremium ? (
        <div className="flex gap-2">
          {interval !== "year" && (
            <CoButton
              label={billingLabels["annual-discount"].replace(
                "{discount}",
                ANNUAL_PRICE_DISCOUNT
              )}
              onClick={onUpgradeToAnnualClick}
              size="sm"
              variant={ButtonVariant.Secondary}
            />
          )}
          <CoButton
            label={billingLabels["cancel"]}
            onClick={onCancelClick}
            size="sm"
            variant={ButtonVariant.Flat}
          />
        </div>
      ) : null}
    </div>
  );
};

export default SubscriptionBillingCard;

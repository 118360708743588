export function numberWithCommas(x: string) {
  return x.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function capitalizeString(value: string) {
  const words = value.split(" ");

  return words
    .map((word) => {
      return word[0].toUpperCase() + word.substring(1);
    })
    .join(" ");
}
export const camelCaseToLabel = (camelCase: string): string => {
  const words = camelCase.split(/(?=[A-Z])/);
  const firstWord = capitalizeString(words[0]);
  const theRest = words.map((word) => word.toLowerCase()).slice(1);
  const labelWords = [firstWord, ...theRest];
  return labelWords.join(" ");
};

export function formatCurrency(value: number) {
  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "usd",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  return currencyFormatter.format(value);
}

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

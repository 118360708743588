import { useAuth } from "@clerk/nextjs";
import { CoButton } from "components/CoButton";
import path from "constants/path";
import { isSignUpOrSignInPage } from "helpers/pageUrl";
import useLabels from "hooks/useLabels";
import Link from "next/link";
import { useRouter } from "next/router";
import OpenSidebarButton from "./OpenSidebarButton";

/**
 * @component TopBar
 * @description TopBar component to all of the pages.
 */
const TopBar = () => {
  const router = useRouter();
  const [generalLabels] = useLabels("general");
  const { isSignedIn, isLoaded } = useAuth();

  const redirect = `/#/?redirect_url=${
    [path.SIGN_IN, path.SIGN_UP].includes(router?.route) ? "" : router?.asPath
  }`;

  const showSignInButtons = !isSignUpOrSignInPage(router.pathname);

  return (
    <div data-testid="topbar" className="flex justify-between">
      <OpenSidebarButton />

      {/* Only show signup button for logged out users on non sign-up or sign-in page */}
      {isLoaded && !isSignedIn && showSignInButtons && (
        <Link href={`${path.SIGN_UP}${redirect}`} passHref legacyBehavior>
          <CoButton label={generalLabels["signup"]} />
        </Link>
      )}
    </div>
  );
};

export default TopBar;

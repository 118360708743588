import { useAuth } from "@clerk/nextjs";
import { ButtonVariant, CoButton } from "components/CoButton";
import Popover from "components/Popover";
import CreateBookmarkItemTooltip from "components/Popover/CreateBookmarkItemTooltip/CreateBookmarkItemTooltip";
import Tooltip from "components/Tooltip";
import path from "constants/path";
import { useBookmarks } from "features/Bookmarks";
import { useRouter } from "next/router";
import React, { useCallback, useState } from "react";

type BookmarkCurrentPageButtonProps = {
  dataTestId?: string;
  isDisabled?: boolean;
  isBookmarked: boolean;
  label: string;
  onClick?: () => void;
};

/**
 * @component BookmarkCurrentPageButton
 * @description A button in the top nav that allows you to bookmark the current page (thread/search).
 */
const BookmarkCurrentPageButton = ({
  dataTestId,
  isDisabled,
  isBookmarked,
  label,
  onClick,
}: BookmarkCurrentPageButtonProps) => {
  const router = useRouter();
  const { isSignedIn, isLoaded } = useAuth();
  const { isOutOfFreeBookmarkItems, isBookmarkItemsLoaded } = useBookmarks();

  const [tooltipInstance, setTooltipInstance] = useState<any>(null);

  const handleClickBookmarkCurrentPageButton = useCallback(() => {
    if (!isLoaded) {
      router?.push(path.INTERNAL_SERVER_ERROR);
      return;
    }

    if (!isSignedIn) {
      router.push(
        `${path.SIGN_UP}#/?redirect_url=${encodeURIComponent(
          `${router?.asPath}`
        )}`
      );
      return;
    }

    if (isOutOfFreeBookmarkItems === false && onClick) {
      onClick();
    }
  }, [router, isSignedIn, isLoaded, isOutOfFreeBookmarkItems, onClick]);

  return (
    <Popover
      interactive
      maxWidth={380}
      onShown={(instance: any) => {
        setTooltipInstance(instance);
      }}
      onHidden={(instance: any) => {
        setTooltipInstance(instance);
      }}
      tooltipContent={
        <CreateBookmarkItemTooltip
          onClick={() => {
            if (tooltipInstance != null) {
              tooltipInstance?.hide();
            }
          }}
        />
      }
      className="mt-2 ml-5 rounded-xl premium-bookmark-popup"
      disabled={isOutOfFreeBookmarkItems !== true}
    >
      <Tooltip content={label}>
        <CoButton
          icon={isBookmarked ? "icon-bookmark-solid" : "icon-bookmark"}
          variant={ButtonVariant.Flat}
          onClick={handleClickBookmarkCurrentPageButton}
          disabled={isDisabled || !isBookmarkItemsLoaded}
          data-testid={dataTestId}
          size="sm"
        />
      </Tooltip>
    </Popover>
  );
};

export default BookmarkCurrentPageButton;

import { CoButton } from "components/CoButton";
import path from "constants/path";
import useLabels from "hooks/useLabels";
import { useRouter } from "next/router";
import React from "react";

function SignUpButton({ className = "" }) {
  const router = useRouter();

  const [generalLabels] = useLabels("general");

  const redirect = `/#/?redirect_url=${
    [path.SIGN_UP].includes(router?.route) ? "" : router?.asPath
  }`;

  return (
    <CoButton
      href={`${path.SIGN_UP}${redirect}`}
      className={className ?? ""}
      label={generalLabels["signup"]}
      size="sm"
    />
  );
}

export default SignUpButton;

import { ThreadHistory } from "helpers/api";
import ThreadHistoryItem from "./ThreadHistoryItem";

type ThreadHistoryGroupProps = {
  label: string;
  onDelete: (id: number) => void;
  onNavigate: () => void;
  onSave: (thread: ThreadHistory) => void;
  threads: ThreadHistory[];
};

function ThreadHistoryGroup({
  label,
  threads,
  onNavigate,
  onSave,
  onDelete,
}: ThreadHistoryGroupProps) {
  return (
    <div className="w-full overflow-hidden">
      <h2 className="pl-4 mt-4 mb-2 tiny-md text-fg-muted">{label}</h2>
      <ol className="w-full overflow-hidden">
        {threads.map((thread) => (
          <ThreadHistoryItem
            key={thread.thread_id}
            onDelete={onDelete}
            onNavigate={onNavigate}
            onSave={onSave}
            thread={thread}
          />
        ))}
      </ol>
    </div>
  );
}

export default ThreadHistoryGroup;

import IntlMessageFormat from "intl-messageformat";

/**
 * pluralize - Utility to format strings with pluralization support using ICU MessageFormat syntax.
 *
 * Usage:
 * In your JSON files, define pluralized labels using ICU MessageFormat. You can specify rules for singular, plural,
 * and custom cases (like handling 0 separately). The label should include a `{count, plural, ...}` block where
 * the plural rules are defined.
 *
 * Example of label definition:
 *
 * {
 *   "pro-analysis-left": "You have {count, plural, one {# analysis} other {# analyses}} left.",
 *   "task-status": "You have {count, plural, =0 {no tasks} one {# task} other {# tasks}} remaining.",
 *   "combined-label": "{books, plural, one {# book} other {# books}} and {pens, plural, one {# pen} other {# pens}} available."
 * }
 *
 * Usage:
 *
 *   import pluralize from "helpers/pluralize";
 *
 *   const label = "You have {books, plural, one {# book} other {# books}} and {pens, plural, one {# pen} other {# pens}}.";
 *   const result = pluralize(label, { books: 1, pens: 3 }); // Output: "You have 1 book and 3 pens"
 *
 * The `values` object passed to the `pluralize` function can contain multiple variables. Each variable can have
 * its own pluralization rules. The function dynamically replaces the placeholders based on the value of `count` or
 * other variables.
 */
const pluralize = (label: string, values = {}): string => {
  const message = new IntlMessageFormat(label, "en");
  return String(message.format(values));
};

export default pluralize;

import { useAuth } from "@clerk/nextjs";
import { FeatureFlag } from "enums/feature-flag";
import useIsFeatureEnabled from "hooks/useIsFeatureEnabled";
import { useSidebar } from "hooks/useSidebar";
import { useAppSelector } from "hooks/useStore";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import SidebarCollapsedContent from "./components/SidebarCollapsedContent";
import SidebarContent from "./components/SidebarContent";
export enum SidebarTab {
  Bookmarks = "Bookmarks",
  History = "History",
  ResearchHub = "ResearchHub",
  Home = "Home",
  None = "None",
}

function Sidebar() {
  const { isSignedIn, isLoaded } = useAuth();
  const { toggleSidebar, isSidebarOpen } = useSidebar();
  const is898WideScreen = useAppSelector(
    (state) => state.setting.is898WideScreen
  );
  const router = useRouter();
  const [selectedTab, setSelectedTab] = useState<SidebarTab>(
    SidebarTab.History
  );
  const [sidebarDefaultSet, setSidebarDefaultSet] = useState(false);
  const isResearchHubEnabled = useIsFeatureEnabled(FeatureFlag.RESEARCH_HUB);
  const onSearchPage = router.pathname === "/" || router.pathname === "/search";

  useEffect(() => {
    if (isResearchHubEnabled && onSearchPage) {
      setSelectedTab(SidebarTab.Home);
    } else {
      setSelectedTab(SidebarTab.History);
    }
  }, [isResearchHubEnabled]);

  // Open sidebar by default when not signed in on desktop
  useEffect(() => {
    if (isLoaded && !isSignedIn && !sidebarDefaultSet) {
      if (!is898WideScreen) {
        toggleSidebar();
      }
      setSidebarDefaultSet(true);
    }
  }, [isSignedIn, isLoaded, is898WideScreen, sidebarDefaultSet, toggleSidebar]);

  useEffect(() => {
    // "/search" in the window.location.pathname is the same as "/" in the router.pathname
    if (onSearchPage) {
      if (isResearchHubEnabled) {
        setSelectedTab(SidebarTab.Home);
      } else {
        setSelectedTab(SidebarTab.History);
      }
    } else if (router.pathname.includes("/hub")) {
      setSelectedTab(SidebarTab.ResearchHub);
    } else {
      if (isResearchHubEnabled) {
        setSelectedTab(SidebarTab.None);
      } else {
        setSelectedTab(SidebarTab.History);
      }
    }
  }, [router]);

  const onClickOpenBookmarks = () => {
    toggleSidebar();
    setSelectedTab(SidebarTab.Bookmarks);
  };

  const onClickOpenHistory = () => {
    toggleSidebar();
    setSelectedTab(SidebarTab.History);
  };

  const expandSidebar = () => {
    toggleSidebar();
  };

  const showCollapsedSidebar = !is898WideScreen;

  return (
    <div
      className="z-40 h-full border-r border-base"
      data-testid="sidebar-container"
    >
      {isSidebarOpen ? (
        <SidebarContent
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
      ) : showCollapsedSidebar ? (
        <SidebarCollapsedContent
          onClickOpenBookmarks={onClickOpenBookmarks}
          onClickOpenHistory={onClickOpenHistory}
          onExpand={expandSidebar}
          selectedTab={selectedTab}
        />
      ) : null}
    </div>
  );
}

export default Sidebar;

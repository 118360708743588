import { IconProps } from ".";

/**
 * @component CopyLinkIcon
 * @description Component for showing the share SVG icon. Used instead of an <img> tag so that
 * we are able to change the color with css, e.g. on active. Use in conjunction with the <Icon>
 * component.
 * @example
 * return (
 *   <Icon
 *      className="fill-current"
 *      name="copy-link"
 *      local={true}
 *    />
 * )
 */
function CopyLinkIcon({ className }: IconProps) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      className={`${className} ${className.includes("h-") ? "" : "w-5 h-5"}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 13C10.9295 13.5741 11.4774 14.0491 12.1066 14.3929C12.7357 14.7367 13.4315 14.9411 14.1467 14.9923C14.8618 15.0435 15.5796 14.9403 16.2513 14.6897C16.9231 14.4392 17.5331 14.047 18.04 13.54L21.04 10.54C21.9508 9.59695 22.4548 8.33394 22.4434 7.02296C22.432 5.71198 21.9061 4.45791 20.9791 3.53087C20.0521 2.60383 18.798 2.07799 17.487 2.0666C16.176 2.0552 14.913 2.55918 13.97 3.46997L12.25 5.17997"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5 10.9992C14.0706 10.4251 13.5227 9.95007 12.8935 9.60631C12.2643 9.26255 11.5685 9.05813 10.8534 9.00691C10.1382 8.9557 9.42043 9.05888 8.74867 9.30947C8.07691 9.56005 7.4669 9.95218 6.96002 10.4592L3.96002 13.4592C3.04923 14.4023 2.54525 15.6653 2.55665 16.9763C2.56804 18.2872 3.09388 19.5413 4.02092 20.4683C4.94796 21.3954 6.20203 21.9212 7.51301 21.9326C8.82399 21.944 10.087 21.44 11.03 20.5292L12.74 18.8192"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CopyLinkIcon;

import clsx from "classnames";
import { forwardRef, MouseEvent, ReactElement } from "react";
import { ButtonType, useButtonProps } from "./useButtonProps";

enum ButtonVariant {
  Primary = "primary",
  Secondary = "secondary",
  Tertiary = "tertiary",
  Flat = "flat",
  Toast = "toast",
}

type VariantKey = keyof typeof ButtonVariant;

export type CoButtonPropsType = {
  /**
   * Control the underlying rendered element directly by passing in a valid
   * component type
   */
  as?: keyof JSX.IntrinsicElements | undefined;

  /** The disabled state of the button */
  disabled?: boolean | undefined;

  /** Optionally specify an href to render a `<a>` tag styled as a button */
  href?: string | undefined;

  /** Anchor target, when rendering an anchor as a button */
  target?: string | undefined;

  rel?: string | undefined;

  /** Text to show in the button */
  label?: string;

  type?: ButtonType | undefined;

  /** Icon to show to the left of the label */
  icon?: string; // icon coming from the font icon, if this is specified then the button will only display icon

  /** Icon to show to the right of the label */
  iconRight?: string; // icon coming from the font icon, if this is specified then the button will only display icon

  variant?: typeof ButtonVariant[VariantKey];

  size?: "sm" | "md";

  /** If set, only the icon will be shown on screens smaller than md */
  hideLabelResponsively?: boolean;

  /** Whether to show underline for Flat variant. Defaults to true */
  underline?: boolean;

  onClick?: (event: MouseEvent<HTMLElement>) => void;

  "data-testid"?: string;

  className?: string;

  /** Tab index for accessibility */
  tabIndex?: number;

  id?: string;
};

function createIconElement(icon: string): ReactElement {
  if (/\/icons\/.*\.svg/.test(icon)) {
    return <img src={icon} alt="icon button" />;
  } else {
    return <i className={`text-xl ${icon}`} />;
  }
}

const CoButton = forwardRef<HTMLElement, CoButtonPropsType>(
  (props: CoButtonPropsType, ref) => {
    const [ariaButtonProps, { tagName: Component }] = useButtonProps(props);
    const {
      variant = "primary",
      size = "md",
      label,
      icon,
      iconRight,
      disabled,
      className,
      hideLabelResponsively,
      underline = true,
      ...theRest
    } = props;

    const singleIconOnly = icon && !label && !iconRight;
    const doubleIcon = icon && !label && iconRight;

    const baseClasses = [
      "inline-flex",
      "justify-center",
      "items-center",
      "relative",
      "whitespace-nowrap",
      "align-middle",
      "leading-[1.2]",
      "bg-gradient-to-t",
      "from-white/0",
      "via-white/0",
      "to-white/20",
    ].join(" ");

    let sizeClasses = "";
    let responsiveClasses = "";
    let variantClasses = "";
    const disabledClasses = disabled ? "opacity-60 cursor-not-allowed" : "";

    if (singleIconOnly) {
      // Classes when singleIconOnly is true
      sizeClasses = clsx(
        "rounded-full",
        size === "md" && "h-12 w-12 base-md",
        size === "sm" && "h-10 w-10 sm-md"
      );
    } else if (doubleIcon) {
      sizeClasses = clsx(
        "rounded-full",
        size === "md" && "px-2 h-12 base-md gap-1",
        size === "sm" && "px-2 h-10 sm-md gap-1"
      );
    } else {
      // Classes when label is present
      sizeClasses = clsx(
        "rounded-3xl",
        "py-2",
        size === "md" && "px-5 text-[16px] h-12 base-md",
        size === "sm" && "text-[15px] h-10 sm-md"
      );

      if (size === "md" && hideLabelResponsively) {
        responsiveClasses = "w-12 md:w-auto md:gap-2";
      } else if (size === "sm" && hideLabelResponsively) {
        responsiveClasses = "md:w-auto px-2.5 md:px-4 md:gap-2";
      } else if (size === "sm") {
        sizeClasses = clsx(sizeClasses, "px-4 gap-2");
      } else if (size === "md") {
        sizeClasses = clsx(sizeClasses, "gap-2");
      }
    }

    // Classes based on variant
    variantClasses = clsx(
      variant === ButtonVariant.Primary && [
        "bg-accent-base",
        "text-accent-on-accent",
        "hover:bg-blue-600",
        "border",
        "border-white/[0.08]",
      ],
      variant === ButtonVariant.Secondary && [
        "bg-accent-subtle",
        "text-accent-emphasis",
        "hover:bg-blue-300",
        "border",
        "border-white/[0.04]",
      ],
      variant === ButtonVariant.Tertiary && [
        "bg-bgr-faint",
        "text-fg-base",
        "hover:bg-bgr-subtle",
        "border",
        "border-border-emphasis",
      ],
      variant === ButtonVariant.Flat && ["text-fg-base", "hover:bg-bgr-subtle"],
      variant === ButtonVariant.Toast && [
        "bg-white",
        "text-green-emphasis",
        "border",
        "border-border-base",
      ]
    );

    // Combine all classes
    const classNames = clsx(
      baseClasses,
      sizeClasses,
      responsiveClasses,
      variantClasses,
      disabledClasses,
      className
    );

    return (
      <Component
        {...theRest}
        {...ariaButtonProps}
        ref={ref}
        className={classNames}
        disabled={disabled}
      >
        {singleIconOnly ? (
          createIconElement(icon)
        ) : (
          <>
            {icon ? createIconElement(icon) : null}
            {label && (
              <span
                className={clsx(
                  hideLabelResponsively && "hidden md:block",
                  variant === ButtonVariant.Flat &&
                    underline &&
                    "border-b-1.5 border-fg-base"
                )}
              >
                {label}
              </span>
            )}
            {iconRight ? createIconElement(iconRight) : null}
          </>
        )}
      </Component>
    );
  }
);

CoButton.displayName = "CoButton";

export { CoButton, ButtonVariant };

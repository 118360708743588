export function getMinutesBetweenDates(startDate: Date, endDate: Date): number {
  var diff = endDate.getTime() - startDate.getTime();
  return Math.round(diff / 60000);
}

export function getDaysBetweenDates(startDate: Date, endDate: Date): number {
  var diff = endDate.getTime() - startDate.getTime();
  return Math.round(diff / (1000 * 60 * 60 * 24));
}

export function oneDayAgo(): Date {
  return new Date(new Date().setDate(new Date().getDate() - 1));
}

export function oneYearAgo(): Date {
  return new Date(new Date().setFullYear(new Date().getFullYear() - 1));
}

export function addDaysToCurrentDate(numberOfDays: number): Date {
  return new Date(new Date().setDate(new Date().getDate() + numberOfDays));
}

export function formatDateString(date: Date): string {
  const options: Intl.DateTimeFormatOptions = {
    month: "short",
    day: "2-digit",
    year: "numeric",
  };
  return date.toLocaleDateString("en-US", options);
}

/**
 * Parses a date string in various formats and returns a UTC date.
 * Supported formats:
 * - dd/mm/yyyy or dd/mm/yy
 * - YYYY-MM-DD
 * - Formatted string like "May 16, 2021"
 *
 * @param dateStr The date string to parse
 * @returns Date object in UTC or null if parsing fails
 */
export function parseDateToUTC(dateStr: string): Date | null {
  let date: Date;

  try {
    if (dateStr.includes("/")) {
      // Handle dd/mm/yyyy or dd/mm/yy format
      const [month, day, year] = dateStr.split("/").map(Number);
      const fullYear = year < 100 ? 2000 + year : year;
      date = new Date(Date.UTC(fullYear, month - 1, day));
    } else if (dateStr.includes("-")) {
      // Handle YYYY-MM-DD format
      const [year, month, day] = dateStr.split("-").map(Number);
      date = new Date(Date.UTC(year, month - 1, day));
    } else {
      // Handle formatted string like "May 16, 2021"
      date = new Date(dateStr);
      // Adjust to UTC to avoid timezone issues
      date = new Date(
        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
      );
    }

    // Validate the date
    return isNaN(date.getTime()) ? null : date;
  } catch (error) {
    return null;
  }
}

// Convert a timestamp into a simpler human-readable label such as "Last Week"
export function getTimeAgoLabel(isoTimestamp: string): string {
  const date = new Date(isoTimestamp);
  const now = new Date();

  const diffMs = now.getTime() - date.getTime();
  const diffSeconds = Math.floor(diffMs / 1000);
  const diffMinutes = Math.floor(diffSeconds / 60);
  const diffHours = Math.floor(diffMinutes / 60);
  const diffDays = Math.floor(diffHours / 24);
  const diffYears = now.getFullYear() - date.getFullYear();

  // Helper function for formatting date (e.g., "Mar. 2nd")
  const formatDate = (d: Date, includeYear: boolean = false): string => {
    const options: Intl.DateTimeFormatOptions = {
      month: "short",
      day: "numeric",
    };
    if (includeYear) options.year = "numeric";

    return d.toLocaleDateString("en-US", options);
  };

  if (diffSeconds < 300) return "Just now"; // Less than 5 min
  if (diffMinutes < 60) return `${diffMinutes} minutes ago`;
  if (diffHours < 24)
    return `${diffHours} ${diffHours === 1 ? "hour" : "hours"} ago`;

  if (diffDays === 1) return "Yesterday";
  if (diffYears === 0) return formatDate(date); // "<1 year" case (e.g., "Mar. 2nd")
  return formatDate(date, true); // ">1 year" case (e.g., "Feb. 2nd, 2024")
}

import { QueryFeatures } from "helpers/api";
import { CitationType } from "helpers/cite";

/**
===============================================================================
START UPDATE IF CHANGED WARNING ===============================================
===============================================================================
common/src/python/common/analytics/mixpanel.py
===============================================================================
*/
export enum MixpanelEvent {
  AskPaperClick = "Ask Paper Click",
  AskPaperChat = "Ask Paper Chat",
  Cancellation = "Cancellation",
  ClaimShare = "Claim Share",
  ClaimView = "Claim View",
  CreateList = "Create List",
  ExportResults = "Export Results",
  FullTextClick = "Full Text Click",
  LogIn = "User Log In",
  LogOut = "User Log Out",
  PageView = "Page View",
  PageViewBrowser = "Page View - Browser",
  PaperCite = "Paper Cite",
  PaperSave = "Paper Save",
  PaperShare = "Paper Share",
  PaperView = "Paper View",
  ProAnalysisCopy = "Pro Analysis Copy",
  RelatedSearchClick = "Related Search Click",
  SaveSearch = "Save Search",
  Search = "Search",
  SearchResultsItemClick = "Results Item Click",
  SearchResultsShare = "Results Share",
  SearchResultsView = "Results View",
  SignUp = "User Sign Up",
  StudySnapshot = "Study Snapshot",
  Subscribe = "Subscribe",
  ToggleProAnalysis = "Toggle Pro Analysis",
  ToggleYesNoSummaryTable = "Toggle Yes No Summary Table",
  UploadPaperTriggered = "Upload Paper - Triggered",
  UploadPaperSuccess = "Upload Paper - Success",
  UploadPaperError = "Upload Paper - Error",
  DeleteUploadedPaper = "Delete Uploaded Paper",
  DownloadUploadedPaper = "Download Uploaded Paper",
  DismissBanner = "Dismiss Banner",
  ClickResearchHubLink = "Click Research Hub Link",
}

export enum MixpanelNavigationSource {
  PublicAPI = "Public API",
  ChatGPT = "ChatGPT",
  External = "External",
  LandingPage = "Landing Page",
  Website = "Website",
  RelatedSearch = "Related Search",
}

export enum MixpanelActionSource {
  BookmarkList = "Bookmark List",
  DetailsPage = "Details Page",
  SearchResults = "Search Results",
  Sidebar = "Sidebar",
  ProAnalysis = "Pro Analysis",
  CitationList = "Citation List",
  ReferenceList = "Reference List",
  ResearchHub = "Research Hub",
  ResearchHubAnnouncementBanner = "Research Hub Announcement Banner",
  AskPaper = "Ask Paper",
  Redirect = "Redirect",
  AskPaperResultTag = "Ask Paper Result Tag",
  UploadedPaperDetails = "Uploaded Paper Details",
  ResearchHubZeroStateEducation = "Research Hub Zero State Education",
}

export enum MixpanelPaperSource {
  ConsensusSearch = "Consensus Search",
  UserUpload = "User Upload",
}

export type MixpanelCiteType = CitationType;

export enum MixpanelFullTextLinkType {
  PDF = "PDF",
  S2 = "S2",
  DOI = "DOI",
}

export enum MixpanelExportFileType {
  CSV = "CSV",
  RIS = "RIS",
}

/**
 * Major version tracking in Mixpanel
 * Naming convention: v[increasing integer]-datetime-[short descriptor]
 */
export enum MixpanelEventVersion {
  /**
   * Original claim search based product hunt launch, 20220908
   * Versions were launched with PaperSearch, so an event should also be
   * considered ClaimSearch if version is not set.
   */
  ClaimSearch = 1,
  /** Paper search launch, 20231031 */
  PaperSearch = 2,
  /** Research Hub launch */
  ResearchHub = 3,
}

export interface MixpanelEventData {
  event: MixpanelEvent;
  version: MixpanelEventVersion;
}

export interface PlanProperties {
  value: number;
  currency: string;
}

export interface PaperProperties {
  paperId: string | null;
  journalName: string | null;
  publishYear: number | null;
  paperTitle: string | null;
  firstAuthor: string | null;
  doi: string | null;
  resultText: string | null;
  resultType: "answer" | "takeaway" | null;
}

export interface SearchFilterProperties {
  studyTypes?: string;
  fieldsOfStudy?: string;
  countriesOfStudy?: string;
  // Bool filters
  openAccess?: string;
  hasValidChatPdf?: string;
  filterControlledStudies?: string;
  filterHumanStudies?: string;
  // Range filters
  citationsMin?: string;
  sampleSizeMin?: string;
  sampleSizeMax?: string;
  sjrBestQuartileMin?: string;
  sjrBestQuartileMax?: string;
  studyDurationDaysMin?: string;
  studyDurationDaysMax?: string;
  yearMin?: string;
  yearMax?: string;
  durationMax?: string;
  durationMin?: string;
  country?: string;
}

export interface SearchSettingsProperties {
  proAnalysisOn: boolean | null;
  filters: SearchFilterProperties;
  filtersOn: boolean | null;
  query: string | null;
}

export interface SearchResultsProperties {
  adjustedQuery: string | null;
  error: string | null;
  isNullResults: boolean | null;
  summarySuccess: boolean | null;
  queryFeatures: QueryFeatures;
}

// Events like Paper share require some but not all of the Search Properties.
export interface PaperEventSearchProperties {
  adjustedQuery: string | null;
  filters: SearchFilterProperties;
  query: string | null;
}

export interface ResultProperties {
  pageNumber: number | null;
  resultNumber: number | null;
}

/** User initiates cancelling their subscription. */
export interface CancellationEvent extends MixpanelEventData, PlanProperties {
  event: MixpanelEvent.Cancellation;
}

/** User registers for Consensus. */
export interface SignUpEvent extends MixpanelEventData {
  event: MixpanelEvent.SignUp;
  authMethod?: string;
  createdAt?: number;
}

/** User views a query's results page, regardless of from a search or direct link. */
interface SearchResultsViewEvent
  extends MixpanelEventData,
    SearchSettingsProperties,
    SearchResultsProperties {
  event: MixpanelEvent.SearchResultsView;
  navigationSource: MixpanelNavigationSource;
  pageNumber: number;
  isLoadMore: boolean;
  paperIds: string[];
}

/** User searches from the search bar. */
export interface SearchEvent
  extends MixpanelEventData,
    SearchSettingsProperties {
  event: MixpanelEvent.Search;
  navigationSource: MixpanelNavigationSource;
}

interface SaveSearchEvent
  extends MixpanelEventData,
    SearchSettingsProperties,
    SearchResultsProperties {
  event: MixpanelEvent.SaveSearch;
}

/** User exports search results. */
interface ExportResultsEvent
  extends MixpanelEventData,
    SearchSettingsProperties,
    SearchResultsProperties {
  event: MixpanelEvent.ExportResults;
  actionSource?: MixpanelActionSource;
  fileType: MixpanelExportFileType;
}

/** User views a paper details page, regardless of from a search or direct link. */
interface PaperViewEvent extends MixpanelEventData, PaperProperties {
  event: MixpanelEvent.PaperView;
  navigationSource: MixpanelNavigationSource;
}

/** User sends a message to Ask Paper */
interface AskPaperChatEvent
  extends MixpanelEventData,
    PaperProperties,
    ResultProperties,
    PaperEventSearchProperties {
  event: MixpanelEvent.AskPaperChat;
  navigationSource: MixpanelNavigationSource;
  actionSource: MixpanelActionSource;
  message: string;
  paperSource: MixpanelPaperSource;
  paperId: string | null;
}

/** User clicks to open Ask Paper Drawer */
interface AskPaperClickEvent
  extends MixpanelEventData,
    PaperProperties,
    ResultProperties,
    PaperEventSearchProperties {
  event: MixpanelEvent.AskPaperClick;
  navigationSource: MixpanelNavigationSource;
  actionSource: MixpanelActionSource;
  paperSource: MixpanelPaperSource;
  paperId: string;
}

/** User clicks full text link on share page. */
interface FullTextClickEvent
  extends MixpanelEventData,
    PaperProperties,
    ResultProperties,
    PaperEventSearchProperties {
  event: MixpanelEvent.FullTextClick;
  linkType: MixpanelFullTextLinkType;
}

/** User clicks one of the 4 share options. */
interface SharePaperEvent
  extends MixpanelEventData,
    PaperProperties,
    ResultProperties,
    PaperEventSearchProperties {
  event: MixpanelEvent.PaperShare;
  actionSource: MixpanelActionSource;
  shareType: string;
}

interface SavePaperEvent
  extends MixpanelEventData,
    PaperProperties,
    ResultProperties,
    PaperEventSearchProperties {
  event: MixpanelEvent.PaperSave;
  actionSource: MixpanelActionSource;
}

interface StudySnapshotEvent
  extends MixpanelEventData,
    PaperProperties,
    ResultProperties,
    PaperEventSearchProperties {
  event: MixpanelEvent.StudySnapshot;
  actionSource: MixpanelActionSource;
}

interface CitePaperEvent
  extends MixpanelEventData,
    PaperProperties,
    ResultProperties,
    PaperEventSearchProperties {
  event: MixpanelEvent.PaperCite;
  actionSource: MixpanelActionSource;
  citeType: MixpanelCiteType;
}

/** User clicks a claim. */
interface SearchResultsItemClickEvent
  extends MixpanelEventData,
    PaperProperties,
    ResultProperties,
    SearchSettingsProperties,
    SearchResultsProperties {
  event: MixpanelEvent.SearchResultsItemClick;
  actionSource: MixpanelActionSource;
}

/** User clicks a result from the Pro Analysis summary. */
interface ProAnalysisResultsItemClickEvent extends SearchResultsItemClickEvent {
  referenceLineNumber: number;
  numberOfReferenceLines: number;
  associatedSentence: string;
}

/** User copies the Pro Analysis text. */
interface ProAnalysisCopyEvent
  extends MixpanelEventData,
    SearchSettingsProperties,
    SearchResultsProperties {
  event: MixpanelEvent.ProAnalysisCopy;
  citeType?: MixpanelCiteType;
}

/** User clicks a related search. */
export interface RelatedSearchClickEvent
  extends MixpanelEventData,
    SearchResultsProperties,
    SearchSettingsProperties {
  event: MixpanelEvent.RelatedSearchClick;
  relatedQuery: string;
  resultNumber: number;
  pagePosition?: "top" | "bottom";
}

/** User shares results. */
interface SearchResultsShareEvent
  extends MixpanelEventData,
    SearchSettingsProperties,
    SearchResultsProperties {
  event: MixpanelEvent.SearchResultsShare;
  shareType: string;
  actionSource: MixpanelActionSource;
}

/** User succesfully logs in. */
interface LogInEvent extends MixpanelEventData {
  event: MixpanelEvent.LogIn;
  authMethod: string;
}

/** User succesfully logs out. */
interface LogOutEvent extends MixpanelEventData {
  event: MixpanelEvent.LogOut;
}

/** User views a page. */
interface PageViewEvent extends MixpanelEventData {
  event: MixpanelEvent.PageView;
  pageType: string;
  pageTitle: string;
}

interface CreateListEvent extends MixpanelEventData {
  event: MixpanelEvent.CreateList;
  listName: string;
}

export interface SubscribeEventData extends MixpanelEventData {
  event: MixpanelEvent.Subscribe;
  billingType: string;
  currency: string;
  price?: number;
  plan: string;
  status?: string;
}

export interface ToggleProAnalysisEvent
  extends MixpanelEventData,
    PaperEventSearchProperties {
  event: MixpanelEvent.ToggleProAnalysis;
  value: boolean;
}

export interface ToggleYesNoSummaryTableEvent
  extends MixpanelEventData,
    PaperEventSearchProperties {
  event: MixpanelEvent.ToggleYesNoSummaryTable;
  value: boolean;
}

export interface UploadPaperEvent extends MixpanelEventData {
  event: MixpanelEvent;
  actionSource: MixpanelActionSource;
  navigationSource?: MixpanelNavigationSource;
  errorMessage?: string;
}
export interface AskPaperEvent extends MixpanelEventData {
  event: MixpanelEvent;
  actionSource: MixpanelActionSource;
  navigationSource: MixpanelNavigationSource;
  paperId: string;
  paperSource: MixpanelPaperSource;
}

export type MixpanelTrackEventData =
  | AskPaperChatEvent
  | AskPaperClickEvent
  | AskPaperEvent
  | CancellationEvent
  | CitePaperEvent
  | CreateListEvent
  | ExportResultsEvent
  | FullTextClickEvent
  | LogInEvent
  | LogOutEvent
  | PageViewEvent
  | PaperViewEvent
  | ProAnalysisCopyEvent
  | ProAnalysisResultsItemClickEvent
  | RelatedSearchClickEvent
  | SavePaperEvent
  | SaveSearchEvent
  | SearchEvent
  | SearchResultsItemClickEvent
  | SearchResultsShareEvent
  | SearchResultsViewEvent
  | SharePaperEvent
  | StudySnapshotEvent
  | SubscribeEventData
  | ToggleProAnalysisEvent
  | ToggleYesNoSummaryTableEvent
  | UploadPaperEvent;

/**
===============================================================================
END UPDATE IF CHANGED WARNING =================================================
===============================================================================
*/

import { useClerk, useUser } from "@clerk/nextjs";
import { VenetianMask } from "components/Icon/local";
import Switch from "components/Switch";
import path from "constants/path";
import { FeatureFlag as FeatureFlagEnum } from "enums/feature-flag";
import useCredits from "hooks/useCredits";
import { useIncognitoMode } from "hooks/useIncognitoMode";
import useIsFeatureEnabled from "hooks/useIsFeatureEnabled";
import useLabels from "hooks/useLabels";
import Link from "next/link";
import IncognitoModeUserPicture from "./IncognitoModeUserPicture";

export const UserMenuContents = () => {
  const { user } = useUser();
  const { signOut } = useClerk();
  const { subscriptionImgSrc, isPremium } = useCredits();
  const { isIncognitoModeActive, toggleIncognitoMode } = useIncognitoMode();
  const isIncognitoModeFeatureEnabled = useIsFeatureEnabled(
    FeatureFlagEnum.INC_MODE
  );

  const [labels] = useLabels("incognito-mode");

  return (
    <div className="w-60 pb-1">
      <div className="flex items-center gap-3 p-3 mb-1 border-b border-b-border-base">
        <div className="flex items-center justify-center w-6 h-6 rounded-full">
          {isIncognitoModeActive ? (
            <IncognitoModeUserPicture />
          ) : (
            <img
              src={user?.imageUrl}
              className="w-full h-full rounded-full"
              alt="user profile"
            />
          )}
        </div>
        <div className="overflow-hidden flex-1">
          <span className="block tiny-md">
            {user?.firstName}&nbsp;{user?.lastName}
          </span>
          <span className="block tiny-normal text-fg-muted truncate">
            {user?.primaryEmailAddress?.emailAddress}
          </span>
        </div>
      </div>
      {isIncognitoModeFeatureEnabled && (
        <div className="flex items-center justify-between p-2 rounded-lg hover:bg-bgr-faint tiny-md mx-1">
          <div className="flex items-center gap-3 text-fg-base">
            <VenetianMask className="text-fg-base text-xl" />
            <span>{labels["incognito-mode"]}</span>
          </div>
          <Switch
            active={isIncognitoModeActive}
            onChange={() => {
              toggleIncognitoMode();
            }}
          />
        </div>
      )}
      <Link
        href="/account"
        className="flex items-center gap-3 p-2 mx-1 rounded-lg hover:bg-bgr-faint tiny-md"
      >
        <i className="text-xl icon-settings" />
        <span>Settings</span>
      </Link>
      <Link
        href="/subscription"
        className="flex items-center gap-3 p-2 mx-1 rounded-lg hover:bg-bgr-faint tiny-md"
      >
        <i className="text-xl icon-credit-card" />
        <span>Subscription</span>
      </Link>
      <div className="p-2 mx-1 rounded-lg hover:bg-bgr-faint text-red-emphasis">
        <button
          className="flex items-center w-full gap-3 tiny-md"
          onClick={() => signOut({ redirectUrl: path.SIGN_IN })}
        >
          <i className="text-xl icon-arrow-right-from-line" />
          <span>Sign out</span>
        </button>
      </div>
    </div>
  );
};

export default UserMenuContents;

import { useModalStack } from "hooks/useModalStack";

export const FILTER_DRAWER_MODAL_STACK_ID = "filter-drawer";

/**
 * @component useFilterDrawer
 * @description A hook used to open and close the filter sidebar using the modal stack.
 */
function useFilterDrawer() {
  const { isModalOpen, openModal, closeModalIfTop } = useModalStack(
    FILTER_DRAWER_MODAL_STACK_ID
  );

  return {
    isFilterDrawerOpen: isModalOpen,
    openFilterDrawer: openModal,
    closeFilterDrawer: closeModalIfTop,
  };
}

export default useFilterDrawer;

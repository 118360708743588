import Popover from "components/Popover";
import StudyTypeTooltip from "components/Popover/StudyTypeTooltip/StudyTypeTooltip";
import { RCT_URL } from "constants/config";
import useLabels from "hooks/useLabels";
import React from "react";
import { Tag } from "./Tag";

interface RctTagProps {
  pressable?: boolean;
}

function RctTag(props?: RctTagProps) {
  const [pageLabels] = useLabels("tooltips.rct");

  return (
    <Popover
      interactive
      maxWidth={306}
      tooltipContent={
        <StudyTypeTooltip
          title={pageLabels.title}
          content={pageLabels.content}
          icon="/icons/rct.svg"
          iconPyramid="top"
          learnMoreLink={RCT_URL}
        />
      }
    >
      <Tag
        variant="white"
        data-testid="rct-tag"
        className="border-green-base"
        onClick={(e) => {
          if (!props?.pressable) {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
      >
        <img alt="Info" src="/icons/rct.svg" width="20px" height="20px" />
        <span>{pageLabels["tag"]}</span>
      </Tag>
    </Popover>
  );
}

export default RctTag;

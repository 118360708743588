import { IconProps } from ".";

/**
 * @component FilterIcon
 * @description Component for showing the share SVG icon. Used instead of an <img> tag so that
 * we are able to change the color with css, e.g. on active. Use in conjunction with the <Icon>
 * component.
 * @example
 * return (
 *   <Icon
 *      className="fill-current"
 *      name="filter"
 *      local
 *    />
 * )
 */
function FilterIcon({ className }: IconProps) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 8 8"
      fill="none"
      className={`${className} ${className.includes("h-") ? "" : "w-5 h-5"}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="filter">
        <g id="Group">
          <path
            id="Vector"
            d="M6.00002 4.33464H2.00002C1.80002 4.33464 1.66669 4.2013 1.66669 4.0013C1.66669 3.8013 1.80002 3.66797 2.00002 3.66797H6.00002C6.20002 3.66797 6.33335 3.8013 6.33335 4.0013C6.33335 4.2013 6.20002 4.33464 6.00002 4.33464Z"
          />
        </g>
        <g id="Group_2">
          <path
            id="Vector_2"
            d="M5.00002 6.33464H3.00002C2.80002 6.33464 2.66669 6.2013 2.66669 6.0013C2.66669 5.8013 2.80002 5.66797 3.00002 5.66797H5.00002C5.20002 5.66797 5.33335 5.8013 5.33335 6.0013C5.33335 6.2013 5.20002 6.33464 5.00002 6.33464Z"
          />
        </g>
        <g id="Group_3">
          <path
            id="Vector_3"
            d="M7.00002 2.33464H1.00002C0.80002 2.33464 0.666687 2.2013 0.666687 2.0013C0.666687 1.8013 0.80002 1.66797 1.00002 1.66797H7.00002C7.20002 1.66797 7.33335 1.8013 7.33335 2.0013C7.33335 2.2013 7.20002 2.33464 7.00002 2.33464Z"
          />
        </g>
      </g>
    </svg>
  );
}

export default FilterIcon;

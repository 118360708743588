"use client";

/**
 * @hook useIsMac
 * @description Client-only hook that determines if the browser is running on mac. Useful for e.g. determining hotkeys: "⌘" vs "Ctrl"
 */
const useIsMac = () => {
  // Note: navigator.platform is deprecated, but it is unlikely that browsers will remove support for it any time soon:
  // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/platform#browser_compatibility
  return window.navigator.platform.toUpperCase().includes("MAC");
};

export default useIsMac;

export enum Domain {
  AgriculturalAndFoodSciences = "Agricultural and Food Sciences",
  Art = "Art",
  Biology = "Biology",
  Business = "Business",
  Chemistry = "Chemistry",
  ComputerScience = "Computer Science",
  Economics = "Economics",
  Education = "Education",
  Engineering = "Engineering",
  EnvironmentalScience = "Environmental Science",
  Geography = "Geography",
  Geology = "Geology",
  History = "History",
  Law = "Law",
  Linguistics = "Linguistics",
  MaterialsScience = "Materials Science",
  Mathematics = "Mathematics",
  Medicine = "Medicine",
  Philosophy = "Philosophy",
  Physics = "Physics",
  PoliticalScience = "Political Science",
  Psychology = "Psychology",
  Sociology = "Sociology",
}

export const ALL_DOMAINS: Domain[] = Object.values(Domain);

export const YEAR_FILTER_OPTIONS = [
  2025, 2024, 2023, 2022, 2021, 2020, 2019, 2018, 2015, 2010, 2005, 2000, 1990,
];

import { useSidebar } from "hooks/useSidebar";

function OpenSidebarButton() {
  const { toggleSidebar } = useSidebar();

  const handleOnClick = () => {
    toggleSidebar();
  };

  return (
    <button
      onClick={handleOnClick}
      className="flex gap-3 items-center justify-center px-3 py-1.5 bg-white border border-border-base rounded-xl"
    >
      <img className="w-7 h-7" alt="open sidebar" src="/icons/logo-min.svg" />
      <i className="icon-hamburger-menu text-2xl" />
    </button>
  );
}

export default OpenSidebarButton;

import crypto from "crypto";
import { SubscriptionPlan } from "enums/subscription-plans";
import { getFilterParams } from "helpers/search";
import IntlMessageFormat from "intl-messageformat";
import Stripe from "stripe";
import {
  AskPaperThreadMessage,
  AskPaperThreadRole,
  getCustomerSubscriptionAPI,
  getCustomerUpcomingInvoiceAPI,
  getSubscriptionProductsAPI,
  getTeamSubscriptionAPI,
} from "./api";
import { IClerkPublicMetaData } from "./clerk";
import { sortProducts } from "./products";

export interface ISubscriptionUsageData {
  stripeCustomerId?: string;
  userCreatedDate: string;
  userLastResetDate: string;
  studySnapshotUsedPaperIds: string[];
  proAnalysisUsedQueries: string[];
  consensusSnapshotUsedQueries: string[];
  askPaperMessagesPerPaperCount: { [key: string]: number };
  isSet: boolean;
}

export interface SubscriptionUsageSummary {
  creditLeft: number;
  refreshDate: string;
}

export interface UserSubscription {
  cancel_at_period_end?: boolean;
  cancel_at?: number | null;
  current_period_end: number;
  customer: string;
  default_payment_method?: string;
  id: string;
  plan: {
    amount_decimal: string;
    amount: number;
    id: string;
    interval: string;
    product: string;
    metadata?: { type: string };
    tiers_mode?: string | null;
  };
  status: string;
  quantity?: number;
  items?: { data: { id: string; price: { id: string } }[] };
  discount?: {
    coupon: {
      name: string;
      percent_off: number;
    };
  };
}

export interface OrganizationSubscription {
  orgName: string;
}

export enum ISubscriptionType {
  Premium = "Premium",
  Enterprise = "Enterprise",
  Team = "Team",
  None = "None",
}

export interface ISubscriptionData {
  user: UserSubscription | null;
  org: OrganizationSubscription | null;
  team?: boolean | null;
}

export const getActiveSubscription = async ({
  customerId,
  emailAddresses,
  headerData,
}: {
  customerId?: string;
  emailAddresses?: string[];
  headerData?: any;
}): Promise<ISubscriptionData> => {
  if (!customerId && (!emailAddresses || !emailAddresses.length)) {
    // Return an empty ISubscriptionData object if no identifying params
    // are available.
    return {
      user: null,
      org: null,
      team: null,
    };
  }

  let subscriptionRes = await getCustomerSubscriptionAPI(
    {
      stripe_customer_id: customerId,
      email_addresses: emailAddresses,
    },
    headerData
  );

  let organizationSubscription: OrganizationSubscription | null = null;
  if (subscriptionRes && subscriptionRes.org_name) {
    organizationSubscription = { orgName: subscriptionRes.org_name };
  }

  let subscription = null;
  try {
    subscription = subscriptionRes.user_subscription || null;
    subscription = subscription ? JSON.parse(subscription!) : null;
    if (subscription && !isSubscriptionActive(subscription)) {
      subscription = null;
    }
  } catch (error) {
    subscription = null;
  }

  let userSubscription: UserSubscription | null = null;
  if (subscription && subscription.plan.tiers_mode === "volume") {
    userSubscription = subscription;
  } else if (subscription && subscription.plan.tiers_mode == null) {
    userSubscription = subscription;
  }

  return {
    user: userSubscription,
    team: subscriptionRes.team,
    org: organizationSubscription,
  };
};

export const getTeamSubscriptionActiveStatus = async (teamId: string) => {
  const teamSubscription = await getTeamSubscriptionAPI(teamId);
  if (teamSubscription && teamSubscription.subscription) {
    const stripeSubJson = JSON.parse(teamSubscription.subscription);
    if (
      isSubscriptionTeams(stripeSubJson as Stripe.Subscription) &&
      isSubscriptionActive(stripeSubJson)
    ) {
      return { active: true };
    }
  }
  return { active: false };
};

export const getCustomerUpcomingInvoice = async (
  customerId?: string | null
) => {
  if (!customerId) {
    return null;
  }
  const upcomingInvoice = await getCustomerUpcomingInvoiceAPI(customerId);
  return upcomingInvoice;
};

export const getSubscriptionProducts = async () => {
  let sortedProducts = null;
  const subscriptionProducts = await getSubscriptionProductsAPI("");
  if (subscriptionProducts) {
    sortedProducts = sortProducts(
      subscriptionProducts["subscription_products"]
    );
  }
  return sortedProducts;
};

export function getCancellationExpiryDate(
  userSubscription: UserSubscription | null
): Date | null {
  if (
    userSubscription &&
    ["active", "trialing"].includes(userSubscription.status) &&
    userSubscription.cancel_at_period_end &&
    userSubscription.cancel_at
  ) {
    return new Date(userSubscription.cancel_at * 1000);
  }
  return null;
}

export function getPremiumProduct(products: any) {
  for (let i = 0; i < products.length; i++) {
    const productData = products[i].product_data;
    if (productData.metadata.tier == "premium") {
      return productData;
    }
  }
  return null;
}

// Return the total number of ask paper responses that have been generated
// since the last time the user's credits were reset.
export function calculateAskPaperMessageCountForPaper(
  messages: AskPaperThreadMessage[],
  lastResetDate: string
): number {
  const cutoffDate = new Date(lastResetDate);

  const count = messages.filter((message) => {
    if (message.role !== AskPaperThreadRole.ASSISTANT) return false;

    const messageDate = new Date(message.created_at);
    return messageDate > cutoffDate;
  }).length;

  return count;
}

export function extractSubscriptionUsageDataFromUserMetadata(
  userMetadata: IClerkPublicMetaData
): ISubscriptionUsageData {
  let usageData = {
    userCreatedDate: userMetadata.created_date
      ? userMetadata.created_date
      : new Date().toISOString(),
    userLastResetDate: userMetadata.last_reset_date
      ? userMetadata.last_reset_date
      : new Date().toISOString(),
    proAnalysisUsedQueries: userMetadata.used_pro_analysis_queries ?? [],
    askPaperMessagesPerPaperCount:
      userMetadata.ask_paper_messages_per_paper_count ?? {},
    studySnapshotUsedPaperIds: userMetadata.used_study_paper_ids ?? [],
    consensusSnapshotUsedQueries:
      userMetadata.used_consensus_snapshot_queries ?? [],
    stripeCustomerId: userMetadata.stripeCustomerId,
    isSet: true,
  };

  return usageData;
}

export function convertSubscriptionDataToPartialUserMetadata(
  subscription: ISubscriptionData
): Partial<IClerkPublicMetaData> {
  return {
    is_premium: isSubscriptionPremium(subscription),
  };
}

export function isUserMetadataInSync(
  userMetadata: IClerkPublicMetaData,
  subscription: ISubscriptionData
) {
  const partialUserMetadata =
    convertSubscriptionDataToPartialUserMetadata(subscription);
  const partialKeys = Object.keys(
    partialUserMetadata
  ) as (keyof IClerkPublicMetaData)[];
  for (const key of partialKeys) {
    if (partialUserMetadata[key] !== userMetadata[key]) {
      return false;
    }
  }
  return true;
}

export function convertSubscriptionUsageDataToPartialUserMetadata(
  usageData: ISubscriptionUsageData
): Partial<IClerkPublicMetaData> {
  return {
    created_date: usageData.userCreatedDate,
    last_reset_date: usageData.userLastResetDate,
    used_pro_analysis_queries: usageData.proAnalysisUsedQueries,
    ask_paper_messages_per_paper_count: usageData.askPaperMessagesPerPaperCount,
    used_study_paper_ids: usageData.studySnapshotUsedPaperIds,
    used_consensus_snapshot_queries: usageData.consensusSnapshotUsedQueries,
  };
}

export function getCreditRefreshDate(
  subscriptionUsageData: ISubscriptionUsageData
): string {
  let refreshDate = new Date(subscriptionUsageData.userLastResetDate!);
  refreshDate.setDate(refreshDate.getDate() + 30);

  const day = refreshDate.getDate();
  const month = refreshDate.toLocaleDateString("en-US", { month: "long" });

  const ordinalFormat = new IntlMessageFormat(
    "{day, selectordinal, one {#st} two {#nd} few {#rd} other {#th}}",
    "en"
  );
  const dayWithSuffix = ordinalFormat.format({ day });

  return `${month} ${dayWithSuffix}`;
}

export function hashQuery(query: { [key: string]: any }) {
  const fullQuery = (query.q as string) + getFilterParams(query);

  const queryHash = crypto.createHash("md5").update(fullQuery).digest("hex");

  return queryHash;
}

export function isSubscriptionPremium(
  subscription: ISubscriptionData
): boolean {
  const isPremium = Boolean(
    subscription.user || subscription.team || subscription.org
  );
  return isPremium;
}

export function getSubscriptionType(
  subscription: ISubscriptionData
): ISubscriptionType {
  if (subscription.org) return ISubscriptionType.Enterprise;
  if (subscription.team) return ISubscriptionType.Team;
  if (subscription.user) return ISubscriptionType.Premium;
  return ISubscriptionType.None;
}

export function isSubscriptionTeams(
  subscription: Stripe.Subscription
): Stripe.SubscriptionItem | null {
  if (subscription.items.data.length > 0) {
    for (let data of subscription.items.data) {
      if (data.plan.tiers_mode === "volume") {
        return data;
      }
    }
  }
  return null;
}

export function isSubscriptionApi(
  subscription: Stripe.Subscription
): Stripe.SubscriptionItem | null {
  if (subscription.items.data.length > 0) {
    for (let data of subscription.items.data) {
      if (
        data.plan.metadata &&
        data.plan.metadata.type === "api" &&
        data.price.recurring &&
        data.price.recurring.usage_type === "metered"
      ) {
        return data;
      }
    }
  }
  return null;
}

export function getSubscriptionPriceId(
  subscription: Stripe.Subscription
): string | null {
  if (subscription.items.data.length > 0) {
    for (let data of subscription.items.data) {
      return data.price.id;
    }
  }
  return null;
}

function isSubscriptionPaused(subscription: any) {
  if (subscription && subscription.pause_collection != null) {
    return true;
  }
  return false;
}

export function isSubscriptionActive(subscription: any) {
  if (subscription && !["active", "trialing"].includes(subscription.status)) {
    return false;
  } else if (subscription.cancel_at_period_end) {
    if (Date.now() > subscription.cancel_at * 1000) {
      return false;
    } else {
      return true;
    }
  } else if (isSubscriptionPaused(subscription)) {
    return false;
  } else {
    return true;
  }
}

export function isBillingAdminSubscription(subcription?: UserSubscription) {
  return (
    subcription?.plan.metadata?.type?.toLowerCase() ===
    SubscriptionPlan.TEAM.toLowerCase()
  );
}

import Checkbox from "components/Checkbox";
import { useState } from "react";

type SearchableCheckboxListProps<T> = {
  items: T[]; // List of items
  selectedItems: T[]; // Currently selected items
  onChange: (updatedItems: T[]) => void; // Callback when selection changes
  getLabel: (item: T) => string; // Function to extract label from item
  placeholder?: string; // Placeholder text for search input
};

/**
 * @component SearchableCheckboxList
 * @description A generic reusable compnent for filtering and selecting from a list of checkboxes, such as the country and domain selectors in the filter sidebar.
 */
function SearchableCheckboxList<T>({
  items,
  selectedItems,
  onChange,
  getLabel,
  placeholder = "Search",
}: SearchableCheckboxListProps<T>) {
  const [filteredItems, setFilteredItems] = useState(items);

  const toggleItem = (item: T) => {
    const updatedList = selectedItems.includes(item)
      ? selectedItems.filter((i) => i !== item) // Remove item if already selected
      : [...selectedItems, item]; // Add item if not selected

    onChange(updatedList);
  };

  const handleFilterItems = (search: string) => {
    setFilteredItems(
      search
        ? items.filter((item) =>
            getLabel(item).toLowerCase().includes(search.toLowerCase())
          )
        : items
    );
  };

  return (
    <div className="flex flex-col items-start w-full">
      {/* Search input */}
      <input
        onChange={(e) => handleFilterItems(e.target.value)}
        className="h-[50px] w-full py-2 px-3 rounded-md border border-gray-200 mb-4 placeholder-[#364B4480]"
        placeholder={placeholder}
      />

      {/* Checkbox list */}
      {filteredItems.map((item, index) => (
        <div className="flex gap-3 py-2" key={index}>
          <Checkbox
            isChecked={selectedItems.includes(item)}
            onToggle={() => toggleItem(item)}
          />
          <span>{getLabel(item)}</span>
        </div>
      ))}
    </div>
  );
}

export default SearchableCheckboxList;

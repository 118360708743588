import { EXAMPLE_SEARCH_URL_2 } from "constants/config";
import useLabels from "hooks/useLabels";
import Link from "next/link";
import React from "react";

type HistoryEmptyProps = {
  onNavigate: () => void;
};

function SearchHistoryEmpty({ onNavigate }: HistoryEmptyProps) {
  const [searchHistoryLabels] = useLabels("screens.sidebar.search-history");

  return (
    <div className="mx-4 tracking-wide text-center">
      <i className="icon-history text-2xl text-fg-muted" />
      <h2
        data-testid="title"
        className="w-full tiny-md text-fg-muted my-3 text-center"
      >
        {searchHistoryLabels["title"]}
      </h2>
      <Link
        passHref
        href={EXAMPLE_SEARCH_URL_2}
        className="p-3 border border-border-base rounded-xl sm-normal flex items-center gap-4 justify-between"
        onClick={onNavigate}
      >
        {searchHistoryLabels["query-text"]}
        <i className="icon-search" />
      </Link>
    </div>
  );
}

export default SearchHistoryEmpty;

export enum FeatureFlag {
  API = "api",
  ASK_PAPER = "ask_paper",
  BOOKMARK = "bookmark",
  RELEVANT_RESULTS = "relevant-results",
  STUDY_SNAPSHOT = "study-snapshot",
  TEST_FEATURE = "test-feature",
  THREADS = "threads",
  INC_MODE = "inc-mode",
  RESEARCH_HUB = "research-hub",
  THREADS_DEBUG_TOOLS = "threads-debug-tools",
}

export const FeatureFlagOptions = [
  {
    label: "Ask Paper",
    name: FeatureFlag.ASK_PAPER,
  },
  {
    label: "API",
    name: FeatureFlag.API,
  },
  {
    label: "Threads",
    name: FeatureFlag.THREADS,
  },
  {
    label: "Incognito Mode",
    name: FeatureFlag.INC_MODE,
  },
  {
    label: "Research Hub",
    name: FeatureFlag.RESEARCH_HUB,
  },
  {
    label: "Threads Debug Tools",
    name: FeatureFlag.THREADS_DEBUG_TOOLS,
  },
];

/** Features that are enabled by default. */
export const ForceEnabledFeatures: FeatureFlag[] = [
  FeatureFlag.API,
  FeatureFlag.ASK_PAPER,
  FeatureFlag.INC_MODE,
  FeatureFlag.RESEARCH_HUB,
];

/**
 * Parses extracted answers testing arguments from a URL query and returns a url
 * parameter formatted string to pass to the backend.
 */
export function getExtractedAnswersEndpointParams(query?: {
  [key: string]: any;
}): string | undefined {
  if (!query) {
    return "";
  }

  const params = new URLSearchParams();

  if (query.lang) {
    params.append("lang", query.lang);
  }
  if (query.cache_off) {
    params.append("cache_off", "true");
  }
  if (query.debug) {
    params.append("debug", "true");
  }

  return params.toString();
}

/**
 * Parses yes/no testing arguments from a URL query and returns a url parameter
 * formatted string to pass to the backend.
 */
export function getYesNoEndpointParams(query?: {
  [key: string]: any;
}): string | undefined {
  if (!query) {
    return "";
  }

  const params = new URLSearchParams();

  if (query.lang) {
    params.append("lang", query.lang);
  }
  if (query.synthesize_threshold) {
    params.append("synthesize_threshold", String(query.synthesize_threshold));
  }
  if (query.meter_answer_threshold) {
    params.append(
      "meter_answer_threshold",
      String(query.meter_answer_threshold)
    );
  }
  if (query.yes_no_model) {
    params.append("yes_no_model", query.yes_no_model);
  }
  if (query.include_unknown) {
    params.append("include_unknown", "true");
  }
  if (query.meter_force_run) {
    params.append("meter_force_run", "true");
  }
  if (query.cache_off) {
    params.append("cache_off", "true");
  }
  if (query.debug) {
    params.append("debug", "true");
  }

  return params.toString();
}

/**
 * Parses yes/no analysis testing arguments from a URL query and returns a url parameter
 * formatted string to pass to the backend.
 */
export function getYesNoAnalysisEndpointParams(query?: {
  [key: string]: any;
}): string | undefined {
  if (!query) {
    return "";
  }

  const params = new URLSearchParams();

  if (query.lang) {
    params.append("lang", query.lang);
  }
  if (query.cache_off) {
    params.append("cache_off", "true");
  }
  if (query.debug) {
    params.append("debug", "true");
  }

  return params.toString();
}

/**
 * Parses autocomplete testing arguments from a URL query and returns a url parameter
 * formatted string to pass to the backend.
 */
export function getAutocompleteEndpointParams(query?: {
  [key: string]: any;
}): string | undefined {
  if (!query) {
    return "";
  }

  const params = new URLSearchParams();

  if (query.autocomplete_exact_match) {
    params.append("autocomplete_exact_match", "true");
  }
  if (query.autocomplete_exact_match_better) {
    params.append("autocomplete_exact_match_better", "true");
  }
  if (query.autocomplete_spelling) {
    params.append("autocomplete_spelling", "true");
  }
  if (query.autocomplete_preferred_only) {
    params.append("autocomplete_preferred_only", "true");
  }
  if (query.autocomplete_remove_space) {
    params.append("autocomplete_remove_space", "true");
  }
  if (query.autocomplete_add_space) {
    params.append("autocomplete_add_space", "true");
  }
  if (query.autocomplete_switch_to_exact) {
    params.append(
      "autocomplete_switch_to_exact",
      String(query.autocomplete_switch_to_exact)
    );
  }
  if (query.autocomplete_switch_to_exact_words) {
    params.append(
      "autocomplete_switch_to_exact_words",
      String(query.autocomplete_switch_to_exact_words)
    );
  }
  if (query.autocomplete_preferred_boost) {
    params.append(
      "autocomplete_preferred_boost",
      String(query.autocomplete_preferred_boost)
    );
  }
  if (query.autocomplete_words_boost) {
    params.append(
      "autocomplete_words_boost",
      String(query.autocomplete_words_boost)
    );
  }

  return params.toString();
}

/**
 * Parses study_details testing arguments from a URL query and returns a url parameter
 * formatted string to pass to the backend.
 */
export function getStudyDetailsEndpointParams(query?: {
  [key: string]: any;
}): string | undefined {
  if (!query) {
    return "";
  }

  const params = new URLSearchParams();

  if (query.cache_off) {
    params.append("cache_off", "true");
  }

  return params.toString();
}

/**
 * Parses summary testing arguments from a URL query and returns a url parameter
 * formatted string to pass to the backend.
 */
export function getSummaryEndpointParams(query?: {
  [key: string]: any;
}): string | undefined {
  if (!query) {
    return "";
  }

  const params = new URLSearchParams();

  if (query.synthesize_threshold) {
    params.append("synthesize_threshold", String(query.synthesize_threshold));
  }
  if (query.summary_max_results) {
    params.append("summary_max_results", String(query.summary_max_results));
  }
  if (query.lang) {
    params.append("lang", query.lang);
  }
  if (query.cache_off) {
    params.append("cache_off", "true");
  }
  if (query.debug) {
    params.append("debug", "true");
  }

  return params.toString();
}

/**
 * Parses pro analysis arguments from a URL query and returns a url parameter
 * formatted string to pass to the backend.
 */
export function getProAnalysisEndpointParams(query?: {
  [key: string]: any;
}): string | undefined {
  if (!query) {
    return "";
  }

  const params = new URLSearchParams();

  if (query.lang) {
    params.append("lang", query.lang);
  }
  if (query.synthesize_threshold) {
    params.append("synthesize_threshold", String(query.synthesize_threshold));
  }
  if (query.cache_off) {
    params.append("cache_off", "true");
  }
  if (query.debug) {
    params.append("debug", "true");
  }

  return params.toString();
}

/**
 * Parses thread interaction arguments from a URL query and returns a url parameter
 * formatted string to pass to the backend.
 */
export function getThreadInteractionAnalysisEndpointParams(query?: {
  [key: string]: any;
}): string | undefined {
  if (!query) {
    return "";
  }

  const params = new URLSearchParams();

  if (query.lang) {
    params.append("lang", query.lang);
  }

  // Note: Changed from returning '?lang=xxx' to just returning 'lang=xxx'
  // The calling code should add the '?' if needed
  return params.toString();
}

/**
 * Parses arguments from the browser's URL query params and returns a url parameter
 * formatted string to pass to the backend.
 */
export function getAskPaperEndpointParams(query?: {
  [key: string]: any;
}): string | undefined {
  if (!query) {
    return "";
  }

  const params = new URLSearchParams();

  if (query.lang) {
    params.append("lang", query.lang);
  }

  return params.toString();
}

/**
 * Parses paper details page testing arguments from a URL query and returns a url parameter
 * formatted string to pass to the backend.
 */
export function getPaperDetailsEndpointParams(query?: {
  [key: string]: any;
}): string | undefined {
  if (!query) {
    return "";
  }

  const params = new URLSearchParams();

  if (query.utm_source) {
    params.append("utm_source", query.utm_source);
  }

  return params.toString();
}

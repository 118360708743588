import { Combobox, OptionObj, OptionType } from "components/Combobox";
import useLabels from "hooks/useLabels";
import { useEffect, useState } from "react";
import { Filter, Filters } from "store/slices/search";
import NumberInput from "./NumberInput";

export type DurationUnitType = "day" | "week" | "month" | "year";

const durationEqualityOptions = [
  { label: "At Least", value: ">=" },
  { label: "Less than", value: "<" },
];

export const durationUnits = [
  { label: "Day", value: "day" },
  { label: "Week", value: "week" },
  { label: "Month", value: "month" },
  { label: "Year", value: "year" },
];
const durationUnitsPlurals = [
  { label: "Days", value: "day" },
  { label: "Weeks", value: "week" },
  { label: "Months", value: "month" },
  { label: "Years", value: "year" },
];

const getDurationUnits = (duration?: number) => {
  if (duration !== undefined && duration > 1) {
    return durationUnitsPlurals;
  }
  return durationUnits;
};

type DurationInputProps = {
  changePendingFilter: <T extends Filter>(filter: T, value: Filters[T]) => void;
  pendingFilters: Filters;
};

/**
 * @component DurationInput
 * @description An input that allows inputting a number, equality sign, and duration unit. Includes logic to set and clear the proper filters when any of these change.
 */
function DurationInput({
  changePendingFilter,
  pendingFilters,
}: DurationInputProps) {
  const [filterLabels] = useLabels("filter");

  const duration = pendingFilters.durationMin ?? pendingFilters.durationMax;

  const [durationEquality, setDurationEquality] = useState<OptionType>(
    pendingFilters.durationMax !== undefined
      ? durationEqualityOptions[1]
      : durationEqualityOptions[0]
  );

  const handleChangeDuration = (duration?: number) => {
    const isAtLeast = (durationEquality as OptionObj).value === ">=";
    changePendingFilter(isAtLeast ? "durationMin" : "durationMax", duration);
    changePendingFilter(isAtLeast ? "durationMax" : "durationMin", undefined);

    // Set default duration unit if a duration is provided but no unit is selected
    if (duration !== undefined && pendingFilters.durationUnit === undefined) {
      changePendingFilter("durationUnit", "day");
    }
  };

  useEffect(() => {
    // Update duration filters when equality changes
    handleChangeDuration(duration);
  }, [durationEquality]);

  return (
    <div className="w-full">
      <span className="block mb-2 sm-md">{filterLabels["duration"]}</span>
      <div className="flex flex-wrap gap-2">
        <div className="flex gap-2">
          <Combobox
            options={durationEqualityOptions}
            inputFieldClassName="h-[50px]"
            defaultValue={durationEquality}
            onChange={setDurationEquality}
            className="flex-1"
          />
          <NumberInput
            value={duration}
            min={0}
            onChange={handleChangeDuration}
            placeholder="Min 0"
            className="flex-1"
          />
        </div>
        <Combobox
          options={getDurationUnits(duration)}
          className="w-full"
          inputFieldClassName="h-[50px]"
          defaultValue={pendingFilters.durationUnit}
          onChange={(unit) =>
            changePendingFilter(
              "durationUnit",
              (unit as OptionObj).value as DurationUnitType
            )
          }
        />
      </div>
    </div>
  );
}

export default DurationInput;

import { useAuth } from "@clerk/nextjs";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useFilters from "features/FilterDrawer/hooks/useFilters";
import {
  convertDurationToDays,
  getQueryFromFilters,
} from "features/FilterDrawer/utils/queryParams/filterQueryParams";
import { THREADS_QUERY_KEY } from "features/Sidebar/hooks/useThreads";
import { parseThreadResponse } from "features/Thread/types";
import { createThread, ThreadResponse } from "helpers/api";
import { newThreadPagePath, threadPath } from "helpers/pageUrl";
import useProToggle from "hooks/useProToggle";
import { useAppSelector } from "hooks/useStore";
import { getThreadQueryKey } from "hooks/useThread";
import { useRouter } from "next/router";

interface UseNewThreadResult {
  isCreating: boolean;
  error: Error | null;
  createNewThread: (userMessage: string) => Promise<void>;
}

/**
 * `useNewThread` is a custom React hook for creating a new thread.
 * It uses `react-query` for managing API calls and caching, and integrates with Next.js routing for navigation.
 *
 * When creating a thread, first we check if the user is logged in. If not, we redirect them through sign-in to /t/new, which will again use this hook to try to create the thread.
 *
 * If the user is logged in, we create the thread, save the resulting thread in the react-query cache, and redirect to the new thread's page.
 *
 *
 * Exposed values:
 * - `isCreating`: Indicates whether the thread creation is in progress.
 * - `error`: Captures any error encountered during the creation process.
 * - `createNewThread`: A function to create a new thread and handle related logic.
 */
export function useNewThread(): UseNewThreadResult {
  const queryClient = useQueryClient();
  const router = useRouter();
  const { isSignedIn, isLoaded } = useAuth();
  const { isProToggleOn } = useProToggle();
  const { appliedFilters } = useFilters();
  const lang = useAppSelector((state) => state.user.settings.lang);

  // Mutation to create a new thread
  const mutation = useMutation(
    async ({ userMessage }: { userMessage: string }) => {
      // API only accepts "day" units for duration, so convert it before passing.
      const filters = convertDurationToDays(appliedFilters);

      const threadResponse = await createThread({
        userMessage,
        isProEnabled: isProToggleOn ?? false,
        filters: getQueryFromFilters(filters),
        lang,
      });
      return threadResponse;
    },
    {
      onSuccess: (data: ThreadResponse) => {
        // Cache the new thread data for the created thread so that we don't refetch it after redirecting to the thread page.
        queryClient.setQueryData(
          getThreadQueryKey(data.thread_id),
          parseThreadResponse(data)
        );

        // Update list of threads in sidebar to reflect new thread
        queryClient.invalidateQueries({
          queryKey: THREADS_QUERY_KEY,
          exact: true,
        });

        // Redirect to the thread page
        const redirect = threadPath(data.thread_id, data.title);
        router.push(redirect);
      },
    }
  );

  const createNewThread = async (userMessage: string) => {
    if (!isLoaded) return;

    if (!isSignedIn) {
      // redirect to sign in
      router.push(
        `/sign-in#/?redirect_url=${encodeURIComponent(
          `${window.location.origin}${newThreadPagePath(userMessage)}`
        )}`
      );
      return;
    }

    await mutation.mutateAsync({ userMessage });
  };

  return {
    isCreating: mutation.isLoading,
    error: mutation.error as Error | null,
    createNewThread,
  };
}

import { SubscriptionPlan } from "enums/subscription-plans";
import { ISubscriptionData } from "helpers/subscription";
import useLabels from "hooks/useLabels";
import useOrg from "hooks/useOrg";
import { useAppSelector } from "hooks/useStore";
import React from "react";
import Markdown from "react-markdown";
import LoadingSubscriptionCard from "../LoadingSubscriptionCard/LoadingSubscriptionCard";

type SubscriptionCurrentPlanCardProps = {
  currentPlan: SubscriptionPlan;
  isLoading: boolean;
  subscription: ISubscriptionData;
};

/**
 * @component SubscriptionCurrentPlanCard
 * @description Displays the features of the user's current plan.
 * @example
 * return (
 *   <SubscriptionCurrentPlanCard
 *     currentPlan={currentPlan}
 *     isLoading={isLoading}
 *     subscription={subscription}
 *   />
 * )
 */
const SubscriptionCurrentPlanCard = ({
  currentPlan,
  isLoading,
  subscription,
}: SubscriptionCurrentPlanCardProps) => {
  const [currentPlanCardLabels] = useLabels("current-plan-card");
  const { premium, free, enterprise, team } = useAppSelector(
    (state) => state.subscription.pricingText
  );
  const { organization, loading: isOrgInfoLoading } = useOrg();

  let featuresList: string[] = [];
  let title = "";
  let subtitle = "";
  let planIconName = "";

  if (currentPlan === SubscriptionPlan.FREE || currentPlan === undefined) {
    featuresList = free?.features;
    title = currentPlanCardLabels["title-free"];
    subtitle = currentPlanCardLabels["subtitle-free"];
    planIconName = "free.svg";
  } else if (currentPlan === SubscriptionPlan.PREMIUM) {
    featuresList = premium.features;
    title = currentPlanCardLabels["title-premium"];
    subtitle = currentPlanCardLabels["subtitle-premium"];
    planIconName = "premium.svg";
  } else if (currentPlan === SubscriptionPlan.TEAM) {
    featuresList = team.features;
    title = currentPlanCardLabels["title-team"];
    subtitle = organization?.name ?? currentPlanCardLabels["subtitle-premium"];
    planIconName = "team.svg";
  } else if (currentPlan === SubscriptionPlan.ENTERPRISE) {
    featuresList = enterprise.features;
    title = currentPlanCardLabels["title-enterprise"];
    subtitle = subscription.org?.orgName
      ? currentPlanCardLabels["subtitle-enterprise"].replace(
          "{organization}",
          subscription.org?.orgName
        )
      : currentPlanCardLabels["subtitle-premium"];

    planIconName = "enterprise.svg";
  }

  if (isLoading || isOrgInfoLoading) {
    return <LoadingSubscriptionCard />;
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-2">
        <img src={`/icons/${planIconName}`} alt={title} className="w-6 h-6" />
        <h3 className="flex-1 lg-bold">{title}</h3>
      </div>
      <span className="text-fg-muted">{subtitle}</span>
      <ul className="flex flex-col gap-2">
        {featuresList.map((feature: any) => (
          <li key={`feature-${feature}`} className="flex gap-2">
            <i className="text-green-emphasis text-xl icon-check" />
            <span className="flex-1 sm-normal text-fg-base">
              <Markdown>{feature}</Markdown>
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SubscriptionCurrentPlanCard;

/**
 * shared_data_constants is copied into this project in the Dockerfile.
 * It represents static data that is shared with the Python backend.
 */
import countryCodes from "shared_data_constants/country_codes.json";
import languageCodes from "shared_data_constants/language_codes.json";

export interface CountryCode {
  name?: string;
  displayName: string;
  code: string;
}

export const countries = countryCodes as CountryCode[];

export type LangDirType = "rtl" | "ltr";
export interface LanguageCode {
  code: string;
  name: string;
  localName: string;
  dir?: LangDirType;
}

export const languages = languageCodes as LanguageCode[];

const codes = languages.map((lang) => lang.code);
const langCodes = [...codes] as const;
export type LangCodeType = typeof langCodes[number];

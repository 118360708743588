import classNames from "classnames";
import Tag from "components/Tag";
import { ANNUAL_PRICE_DISCOUNT } from "constants/config";
import useLabels from "hooks/useLabels";
import React, { useEffect, useState } from "react";

export enum PricingSwitchFlag {
  MONTHLY = "monthly",
  ANNUALLY = "annually",
}
type PricingSwitchProps = {
  onChange: (flag: PricingSwitchFlag) => void;
  initialFlag: PricingSwitchFlag;
};

function PricingSwitch({
  onChange,
  initialFlag = PricingSwitchFlag.ANNUALLY,
}: PricingSwitchProps) {
  const [activeFlag, setActiveFlag] = useState(initialFlag);

  const [pricingLabels] = useLabels("screens.pricing");

  if (!onChange) {
    throw new Error("onChange props is required!");
  }

  const handleOnChange = () => {
    if (activeFlag === PricingSwitchFlag.ANNUALLY) {
      setActiveFlag(PricingSwitchFlag.MONTHLY);
      onChange(PricingSwitchFlag.MONTHLY);
    } else if (activeFlag === PricingSwitchFlag.MONTHLY) {
      setActiveFlag(PricingSwitchFlag.ANNUALLY);
      onChange(PricingSwitchFlag.ANNUALLY);
    }
  };

  useEffect(() => {
    // Update state if change made after initialization of component
    setActiveFlag(initialFlag);
  }, [initialFlag]);

  return (
    <button
      type="button"
      role="switch"
      aria-checked={activeFlag === PricingSwitchFlag.MONTHLY}
      data-testid="switch"
      className="relative flex items-center p-1 bg-white border rounded-full w-[216px] border-border-emphasis"
      onClick={handleOnChange}
    >
      {activeFlag === PricingSwitchFlag.MONTHLY && (
        <Tag
          size="sm"
          className="absolute px-2 py-0.5 rounded-full bg-green-faint text-green-emphasis right-[-6px] top-[-9.5px] text-[0.625rem] font-medium"
        >
          {pricingLabels["save-discount"].replace(
            "{DISCOUNT}",
            ANNUAL_PRICE_DISCOUNT
          )}
        </Tag>
      )}
      <div
        className={classNames(
          "transition px-4 py-1 rounded-full flex-1",
          activeFlag === PricingSwitchFlag.MONTHLY
            ? "text-accent-emphasis bg-accent-faint sm-md"
            : "text-fg-muted sm-normal"
        )}
      >
        {pricingLabels["monthly"]}
      </div>
      <div
        className={classNames(
          "transition px-4 py-1 rounded-full flex-1",
          activeFlag === PricingSwitchFlag.ANNUALLY
            ? "text-accent-emphasis bg-accent-faint sm-md"
            : "text-fg-muted sm-normal"
        )}
      >
        {pricingLabels["annually"]}
      </div>
    </button>
  );
}

export default PricingSwitch;

import { IconProps } from ".";

/**
 * @component TwitterIcon
 * @description Component for showing the twitter (X) SVG icon. Used instead of an <img> tag so that
 * we are able to change the color with css, e.g. on active. Use in conjunction with the <Icon>
 * component.
 * @example
 * return (
 *   <Icon
 *      className="fill-current"
 *      name="twitter"
 *      local
 *    />
 * )
 */
function TwitterIcon({ className }: IconProps) {
  return (
    <svg
      aria-label="Twitter"
      xmlns="http://www.w3.org/2000/svg"
      className={` ${className} ${className.includes("h-") ? "" : "w-5 h-5"}`}
      viewBox="0 0 20 21"
      fill="none"
    >
      <path d="M11.3333 9.42873L16.5459 3.5H15.3111L10.7831 8.64678L7.1692 3.5H3L8.46607 11.2836L3 17.5H4.2348L9.01347 12.0637L12.8308 17.5H17M4.68047 4.41111H6.57747L15.3102 16.6337H13.4127" />
    </svg>
  );
}

export default TwitterIcon;

import { IconProps } from ".";

/**
 * @component SearchHistoryIcon
 * @description Component for showing the share SVG icon. Used instead of an <img> tag so that
 * we are able to change the color with css, e.g. on active. Use in conjunction with the <Icon>
 * component.
 * @example
 * return (
 *   <Icon
 *      className="fill-current"
 *      name="share"
 *      local={true}
 *    />
 * )
 */
function SearchHistoryIcon({ className }: IconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      className={`${className} ${className.includes("h-") ? "" : "w-5 h-5"}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_11872_116930)">
        <path
          // strokeWidth="0"
          d="M9.90937 10.8156L13.6594 13.6281C13.9356 13.8353 14.3272 13.7794 14.5344 13.5031C14.7416 13.2269 14.6856 12.8353 14.4094 12.6281L10.9375 10V4.6875C10.9375 4.34219 10.6578 4.0625 10.3125 4.0625C9.96719 4.0625 9.6875 4.34219 9.6875 4.6875V10.3125C9.6875 10.52 9.77437 10.7069 9.90937 10.8156Z"
        />
        <path
          // strokeWidth="0"
          d="M10.6249 0.625C6.05026 0.625 2.1437 3.92687 1.3812 8.4375L1.1437 8.08438C0.950258 7.79781 0.561508 7.72219 0.274945 7.91562C-0.0116173 8.10906 -0.0872423 8.49781 0.106195 8.78438L1.3562 10.6594C1.45901 10.8156 1.62651 10.9178 1.81245 10.9375H1.87495C2.04057 10.9369 2.19901 10.8706 2.31557 10.7531L3.87807 9.19062C4.12213 8.94656 4.12213 8.55031 3.87807 8.30625C3.63401 8.06219 3.23776 8.06219 2.9937 8.30625L2.6062 8.69687C3.32588 4.2675 7.49994 1.26062 11.929 1.98031C16.3581 2.7 19.3656 6.87375 18.6459 11.3031C18.0068 15.2362 14.6096 18.1256 10.6249 18.125C8.11182 18.1706 5.73495 16.985 4.25932 14.95C4.05901 14.6688 3.6687 14.6028 3.38745 14.8031C3.1062 15.0034 3.04026 15.3937 3.24057 15.675C4.94932 18.0412 7.70682 19.4228 10.6249 19.375C15.8028 19.375 19.9999 15.1778 19.9999 10C19.9999 4.82219 15.8028 0.625 10.6249 0.625Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_11872_116930">
          <rect width="20" height="20" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SearchHistoryIcon;

import clsx from "classnames";
import { HTMLAttributes, ReactNode } from "react";

type TagProps = {
  children: ReactNode;
  size?: "sm" | "default";
  variant?: "white" | "default" | "accent" | "danger";
  className?: string;
} & HTMLAttributes<HTMLSpanElement>;

const Tag = ({
  children,
  size = "default",
  variant = "default",
  className,
  ...otherProps
}: TagProps) => {
  let textColor = "text-fg-muted";
  if (variant === "accent") {
    textColor = "text-accent-emphasis";
  }

  return (
    <span
      data-testid="tag"
      className={clsx(
        // Always applied classes
        "flex items-center gap-2 px-2 rounded-lg whitespace-nowrap overflow-hidden font-md",
        !className?.includes("text-") && textColor,

        // Size-based classes
        size === "sm" && "h-6 text-[11px]",
        size === "default" && "h-[30px] text-[13px]",

        // Variant-based classes
        variant === "white" && !className?.includes("bg-") && "bg-bgr-base",
        variant === "white" && "border",
        variant === "white" &&
          !className?.includes("border-") &&
          "border-border-base",
        variant === "default" && !className?.includes("bg-") && "bg-bgr-faint",

        variant === "accent" &&
          !className?.includes("bg-") &&
          "bg-accent-subtle",
        variant === "accent" && "text-accent-emphasis",

        variant === "danger" && "bg-red-faint",

        // Additional custom classes
        className
      )}
      {...otherProps}
    >
      {children}
    </span>
  );
};

export { Tag };

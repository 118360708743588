import { useCallback } from "react";
import {
  clearModals,
  popModalAsync,
  pushModal,
  removeModal,
  selectIsModalOnTop,
  selectIsModalOpen,
} from "../../store/slices/modalStack";
import { useAppDispatch, useAppSelector } from "../useStore";

/**
 * Hook for interacting with the modal stack.
 * For most cases, using the EmptyModal component directly is simpler.
 * This hook is useful for custom modal components or global modal management.
 */
export const useModalStack = (modalId: string) => {
  const dispatch = useAppDispatch();
  /**
   * Check if the top modal is currently closing
   */
  const isClosingTopModal = useAppSelector(
    (state) => state.modalStack.isClosingTopModal
  );

  /**
   * Check if the modal referenced by the modalId is open
   */
  const isModalOpen = useAppSelector((state) =>
    selectIsModalOpen(state, modalId)
  );

  /**
   * Check if the modal referenced by the modalId is on top of the stack
   */
  const isTopModal = useAppSelector((state) =>
    selectIsModalOnTop(state, modalId)
  );

  /**
   * Open the modal referenced by the modalId by pushing it onto the stack
   */
  const openModal = useCallback(() => {
    dispatch(pushModal(modalId));
  }, [dispatch, modalId]);

  /**
   * Closes the modal referenced by the modalId if it is at the top of the stack.
   * If the modal is not at the top of the stack, this function does nothing.
   * If a modal is currently in the process of closing, this function will not close the modal.
   * If a callback is provided, it will be executed after the modal is closed.
   */
  const closeModalIfTop = useCallback(
    (onCloseCallback?: () => void) => {
      // Only proceed if:
      // 1. The modal is at the top of the stack
      // 2. No modal is currently in the process of closing
      if (isTopModal && !isClosingTopModal) {
        // Start the closing process
        dispatch(popModalAsync());

        // Execute the callback if provided
        if (onCloseCallback) {
          onCloseCallback();
        }
      }
    },
    [dispatch, isTopModal, isClosingTopModal]
  );

  /**
   * Close the modal referenced by the modalId, regardless of its position in the stack
   * If a callback is provided, it will be executed after the modal is closed
   */
  const closeModal = useCallback(
    (onCloseCallback?: () => void) => {
      dispatch(removeModal(modalId));
      if (onCloseCallback) {
        onCloseCallback();
      }
    },
    [dispatch, modalId]
  );

  /**
   * Close all modals on the stack
   */
  const closeAllModals = useCallback(() => {
    dispatch(clearModals());
  }, [dispatch]);

  return {
    isTopModal,
    isModalOpen,
    openModal,
    closeModalIfTop,
    closeModal,
    closeAllModals,
  };
};

export default useModalStack;

import { ThreadHistory } from "helpers/api";

export type ThreadGroups = {
  today: ThreadHistory[];
  lastSevenDays: ThreadHistory[];
  lastThirtyDays: ThreadHistory[];
  lastYear: ThreadHistory[];
  count: number;
};

export function groupThreadsByDate(threads: ThreadHistory[]): ThreadGroups {
  const groups: ThreadGroups = {
    today: [],
    lastSevenDays: [],
    lastThirtyDays: [],
    lastYear: [],
    count: 0,
  };

  const now = new Date();

  threads.forEach((thread) => {
    const searchDate = new Date(thread.created_at);
    const diffTime = now.getTime() - searchDate.getTime();
    if (diffTime < 0) return;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays === 1) {
      groups.today.push(thread);
    } else if (diffDays <= 7) {
      groups.lastSevenDays.push(thread);
    } else if (diffDays <= 30) {
      groups.lastThirtyDays.push(thread);
    } else {
      groups.lastYear.push(thread);
    }
  });

  groups.count = threads.length;

  return groups;
}

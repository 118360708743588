import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Paper, PaperDetails, QueryFeatures } from "helpers/api";
import {
  MixpanelActionSource,
  MixpanelNavigationSource,
  SearchFilterProperties,
} from "helpers/services/mixpanel/events";

export interface AnalyticUser {
  id: string;
  createdAt: string | null;
  lastSignInAt: string | null;
}

export type AnalyticSearch = {
  adjustedQuery: string | null;
  error: string | null;
  filters: AnalyticSearchFilters;
  filtersOn: boolean;
  isNullResults: boolean | null;
  proAnalysisOn: boolean;
  query: string | null;
  queryFeatures: QueryFeatures;
};

export type AnalyticSearchFilters = SearchFilterProperties;

export type AnalyticResult = {
  page: number | null;
  index: number | null;
  item: Paper | null;
};

interface AnalyticState {
  actionSource: MixpanelActionSource;
  paper?: PaperDetails;
  navigationSource: MixpanelNavigationSource;
  result: AnalyticResult;
  results?: Paper[];
  search: AnalyticSearch;
  user?: AnalyticUser;
}

const initialState = {
  search: {
    proAnalysisOn: false,
    filters: {},
    filtersOn: false,
  },
  result: {},
  navigationSource: MixpanelNavigationSource.External, //Default to external unless otherwise set.
} as AnalyticState;

const analyticSlice = createSlice({
  name: "analytic",
  initialState,
  reducers: {
    setAnalyticPaper(
      state: AnalyticState,
      { payload }: PayloadAction<PaperDetails | undefined>
    ) {
      state.paper = payload;
    },
    setAnalyticActionSource(
      state: AnalyticState,
      { payload }: PayloadAction<MixpanelActionSource>
    ) {
      state.actionSource = payload;
    },
    setAnalyticNavigationSource(
      state: AnalyticState,
      { payload }: PayloadAction<MixpanelNavigationSource>
    ) {
      state.navigationSource = payload;
    },
    setAnalyticSearch(
      state: AnalyticState,
      { payload }: PayloadAction<Partial<AnalyticSearch>>
    ) {
      state.search = {
        ...state.search,
        ...payload,
      };
    },
    setAnalyticSearchFilters(
      state: AnalyticState,
      { payload }: PayloadAction<SearchFilterProperties>
    ) {
      state.search = {
        ...state.search,
        filters: payload,
        filtersOn: !Object.values(payload).every(
          (value) => value === undefined
        ),
      };
    },
    setAnalyticResult(
      state: AnalyticState,
      { payload }: PayloadAction<Partial<AnalyticResult>>
    ) {
      state.result = {
        ...state.result,
        ...payload,
      };
    },
    setAnalyticUser(
      state: AnalyticState,
      { payload }: PayloadAction<AnalyticUser>
    ) {
      state.user = payload;
    },
  },
});

export const {
  setAnalyticActionSource,
  setAnalyticPaper,
  setAnalyticNavigationSource,
  setAnalyticResult,
  setAnalyticSearch,
  setAnalyticSearchFilters,
  setAnalyticUser,
} = analyticSlice.actions;
export default analyticSlice.reducer;

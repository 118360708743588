import { combineReducers, configureStore } from "@reduxjs/toolkit";
import analytic from "./slices/analytic";
import bookmark from "./slices/bookmark";
import modalStack from "./slices/modalStack";
import search from "./slices/search";
import setting from "./slices/setting";
import subscription from "./slices/subscription";
import user from "./slices/user";

const rootReducer = combineReducers({
  search,
  analytic,
  setting,
  subscription,
  bookmark,
  user,
  modalStack,
});

export function setupStore(preloadedState?: Partial<RootState>) {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
}

export const getInitialReduxState = () => {
  // Using rootReducer with undefined state and a dummy action to get the default initial state
  return rootReducer(undefined, { type: "@@INIT" });
};

export const store = setupStore();

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];

import { useModalStack } from "hooks/useModalStack";
import { ReactNode } from "react";
import EmptyModal from "./EmptyModal";

type StackedEmptyModalProps = {
  modalId: string;
  children: ReactNode;
  blurBackground?: boolean;
  onClose?: () => void;
};

/**
 * @component StackedEmptyModal
 * @description A wrapper around EmptyModal that uses the modal stack for state management.
 * This component integrates with the global modal stack, allowing for multiple modals to be stacked on top of each other.
 */
function StackedEmptyModal({
  modalId,
  children,
  blurBackground = false,
  onClose,
}: StackedEmptyModalProps) {
  const { isModalOpen, closeModalIfTop } = useModalStack(modalId);

  const handleClose = () => {
    closeModalIfTop(onClose);
  };

  return (
    <EmptyModal
      open={isModalOpen}
      onClose={handleClose}
      blurBackground={blurBackground}
    >
      {children}
    </EmptyModal>
  );
}

export default StackedEmptyModal;

import { MAX_MOBILE_WIDTH } from "constants/config";
import { useAppDispatch } from "hooks/useStore";
import { useCallback, useEffect } from "react";
import {
  set1024WideScreen,
  set898WideScreen,
  setIsMobile,
} from "store/slices/setting";

function HandleScreenSizes() {
  const dispatch = useAppDispatch();

  const settingIsMobile = useCallback(() => {
    dispatch(setIsMobile(window.innerWidth < MAX_MOBILE_WIDTH));
    dispatch(set898WideScreen(window.innerWidth < 898));
    dispatch(set1024WideScreen(window.innerWidth < 1024));
  }, [dispatch]);

  useEffect(() => {
    settingIsMobile();
    addEventListener("resize", (event) => {
      settingIsMobile();
    });
  }, [settingIsMobile]);

  return null;
}

export default HandleScreenSizes;

import { ToggleState } from "enums/toggles";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import { getCookie, setCookie } from "react-use-cookie";
import { setAnalyticSearch } from "store/slices/analytic";
import { setIsProToggleOn } from "store/slices/setting";
import useAnalytics from "./useAnalytics";
import useSetQueryParam from "./useSetQueryParam";
import { useAppDispatch, useAppSelector } from "./useStore";

export const PRO_ANALYSIS_QUERY_PARAM = "pro";
const PRO_TOGGLE_COOKIE = "proToggle";

/**
 * @hook useProToggle
 * @description Encapsulates the business logic for if the pro toggle can be enabled.
 */
const useProToggle = () => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const setQueryParam = useSetQueryParam();

  const { toggleProAnalysisEvent } = useAnalytics();

  const queryData = useAppSelector((state) => state.search.queryData);

  const isNotEnoughResults = Boolean(
    queryData.synthesisFeatures?.not_enough_results_for_pro_analysis
  );
  const isNuanceRequired = Boolean(queryData.queryFeatures?.is_offensive);

  // The user's saved preference for if the toggle should be enabled for the next search.
  const [isProTogglePreferred, setIsProTogglePreferred] = useState<boolean>(
    () => {
      return getCookie(PRO_TOGGLE_COOKIE) !== ToggleState.OFF; // Default to true if not set.
    }
  );

  const isProToggleOn = useAppSelector((state) => state.setting.isProToggleOn);

  const handleSetIsProToggleOn = useCallback(
    (value: boolean) => {
      dispatch(setIsProToggleOn(value));
    },
    [dispatch]
  );

  // If it has not been set elsewhere yet,initialize the toggle state from the URL, or fall back to the stored preference.
  useEffect(() => {
    if (isProToggleOn === undefined) {
      handleSetIsProToggleOn(
        router.query[PRO_ANALYSIS_QUERY_PARAM] === ToggleState.ON
          ? true
          : router.query[PRO_ANALYSIS_QUERY_PARAM] === ToggleState.OFF
          ? false
          : isProTogglePreferred
      );
    }
  }, [
    handleSetIsProToggleOn,
    isProToggleOn,
    isProTogglePreferred,
    router.query,
  ]);

  const canEnableProToggle =
    queryData.synthesisFeatures?.can_pro_analysis_succeed !== false;

  // Handle the user clicking the toggle, which not only toggles the state but also updates their preference and fires an event
  const handleChangeProToggle = useCallback(
    async (value: boolean) => {
      if (value && !canEnableProToggle) return;

      handleSetIsProToggleOn(value);
      setQueryParam(PRO_ANALYSIS_QUERY_PARAM, value ? ToggleState.ON : null);

      // When the user hits the toggle, save their preference for next time.
      setIsProTogglePreferred(value);
      setCookie(PRO_TOGGLE_COOKIE, value ? ToggleState.ON : ToggleState.OFF);

      toggleProAnalysisEvent(value);
    },
    [
      canEnableProToggle,
      handleSetIsProToggleOn,
      setQueryParam,
      toggleProAnalysisEvent,
    ]
  );

  useEffect(() => {
    setAnalyticSearch({ proAnalysisOn: isProToggleOn });
  }, [isProToggleOn]);

  return {
    canEnableProToggle,
    changeProToggle: handleChangeProToggle,
    isProTogglePreferred,
    isNotEnoughResults,
    isNuanceRequired,
    isProToggleOn: isProToggleOn && canEnableProToggle, // Don't overwrite global isProToggleOn when canEnable is false, so that if canEnable changes for the subsequent search, the toggle will flip back on.
  };
};

export default useProToggle;

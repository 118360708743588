import { Tooltip as HeroTooltip, TooltipPlacement } from "@heroui/tooltip";
import { ReactNode } from "react";

type PropsType = {
  content: string;
  children: ReactNode;
  placement?: TooltipPlacement;
};
const Tooltip = ({ content, children, placement = "top" }: PropsType) => {
  return (
    <HeroTooltip
      content={content}
      showArrow
      classNames={{
        base: [
          // arrow color
          "before:bg-bgr-emphasis",
        ],
        content: ["bg-bgr-emphasis text-white rounded-lg p-2 tiny-normal"],
      }}
      delay={300} // standard
      placement={placement}
    >
      {children}
    </HeroTooltip>
  );
};

export default Tooltip;

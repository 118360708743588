import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Domain } from "constants/filters";
import { defaultMeterFilter } from "features/ConsensusMeter/utils";
import { DurationUnitType } from "features/FilterDrawer/components/DurationInput";
import {
  ExtractedAnswersResponse,
  Paper,
  QueryFeatures,
  StudyType,
  SummaryResponse,
  SynthesisFeatures,
} from "helpers/api";
import { CountryCode } from "helpers/sharedDataConstants";
import { MeterFilterParams } from "types/MeterFilterParams";

export interface QueryData {
  adjustedQuery?: string;
  isEnd?: boolean;
  numRelevantResults?: number;
  numTopResults?: number;
  queryFeatures?: QueryFeatures;
  searchId?: string;
  synthesisFeatures?: SynthesisFeatures;
}

const defaultQueryData: QueryData = {
  searchId: undefined,
  queryFeatures: undefined,
  synthesisFeatures: undefined,
};

export interface Filters {
  // Group filters
  countries?: CountryCode[];
  domains?: Domain[];
  studyTypes?: StudyType[];
  // Bool filters
  excludePreprints?: boolean;
  filterControlledStudies?: boolean;
  filterHumanStudies?: boolean;
  hasValidChatPdf?: boolean;
  openAccess?: boolean;
  // Range filters
  citationsMin?: number;
  durationMax?: number;
  durationMin?: number;
  durationUnit?: DurationUnitType;
  sampleSizeMax?: number;
  sampleSizeMin?: number;
  sjrBestQuartileMax?: number;
  sjrBestQuartileMin?: number;
  yearMax?: number;
  yearMin?: number;
}

export type Filter = keyof Filters;

interface SearchState {
  appliedFilters: Filters;
  extractedAnswers?: { [paperId: string]: string };
  isEnd: boolean;
  isProAnalysisComplete?: boolean;
  isRefresh: boolean;
  isSearching: boolean;
  meterFilter: MeterFilterParams;
  threadInteractionMeterFilters: {
    [threadInteractionId: number]: MeterFilterParams;
  };
  page: number;
  proAnalysisContent: string;
  proAnalysisSuccess?: boolean | null;
  queryData: QueryData;
  results: Paper[];
  summaryData?: SummaryResponse;
  summarySuccess: boolean | null;
}

const initialState = {
  appliedFilters: {},
  extractedAnswers: undefined,
  isEnd: false,
  isProAnalysisComplete: false,
  isRefresh: false,
  isSearching: false,
  meterFilter: defaultMeterFilter,
  threadInteractionMeterFilters: {},
  page: 0,
  proAnalysisContent: "",
  proAnalysisSuccess: null,
  queryData: defaultQueryData,
  relatedSearches: undefined,
  results: [],
  summaryData: undefined,
  summarySuccess: null,
} as SearchState;

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setAppliedFilters(state: SearchState, { payload }: PayloadAction<Filters>) {
      state.appliedFilters = payload;
    },
    setProAnalysisSuccess(
      state: SearchState,
      { payload }: PayloadAction<boolean>
    ) {
      state.proAnalysisSuccess = payload;
    },
    setExtractedAnswers(
      state: SearchState,
      { payload }: PayloadAction<ExtractedAnswersResponse | null>
    ) {
      if (payload) {
        state.extractedAnswers = payload.idToAnswers;
      } else {
        // Default to an empty map if loading fails
        state.extractedAnswers = {};
      }
    },
    setIsSearching(state: SearchState, { payload }: PayloadAction<boolean>) {
      state.isSearching = payload;
      if (payload) {
        state.results = [];
        state.queryData = defaultQueryData;
        state.extractedAnswers = undefined;
        state.summaryData = undefined;
        state.page = 0;
      }
    },
    setPage(state: SearchState, { payload }: PayloadAction<number>) {
      state.page = payload;
    },
    setIsRefresh(state: SearchState, { payload }: PayloadAction<boolean>) {
      state.isRefresh = payload;
    },
    setIsEnd(state: SearchState, { payload }: PayloadAction<boolean>) {
      state.isEnd = payload;
    },
    setMeterFilter(
      state: SearchState,
      { payload }: PayloadAction<MeterFilterParams>
    ) {
      state.meterFilter = payload;
    },
    setThreadInteractionMeterFilter(
      state: SearchState,
      {
        payload,
      }: PayloadAction<{
        threadInteractionId: number;
        meterFilter: MeterFilterParams;
      }>
    ) {
      state.threadInteractionMeterFilters[payload.threadInteractionId] =
        payload.meterFilter;
    },
    resetThreadInteractionMeterFilter(
      state: SearchState,
      { payload }: PayloadAction<number>
    ) {
      delete state.threadInteractionMeterFilters[payload];
    },
    setQueryResultData(
      state: SearchState,
      { payload }: PayloadAction<QueryData>
    ) {
      state.queryData = {
        ...state.queryData,
        ...payload,
      };
    },
    setSearchResults(state: SearchState, { payload }: PayloadAction<Paper[]>) {
      state.results = payload;
    },
    setSummaryData(
      state: SearchState,
      { payload }: PayloadAction<SummaryResponse>
    ) {
      state.summaryData = payload;
    },
    setSummarySuccess(state: SearchState, { payload }: PayloadAction<boolean>) {
      state.summarySuccess = payload;
    },

    setProAnalysisComplete(
      state: SearchState,
      { payload }: PayloadAction<string>
    ) {
      state.proAnalysisContent = payload;
      state.isProAnalysisComplete = true;
    },
    resetProAnalysis(state: SearchState) {
      state.proAnalysisContent = "";
      state.isProAnalysisComplete = false;
    },
  },
});

export const {
  resetProAnalysis,
  setAppliedFilters,
  setExtractedAnswers,
  setIsEnd,
  setIsRefresh,
  setIsSearching,
  setMeterFilter,
  setThreadInteractionMeterFilter,
  resetThreadInteractionMeterFilter,
  setPage,
  setProAnalysisComplete,
  setProAnalysisSuccess,
  setQueryResultData,
  setSearchResults,
  setSummaryData,
  setSummarySuccess,
} = searchSlice.actions;
export default searchSlice.reducer;

import classNames from "classnames";
import { ButtonVariant, CoButton } from "components/CoButton";
import useLabels from "hooks/useLabels";
import { ReactNode } from "react";
import EmptyModal from "./EmptyModal";
import StackedEmptyModal from "./StackedEmptyModal";

type BaseModalProps = {
  buttonDisabled?: boolean;
  buttonText?: string;
  children?: ReactNode;
  fullWidth?: boolean; // For e.g. dividers that go all the way to the edge
  onButtonClicked?: () => void;
  onClose: () => void;
  subtitle?: ReactNode;
  title: string;
  contentClassName?: string;
  headerClassName?: string;
};

type ModalProps = BaseModalProps & {
  open?: boolean;
};

type StackedModalProps = BaseModalProps & {
  modalId: string;
};

/**
 * @component BaseModal
 * @description Internal component containing the modal UI structure
 *
 * Design Reference: https://www.figma.com/design/X5eDWdGQXKwiobJgOJZiw5/Consensus-Design-Library?node-id=88-262
 */
function BaseModal({
  buttonDisabled,
  buttonText,
  children,
  fullWidth = false,
  onButtonClicked,
  onClose,
  subtitle,
  title,
  contentClassName = "",
  headerClassName = "",
}: BaseModalProps) {
  const [modalLabels] = useLabels("general");

  return (
    <div className="bg-white rounded-t-xl w-full sm:rounded-b-xl sm:w-[500px] max-h-[95vh] border-box sm:max-h-[600px] overflow-hidden flex flex-col justify-items-stretch items-stretch border sm:border-0">
      <div
        className={classNames(
          "py-3 pl-4 pr-1.5",
          !!children ? "border-b" : "",
          headerClassName
        )}
      >
        <div className="flex items-stretch">
          <h1 className="flex items-center flex-1 lg-bold text-fg-base">
            {title}
          </h1>
          <CoButton
            variant={ButtonVariant.Flat}
            size="sm"
            onClick={onClose}
            icon="icon-x"
            data-testid="close-button"
          />
        </div>
        {subtitle && (
          <span className="sm-normal text-fg-muted block mr-4">{subtitle}</span>
        )}
      </div>
      <div className="flex-1 overflow-auto">
        {children !== undefined && (
          <div className={`${fullWidth ? "my-4" : "m-4"} ${contentClassName}`}>
            {children}
          </div>
        )}

        {!!onButtonClicked && (
          <div
            className={classNames(
              "flex flex-col-reverse gap-2 p-4 sm:flex-row sm:justify-end sticky bottom-0 bg-white",
              !!children ? "border-t" : ""
            )}
          >
            <CoButton
              variant={ButtonVariant.Tertiary}
              size="sm"
              onClick={onClose}
              data-testid="cancel-button"
              label={modalLabels["cancel"]}
            />
            <CoButton
              variant={ButtonVariant.Primary}
              size="sm"
              onClick={onButtonClicked}
              label={buttonText}
              disabled={buttonDisabled}
            />
          </div>
        )}
      </div>
    </div>
  );
}

/**
 * @component Modal
 * @description Renders a modal with standardized styling, with a title and subtitle, a body
 *   content section, and close and submit buttons. If onButtonClicked prop is not included,
 *   no buttons will be rendered.
 */
function Modal({ open, onClose, ...rest }: ModalProps) {
  return (
    <EmptyModal open={open} onClose={() => {}}>
      <BaseModal onClose={onClose} {...rest} />
    </EmptyModal>
  );
}

/**
 * @component StackedModal
 * @description Renders a modal that integrates with the modal stack system.
 * Functions the same as Modal but automatically manages its open state via the modal stack.
 */
function StackedModal({ modalId, onClose, ...rest }: StackedModalProps) {
  return (
    <StackedEmptyModal modalId={modalId} onClose={onClose}>
      <BaseModal onClose={onClose} {...rest} />
    </StackedEmptyModal>
  );
}

export { Modal, StackedModal };
export default Modal;

import { UserResource } from "@clerk/types";
import { ToggleState } from "enums/toggles";
import { Paper, PaperDetails } from "helpers/api";
import * as sharedServerEvents from "helpers/services/events";
import * as googleTagManager from "helpers/services/googleTagManager";
import * as intercom from "helpers/services/intercom";
import * as metaPixel from "helpers/services/metaPixel";
import * as mixpanel from "helpers/services/mixpanel";
import {
  MixpanelActionSource,
  MixpanelCiteType,
  MixpanelExportFileType,
  MixpanelFullTextLinkType,
  MixpanelNavigationSource,
  RelatedSearchClickEvent,
} from "helpers/services/mixpanel/events";
import { useAppDispatch, useAppSelector } from "hooks/useStore";
import * as slice from "store/slices/analytic";
import {
  AnalyticResult,
  AnalyticSearch,
  AnalyticSearchFilters,
} from "store/slices/analytic";
import { ShareType } from "./types";

const ERROR_PAGE_ROUTES = ["/500", "/429", "/400"];

declare global {
  interface Window {
    // Adds a quick type to satisfy the type checker.
    dataLayer: any;
  }
}

const useAnalytics = () => {
  const dispatch = useAppDispatch();

  const results = useAppSelector((state) => state.analytic.results);

  const setAnalyticUser = (user: UserResource) => {
    mixpanel.identifyUser(user);
    const analyticUser: slice.AnalyticUser = {
      id: user.id,
      createdAt: user.createdAt ? new Date(user.createdAt).toISOString() : null,
      lastSignInAt: user.lastSignInAt
        ? new Date(user.lastSignInAt).toISOString()
        : null,
    };
    dispatch(slice.setAnalyticUser(analyticUser));
  };

  const setAnalyticPaper = (paper: PaperDetails) => {
    // Only result or detail can be set at once.
    dispatch(slice.setAnalyticPaper(paper));
    dispatch(slice.setAnalyticResult({ item: null }));
  };

  const setAnalyticNavigationSource = (
    navigationSource: MixpanelNavigationSource
  ) => {
    dispatch(slice.setAnalyticNavigationSource(navigationSource));
  };

  const setAnalyticActionSource = (actionSource: MixpanelActionSource) => {
    dispatch(slice.setAnalyticActionSource(actionSource));
  };

  const setAnalyticSearch = (data: Partial<AnalyticSearch>) => {
    dispatch(slice.setAnalyticSearch(data));
  };

  const setAnalyticSearchFilters = (data: AnalyticSearchFilters) => {
    dispatch(slice.setAnalyticSearchFilters(data));
  };

  const setAnalyticResult = (data: Partial<AnalyticResult>) => {
    // Only result or detail can be set at once.
    dispatch(slice.setAnalyticResult(data));
    dispatch(slice.setAnalyticPaper(undefined));
  };

  const viewResultsEvent = (data: {
    items: Paper[];
    page: number;
    query: string;
    isLoadMore: boolean;
  }) => {
    const { items, page, query, isLoadMore } = data;
    googleTagManager.logViewItemListEvent(items, page, query);
    mixpanel.logResultsViewEvent(items, page, isLoadMore);
  };

  const viewPaperEvent = () => {
    googleTagManager.logViewItemEvent();
    mixpanel.logPaperViewEvent();
  };

  const askPaperClickEvent = async (paperId: string) => {
    await mixpanel.logAskPaperClickEvent(paperId);
  };

  const askPaperChatEvent = async (message: string, paperId: string) => {
    await mixpanel.logAskPaperChatEvent(message, paperId);
  };

  const clickResultItemEvent = () => {
    mixpanel.logClickResultItemEvent();
  };

  const clickProAnalysisResultItemEvent = (
    referenceLineNumber: number,
    numberOfReferenceLines: number,
    associatedSentence: string
  ) => {
    mixpanel.logProAnalysisClickResultItemEvent(
      referenceLineNumber,
      numberOfReferenceLines,
      associatedSentence
    );
  };

  const clickRelatedSearchEvent = (
    relatedQuery: string,
    resultNumber: number,
    pagePosition?: RelatedSearchClickEvent["pagePosition"]
  ) => {
    mixpanel.logClickRelatedSearchEvent(
      relatedQuery,
      resultNumber,
      pagePosition
    );
  };

  const citeEvent = (citeType: MixpanelCiteType) => {
    mixpanel.logCitePaperEvent(citeType);
  };

  const clickFullTextLinkEvent = (linkType: MixpanelFullTextLinkType) => {
    mixpanel.logFullTextClickEvent(linkType);
  };

  const saveSearchEvent = () => {
    mixpanel.logSaveSearchEvent();
  };

  const savePaperEvent = () => {
    mixpanel.logSavePaperEvent();
  };

  const studySnapshotEvent = () => {
    mixpanel.logStudySnapshotEvent();
  };

  const clickShareEvent = () => {
    googleTagManager.logClickShareEvent();
    // Skip logging in mixpanel
  };

  const clickShareResultsEvent = (items: Paper[]) => {
    googleTagManager.logClickShareResultsEvent(items);
    // Skip logging in mixpanel
  };

  const executeShareEvent = (shareType: ShareType) => {
    mixpanel.logExecuteSharePaperEvent(shareType);
    googleTagManager.logExecuteShareEvent(shareType);
  };

  const executeShareResultsEvent = (shareType: ShareType) => {
    mixpanel.logShareResultsEvent(shareType);
    googleTagManager.logShareResultsEvent(shareType, results || []);
  };

  const searchEvent = (query: string, isProAnalysisOn?: ToggleState) => {
    dispatch(
      slice.setAnalyticSearch({
        query: query,
        proAnalysisOn: isProAnalysisOn === ToggleState.ON,
      })
    );
    mixpanel.logSearchEvent();
    metaPixel.logSearchEvent(query);
    googleTagManager.logSearchEvent(query);
    intercom.logSearchEvent(query, isProAnalysisOn);
  };

  const logoutEvent = () => {
    googleTagManager.logUserLogoutEvent();
    try {
      mixpanel.logUserLogoutEvent();
    } catch (error) {
      // do nothing
    }
  };

  const loginEvent = (loginMethod: string) => {
    mixpanel.logUserLoginEvent(loginMethod);
    googleTagManager.logUserLoginEvent(loginMethod);
  };

  const signupEvent = (signupMethod: string) => {
    mixpanel.registerUser(signupMethod);
    metaPixel.logUserSignupEvent(signupMethod);
    googleTagManager.logUserSignupEvent(signupMethod);
    // Mixpanel event logged server side
  };

  const studentDiscountEmailEvent = () => {
    metaPixel.logStudentDiscountEmailEvent();
  };

  const createListEvent = (listName: string) => {
    mixpanel.logCreateListEvent(listName);
  };

  const pageViewEvent = (data: { route: string; pageTitle: string }) => {
    metaPixel.logPageViewEvent(data.route);
    mixpanel.logPageViewEvent(data);
    sharedServerEvents.logPageViewEvent(data);
    if (!ERROR_PAGE_ROUTES.includes(data.route)) {
      // Skip logging page view events for error pages in GA to reduce volume.
      googleTagManager.logPageViewEvent(data.route);
    }
  };

  const copyProAnalysisEvent = (citeType?: MixpanelCiteType) => {
    mixpanel.logProAnalysisCopyEvent(citeType);
  };

  const toggleProAnalysisEvent = (value: boolean) => {
    mixpanel.logToggleProAnalysisEvent(value);
  };

  const toggleYesNoSummaryTableEvent = (value: boolean) => {
    mixpanel.logToggleYesNoSummaryTableEvent(value);
  };

  const exportResultsEvent = (
    actionSource?: MixpanelActionSource,
    fileType: MixpanelExportFileType = MixpanelExportFileType.CSV
  ) => {
    mixpanel.logExportResultsEvent(actionSource, fileType);
  };

  const errorEvent = (message: string) => {
    googleTagManager.logErrorEvent(message);
  };

  return {
    askPaperChatEvent,
    askPaperClickEvent,
    citeEvent,
    clickFullTextLinkEvent,
    clickProAnalysisResultItemEvent,
    clickRelatedSearchEvent,
    clickResultItemEvent,
    clickShareEvent,
    clickShareResultsEvent,
    copyProAnalysisEvent,
    createListEvent,
    errorEvent,
    executeShareEvent,
    executeShareResultsEvent,
    exportResultsEvent,
    loginEvent,
    logoutEvent,
    pageViewEvent,
    savePaperEvent,
    saveSearchEvent,
    searchEvent,
    setAnalyticActionSource,
    setAnalyticNavigationSource,
    setAnalyticPaper,
    setAnalyticResult,
    setAnalyticSearch,
    setAnalyticSearchFilters,
    setAnalyticUser,
    signupEvent,
    studentDiscountEmailEvent,
    studySnapshotEvent,
    toggleProAnalysisEvent,
    toggleYesNoSummaryTableEvent,
    viewPaperEvent,
    viewResultsEvent,
  };
};

export default useAnalytics;

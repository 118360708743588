import classNames from "classnames";

function SidebarContentLoading() {
  return (
    <div className="pt-3 pl-8" data-testid="sidebar-content-loading">
      {Array.from({ length: 3 }, (value, index) => index).map((item) => (
        <div
          className={classNames("animate-pulse py-4 flex justify-between", {
            "w-full ": item < 2,
          })}
          key={item}
        >
          <div
            className={classNames("bg-loading-200 h-2.5 rounded-xl", {
              "w-[80%]": item === 0,
              "w-[70%]": item > 0,
            })}
          />
        </div>
      ))}
    </div>
  );
}

export default SidebarContentLoading;

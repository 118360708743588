/**
 * `HotkeyIcon` is a small, non-interactive UI component for displaying
 * a hotkey inside a styled box that resembles the key of a keyboard.
 */

type HotkeyIconProps = {
  /** A string representing the hotkey (e.g., "⌘", "K", "Shift") */
  hotkey: string;
};

function HotkeyIcon({ hotkey }: HotkeyIconProps) {
  return (
    <div
      className="flex items-center justify-center h-6 px-1 bg-white border rounded-md min-w-6 border-b-3 border-border-base text-fg-muted"
      role="img"
      aria-label={`Key: ${hotkey}`}
    >
      {hotkey}
    </div>
  );
}

export default HotkeyIcon;

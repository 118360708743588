import { ButtonVariant, CoButton } from "components/CoButton";
import Modal from "components/Modal";
import { SUPPORT_EMAIL } from "constants/config";
import useLabels from "hooks/useLabels";
import { useAppSelector } from "hooks/useStore";
import Link from "next/link";
import React from "react";

type SubscriptionCancelConfirmModalProps = {
  open?: boolean;
  onClose: () => void;
  onClickCancelSubscription?: () => void;
  onClickKeepPremium?: () => void;
};

/**
 * @component SubscriptionCancelConfirmModal
 * @description Component for canceling your subscription
 * @example
 * return (
 *   <SubscriptionCancelConfirmModal
 *    open={true}
 *    onClick={fn}
 *    title="Confirm"
 *    text="Hello"
 *   />
 * )
 */
function SubscriptionCancelConfirmModal({
  onClose,
  open,
  onClickCancelSubscription,
  onClickKeepPremium,
}: SubscriptionCancelConfirmModalProps) {
  const [modalLabels] = useLabels("screens.subscription.cancel-confirm-modal");
  const { premium } = useAppSelector((state) => state.subscription.pricingText);
  const subscription = useAppSelector(
    (state) => state.subscription.subscription
  );

  const features_list: string[] = premium?.features ?? [];

  const handleCancelSubscription = () => {
    onClose();
    onClickCancelSubscription?.();
  };

  const handleKeepPremium = () => {
    onClose();
    onClickKeepPremium?.();
  };

  const renderSubtitle = () => {
    if (subscription.user) {
      const dateFormatPeriodEnd = new Date(
        subscription.user?.current_period_end * 1000
      );
      const dateOptions: Intl.DateTimeFormatOptions = {
        month: "long",
        day: "numeric",
        year: "numeric",
      };
      return (
        <p
          className="sm-normal"
          dangerouslySetInnerHTML={{
            __html: modalLabels["subtitle"].replace(
              "{date}",
              dateFormatPeriodEnd.toLocaleString("en-US", dateOptions)
            ),
          }}
        ></p>
      );
    }
    return null;
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={modalLabels["cancel-subscription"]}
      fullWidth
    >
      <div className="flex flex-col gap-4 mx-4 text-fg-base">
        <h2 className="base-bold">{modalLabels["title"]}</h2>
        {renderSubtitle()}
        <ul className="flex flex-col gap-3">
          {features_list.map((feature: any) => (
            <li
              key={`feature-${feature}`}
              className="flex items-center w-full sm-normal feture-list-item"
            >
              <i className="icon-minus text-red-base" />
              <span className="flex-1 ml-3 sm-normal">{feature}</span>
            </li>
          ))}
        </ul>
        <span className="sm-normal">
          {modalLabels["help-us-grow"].replace("{EMAIL}", "")}
          <Link className="underline" href={`mailto:${SUPPORT_EMAIL}`}>
            {SUPPORT_EMAIL}
          </Link>
          .
        </span>
      </div>
      <div className="pt-4 mt-4 border-t">
        <div className="flex flex-col-reverse gap-2 px-4 sm:flex-row sm:justify-end">
          <CoButton
            variant={ButtonVariant.Tertiary}
            size="sm"
            onClick={handleCancelSubscription}
            label={modalLabels["cancel-subscription"]}
          />
          <CoButton
            variant={ButtonVariant.Primary}
            size="sm"
            onClick={handleKeepPremium}
            label={modalLabels["keep-premium"]}
          />
        </div>
      </div>
    </Modal>
  );
}

export default SubscriptionCancelConfirmModal;

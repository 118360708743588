import { getFiltersFromQuery } from "features/FilterDrawer/utils/queryParams/filterQueryParams";
import {
  ThreadInteractionResponse,
  ThreadInteractionResultsResponse,
  ThreadResponse,
} from "helpers/api";
import { ParsedUrlQuery } from "querystring";
import { Filters } from "store/slices/search";

// Types and utility functions for converting from the response objects sent over the threads apis
// into the internal representations used on the frontend.

// The main reason this is necessary is because the thread apis represent an interaction's filters
// as a series of params, but we need to parse it into our properly typed Filters object.
// e.g. { study_types: "rct,meta" } → { studyTypes: [StudyType.RCT, StudyType.META_ANALYSIS] }

export interface ThreadInteractionResults
  extends Omit<ThreadInteractionResultsResponse, "filters"> {
  // Parse FilterParams from the API into a Filters object.
  filters: Filters;
}

function parseThreadInteractionResultsResponse(
  response: ThreadInteractionResultsResponse
): ThreadInteractionResults {
  return {
    ...response,
    filters: !!response.filters
      ? getFiltersFromQuery(response.filters as ParsedUrlQuery)
      : {},
  };
}

export interface ThreadInteraction
  extends Omit<ThreadInteractionResponse, "results"> {
  results?: ThreadInteractionResults;
  // Extra client-side props to support optimistic updates
  isLoading?: boolean;
}

export function parseThreadInteractionResponse(
  response: ThreadInteractionResponse
): ThreadInteraction {
  return {
    ...response,
    results: !!response.results
      ? parseThreadInteractionResultsResponse(response.results)
      : undefined,
  };
}

export interface Thread extends Omit<ThreadResponse, "interactions"> {
  interactions: Array<ThreadInteraction>;
}

export function parseThreadResponse(response: ThreadResponse): Thread {
  return {
    ...response,
    interactions: response.interactions.map(parseThreadInteractionResponse),
  };
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AskPaperDrawerSettings {
  isAskPaperDrawerOpen: boolean;
  paperId?: string;
  title?: string;
  fullTextURL?: string;
}

interface SettingState {
  askPaperDrawer: AskPaperDrawerSettings;
  is1024WideScreen: boolean;
  is898WideScreen: boolean;
  isFilterDrawerOpen: boolean;
  isMobile: boolean | undefined;
  isProToggleOn?: boolean;
  isSidebarOpen: boolean;
}

const initialState: SettingState = {
  askPaperDrawer: {
    isAskPaperDrawerOpen: false,
    paperId: undefined,
    title: undefined,
    fullTextURL: undefined,
  },
  is1024WideScreen: false,
  is898WideScreen: false,
  isFilterDrawerOpen: false,
  isMobile: undefined,
  isProToggleOn: undefined,
  isSidebarOpen: false,
};

const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    setIsMobile(state: SettingState, { payload }: PayloadAction<boolean>) {
      state.isMobile = payload;
    },
    toogleSidebarOpen(state: SettingState) {
      state.isSidebarOpen = !state.isSidebarOpen;
    },
    set1024WideScreen(
      state: SettingState,
      { payload }: PayloadAction<boolean>
    ) {
      state.is1024WideScreen = payload;
    },
    setAskPaperDrawerSettings(
      state: SettingState,
      { payload }: PayloadAction<AskPaperDrawerSettings>
    ) {
      state.askPaperDrawer = payload;
    },
    set898WideScreen(state: SettingState, { payload }: PayloadAction<boolean>) {
      state.is898WideScreen = payload;
    },
    setIsProToggleOn(state: SettingState, { payload }: PayloadAction<boolean>) {
      state.isProToggleOn = payload;
    },
    setIsFilterDrawerOpen(
      state: SettingState,
      { payload }: PayloadAction<boolean>
    ) {
      state.isFilterDrawerOpen = payload;
    },
  },
});

export const {
  set1024WideScreen,
  set898WideScreen,
  setAskPaperDrawerSettings,
  setIsFilterDrawerOpen,
  setIsMobile,
  setIsProToggleOn,
  toogleSidebarOpen,
} = settingSlice.actions;
export default settingSlice.reducer;

import {
  durationUnits,
  DurationUnitType,
} from "features/FilterDrawer/components/DurationInput";
import { FilterParam, FilterParams } from "helpers/pageUrl";
import { ParsedUrlQuery } from "querystring";
import { Filter, Filters } from "store/slices/search";
import {
  getCountriesFromQueryParams,
  getQueryParamsFromCountries,
} from "./countries";
import {
  getDomainsFromQueryParams,
  getQueryParamsFromDomains,
} from "./domains";
import {
  getQueryParamsFromStudyTypes,
  getStudyTypesFromQueryParams,
} from "./studyTypes";

const QUERY_PARAM_TO_FILTER: Record<FilterParam, Filter> = {
  study_types: "studyTypes",
  domain: "domains",
  country: "countries",
  controlled: "filterControlledStudies",
  human: "filterHumanStudies",
  open_access: "openAccess",
  oa: "openAccess",
  chat_pdf: "hasValidChatPdf",
  exclude_preprints: "excludePreprints",
  cite_min: "citationsMin",
  sample_size_max: "sampleSizeMax",
  sample_size_min: "sampleSizeMin",
  sjr_max: "sjrBestQuartileMax",
  sjr_min: "sjrBestQuartileMin",
  year_max: "yearMax",
  year_min: "yearMin",
  duration_max: "durationMax",
  duration_min: "durationMin",
  duration_unit: "durationUnit",
};

// Converts a URL query object (e.g., router.query) into a structured Filters object.
// This parses and normalizes query parameters, transforming string-based filter values
// into their corresponding data types.
// Example: { study_types: "rct,meta" } → { studyTypes: [StudyType.RCT, StudyType.META_ANALYSIS] }.
export function getFiltersFromQuery(query: ParsedUrlQuery): Filters {
  const getQueryParamString = (key: FilterParam): string | undefined => {
    const value = query[key];
    return Array.isArray(value) ? value[0] : value;
  };

  const parseNumber = (value?: string) =>
    value && !isNaN(Number(value)) ? Number(value) : undefined;

  const parseBoolean = (value?: string) =>
    value === "true" ? true : value === "false" ? false : undefined;

  const parseDurationUnit = (value?: string): DurationUnitType | undefined =>
    durationUnits.some((unit) => unit.value === value)
      ? (value as DurationUnitType)
      : undefined;

  // Map the query parameters to a structured Filters object
  const filters: Partial<Filters> = {};

  Object.entries(QUERY_PARAM_TO_FILTER).forEach(([queryKey, filterKey]) => {
    const value = getQueryParamString(queryKey as FilterParam);

    if (value !== undefined) {
      switch (filterKey) {
        case "domains":
          filters.domains = getDomainsFromQueryParams(value);
          break;
        case "studyTypes":
          filters.studyTypes = getStudyTypesFromQueryParams(value);
          break;
        case "countries":
          filters.countries = getCountriesFromQueryParams(value);
          break;
        case "filterControlledStudies":
        case "filterHumanStudies":
        case "openAccess":
        case "hasValidChatPdf":
        case "excludePreprints":
          filters[filterKey] = parseBoolean(value);
          break;
        case "citationsMin":
        case "sampleSizeMax":
        case "sampleSizeMin":
        case "sjrBestQuartileMax":
        case "sjrBestQuartileMin":
        case "yearMax":
        case "yearMin":
        case "durationMin":
        case "durationMax":
          filters[filterKey] = parseNumber(value);
          break;
        case "durationUnit":
          filters.durationUnit = parseDurationUnit(value);
          break;
        default:
          break;
      }
    }
  });

  // Special case: Annoyingly the open access filter is "oa" in the browser URL, but "open_access" in the API. Account for that here.
  if (query["oa"] === "true") filters.openAccess = true;

  return filters as Filters;
}

/**
 * Converts a Filters object into a URL-compatible query object.
 * Example:
 * { studyTypes: [StudyType.RCT, StudyType.META_ANALYSIS] }
 * → { study_types: "rct,meta" }
 */
export function getQueryFromFilters(filters: Filters): FilterParams {
  const stringifyNumber = (value?: number) =>
    value !== undefined ? String(value) : undefined;

  const stringifyBoolean = (value?: boolean) =>
    value !== undefined ? String(value) : undefined;

  const stringifyDurationUnit = (value?: DurationUnitType) => {
    const validUnits = durationUnits.map((unit) => unit.value);
    return validUnits.includes(value ?? "") ? value : undefined;
  };

  const filterQueryParams: FilterParams = {
    domain: getQueryParamsFromDomains(filters.domains),
    study_types: getQueryParamsFromStudyTypes(filters.studyTypes),
    country: getQueryParamsFromCountries(filters.countries),
    controlled: stringifyBoolean(filters.filterControlledStudies),
    human: stringifyBoolean(filters.filterHumanStudies),
    open_access: stringifyBoolean(filters.openAccess),
    chat_pdf: stringifyBoolean(filters.hasValidChatPdf),
    exclude_preprints: stringifyBoolean(filters.excludePreprints),
    cite_min: stringifyNumber(filters.citationsMin),
    sample_size_max: stringifyNumber(filters.sampleSizeMax),
    sample_size_min: stringifyNumber(filters.sampleSizeMin),
    sjr_max: stringifyNumber(filters.sjrBestQuartileMax),
    sjr_min: stringifyNumber(filters.sjrBestQuartileMin),
    year_max: stringifyNumber(filters.yearMax),
    year_min: stringifyNumber(filters.yearMin),
    duration_max: stringifyNumber(filters.durationMax),
    duration_min: stringifyNumber(filters.durationMin),
    duration_unit: stringifyDurationUnit(filters.durationUnit),
  };

  return filterQueryParams;
}

/**
 * Merges the current query with the filters, preserving non-filter parameters.
 * This is useful for updating the URL with a new set of filters while keeping other query params.
 */
export function applyFiltersToQuery(
  currentQuery: ParsedUrlQuery,
  filters: Filters
): ParsedUrlQuery {
  // Preserve non-filter query params
  const nonFilterQueryParams: ParsedUrlQuery = Object.keys(currentQuery)
    .filter((key) => !(key in QUERY_PARAM_TO_FILTER))
    .reduce((acc, key) => {
      acc[key] = currentQuery[key];
      return acc;
    }, {} as ParsedUrlQuery);

  // Convert filters into query parameters and remove undefined values
  let filterQueryParams = Object.fromEntries(
    Object.entries(getQueryFromFilters(filters)).filter(
      ([_, value]) => value !== undefined
    )
  );

  // Special case: Annoyingly, the open access filter is "oa" in the browser URL, but "open_access" in the API. Account for that here.
  if (filterQueryParams.open_access === "true") {
    delete filterQueryParams.open_access;
    filterQueryParams.oa = "true";
  }

  // Merge preserved query params with updated filter params
  return { ...nonFilterQueryParams, ...filterQueryParams };
}

/**
 * The API only accepts duration in units of days. So before calling the API, you must convert the current unit back to days.
 */
export function convertDurationToDays(filters: Filters): Filters {
  const conversionRates: Record<DurationUnitType, number> = {
    day: 1,
    week: 7,
    month: 30,
    year: 365,
  };

  if (!filters.durationUnit) return filters; // No conversion needed if unit is missing

  const conversionFactor = conversionRates[filters.durationUnit];

  return {
    ...filters,
    durationMin: filters.durationMin
      ? filters.durationMin * conversionFactor
      : undefined,
    durationMax: filters.durationMax
      ? filters.durationMax * conversionFactor
      : undefined,
    durationUnit: "day", // Convert unit to days
  };
}

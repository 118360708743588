import Popover from "components/Popover";
import StudyTypeTooltip from "components/Popover/StudyTypeTooltip/StudyTypeTooltip";
import { IN_VITRO_STUDY_URL } from "constants/config";
import useLabels from "hooks/useLabels";
import { useAppSelector } from "hooks/useStore";
import React from "react";
import { Tag } from "./Tag";

interface InVitroStudyTagProps {
  pressable?: boolean;
}

function InVitroStudyTag(props?: InVitroStudyTagProps) {
  const [pageLabels] = useLabels("tooltips.in-vitro-trial");
  const isMobile = useAppSelector((state) => state.setting.isMobile);

  return (
    <Popover
      interactive
      maxWidth={306}
      tooltipContent={
        <StudyTypeTooltip
          title={pageLabels.title}
          content={pageLabels.content}
          icon="/icons/in-vitro-trial.svg"
          iconPyramid="bottom"
          learnMoreLink={IN_VITRO_STUDY_URL}
        />
      }
    >
      <Tag
        variant="white"
        data-testid="in-vitro-trial-tag"
        className="border-red-base"
        onClick={(e) => {
          if (!props?.pressable) {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
      >
        <img
          alt="Info"
          src="/icons/in-vitro-trial.svg"
          width="20px"
          height="20px"
        />
        <span>{pageLabels["tag"]}</span>
      </Tag>
    </Popover>
  );
}

export default InVitroStudyTag;

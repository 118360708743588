import { MetaPixelTrackEventData } from "../metaPixel/events";
import {
  MixpanelEventVersion,
  MixpanelTrackEventData,
  SearchEvent as MixpanelSearchEvent,
} from "../mixpanel/events";

export enum SharedServerEvent {
  PageView = "Page View",
  Search = "Search",
  Subscribe = "Subscribe",
}

export interface PageViewEventData {
  event: SharedServerEvent.PageView;
  pageType: string;
  pageTitle: string;
  version: MixpanelEventVersion;
}

// Equivalent to the mixpanel SearchEvent, but with a shared "event" value.
export interface SearchEventData extends Omit<MixpanelSearchEvent, "event"> {
  event: SharedServerEvent.Search;
}

export interface SubscribeEventData {
  event: SharedServerEvent.Subscribe;
  currency: string;
  value?: number;
  billingType: string;
  plan: string;
  status?: string;
}

export type SharedTrackEventData =
  | PageViewEventData
  | SearchEventData
  | SubscribeEventData;

export type TrackEventData =
  | MixpanelTrackEventData
  | MetaPixelTrackEventData
  | SharedTrackEventData;

import Popover from "components/Popover";
import StudyTypeTooltip from "components/Popover/StudyTypeTooltip/StudyTypeTooltip";
import { CASE_REPORT_URL } from "constants/config";
import useLabels from "hooks/useLabels";
import React from "react";
import { Tag } from "./Tag";

interface CaseStudyTagProps {
  pressable?: boolean;
}

function CaseStudyTag(props?: CaseStudyTagProps) {
  const [pageLabels] = useLabels("tooltips.case-study");

  return (
    <Popover
      interactive
      maxWidth={306}
      tooltipContent={
        <StudyTypeTooltip
          title={pageLabels.title}
          content={pageLabels.content}
          icon="/icons/case-report.svg"
          iconPyramid="bottom"
          learnMoreLink={CASE_REPORT_URL}
        />
      }
    >
      <Tag
        variant="white"
        data-testid="highly-cited-tag"
        className="border-red-base"
        onClick={(e) => {
          if (!props?.pressable) {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
      >
        <img
          alt="Info"
          src="/icons/case-report.svg"
          width="20px"
          height="20px"
        />
        <span>{pageLabels["tag"]}</span>
      </Tag>
    </Popover>
  );
}

export default CaseStudyTag;

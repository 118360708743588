import {
  applyFiltersToQuery,
  getFiltersFromQuery,
} from "features/FilterDrawer/utils/queryParams/filterQueryParams";
import { SearchHistory } from "helpers/api";
import { SearchFilterProperties } from "helpers/services/mixpanel/events";
import { ParsedUrlQuery } from "querystring";
import { parseUrlToQuery, ResultPageParams, resultsPagePath } from "./pageUrl";
import { getFiltersFromParams } from "./search";

export type SearchHistoryGroups = {
  today: SearchHistory[];
  lastSevenDays: SearchHistory[];
  lastThirtyDays: SearchHistory[];
  lastYear: SearchHistory[];
  count: number;
};

export function groupSearchHistoriesByDate(
  searchHistories: SearchHistory[]
): SearchHistoryGroups {
  const groups: SearchHistoryGroups = {
    today: [],
    lastSevenDays: [],
    lastThirtyDays: [],
    lastYear: [],
    count: 0,
  };

  const now = new Date();

  searchHistories.forEach((searchHistory) => {
    const searchDate = new Date(searchHistory.last_searched_at);
    const diffTime = now.getTime() - searchDate.getTime();
    if (diffTime < 0) return;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays === 1) {
      groups.today.push(searchHistory);
    } else if (diffDays <= 7) {
      groups.lastSevenDays.push(searchHistory);
    } else if (diffDays <= 30) {
      groups.lastThirtyDays.push(searchHistory);
    } else {
      groups.lastYear.push(searchHistory);
    }
  });

  groups.count = searchHistories.length;

  return groups;
}

// Given the URL to the backend search API, parse out the query and filter parameters in order to construct the equivalent frontend search URL.
//
// Example full_url: http://python-backend-url:8080/paper_search/?query=write%205%20words%20about%20vaccines&page=0&size=10&open_access=true&cite_min=10
export function getSearchPathFromSearchHistory(
  searchHistory: SearchHistory,
  extraParams: ResultPageParams = {}
): string {
  // Maintain current parameters not set by the search history, such as pro
  // or testing params
  let searchHistoryParams = parseUrlToQuery(searchHistory.full_url);

  if (searchHistoryParams.open_access === "true") {
    // Special case: Annoyingly, the open access filter is "oa" in the browser URL, but "open_access" in the API, which searchHistory stores. Account for that here.
    searchHistoryParams.oa = "true";
    delete searchHistoryParams.open_access;
  }

  let combinedParams = applyFiltersToQuery(
    extraParams as ParsedUrlQuery,
    getFiltersFromQuery(searchHistoryParams)
  );

  return resultsPagePath(searchHistory.query, combinedParams);
}

export function getSearchHistoryFilters(
  searchHistory: SearchHistory
): SearchFilterProperties {
  // Parsing the URL to get the query parameters and handle for situations where the URL is relative
  const urlObj = new URL(
    searchHistory.full_url.startsWith("http")
      ? searchHistory.full_url
      : `${window.location.origin}${searchHistory.full_url}`
  );
  const queryParams = urlObj.searchParams;

  // Map the query parameters to an object
  const queryObj: { [key: string]: any } = {};

  queryParams.forEach((value, key) => {
    if (value === "true" || value === "false") {
      queryObj[key] = value === "true";
    } else if (!isNaN(Number(value))) {
      queryObj[key] = Number(value);
    } else {
      queryObj[key] = value;
    }
  });

  return getFiltersFromParams(queryObj);
}

export function getFilterCountForSearchHistory(
  searchHistory: SearchHistory
): number {
  const filters = getSearchHistoryFilters(searchHistory) as {
    [key: string]: any;
  };

  return Object.keys(filters).filter((key) => filters[key] !== undefined)
    .length;
}

import { LoginButton, SignUpButton } from "components/Button";
import useLabels from "hooks/useLabels";
import { useSidebar } from "hooks/useSidebar";

function SignedOutContent() {
  const { toggleSidebar } = useSidebar();

  const [sidebarLabels] = useLabels("screens.sidebar");

  const handleOnNavigate = () => {
    toggleSidebar();
  };

  return (
    <div className="flex flex-col w-full h-full px-4 pb-32">
      <div className="flex flex-col items-center justify-center flex-1 w-full">
        <div className="max-w-[260px]">
          <span className="h2-md">{sidebarLabels.title}</span>
          <ul className="flex flex-col gap-3 my-8 overflow-hidden whitespace-nowrap">
            {sidebarLabels.bullets.map((bullet: string) => {
              return (
                <li
                  key={bullet}
                  className="flex items-center justify-start gap-2"
                >
                  <span>
                    <i className="text-xl icon-check text-green-emphasis" />
                  </span>
                  <span>{bullet}</span>
                </li>
              );
            })}
          </ul>
        </div>
        <div
          onClick={handleOnNavigate}
          className="flex justify-center w-full gap-2"
        >
          <SignUpButton className="flex-1" />
          <LoginButton />
        </div>
      </div>
    </div>
  );
}

export default SignedOutContent;

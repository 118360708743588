import { useAuth } from "@clerk/nextjs";
import { InfiniteData, useQueryClient } from "@tanstack/react-query";
import { SEARCH_ITEM } from "constants/config";
import path from "constants/path";
import { ToggleState } from "enums/toggles";
import useFilters from "features/FilterDrawer/hooks/useFilters";
import { getQueryFromFilters } from "features/FilterDrawer/utils/queryParams/filterQueryParams";
import { PaperSearchResponse } from "helpers/api";
import {
  isResultsPageUrl,
  isSearchPageUrl,
  resultsPagePath,
} from "helpers/pageUrl";
import { getQueryFilterKeys, queryToResultPageParams } from "helpers/search";
import { MixpanelNavigationSource } from "helpers/services/mixpanel/events";
import useAnalytics from "hooks/useAnalytics";
import { useAppDispatch, useAppSelector } from "hooks/useStore";
import { useRouter } from "next/router";
import { useCallback } from "react";
import { setIsRefresh, setIsSearching } from "store/slices/search";
import useProToggle, { PRO_ANALYSIS_QUERY_PARAM } from "./useProToggle";

export function useSearch() {
  const router = useRouter();
  const { isSignedIn, isLoaded } = useAuth();
  const isSearching = useAppSelector((state) => state.search.isSearching);
  const { lang } = useAppSelector((state) => state.user.settings);
  const dispatch = useAppDispatch();
  const { searchEvent, setAnalyticNavigationSource } = useAnalytics();

  const queryClient = useQueryClient();
  const queryResultKey = ["results", ...getQueryFilterKeys(router.query)];
  const firstPageSearchId =
    queryClient.getQueryData<InfiniteData<PaperSearchResponse>>(queryResultKey)
      ?.pages[0]?.search_id;

  const { isProTogglePreferred } = useProToggle();

  const { appliedFilters } = useFilters();
  const filterParams = getQueryFromFilters(appliedFilters);

  const handleSearch = useCallback(
    (
      query: string,
      {
        navigationSource = MixpanelNavigationSource.Website,
        forceProAnalysisOn = false,
      } = {}
    ) => {
      if (!isLoaded) {
        router?.push(path.INTERNAL_SERVER_ERROR);
        return;
      }

      const proAnalysisToggleState =
        forceProAnalysisOn || isProTogglePreferred ? ToggleState.ON : undefined;

      if (!isSignedIn) {
        localStorage.setItem(SEARCH_ITEM, query);
        router.push(
          `${path.SIGN_IN}#/?redirect_url=${encodeURIComponent(
            `${window.location.origin}${resultsPagePath(query, {
              [PRO_ANALYSIS_QUERY_PARAM]: proAnalysisToggleState,
              ...filterParams,
            })}`
          )}`
        );
        return;
      }

      if (query && !isSearching && router) {
        dispatch(setIsSearching(true));

        // All searches are dispatched from the "Website" source by definition unless otherwise specified.
        setAnalyticNavigationSource(navigationSource);
        searchEvent(query, proAnalysisToggleState);

        router.push(
          resultsPagePath(query, {
            [PRO_ANALYSIS_QUERY_PARAM]: proAnalysisToggleState,
            lang: lang, // this is important to make sure the user preferred language is added in search url
            ...filterParams,
          }),
          undefined,
          {
            shallow: true,
            scroll: true,
          }
        );

        if (
          query == router.query.q ||
          (isResultsPageUrl(router.route) == false &&
            isSearchPageUrl(router.route) == false)
        ) {
          dispatch(setIsRefresh(true));
        }
      }
    },
    [
      isLoaded,
      router,
      isSignedIn,
      isSearching,
      dispatch,
      searchEvent,
      lang,
      isProTogglePreferred,
      filterParams,
    ]
  );

  const buildSearchQueryLink = useCallback(
    (value: string, { forceProAnalysisOn = false } = {}) => {
      const proAnalysisToggleState =
        forceProAnalysisOn || isProTogglePreferred ? ToggleState.ON : undefined;

      if (!isSignedIn) {
        return `${path.SIGN_IN}#/?redirect_url=${encodeURIComponent(
          `${window.location.origin}${resultsPagePath(value, {
            [PRO_ANALYSIS_QUERY_PARAM]: proAnalysisToggleState,
            ...filterParams,
          })}`
        )}`;
      }

      if (value) {
        return resultsPagePath(value, {
          ...queryToResultPageParams(router.query),
          [PRO_ANALYSIS_QUERY_PARAM]: proAnalysisToggleState,
          lang: lang,
          ...filterParams,
        });
      }
    },
    [isProTogglePreferred, isSignedIn, lang, router, filterParams]
  );

  const dispatchSearchEvent = useCallback(
    (
      value: string,
      {
        navigationSource = MixpanelNavigationSource.Website,
        forceProAnalysisOn = false,
      }
    ) => {
      const proAnalysisToggleState =
        forceProAnalysisOn || isProTogglePreferred ? ToggleState.ON : undefined;

      // All searches are dispatched from the "Website" source by definition unless otherwise specified.
      setAnalyticNavigationSource(navigationSource);
      searchEvent(value, proAnalysisToggleState);
    },
    [isProTogglePreferred, searchEvent, setAnalyticNavigationSource]
  );

  return {
    handleSearch,
    buildSearchQueryLink,
    dispatchSearchEvent,
    authState: { isSignedIn, isLoaded },
    firstPageSearchId,
    queryResultKey,
  };
}

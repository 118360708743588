import { useAuth } from "@clerk/nextjs";
import CardTooltip from "components/Popover/CardTooltip";
import { PRO_ANALYSIS_CREDITS_PER_MONTH } from "constants/config";
import pluralize from "helpers/pluralize";
import useCredits from "hooks/useCredits";
import useLabels from "hooks/useLabels";

export const PRO_TOGGLE_TOOLTIP_ID = "pro-toggle-tooltip";

/**
 * @component ProToggleTooltip
 * @description Tooltip for Pro Toggle for unlocked state
 */

interface ProToggleTooltipProps {
  isNotEnoughResults: boolean;
  isNuanceRequired: boolean;
  onClickUpgradeToPremium?: () => void;
}

function ProToggleTooltip({
  isNotEnoughResults,
  isNuanceRequired,
  onClickUpgradeToPremium,
}: ProToggleTooltipProps) {
  const [proToggleLabels, creditsLabels] = useLabels(
    "tooltips.pro-toggle",
    "tooltips.credits"
  );

  const { isSignedIn, isLoaded } = useAuth();
  const notSignedIn = isLoaded && isSignedIn === false;

  const { isPremium, proAnalysisCreditsRemaining, creditRefreshDate } =
    useCredits();

  const handleClickUpgradePremium = async () => {
    if (onClickUpgradeToPremium) {
      onClickUpgradeToPremium();
    }
  };

  const showCreditsRemaining = !isPremium && !notSignedIn;

  return (
    <CardTooltip id={PRO_TOGGLE_TOOLTIP_ID}>
      <CardTooltip.Header
        title={proToggleLabels["pro-analysis"]}
        hint={
          showCreditsRemaining &&
          creditsLabels["credits-left"].replace(
            "{CREDITS_REMAINING}",
            proAnalysisCreditsRemaining
          )
        }
        isBeta
      />
      <CardTooltip.Description content={proToggleLabels["description"]} />
      <CardTooltip.InfoSection
        infoText={
          isNuanceRequired
            ? proToggleLabels["nuance-required"]
            : isNotEnoughResults
            ? proToggleLabels["not-enough-results"]
            : null
        }
      />
      {showCreditsRemaining && (
        <CardTooltip.Hint
          content={pluralize(creditsLabels["pro-analyses-left"], {
            CREDITS_REMAINING: proAnalysisCreditsRemaining,
            MAX_CREDIT: PRO_ANALYSIS_CREDITS_PER_MONTH,
            RENEW_DATE: creditRefreshDate,
          })}
        />
      )}
      {showCreditsRemaining && (
        <CardTooltip.Button
          data-testid="pro-analysis-btn-upgrade-premium"
          icon="/icons/premium.svg"
          label={creditsLabels["upgrade-to-premium"]}
          onClick={handleClickUpgradePremium}
        />
      )}
    </CardTooltip>
  );
}

export default ProToggleTooltip;

import { Combobox, Transition } from "@headlessui/react";
import classNames from "classnames";
import { DEFAULT_LANG } from "constants/config";
import {
  LangCodeType,
  LanguageCode,
  languages,
} from "helpers/sharedDataConstants";
import useLabels from "hooks/useLabels";
import { Fragment, useState } from "react";
const enLang = languages.find(
  (item) => item.code === DEFAULT_LANG
) as LanguageCode;

export enum modes {
  insideModalMode = "inside-modal",
  defaultMode = "default",
}
type PropsType = {
  onChange: (item: LanguageCode) => void;
  initialValue?: LangCodeType;
  mode?: "inside-modal" | "default";
};
export const LanguageSelectBox = ({
  onChange,
  initialValue,
  mode = modes.defaultMode,
}: PropsType) => {
  const [query, setQuery] = useState("");
  const initialValueObj = languages.find(
    (item) => item.code === initialValue
  ) as LanguageCode;
  const [selectedLang, setSelectedLang] = useState<LanguageCode>(
    initialValueObj || enLang
  );

  const [labels] = useLabels("general");

  const handleSelect = (item: LanguageCode) => {
    setSelectedLang(item);
    onChange && onChange(item);
  };

  let filtered =
    query === ""
      ? languages
      : languages.filter((lang) => {
          return (
            lang.name.toLowerCase().startsWith(query.toLowerCase()) ||
            lang.localName.toLowerCase().startsWith(query.toLowerCase())
          );
        });

  const selectedItemIndex = filtered.findIndex(
    (item) => item.code === selectedLang.code
  );
  if (filtered.length) {
    // make the selected item first to force the options to open with the selected item
    // at the top
    filtered.unshift(filtered.splice(selectedItemIndex, 1)[0]);
  }

  return (
    <Combobox value={selectedLang} onChange={handleSelect}>
      {({ open }) => (
        <div
          className={classNames("relative", {
            "md:static": mode === modes.insideModalMode,
          })}
        >
          <div className="relative">
            <Combobox.Input
              className={classNames(
                "border border-border-base pr-2 py-2 w-full pl-14 rounded-t-lg focus:outline-none",
                {
                  "rounded-b-lg": !open,
                }
              )}
              displayValue={(item: LanguageCode) => item.localName}
              onChange={(event) => setQuery(event.target.value)}
              placeholder="Search languages..."
            />
            <span className="absolute left-4 top-2 w-7 h-7 inline-flex justify-center items-center font-semibold text-[10px] rounded-full bg-bgr-subtle">
              {selectedLang?.code.toUpperCase() || enLang?.code.toUpperCase()}
            </span>
            <Combobox.Button className="absolute bg-transparent left-0 right-0 h-full">
              <div className="flex justify-end mx-2">
                {open ? (
                  <i className="icon-chevron-up text-xl" />
                ) : (
                  <i className="icon-chevron-down text-xl" />
                )}
              </div>
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery("")}
          >
            <Combobox.Options
              className={classNames(
                "absolute max-h-40 w-full overflow-auto rounded-b-md bg-white py-1 shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm z-50 scrollbar-hide",
                { "md:max-w-[468px]": mode === modes.insideModalMode }
              )}
            >
              {!filtered.length && (
                <div className="py-3 px-2 text-center tiny-normal text-fg-muted">
                  {labels["no-result-for"]}"{query}"
                </div>
              )}
              {filtered.map((lang) => (
                <Combobox.Option key={lang.code} value={lang}>
                  {({ active, selected }) => (
                    <div
                      className={classNames(
                        "px-2 mx-2 py-2 flex justify-between items-center rounded-lg hover:bg-bgr-faint",
                        { "bg-bgr-faint": active }
                      )}
                    >
                      <div>
                        <span className="w-7 h-7 inline-flex justify-center items-center text-[10px] rounded-full font-semibold bg-bgr-subtle mr-3">
                          {lang.code.toUpperCase()}
                        </span>
                        <span className="tiny-md">{lang.name}</span>
                        {lang.name !== lang.localName && (
                          <span className="tiny-normal text-fg-muted ml-2">
                            {lang.localName}
                          </span>
                        )}
                      </div>
                      {selected && (
                        <span>
                          <i className="icon-check text-xl text-fg-muted" />
                        </span>
                      )}
                    </div>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          </Transition>
        </div>
      )}
    </Combobox>
  );
};

"use client";

import { usePathname, useSearchParams } from "next/navigation";

// Set or remove an existing query param in the URL path without navigating.
const useSetQueryParam = () => {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const setQueryParam = (key: string, value?: string | null) => {
    const newSearchParams = new URLSearchParams(searchParams?.toString() || "");

    if (value === undefined || value === null) {
      // If value is undefined or null, remove the key from the search params
      newSearchParams.delete(key);
    } else {
      // Otherwise, set the key to the provided value
      newSearchParams.set(key, value);
    }

    // Create the new URL with updated search params
    const params = newSearchParams.toString();
    const newUrl = params.length > 0 ? `${pathname}?${params}` : pathname;

    // Replace the current entry in the history stack
    window.history.replaceState(null, "", newUrl);
  };

  return setQueryParam;
};

export default useSetQueryParam;

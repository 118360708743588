/* eslint-disable react-hooks/exhaustive-deps */
import { useAuth, useUser } from "@clerk/nextjs";
import { UserResource } from "@clerk/types";
import { useMutation } from "@tanstack/react-query";
import { SEARCH_ITEM } from "constants/config";
import path from "constants/path";
import { SubscriptionPlan } from "enums/subscription-plans";
import { updateMixpanelUserProfileAPI } from "helpers/api";
import { generateHmac } from "helpers/generateHmac";
import {
  findSubscriptionSeats,
  findSubscriptionUnitAmount,
} from "helpers/products";
import * as clerk from "helpers/services/clerk";
import {
  boot as bootIntercom,
  isIntercomEnabled,
  shutdown as shutdownIntercom,
  update as updateIntercom,
} from "helpers/services/intercom";
import { isSubscriptionPremium } from "helpers/subscription";
import useAnalytics from "hooks/useAnalytics";
import useLabels from "hooks/useLabels";
import { useAppSelector } from "hooks/useStore";
import { useRouter } from "next/router";
import { useEffect, useMemo } from "react";

const LOGIN_METHOD_ITEM = "login_method";
const SIGNUP_METHOD_ITEM = "signup_method";
const OAUTH_METHOD_ITEM = "oauth_method";

/**
 * @component HandleUserAuthEvent
 * @description component handle error from clerk.
 * @example
 * return (
 *   <HandleUserAuthEvent />
 * )
 */

const HandleUserAuthEvent = () => {
  const [errorLabel] = useLabels("error");
  const { setAnalyticUser, loginEvent, logoutEvent, signupEvent, searchEvent } =
    useAnalytics();
  const { route, query } = useRouter();
  const { user } = useUser();
  const { isSignedIn, isLoaded } = useAuth();
  const isUserLoaded = typeof user?.update === "function";
  const hasSubscription = useAppSelector((state) =>
    isSubscriptionPremium(state.subscription.subscription)
  );

  const subscription = useAppSelector(
    (state) => state.subscription.subscription
  );
  const subscriptionInterval = useAppSelector(
    (state) => state.subscription.subscription.user?.plan.interval
  );
  const products = useAppSelector((state) => state.subscription.products);

  const activeOrganization = user?.organizationMemberships?.[0]?.organization;
  const activeRole = user?.organizationMemberships?.[0]?.role;

  const updateMixpanelUserProfileMutation = useMutation(() =>
    updateMixpanelUserProfileAPI()
  );

  const billingPeriod = useMemo(() => {
    if (!hasSubscription) {
      return;
    }
    if (subscriptionInterval === "year") {
      return "Annually";
    }
    if (subscriptionInterval === "month") {
      return "Monthly";
    }
    return;
  }, [subscriptionInterval, hasSubscription]);

  // on every search or page change, update the user across all services
  useEffect(() => {
    if (user && isUserLoaded) {
      const isNewSearch = false;
      clerk.updateUser(user as unknown as UserResource, isNewSearch);
    }
  }, [isUserLoaded, route, query]);

  // on every page reload, try record auth events
  useEffect(() => {
    if (user && isUserLoaded && isSignedIn && isLoaded) {
      const userCreatedAt =
        typeof user.createdAt === "number"
          ? new Date(user.createdAt)
          : user.createdAt;

      if (subscription && isIntercomEnabled) {
        // the subscription info has been loaded successfully
        const plan =
          !subscription?.user && !subscription?.org && !subscription?.team
            ? SubscriptionPlan.FREE
            : !subscription?.user && subscription?.org
            ? SubscriptionPlan.ENTERPRISE
            : !subscription?.user && subscription.team
            ? SubscriptionPlan.TEAM
            : subscription?.user &&
              subscription?.user?.plan?.metadata?.type?.toLowerCase() ===
                SubscriptionPlan.TEAM.toLowerCase()
            ? SubscriptionPlan.TEAM
            : SubscriptionPlan.PREMIUM;

        const amount = findSubscriptionUnitAmount(products, subscription);
        const seats = findSubscriptionSeats(subscription);

        const hash = generateHmac(user.id);
        updateIntercom({
          name: user.fullName,
          email: user.primaryEmailAddress?.emailAddress,
          created_at: userCreatedAt,
          user_id: user.id,
          user_hash: hash,
          Plan: plan,
          "Billing Period": billingPeriod,
          Organization:
            plan == SubscriptionPlan.TEAM
              ? activeOrganization?.name
              : (subscription?.org as unknown as { name: string })?.name,
          Role: activeRole,
          Price: amount ? amount * seats : undefined,
        });
      }

      setAnalyticUser(user as unknown as UserResource);

      const loginMethod = localStorage.getItem(LOGIN_METHOD_ITEM);
      if (loginMethod) {
        loginEvent(loginMethod);
        updateMixpanelUserProfileMutation.mutate();
        localStorage.removeItem(LOGIN_METHOD_ITEM);
        localStorage.removeItem(OAUTH_METHOD_ITEM);
      }

      const signupMethod = localStorage.getItem(SIGNUP_METHOD_ITEM);
      if (signupMethod) {
        signupEvent(signupMethod);
        localStorage.removeItem(SIGNUP_METHOD_ITEM);
        localStorage.removeItem(OAUTH_METHOD_ITEM);
      }

      const searchItem = localStorage.getItem(SEARCH_ITEM);
      if (searchItem) {
        searchEvent(searchItem);
        localStorage.removeItem(SEARCH_ITEM);
      }
    }
  }, [
    isUserLoaded,
    user?.id,
    setAnalyticUser,
    searchEvent,
    signupEvent,
    loginEvent,
    isSignedIn,
    isLoaded,
    hasSubscription,
    billingPeriod,
  ]);

  // on every page change, check if we should delete data in local storage if
  // we are canceling the signin or signup process
  useEffect(() => {
    const hash = typeof window === "undefined" ? "" : window?.location?.hash;
    if (
      [path.SIGN_IN, path.SIGN_UP].includes(route) &&
      !hash.includes("verify")
    ) {
      localStorage.removeItem(SIGNUP_METHOD_ITEM);
      localStorage.removeItem(LOGIN_METHOD_ITEM);
    }

    if (route === "/" || route === "/search") {
      localStorage.removeItem(SEARCH_ITEM);
    }
  }, [route]);

  // register callbacks for handling all clerk events
  useEffect(() => {
    clerk.initEventHandler(async (action: any, response: any) => {
      const clerkEvent = clerk.identifyEvent(action, response);
      switch (clerkEvent) {
        case clerk.ClerkEvent.ERROR_COULDNT_FIND_ACCOUNT: {
          setTimeout(() => {
            // replace error message message with custom text
            const element = document.getElementById("error-identifier");
            if (
              element &&
              element.innerHTML === clerk.COULDNT_FIND_ACCOUNT_MESSAGE
            ) {
              element.innerHTML = errorLabel["couldnt-find-account"];
            }
          }, 100);
          break;
        }
        case clerk.ClerkEvent.LOG_OUT: {
          const user = response.payload?.response?.sessions?.[0]?.user;
          if (user) {
            logoutEvent();
            shutdownIntercom();
            bootIntercom();
          }
          break;
        }
        case clerk.ClerkEvent.LOG_IN: {
          const authMethod = clerk.identifyAuthMethod(action);
          if (authMethod) {
            localStorage.setItem(LOGIN_METHOD_ITEM, authMethod);
            if (clerk.isOauthMethod(authMethod)) {
              localStorage.setItem(OAUTH_METHOD_ITEM, authMethod);
            }
          }
          break;
        }
        case clerk.ClerkEvent.SIGN_UP: {
          const authMethod = clerk.identifyAuthMethod(action);
          if (authMethod) {
            localStorage.setItem(SIGNUP_METHOD_ITEM, authMethod);
            if (clerk.isOauthMethod(authMethod)) {
              localStorage.setItem(OAUTH_METHOD_ITEM, authMethod);
            }
          }
          if (clerk.isTransfer(action)) {
            const oauth = localStorage.getItem(OAUTH_METHOD_ITEM);
            if (oauth) {
              localStorage.setItem(SIGNUP_METHOD_ITEM, oauth);
            }
          }
          break;
        }
      }
    });
  }, []);

  return null;
};

export default HandleUserAuthEvent;

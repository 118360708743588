import { ButtonVariant, CoButton } from "components/CoButton";
import useLabels from "hooks/useLabels";
import React from "react";

interface DisputedPaperTooltipProps {
  reason: string;
  url: string;
}

/**
 * @component DisputedPaperTooltip
 * @description Tooltip for the disputed paper badge
 */
const DisputedPaperTooltip = ({ reason, url }: DisputedPaperTooltipProps) => {
  const [tooltipLabels] = useLabels("tooltips.disputed");

  const handleClick = (e: any) => {
    e.preventDefault();
    window.open(url);
  };

  return (
    <div className="flex flex-col gap-3 text-fg-base">
      <div className="flex items-center gap-3 sm-bold">
        <h2 className="flex items-center flex-1 gap-3">
          <i className="text-2xl icon-triangle-alert" />
          {tooltipLabels["title"]}
        </h2>
      </div>
      <p className="sm-normal">{reason}</p>
      <p className="tiny-normal text-fg-muted">
        {tooltipLabels["description"]}
      </p>
      <CoButton
        onClick={handleClick}
        href={url}
        size="sm"
        variant={ButtonVariant.Tertiary}
        iconRight="icon-external-link"
        label={tooltipLabels["button"]}
      />
    </div>
  );
};

export default DisputedPaperTooltip;

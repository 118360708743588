import { ParsedUrlQuery } from "querystring";
import { AnalyticSearchFilters } from "store/slices/analytic";
import { FilterParams, ResultPageParams } from "./pageUrl";

export enum SearchResultTextType {
  KEY_TAKEAWAY = "key takeaway",
  ANSWER = "answer",
}

/**
 * Parses search testing arguments from a URL query and returns a url parameter
 * formatted string to pass to the backend.
 */
export function getSearchTestingQueryParams(query?: {
  [key: string]: any;
}): string | undefined {
  if (!query) {
    return "";
  }
  let params = "";
  if (query.relevancy_threshold) {
    const relevancy_threshold = query.relevancy_threshold as unknown as number;
    params = `${params}&relevancy_threshold=${relevancy_threshold}`;
  }
  if (query.method) {
    const method = query.method as string;
    params = `${params}&method=${method}`;
  }
  if (query.stopwords) {
    const stopwords = query.stopwords as string;
    params = `${params}&stopwords=${stopwords}`;
  }
  if (query.punctuation) {
    const punctuation = query.punctuation as string;
    params = `${params}&punctuation=${punctuation}`;
  }
  if (query.boost1) {
    const boost1 = query.boost1 as unknown as number;
    params = `${params}&boost1=${boost1}`;
  }
  if (query.boost2) {
    const boost2 = query.boost2 as unknown as number;
    params = `${params}&boost2=${boost2}`;
  }
  if (query.rescore_count) {
    const rescore_count = query.rescore_count as unknown as number;
    params = `${params}&rescore_count=${rescore_count}`;
  }
  if (query.sort) {
    const sort = query.sort as string;
    params = `${params}&sort=${sort}`;
  }
  if (query.p2_sort) {
    const p2_sort = query.p2_sort as string;
    params = `${params}&p2_sort=${p2_sort}`;
  }
  if (query.phase2_size) {
    const phase2_size = query.phase2_size as string;
    params = `${params}&phase2_size=${phase2_size}`;
  }
  if (query.cache_off) {
    params = `${params}&cache_off=true`;
  }
  if (query.ltr_model_id) {
    const ltr_model_id = query.ltr_model_id as string;
    params = `${params}&ltr_model_id=${ltr_model_id}`;
  }
  if (query.ltr_window_size) {
    const ltr_window_size = query.ltr_window_size as unknown as number;
    params = `${params}&ltr_window_size=${ltr_window_size}`;
  }
  if (query.synthesize_statements) {
    params = `${params}&synthesize_statements=true`;
  }
  if (query.debug) {
    params = `${params}&debug=true`;
  }
  return params;
}

export function getFilterParams(query?: {
  [key: string]: any;
}): string | undefined {
  if (!query) {
    return "";
  }
  let params = "";
  // String filters
  if (query.study_types) {
    const study_types = query.study_types as string;
    params = `${params}&study_types=${study_types}`;
  }
  if (query.domain) {
    // Fields of study
    const domain = query.domain as string;
    params = `${params}&domain=${domain}`;
  }
  if (query.country) {
    const country = query.country as string;
    params = `${params}&country=${country}`;
  }
  // Boolean filters
  if (query.oa || query.open_access) {
    params = `${params}&open_access=true`;
  }
  if (query.exclude_preprints) {
    params = `${params}&exclude_preprints=true`;
  }
  if (query.chat_pdf) {
    params = `${params}&chat_pdf=true`;
  }
  if (query.controlled) {
    params = `${params}&controlled=true`;
  }
  if (query.human) {
    params = `${params}&human=true`;
  }
  // Range filters
  if (query.cite_min) {
    const cite_min = query.cite_min as string;
    params = `${params}&cite_min=${cite_min}`;
  }
  if (query.year_min) {
    const year_min = query.year_min as string;
    params = `${params}&year_min=${year_min}`;
  }
  if (query.year_max) {
    const year_max = query.year_max as string;
    params = `${params}&year_max=${year_max}`;
  }
  if (query.sample_size_min) {
    const sample_size_min = query.sample_size_min as string;
    params = `${params}&sample_size_min=${sample_size_min}`;
  }
  if (query.sample_size_max) {
    const sample_size_max = query.sample_size_max as string;
    params = `${params}&sample_size_max=${sample_size_max}`;
  }
  if (query.sjr_min) {
    const sjr_min = query.sjr_min as string;
    params = `${params}&sjr_min=${sjr_min}`;
  }
  if (query.sjr_max) {
    const sjr_max = query.sjr_max as string;
    params = `${params}&sjr_max=${sjr_max}`;
  }
  if (query.duration_min) {
    const duration_min = query.duration_min as string;
    params = `${params}&duration_min=${duration_min}`;
  }
  if (query.duration_max) {
    const duration_max = query.duration_max as string;
    params = `${params}&duration_max=${duration_max}`;
  }
  if (query.duration_unit) {
    const duration_unit = query.duration_unit as string;
    params = `${params}&duration_unit=${duration_unit}`;
  }
  if (query.lang) {
    params = `${params}&lang=${query.lang}`;
  }
  return params;
}

export function getFiltersFromParams(query: {
  [key: string]: any;
}): AnalyticSearchFilters {
  return {
    citationsMin: query.cite_min as string,
    countriesOfStudy: query.country as string,
    fieldsOfStudy: query.domain as string,
    filterControlledStudies: query.controlled as string,
    filterHumanStudies: query.human as string,
    openAccess: (query.oa || query.open_access) as string,
    hasValidChatPdf: query.chat_pdf as string,
    sampleSizeMin: query.sample_size_min as string,
    sjrBestQuartileMax: query.sjr_max as string,
    sjrBestQuartileMin: query.sjr_min as string,
    studyDurationDaysMin: query.study_duration_days_min as string,
    studyDurationDaysMax: query.study_duration_days_max as string,
    studyTypes: query.study_types as string,
    yearMin: query.year_min as string,
    durationMax: query.duration_max as string,
    durationMin: query.duration_min as string,
    country: query.country as string,
  };
}

const getDurationInDays = (duration: string, durationUnit: string) => {
  const durationInt = parseInt(duration);
  switch (durationUnit) {
    case "day":
      return duration;
    case "week":
      return 7 * durationInt;
    case "month":
      return 30 * durationInt;
    case "year":
      return 365 * durationInt;
  }
};

export const rewriteSearchQueryParamsForServerEndpoint = (
  searchQueryParams: string
) => {
  let strParams = searchQueryParams; // e.g &duration_min=10&duration_unit=week&lang=en

  if (strParams.startsWith("&")) {
    strParams = strParams.substring(1); // duration_min=10&duration_unit=week&lang=en
  }

  const query = JSON.parse(
    '{"' + strParams.replace(/&/g, '","').replace(/=/g, '":"') + '"}'
  );
  if (query["duration_max"]) {
    const max = query["duration_max"];
    const unit = query["duration_unit"] || "day";
    const durationMax = getDurationInDays(max, unit);
    query["duration_max"] = durationMax;
  }
  if (query["duration_min"]) {
    const min = query["duration_min"];
    const unit = query["duration_unit"] || "day";
    const durationMin = getDurationInDays(min, unit);
    query["duration_min"] = durationMin;
  }
  delete query["duration_unit"];
  const queryStr = new URLSearchParams(query).toString();
  return "&" + queryStr;
};

export const getQueryFilterKeys = (query: ParsedUrlQuery) => {
  return [
    query.q,
    query.cite_min,
    query.controlled,
    query.domain,
    query.human,
    query.oa,
    query.exclude_preprints,
    query.chat_pdf,
    query.sample_size_min,
    query.sjr_max,
    query.sjr_min,
    query.study_types,
    query.year_min,
    query.duration_min,
    query.duration_max,
    query.country,
    query.duration_unit,
  ];
};

function queryToResultPageFiltersParams(query: {
  [key: string]: string | string[] | undefined;
}): FilterParams {
  return {
    study_types: query.study_types as string,
    domain: query.domain as string,
    country: query.country as string,
    controlled: query.controlled as string,
    human: query.human as string,
    open_access: (query.oa || query.open_access) as string,
    chat_pdf: query.chat_pdf as string,
    cite_min: query.cite_min as string,
    sample_size_max: query.sample_size_max as string,
    sample_size_min: query.sample_size_min as string,
    sjr_max: query.sjr_max as string,
    sjr_min: query.sjr_min as string,
    year_max: query.year_max as string,
    year_min: query.year_min as string,
    duration_max: query.duration_max as string,
    duration_min: query.duration_min as string,
    exclude_preprints: query.exclude_preprints as string,
  };
}

export function queryToResultPageParams(query: {
  [key: string]: any;
}): ResultPageParams {
  return {
    ...queryToResultPageFiltersParams(query),
    pro: query.pro as string,
    lang: query.lang as string,
    cache_off: query.cache_off as string,
    duration_unit: query.duration_unit as string,
  };
}

export function getSearchEndpointParams(query?: {
  [key: string]: any;
}): string | undefined {
  const filterParams = getFilterParams(query);
  const searchTestingParams = getSearchTestingQueryParams(query);
  return `${filterParams}${searchTestingParams}`;
}

import { IconProps } from ".";

/**
 * @component ArrowLeftIcon
 * @description Component for showing the share SVG icon. Used instead of an <img> tag so that
 * we are able to change the color with css, e.g. on active. Use in conjunction with the <Icon>
 * component.
 * @example
 * return (
 *   <Icon
 *      className="fill-current"
 *      name="arrow-left"
 *      local=
 *    />
 * )
 */
function ArrowLeftIcon({ className }: IconProps) {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      className={`${className} ${className.includes("h-") ? "" : "w-5 h-5"}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8333 10.5H4.16666"
        stroke="#085394"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99999 16.3307L4.16666 10.4974L9.99999 4.66406"
        stroke="#085394"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ArrowLeftIcon;

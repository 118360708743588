import {
  STRIPE_PRICE_METADATA_TYPE_PREMIUM,
  STRIPE_PRICE_METADATA_TYPE_TEAM,
  STUDENT_DISCOUNT_PERCENT,
} from "constants/config";
import { SubscriptionPlan } from "enums/subscription-plans";
import { IStripeInvoiceOrUpcomingInvoice } from "./stripe";
import { ISubscriptionData } from "./subscription";

export interface SubscriptionProduct {
  name: string | null;
  price: any | null;
}

export interface MonthAnnualPrice {
  baseMonthlyPrice: any;
  baseYearlyPrice: any;
}

export function sortProducts(products: any): any {
  products.map((product: any) => {
    product.product_data.prices.sort((a: any, b: any) => {
      return a.unit_amount - b.unit_amount;
    });
  });

  products.sort((a: any, b: any) => {
    let aHighPrice = 0;
    let bHighPrice = 0;
    for (let i = 0; i < a.product_data.prices.length; i++) {
      if (a.product_data.prices[i].unit_amount > aHighPrice) {
        aHighPrice = a.product_data.prices[i].unit_amount;
      }
    }
    for (let i = 0; i < b.product_data.prices.length; i++) {
      if (b.product_data.prices[i].unit_amount > bHighPrice) {
        bHighPrice = b.product_data.prices[i].unit_amount;
      }
    }
    return aHighPrice - bHighPrice;
  });

  return products;
}

export function getUpgradePrice(products: any, upgradePlan: string): any {
  let upgradePrice = null;
  products.map((product: any) => {
    if (
      product.product_data.metadata?.tier == upgradePlan.toLocaleLowerCase() &&
      product.product_data.prices.length > 0
    ) {
      for (let i = 0; i < product.product_data.prices.length; i++) {
        if (
          product.product_data.prices[i].unit_amount > 0 &&
          product.product_data.prices[i].recurring.interval == "year" &&
          product.product_data.prices[i].metadata.type ==
            STRIPE_PRICE_METADATA_TYPE_PREMIUM
        ) {
          upgradePrice = product.product_data.prices[i];
          break;
        }

        if (
          product.product_data.prices[i].tiers_mode == "volume" &&
          product.product_data.prices[i].tiers.length &&
          product.product_data.prices[i].recurring.interval == "year" &&
          product.product_data.prices[i].metadata.type ==
            STRIPE_PRICE_METADATA_TYPE_TEAM
        ) {
          upgradePrice = product.product_data.prices[i];
          break;
        }
      }
    }
  });
  return upgradePrice;
}

export function findSubscriptionProduct(
  products: any,
  subscription: ISubscriptionData | null | undefined
): SubscriptionProduct {
  if (products && subscription?.user) {
    for (const product of products) {
      for (const price of product.product_data.prices) {
        if (subscription.user.plan.id == price.id) {
          return {
            name: product.product_data.name,
            price: price,
          };
        }
      }
    }
  }

  return { name: null, price: null };
}

export function findSubscriptionUnitAmount(
  products: any,
  subscription: ISubscriptionData,
  upcomingInvoice?: IStripeInvoiceOrUpcomingInvoice | null
): number {
  const type = subscription.user?.plan?.metadata?.type;
  let amount = 0;

  if (type?.toLowerCase() === SubscriptionPlan.TEAM.toLowerCase()) {
    const priceId = subscription.user?.plan.id;
    const seats = subscription.user?.quantity as number;
    for (const product of products) {
      if (
        product.product_data.metadata?.tier ===
        SubscriptionPlan.TEAM.toLowerCase()
      ) {
        for (const price of product.product_data.prices) {
          if (price.id === priceId) {
            amount = getAmountOfVolumeBasedPrice(seats, price);
            break;
          }
        }
      }
    }
  } else if (type?.toLowerCase() === SubscriptionPlan.PREMIUM.toLowerCase()) {
    amount = upcomingInvoice
      ? upcomingInvoice.amount_due
      : (subscription.user?.plan.amount_decimal as unknown as number);
  } else if (subscription) {
    // legacy subscription product misses plan type metadata
    // but if subscription exist then it must be premium
    amount = upcomingInvoice
      ? upcomingInvoice.amount_due
      : (subscription.user?.plan.amount_decimal as unknown as number);
  }
  return amount;
}

export function findSubscriptionSeats(subscription: ISubscriptionData): number {
  const type = subscription.user?.plan?.metadata?.type;
  let amount = 1;
  if (type?.toLowerCase() === SubscriptionPlan.TEAM.toLowerCase()) {
    amount = subscription.user?.quantity as number;
  }
  return amount;
}

export const getAmountOfVolumeBasedPrice = (seat: number, price: any) => {
  const tiers = getReorderedPriceTiers(price);
  let amount = 0;
  tiers.forEach((tier: any) => {
    if (seat >= tier.min && (seat <= tier.max || tier.max === null)) {
      amount = tier.amount;
    }
  });
  return amount;
};

export const getReorderedPriceTiers = (price: any) => {
  if (!price) {
    return [];
  }
  return price?.tiers
    .slice()
    .reduce((acc: any[], item: any, index: number, orgArr: any[]) => {
      if (index === 0) {
        acc.push({ min: 1, max: item.up_to, amount: item.unit_amount });
        return acc;
      } else {
        acc.push({
          min: acc[index - 1].max + 1,
          max: index === orgArr.length - 1 ? 200 : item.up_to,
          amount: item.unit_amount,
        });
        return acc;
      }
    }, []);
};

export function getPlanBasePrices(
  products: any,
  plan: string = SubscriptionPlan.PREMIUM
): MonthAnnualPrice {
  let monthPrice = null;
  let annualPrice = null;
  if (products && products.length > 0) {
    const premiumProduct = products.find(
      (product: any) =>
        product.product_data.metadata?.tier?.toLowerCase() ===
        plan.toLowerCase()
    );
    premiumProduct.product_data.prices.map((price: any) => {
      if (
        price.recurring.interval == "month" &&
        price.metadata.type == plan.toLowerCase()
      ) {
        monthPrice = price;
      }

      if (
        plan === SubscriptionPlan.PREMIUM &&
        price.unit_amount > 0 &&
        price.recurring.interval == "year" &&
        price.metadata.type == plan.toLowerCase()
      ) {
        annualPrice = price;
      }

      if (
        plan === SubscriptionPlan.TEAM &&
        price.recurring.interval == "year" &&
        price.metadata.type == plan.toLowerCase()
      ) {
        annualPrice = price;
      }
    });
  }

  return {
    baseMonthlyPrice: monthPrice,
    baseYearlyPrice: annualPrice,
  };
}

export const getAnnualSaving = (
  plan: string,
  products: any[],
  isAnnualSelected: boolean,
  isStudent: boolean
) => {
  if (SubscriptionPlan.PREMIUM.toLowerCase() === plan.toLowerCase()) {
    const { baseMonthlyPrice, baseYearlyPrice } = getPlanBasePrices(products);
    const baseMonthlyPriceAmount = baseMonthlyPrice.unit_amount;
    const baseYearlyPriceAmount = baseYearlyPrice.unit_amount;
    const yearlyPerMonthAmount = baseYearlyPriceAmount / 12;
    if (isAnnualSelected && !isStudent) {
      return baseMonthlyPriceAmount * 12 - yearlyPerMonthAmount * 12;
    }

    if (isAnnualSelected && isStudent) {
      const monthlyAnnualPriceAmount = baseYearlyPrice.unit_amount / 12;
      const studentMonthlyAnnualPriceAmount =
        (monthlyAnnualPriceAmount * (100 - STUDENT_DISCOUNT_PERCENT)) / 100;
      return (
        baseMonthlyPrice.unit_amount * 12 - studentMonthlyAnnualPriceAmount * 12
      );
    }

    if (!isAnnualSelected && isStudent) {
      const studentMonthlyPriceAmount =
        (baseMonthlyPrice.unit_amount * (100 - STUDENT_DISCOUNT_PERCENT)) / 100;
      return baseMonthlyPrice.unit_amount - studentMonthlyPriceAmount;
    }
  }

  if (
    SubscriptionPlan.TEAM.toLowerCase() === plan.toLowerCase() &&
    isAnnualSelected
  ) {
    const { baseMonthlyPrice, baseYearlyPrice } = getPlanBasePrices(
      products,
      SubscriptionPlan.TEAM
    );
    const baseYearlyPriceAmount = baseYearlyPrice.tiers[0].unit_amount;
    const baseMonthlyPriceAmount = baseMonthlyPrice.tiers[0].unit_amount;
    const yearlyPerMonthAmount = baseYearlyPriceAmount / 12;
    return baseMonthlyPriceAmount * 12 - yearlyPerMonthAmount * 12;
  }

  return 0;
};

import { useUser } from "@clerk/nextjs";
import { CREDIT_RESET_INTERVAL_DAYS } from "constants/config";
import { updateClerkPublicMetaDataAPI } from "helpers/api";
import { convertUserPublicMetadata, IClerkPublicMetaData } from "helpers/clerk";
import { useEffect, useState } from "react";

function shouldResetUsage(lastResetDateString: unknown): boolean {
  const msInDay = 24 * 60 * 60 * 1000;

  // If value is unset or invalid, reset the usage.
  if (typeof lastResetDateString !== "string") {
    return true;
  }

  const lastResetDate = new Date(lastResetDateString);
  const currentDate = new Date();

  const daysDiff = Math.round(
    Math.abs((Number(currentDate) - Number(lastResetDate)) / msInDay)
  );

  return daysDiff >= CREDIT_RESET_INTERVAL_DAYS;
}

/**
 * @component useClerkMetadata
 * @description Loads clerk metadata from the useUser hook and converts it into the correct type. Checks if the usage needs to be rolled over at the end of the month, and resets it if so.
 */

function useClerkMetadata() {
  const { user } = useUser();

  const [userMetadata, setUserMetadata] = useState<IClerkPublicMetaData | null>(
    null
  );
  const [usageDataIsResetting, setUsageDataIsResetting] = useState(false);

  // When the public metadata loads, check the format and store it in state.
  useEffect(() => {
    if (!user?.publicMetadata) return;
    const userPublicMetadata = convertUserPublicMetadata(user.publicMetadata);
    if (!usageDataIsResetting) setUserMetadata(userPublicMetadata);
  }, [user?.publicMetadata]);

  // When the metadata loads, check if the credits should be reset and dispatch a call to the update API if so.
  useEffect(() => {
    if (userMetadata === null || usageDataIsResetting) return;

    const resetPublicMetadata = async (
      userPublicMetadata: IClerkPublicMetaData
    ) => {
      const updatedPublicMetaData: IClerkPublicMetaData = {
        ...userPublicMetadata,
        ask_paper_messages_per_paper_count: {},
        last_reset_date: new Date().toISOString(),
        used_pro_analysis_queries: [],
        used_study_paper_ids: [],
      };

      setUserMetadata(updatedPublicMetaData);
      setUsageDataIsResetting(true);
      await updateClerkPublicMetaDataAPI(updatedPublicMetaData);
      setUsageDataIsResetting(false);
    };

    if (
      shouldResetUsage(userMetadata.last_reset_date) &&
      !usageDataIsResetting
    ) {
      resetPublicMetadata(userMetadata);
    }
  }, [userMetadata, usageDataIsResetting]);

  return { userMetadata };
}

export default useClerkMetadata;

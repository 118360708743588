import { useAuth } from "@clerk/nextjs";
import { updateClerkPublicMetaDataAPI } from "helpers/api";
import { IClerkPublicMetaData } from "helpers/clerk";
import {
  convertSubscriptionDataToPartialUserMetadata,
  convertSubscriptionUsageDataToPartialUserMetadata,
} from "helpers/subscription";
import { useAppDispatch, useAppSelector } from "hooks/useStore";
import { useEffect } from "react";
import { setShouldUpdateClerk } from "store/slices/user";

const HandleSyncClerkMetadata = () => {
  const { isSignedIn, isLoaded } = useAuth();
  const dispatch = useAppDispatch();

  const subscription = useAppSelector(
    (state) => state.subscription.subscription
  );
  const isSubscriptionLoaded = useAppSelector(
    (state) => state.subscription.isSubscriptionLoaded
  );
  const userUsageData = useAppSelector((state) => state.user.usage);
  const userSettings = useAppSelector((state) => state.user.settings);
  const shouldUpdateClerk = useAppSelector(
    (state) => state.user.shouldUpdateClerk
  );
  const isIncognitoModeActive = useAppSelector(
    (state) => state.user.isIncognitoModeActive
  );
  const isResearchHubBannerHidden = useAppSelector(
    (state) => state.user.isResearchHubBannerHidden
  );

  const isUserMetadataLoaded = useAppSelector(
    (state) => state.user.isUserMetadataLoaded
  );

  useEffect(() => {
    const syncMetadataToClerk = async (
      updatedMetadata: Partial<IClerkPublicMetaData>
    ): Promise<void> => {
      await updateClerkPublicMetaDataAPI(updatedMetadata);
    };

    if (
      isLoaded &&
      isSignedIn &&
      isUserMetadataLoaded &&
      isSubscriptionLoaded &&
      shouldUpdateClerk
    ) {
      const updatedMetadata: Partial<IClerkPublicMetaData> = {
        ...convertSubscriptionDataToPartialUserMetadata(subscription),
        ...convertSubscriptionUsageDataToPartialUserMetadata(userUsageData),
        settings: { ...userSettings },
        is_incognito_mode_active: isIncognitoModeActive,
        is_research_hub_banner_hidden: isResearchHubBannerHidden,
      };
      syncMetadataToClerk(updatedMetadata);
      dispatch(setShouldUpdateClerk(false));
    }
  }, [
    isLoaded,
    isSignedIn,
    subscription,
    isSubscriptionLoaded,
    userUsageData,
    shouldUpdateClerk,
    isUserMetadataLoaded,
    isIncognitoModeActive,
    isResearchHubBannerHidden,
  ]);

  // This component does not render anything
  return null;
};

export default HandleSyncClerkMetadata;

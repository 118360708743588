import { ClerkProvider } from "@clerk/nextjs";
import { HeroUIProvider } from "@heroui/system";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import classNames from "classnames";
import HandleAnalytic from "components/HandleAnalytic";
import HandleScreenSizes from "components/HandleScreenSizes";
import HandleSubscriptionAndUsage from "components/HandleSubscriptionAndUsage";
import HandleSyncClerkMetadata from "components/HandleSyncClerkMetadata";
import HandleUserAuthEvent from "components/HandleUserAuthEvent";
import { QueryLanguageModal } from "components/QueryLanguageModal";
import SearchInput from "components/SearchInput";
import {
  PremiumFeatureModal,
  SubscriptionProvider,
} from "components/Subscription";
import TopBar from "components/TopBar";
import { SaveBookmarkModal } from "features/Bookmarks";
import FilterDrawer from "features/FilterDrawer";
import Sidebar, { SidebarOverlay } from "features/Sidebar";
import "helpers/polyfills";
import { isIntercomEnabled } from "helpers/services/intercom";
import { IntercomProvider } from "helpers/services/intercom/IntercomProvider";
import * as metaPixel from "helpers/services/metaPixel";
import * as mixpanel from "helpers/services/mixpanel";
import useLabels from "hooks/useLabels";
import type { AppContext, AppProps } from "next/app";
import Head from "next/head";
import Script from "next/script";
import { useEffect } from "react";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { store } from "store";
import "styles/clerk.css";
import "styles/globals.css";
import "../../public/fontello/css/fontello.css";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
    },
  },
});

type MyAppProps = AppProps & {};

/**
 * @description Wrapper component to all of the pages.
 */
const MyApp = ({ Component, pageProps }: MyAppProps) => {
  const [pageTitle] = useLabels("page-title");

  const { hideSearchBar } = pageProps;

  // Initialize services
  useEffect(() => {
    mixpanel.initialize();
    metaPixel.initialize();
  }, []);

  return (
    <IntercomProvider enabled={isIntercomEnabled}>
      <SubscriptionProvider>
        <ClerkProvider
          {...pageProps}
          appearance={{
            elements: {
              card: "shadow-none",
              formButtonPrimary:
                "rounded-full bg-accent-base text-accent-on-accent hover:bg-blue-600 border-white/[0.08] !shadow-none h-12 base-md",
              buttonArrowIcon: "hidden",
              socialButtonsBlockButtonText: "base-md",
            },
            variables: {
              fontSize: "1rem",
            },
            layout: {
              logoImageUrl: "/icons/logo-full.svg",
            },
          }}
        >
          <QueryClientProvider client={queryClient}>
            <Provider store={store}>
              <HeroUIProvider>
                <Head>
                  <title>{pageTitle}</title>
                  <meta
                    property="og:title"
                    content="Consensus"
                    key="og:title"
                  />
                  <meta name="robots" content="all" />
                  <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
                  />
                  <link rel="icon" type="image/png" href="/favicon.png" />
                </Head>
                <Script
                  id="gtm-script"
                  strategy="afterInteractive"
                >{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                      })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}');`}</Script>
                <Script src="https://js.stripe.com/v3" async></Script>
                <div className={pageProps?.pathname?.replaceAll("/", "")}>
                  <HandleAnalytic />
                  <HandleScreenSizes />
                  <HandleSubscriptionAndUsage />
                  <HandleSyncClerkMetadata />
                  <HandleUserAuthEvent />
                  <div className="flex">
                    <aside className="sidebar">
                      <Sidebar />
                    </aside>
                    <main className="min-h-screen main-content bg-gradient">
                      <SidebarOverlay />
                      {!pageProps.hideTopBar ? (
                        <div className="px-4 pt-4 sm:px-6 sm:pt-6 md:hidden">
                          <TopBar />
                        </div>
                      ) : null}
                      {!hideSearchBar ? (
                        <div className="sticky top-0 z-20 w-full">
                          <div
                            className={classNames(
                              `max-w-page mx-auto md:px-4`,
                              pageProps.pageContainerClassname
                            )}
                          >
                            <div className="px-4 pt-4 pb-4 sm:px-6 md:px-0 md:py-6">
                              <SearchInput />
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <div className="flex-1">
                        <div
                          className={classNames(
                            !pageProps.fullWidthPage &&
                              `max-w-page mx-auto md:px-4`,
                            pageProps.pageContainerClassname
                          )}
                        >
                          <Component {...pageProps} />
                        </div>
                        {<PremiumFeatureModal />}
                        {<QueryLanguageModal />}
                        <SaveBookmarkModal />
                      </div>
                    </main>
                  </div>
                  <FilterDrawer />
                  <ToastContainer
                    position="top-center"
                    closeButton={false}
                    hideProgressBar
                    theme="dark"
                    icon={false}
                    autoClose={2000}
                    toastClassName={(context) =>
                      classNames(
                        "p-1 pl-3 pr-3 rounded-md justify-center overflow-hidden cursor-pointer text-white text-sm w-fit mx-auto",
                        {
                          "bg-red-base": context?.type === "error",
                          "bg-green-emphasis": context?.type !== "error",
                        }
                      )
                    }
                    bodyClassName={() => "flex text-sm py-1 px-1"}
                  />
                </div>
              </HeroUIProvider>
            </Provider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </ClerkProvider>
      </SubscriptionProvider>
    </IntercomProvider>
  );
};

MyApp.getInitialProps = async ({ Component, ctx }: AppContext) => {
  let pageProps: any = {};

  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx);
  }

  pageProps.pathname = ctx.pathname;

  return { pageProps };
};

export default MyApp;

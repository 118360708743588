import { trackEventServerSide } from "helpers/api";
import { getMajorVersion } from "../mixpanel";
import { SharedServerEvent } from "./events";

export function logPageViewEvent(data: { route: string; pageTitle: string }) {
  trackEventServerSide({
    event: SharedServerEvent.PageView,
    pageType: data.route,
    pageTitle: data.pageTitle,
    version: getMajorVersion(),
  });
}

import { VenetianMask } from "components/Icon/local";
interface IncognitoModeUserPictureProps {
  className?: string;
}

export const IncognitoModeUserPicture = ({
  className = "w-6 h-6",
}: IncognitoModeUserPictureProps) => {
  return (
    <div
      className={`flex items-center justify-center rounded-full bg-[#3a5264] ${className}`}
    >
      <VenetianMask className="w-4 h-4 text-white" />
    </div>
  );
};

export default IncognitoModeUserPicture;

import { IconProps } from ".";

/**
 * @component ShareIcon
 * @description Component for showing the share SVG icon. Used instead of an <img> tag so that
 * we are able to change the color with css, e.g. on active. Use in conjunction with the <Icon>
 * component.
 * @example
 * return (
 *   <Icon
 *      className="fill-current"
 *      name="share"
 *      local={true}
 *    />
 * )
 */
function ShareIcon({ className }: IconProps) {
  return (
    <svg
      role="img"
      aria-label="Share"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      className={` ${className} ${className.includes("h-") ? "" : "w-5 h-5"}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M19.2719 13.0874C19.0788 13.0874 18.8936 13.17 18.7571 13.3171C18.6206 13.4642 18.5439 13.6637 18.5439 13.8718V16.5505C18.5439 17.1819 18.3111 17.7874 17.8967 18.2339C17.4823 18.6804 16.9202 18.9312 16.3342 18.9312H3.66582C3.07977 18.9312 2.51773 18.6804 2.10334 18.2339C1.68894 17.7874 1.45613 17.1819 1.45613 16.5505V13.8718C1.45613 13.6637 1.37943 13.4642 1.24289 13.3171C1.10635 13.17 0.921162 13.0874 0.728067 13.0874C0.534972 13.0874 0.349785 13.17 0.213246 13.3171C0.0767068 13.4642 0 13.6637 0 13.8718V16.5505C0.000963748 17.5977 0.387492 18.6016 1.07476 19.3421C1.76202 20.0825 2.69388 20.499 3.66582 20.5H16.3342C17.3061 20.499 18.238 20.0825 18.9252 19.3421C19.6125 18.6016 19.999 17.5977 20 16.5505V13.8718C20 13.6637 19.9233 13.4642 19.7868 13.3171C19.6502 13.17 19.465 13.0874 19.2719 13.0874Z" />
      <path d="M6.35966 6.30226L9.27193 3.16464V14.8131C9.27193 15.0211 9.34864 15.2206 9.48518 15.3677C9.62172 15.5148 9.8069 15.5975 10 15.5975C10.1931 15.5975 10.3783 15.5148 10.5148 15.3677C10.6514 15.2206 10.7281 15.0211 10.7281 14.8131V3.17641L13.6403 6.31403C13.708 6.38691 13.7883 6.44472 13.8767 6.48416C13.9651 6.5236 14.0598 6.5439 14.1554 6.5439C14.2511 6.5439 14.3458 6.5236 14.4342 6.48416C14.5226 6.44472 14.6029 6.38691 14.6705 6.31403C14.7382 6.24115 14.7919 6.15463 14.8285 6.05941C14.8651 5.96419 14.8839 5.86213 14.8839 5.75906C14.8839 5.65599 14.8651 5.55394 14.8285 5.45872C14.7919 5.36349 14.7382 5.27697 14.6705 5.20409L10.5169 0.729062C10.4157 0.623296 10.29 0.548792 10.1529 0.51335C10.0351 0.489981 9.91374 0.497371 9.7991 0.534902C9.68446 0.572432 9.57984 0.639011 9.49399 0.729062L5.34037 5.19233C5.22478 5.34218 5.1651 5.53301 5.17293 5.72767C5.18077 5.92232 5.25556 6.10687 5.38276 6.24538C5.50995 6.38388 5.68044 6.46643 5.86102 6.47694C6.0416 6.48746 6.21936 6.42519 6.35966 6.30226Z" />
    </svg>
  );
}

export default ShareIcon;

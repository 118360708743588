import { useAuth } from "@clerk/nextjs";
import { usePremiumFeatureModal } from "components/Subscription";
import {
  ABOUT_URL,
  FOR_DOCTORS_URL,
  FOR_UNIVERSITIES_URL,
  GPT_URL,
  HELP_CENTER_URL,
  HOME_PAGE_URL,
  SLACK_URL,
  TWITTER_URL,
} from "constants/config";
import { FeatureFlag } from "enums/feature-flag";
import { searchController } from "helpers/api";
import { canOrgRoleAccessApi } from "helpers/organization";
import { apiManagementPageUrl } from "helpers/pageUrl";
import { isSubscriptionPremium } from "helpers/subscription";
import useIsFeatureEnabled from "hooks/useIsFeatureEnabled";
import useLabels from "hooks/useLabels";
import useOrg from "hooks/useOrg";
import { useSidebar } from "hooks/useSidebar";
import { useAppDispatch, useAppSelector } from "hooks/useStore";
import Link from "next/link";
import { setIsSearching } from "store/slices/search";
import { CreditedFeature } from "store/slices/subscription";
import { SidebarTab } from "../Sidebar";
import { SidebarContentAccountMenu } from "./SidebarContentAccountMenu";
import SignedInContent from "./SignedInContent";
import SignedOutContent from "./SignedOutContent";

type SidebarContentProps = {
  selectedTab: SidebarTab;
  setSelectedTab: (selectedTab: SidebarTab) => void;
};

function SidebarContent({ selectedTab, setSelectedTab }: SidebarContentProps) {
  const dispatch = useAppDispatch();
  const { toggleSidebar } = useSidebar();
  const [sidebarLabels] = useLabels("screens.sidebar");
  const { orgRole } = useOrg();
  const userCanViewApiPage =
    useIsFeatureEnabled(FeatureFlag.API) && canOrgRoleAccessApi(orgRole);

  const { isSignedIn, isLoaded } = useAuth();

  const subscription = useAppSelector(
    (state) => state.subscription.subscription
  );
  const isPremium = isSubscriptionPremium(subscription);

  const handleClickHome = () => {
    searchController.abort();
    dispatch(setIsSearching(false));
  };

  const closeSidebar = () => {
    toggleSidebar();
  };

  const { openPremiumFeatureModal } = usePremiumFeatureModal();

  const onClickUpgradeToPremium = () => {
    openPremiumFeatureModal(CreditedFeature.PRO_ANALYSIS);
  };

  if (!isLoaded) return null;

  return (
    <div className="flex flex-col h-full overflow-x-hidden">
      <div className="flex items-center justify-between h-[72px] px-4">
        <Link href="/search" target="_blank" passHref legacyBehavior>
          <a onClick={handleClickHome} className="flex items-center h-full">
            <img alt="Consensus" src="/icons/logo-full.svg" className="h-7" />
          </a>
        </Link>
        <button
          onClick={closeSidebar}
          aria-label="Close sidebar"
          className="flex items-center justify-center h-10 w-10 hover:bg-bgr-faint rounded-full"
        >
          <i className="text-xl icon-panel-left-close text-fg-muted" />
        </button>
      </div>
      <div className="flex-1 overflow-hidden">
        {isSignedIn ? (
          <SignedInContent
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        ) : (
          <SignedOutContent />
        )}
      </div>
      <div className="flex flex-col bg-white whitespace-nowrap">
        {isSignedIn ? (
          <a
            className="flex items-center justify-center px-2 py-3 mx-2 rounded-lg hover:bg-gray-100"
            href={HELP_CENTER_URL}
            target="_blank"
          >
            <i className="w-6 text-xl text-center icon-circle-help text-fg-muted" />
            <span className="flex-1 ml-3 text-left text-fg-muted">
              {sidebarLabels["help-center"]}
            </span>
            <i className="text-xl icon-external-link text-fg-muted" />
          </a>
        ) : (
          <a
            href={HOME_PAGE_URL}
            className="flex items-center justify-center px-2 py-3 mx-2 rounded-lg hover:bg-gray-100"
          >
            <i className="w-6 text-xl text-center icon-circle-help text-fg-muted" />
            <span className="flex-1 ml-3 text-left text-fg-muted">
              {sidebarLabels["learn-more"]}
            </span>
            <i className="text-xl icon-chevron-right text-fg-muted" />
          </a>
        )}
        {!isPremium && isSignedIn && (
          <button
            className="flex items-center px-2 py-3 mx-2 text-blue-600 rounded-lg hover:bg-gray-100"
            onClick={onClickUpgradeToPremium}
          >
            <img src="/icons/premium.svg" alt="Open AI" className="w-5 h-5" />
            <span className="flex-1 ml-3 text-left sm-md text-accent-base">
              {sidebarLabels["upgrade-to-premium"]}
            </span>
            <i className="text-xl icon-chevron-right text-accent-base" />
          </button>
        )}
        {isSignedIn && <SidebarContentAccountMenu />}
        <section className="px-4 pt-3 pb-4 bg-bgr-faint">
          <div className="flex justify-between gap-3">
            <div className="flex gap-3">
              <Link
                className="flex items-center justify-center"
                href={TWITTER_URL}
                target="_blank"
                passHref
              >
                <i className="text-xl text-fg-muted icon-x-social" />
              </Link>
              <Link
                className="flex items-center justify-center"
                href={SLACK_URL}
                target="_blank"
                passHref
              >
                <i className="text-xl text-fg-muted icon-slack" />
              </Link>
            </div>
            <Link
              className="flex items-center justify-center gap-2 py-0.5 pl-1 pr-2 rounded-lg bg-bgr-subtle"
              href={GPT_URL}
              target="_blank"
              passHref
            >
              <i className="text-xl icon-gpt" />
              <i className="icon-external-link text-fg-faint" />
            </Link>
          </div>
          <div className="flex gap-3 mt-4 underline text-fg-muted tiny-normal">
            <a href={ABOUT_URL} target="_blank">
              {sidebarLabels["about"]}
            </a>
            <a href={FOR_UNIVERSITIES_URL} target="_blank">
              {sidebarLabels["for-universities"]}
            </a>
            <a href={FOR_DOCTORS_URL} target="_blank">
              {sidebarLabels["for-doctors"]}
            </a>
            {isSignedIn && userCanViewApiPage && (
              <a href={apiManagementPageUrl()} target="_blank">
                {sidebarLabels["api"]}
              </a>
            )}
          </div>
        </section>
      </div>
    </div>
  );
}

export default SidebarContent;

import classNames from "classnames";
import Popover from "components/Popover";
import { ThreadHistory } from "helpers/api";
import { threadPath } from "helpers/pageUrl";
import { useAppSelector } from "hooks/useStore";
import Link from "next/link";
import { MouseEventHandler, useMemo, useState } from "react";
import ThreadHistoryActionsModal from "./ThreadHistoryActionsModal";

type ThreadHistoryItemProps = {
  onDelete: (id: number) => void;
  onNavigate: () => void;
  onSave: (thread: ThreadHistory) => void;
  thread: ThreadHistory;
};

function ThreadHistoryItem({
  thread,
  onNavigate,
  onSave,
  onDelete,
}: ThreadHistoryItemProps) {
  const isMobile = useAppSelector((state) => state.setting.isMobile);

  const [isSelected, setIsSelected] = useState(false);

  const link = useMemo(() => {
    return threadPath(thread.thread_id, thread.title);
  }, [thread]);

  const handleOnClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
    if (isMobile && !isSelected) {
      // Disable the link and toggle selected state upon first click on mobile
      e.preventDefault();
      setIsSelected(true);
    } else {
      // Allow the link to proceed
      onNavigate();
    }
  };

  return (
    <li
      key={thread.thread_id}
      className="relative w-full overflow-hidden group"
    >
      <div
        className={classNames(
          "flex mx-3 gap-2 rounded-lg items-center hover:bg-bgr-faint",
          isSelected && "bg-gray-100"
        )}
      >
        <Link href={link} passHref legacyBehavior>
          <a
            onClick={handleOnClick}
            className="flex flex-1 p-2 text-fg-base sm-normal"
          >
            <span
              className={classNames(
                "flex-1 line-clamp-2 leading-[175%] pr-2",
                !isMobile ? "group-hover:pr-0" : isSelected && "pr-0"
              )}
            >
              {thread.title}
            </span>
          </a>
        </Link>
        <div>
          <Popover
            interactive
            placement="right"
            tooltipContent={
              <ThreadHistoryActionsModal
                onDelete={() => onDelete(thread.thread_id)}
                onSave={() => onSave(thread)}
              />
            }
            trigger="click"
          >
            <button
              className={classNames(
                "px-2",
                !isMobile
                  ? "hidden group-hover:block"
                  : isSelected
                  ? "block"
                  : "hidden"
              )}
            >
              <i className="icon-ellipsis-vertical text-fg-muted" />
            </button>
          </Popover>
        </div>
      </div>
    </li>
  );
}

export default ThreadHistoryItem;

import Popover from "components/Popover";
import StudyTypeTooltip from "components/Popover/StudyTypeTooltip/StudyTypeTooltip";
import { NON_RCT_URL } from "constants/config";
import useLabels from "hooks/useLabels";
import React from "react";
import { Tag } from "./Tag";

interface NonRctTagProps {
  pressable?: boolean;
}

function NonRctTag(props?: NonRctTagProps) {
  const [pageLabels] = useLabels("tooltips.non-rct-trial");

  return (
    <Popover
      interactive
      maxWidth={306}
      tooltipContent={
        <StudyTypeTooltip
          title={pageLabels.title}
          content={pageLabels.content}
          icon="/icons/non-rct.svg"
          iconPyramid="middle"
          learnMoreLink={NON_RCT_URL}
        />
      }
    >
      <Tag
        variant="white"
        data-testid="non-rct-trial-tag"
        className="border-yellow-base"
        onClick={(e) => {
          if (!props?.pressable) {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
      >
        <img alt="Info" src="/icons/non-rct.svg" width="20px" height="20px" />
        <span>{pageLabels["tag"]}</span>
      </Tag>
    </Popover>
  );
}

export default NonRctTag;

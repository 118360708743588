import { useAuth } from "@clerk/nextjs";
import classNames from "classnames";
import Checkbox from "components/Checkbox";
import Modal from "components/Modal";
import Popover from "components/Popover";
import CreateBookmarkItemTooltip from "components/Popover/CreateBookmarkItemTooltip/CreateBookmarkItemTooltip";
import OutOfFreeBookmarkListsTooltip from "components/Popover/OutOfFreeBookmarkListsTooltip/OutOfFreeBookmarkListsTooltip";
import { usePremiumFeatureModal } from "components/Subscription/PremiumFeatureModal/usePremiumFeatureModal";
import { BOOKMARK_MAX_ITEM_NUM } from "constants/config";
import path from "constants/path";
import BookmarkListItem from "features/BookmarkLists/components/BookmarkListItem";
import { createBookmarkItemsAPI } from "helpers/api";
import {
  BookmarkType,
  findBookmarkItem,
  getBookmarkItemsCount,
  IBookmarkCreateItemData,
  IBookmarkCreateItemsResponse,
  IBookmarkItem,
  IBookmarkListItem,
} from "helpers/bookmark";
import { isResultsPageUrl } from "helpers/pageUrl";
import { isSubscriptionPremium } from "helpers/subscription";
import useAnalytics from "hooks/useAnalytics";
import useLabels from "hooks/useLabels";
import { useAppDispatch, useAppSelector } from "hooks/useStore";
import { useRouter } from "next/router";
import React, { useCallback, useEffect, useState } from "react";
import {
  removeListTargetForNewBookmarkItem,
  setIsSaveBookmarkModalOpen,
} from "store/slices/bookmark";
import useBookmarks from "../hooks/useBookmarks";
import CreateNewBookmarkListModal from "./CreateNewBookmarkListModal";

/**
 * @component SaveBookmarkModal
 * @description Modal for saving a search to a list
 * @example
 * return (
 *   <SaveBookmarkModal
 *   />
 * )
 */
function SaveBookmarkModal() {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const { isSignedIn, isLoaded } = useAuth();
  const [showCreateNewList, setShowCreateNewList] = useState(false);
  const [modalLabels] = useLabels("save-search-modal");
  const { savePaperEvent, saveSearchEvent } = useAnalytics();

  const subscription = useAppSelector(
    (state) => state.subscription.subscription
  );
  const isSubscriptionLoaded = useAppSelector(
    (state) => state.subscription.isSubscriptionLoaded
  );
  const open = useAppSelector(
    (state) => state.bookmark.isSaveBookmarkModalOpen
  );

  const {
    addListTargetForNewBookmarkItem,
    bookmarkItems,
    bookmarkLists,
    saveBookmarkState,
    deleteBookmarkItem,
    isBookmarkItemsLoaded,
    isBookmarkListLoaded,
    isOutOfFreeBookmarkLists,
    reloadBookmarkItems,
    targetedListsForNewBookmarkItem,
  } = useBookmarks();

  const [newListInputValue, setNewListInputValue] = useState<string>("");
  const [isCreatingItems, setIsCreatingItems] = useState<Boolean>(false);
  const [initialSelectedListNum, setInitialSelectedListNum] = useState<
    number | undefined
  >(undefined);
  const [tooltipInstance, setTooltipInstance] = useState<any>(null);
  const [stateBookmarkList, setStateBookmarkList] = useState<
    Record<string, "check" | "uncheck">
  >({});
  const { isPremiumFeatureModalOpen } = usePremiumFeatureModal();

  const [isFutureBookmarkItemLimited, setIsFutureBookmarkItemLimited] =
    useState<Boolean>(true);
  const handleClickCancelCreateNewList = () => {
    setShowCreateNewList(false);
  };

  const handleClickCancel = useCallback(async () => {
    dispatch(setIsSaveBookmarkModalOpen(false));
    setShowCreateNewList(false);
    setInitialSelectedListNum(undefined);
    setStateBookmarkList({});
  }, [setShowCreateNewList]);

  const handleClickSave = useCallback(async () => {
    if (isBookmarkItemsLoaded !== true || isBookmarkListLoaded !== true) {
      return;
    }

    if (!isLoaded) {
      router?.push(path.INTERNAL_SERVER_ERROR);
      return;
    }

    if (!isSignedIn) {
      router.push(
        `${path.SIGN_UP}#/?redirect_url=${encodeURIComponent(
          `${router?.asPath}`
        )}`
      );
      return;
    }

    if (!isCreatingItems) {
      setIsCreatingItems(true);

      let toBeDeletedBookmarkItems: IBookmarkItem[] = [];
      for (let i = 0; i < bookmarkLists.length; i++) {
        const bookmarkList: IBookmarkListItem = bookmarkLists[i];
        if (
          targetedListsForNewBookmarkItem.includes(bookmarkList.id) == false
        ) {
          const bookmarkItem = findBookmarkItem(
            saveBookmarkState,
            bookmarkList.id,
            bookmarkItems
          );
          if (bookmarkItem != null) {
            toBeDeletedBookmarkItems.push(bookmarkItem);
          }
        }
      }

      if (toBeDeletedBookmarkItems.length > 0) {
        await Promise.all(toBeDeletedBookmarkItems.map(deleteBookmarkItem));
      }

      let toCreateBookmarkItems: IBookmarkCreateItemData[] = [];
      for (let i = 0; i < targetedListsForNewBookmarkItem.length; i++) {
        const item: IBookmarkCreateItemData = {
          list_id: targetedListsForNewBookmarkItem[i],
          paper_id: saveBookmarkState.paperId,
          search_url: saveBookmarkState.searchUrl,
          thread_id: saveBookmarkState.threadId,
          uploaded_paper_id: saveBookmarkState.uploadedPaperId,
        };
        toCreateBookmarkItems.push(item);
      }

      try {
        const ret: IBookmarkCreateItemsResponse = await createBookmarkItemsAPI(
          toCreateBookmarkItems
        );

        if (ret.success) {
          reloadBookmarkItems();
          for (let i = 0; i < ret.created_items.length; i++) {
            if (ret.created_items[i].bookmark_type === BookmarkType.PAPER)
              savePaperEvent();
            if (ret.created_items[i].bookmark_type === BookmarkType.SEARCH)
              saveSearchEvent();
          }
        }
      } catch (error) {}

      setIsCreatingItems(false);
      handleClickCancel();
    }
  }, [
    dispatch,
    router,
    isSignedIn,
    isLoaded,
    setIsCreatingItems,
    targetedListsForNewBookmarkItem,
    saveBookmarkState,
    bookmarkLists,
    bookmarkItems,
    isBookmarkItemsLoaded,
    isBookmarkListLoaded,
    handleClickCancel,
    isCreatingItems,
  ]);

  const handleClickShowCreateNewList = useCallback(async () => {
    if (isResultsPageUrl(router.route)) {
      setNewListInputValue(router.query.q as string);
    }
    setShowCreateNewList(true);
  }, [router]);

  const handleChangeStateUncheckList = useCallback(
    (id: string) => {
      if (stateBookmarkList?.[id] === "check") {
        setStateBookmarkList((currentState) => {
          delete currentState[id];
          return currentState;
        });
      } else {
        setStateBookmarkList((currentState) => ({
          ...currentState,
          [id]: "uncheck",
        }));
      }
    },
    [stateBookmarkList]
  );

  const handleChangeStateCheckList = useCallback(
    (id: string) => {
      if (stateBookmarkList?.[id] === "uncheck") {
        setStateBookmarkList((currentState) => {
          delete currentState[id];
          return currentState;
        });
      } else {
        setStateBookmarkList((currentState) => ({
          ...currentState,
          [id]: "check",
        }));
      }
    },
    [stateBookmarkList]
  );

  const handleClickList = useCallback(
    async (id: string) => {
      if (initialSelectedListNum === undefined) {
        setInitialSelectedListNum(targetedListsForNewBookmarkItem.length);
      }
      if (targetedListsForNewBookmarkItem.includes(id)) {
        dispatch(removeListTargetForNewBookmarkItem(id));
        handleChangeStateUncheckList(id);
      } else {
        addListTargetForNewBookmarkItem(id);
        handleChangeStateCheckList(id);
      }
    },
    [
      initialSelectedListNum,
      targetedListsForNewBookmarkItem,
      dispatch,
      handleChangeStateUncheckList,
      handleChangeStateCheckList,
    ]
  );

  useEffect(() => {
    if (isSubscriptionLoaded && isBookmarkItemsLoaded) {
      const futureBookmarkItemCount =
        getBookmarkItemsCount(bookmarkItems) +
        (targetedListsForNewBookmarkItem.length -
          (initialSelectedListNum === undefined
            ? targetedListsForNewBookmarkItem.length
            : initialSelectedListNum));
      if (
        isSubscriptionPremium(subscription) ||
        futureBookmarkItemCount < BOOKMARK_MAX_ITEM_NUM
      ) {
        setIsFutureBookmarkItemLimited(false);
      } else {
        setIsFutureBookmarkItemLimited(true);
      }
    }
  }, [
    targetedListsForNewBookmarkItem,
    initialSelectedListNum,
    subscription,
    isSubscriptionLoaded,
    isBookmarkListLoaded,
    isBookmarkItemsLoaded,
    bookmarkItems,
    bookmarkLists,
  ]);

  useEffect(() => {
    if (isPremiumFeatureModalOpen) {
      if (tooltipInstance != null) {
        tooltipInstance.hide();
      }
    }
  }, [isPremiumFeatureModalOpen, tooltipInstance]);

  if (showCreateNewList)
    return (
      <CreateNewBookmarkListModal
        initialValue={newListInputValue}
        onClose={handleClickCancelCreateNewList}
        open={showCreateNewList}
      />
    );

  return (
    <Modal
      open={open}
      onClose={handleClickCancel}
      title={modalLabels["title"]}
      onButtonClicked={handleClickSave}
      buttonText={modalLabels["done"]}
    >
      <div className="flex flex-col gap-2 text-fg-base">
        <h2 className="base-bold">{modalLabels["list-name"]}</h2>
        <div className="flex flex-col gap-3">
          {bookmarkLists.length > 0 && (
            <>
              <Popover
                interactive
                maxWidth={340}
                onShown={(instance: any) => {
                  setTooltipInstance(instance);
                }}
                onHidden={(instance: any) => {
                  setTooltipInstance(instance);
                }}
                tooltipContent={<OutOfFreeBookmarkListsTooltip />}
                className="p-3 rounded-xl"
                widthFull={true}
                disabled={isOutOfFreeBookmarkLists !== true}
              >
                <button
                  onClick={(e) => {
                    if (isOutOfFreeBookmarkLists === false) {
                      handleClickShowCreateNewList();
                    }
                  }}
                  className="flex items-center w-full gap-3 p-3 text-left bg-white border rounded-xl min-h-[68px] cursor-pointer"
                >
                  <div className="flex items-center justify-center w-10 h-10 rounded-lg bg-bgr-subtle">
                    <i
                      className={classNames("text-xl text-fg-muted icon-plus")}
                    />
                  </div>
                  <div className="flex flex-col flex-1">
                    <h2 className="sm-md">{modalLabels["create-new-list"]}</h2>
                  </div>
                </button>
              </Popover>
            </>
          )}
          {bookmarkLists.map((bookmarkList: IBookmarkListItem) => {
            return (
              <Popover
                key={bookmarkList.id}
                interactive
                maxWidth={340}
                onShown={(instance: any) => {
                  setTooltipInstance(instance);
                }}
                onHidden={(instance: any) => {
                  setTooltipInstance(instance);
                }}
                tooltipContent={<CreateBookmarkItemTooltip />}
                className="rounded-xl"
                widthFull={true}
                disabled={
                  targetedListsForNewBookmarkItem.includes(bookmarkList.id) ||
                  !isFutureBookmarkItemLimited
                }
              >
                <div
                  onClick={() => {
                    if (
                      targetedListsForNewBookmarkItem.includes(
                        bookmarkList.id
                      ) ||
                      !isFutureBookmarkItemLimited
                    ) {
                      handleClickList(bookmarkList.id);
                    }
                  }}
                >
                  <BookmarkListItem bookmarkList={bookmarkList}>
                    <Checkbox
                      isChecked={targetedListsForNewBookmarkItem.includes(
                        bookmarkList.id
                      )}
                      onToggle={() => {
                        if (
                          targetedListsForNewBookmarkItem.includes(
                            bookmarkList.id
                          ) ||
                          !isFutureBookmarkItemLimited
                        ) {
                          handleClickList(bookmarkList.id);
                        }
                      }}
                    />
                  </BookmarkListItem>
                </div>
              </Popover>
            );
          })}
        </div>
      </div>
    </Modal>
  );
}

export default SaveBookmarkModal;

import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "../index";

export interface ModalStackState {
  stack: string[];
  isClosingTopModal: boolean;
}

const initialState: ModalStackState = {
  stack: [],
  isClosingTopModal: false,
};

/**
 * @description A slice for managing a stack of modal IDs to allow for multiple modals to be open at once.
 */
const modalStackSlice = createSlice({
  name: "modalStack",
  initialState,
  reducers: {
    // Push a modal to the stack
    pushModal(state, { payload }: PayloadAction<string>) {
      // Check if this modal ID is already in the stack
      const existingIndex = state.stack.findIndex((id) => id === payload);

      // If it exists, do not add it to the stack
      if (existingIndex !== -1) return;

      // Add it to the top of the stack
      state.stack.push(payload);
    },

    // Set the closing flag for the top modal
    setClosingTopModalFlag(state, { payload }: PayloadAction<boolean>) {
      state.isClosingTopModal = payload;
    },

    // Remove a modal from the stack
    removeModal(state, { payload }: PayloadAction<string>) {
      state.stack = state.stack.filter((id) => id !== payload);
    },

    // Clear all modals from the stack
    clearModals(state) {
      state.stack = [];
      state.isClosingTopModal = false;
    },

    // Internal reducer for popping a modal - not exported
    _popModal(state) {
      if (state.stack.length > 0) {
        state.stack.pop();
      }
    },
  },
});

/**
 * @description An async thunk for popping a modal from the stack.
 * The timeout is critical to prevent a race condition during click events:
 * When clicking outside of a stacked modal UI, the onClose handlers of lower modals
 * in the stack may fire after the onClose handler of the top modal in the same click event.
 * Without this protection, after the top modal is removed from the stack, lower modals would
 * check if they're now on top (which they would be) and incorrectly close themselves too.
 * The isClosing flag + timeout ensures only one modal closes per click event, even if
 * multiple onClose handlers are triggered in sequence.
 */
export const popModalAsync = createAsyncThunk(
  "modalStack/popModalAsync",
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;

    if (state.modalStack.isClosingTopModal) {
      return;
    }

    // Only proceed if there are modals to pop
    if (state.modalStack.stack.length > 0) {
      // First set the closing flag
      dispatch(modalStackSlice.actions.setClosingTopModalFlag(true));

      // Then pop the modal using the internal action
      dispatch(modalStackSlice.actions._popModal());

      // Clear the flag after next tick
      return new Promise<void>((resolve) => {
        setTimeout(() => {
          dispatch(modalStackSlice.actions.setClosingTopModalFlag(false));
          resolve();
        }, 0);
      });
    }
  }
);

// Export only the public actions
export const { pushModal, removeModal, clearModals } = modalStackSlice.actions;

// Basic selector to get the modal stack
const selectModalStack = (state: RootState) => state.modalStack.stack;

// Selector to get the top modal in the stack
export const selectTopModal = createSelector([selectModalStack], (stack) =>
  stack.length > 0 ? stack[stack.length - 1] : null
);

// Selector to check if a specific modal is open (anywhere in the stack)
export const selectIsModalOpen = createSelector(
  [selectModalStack, (_state: RootState, modalId: string) => modalId],
  (stack, modalId) => stack.includes(modalId)
);

// Selector to check if a specific modal is on top (derived from topModal)
export const selectIsModalOnTop = createSelector(
  [selectTopModal, (_state: RootState, modalId: string) => modalId],
  (topModal, modalId) => topModal === modalId
);

// Selector to check if we're in the process of closing the top modal
export const selectIsClosingTopModal = (state: RootState) =>
  state.modalStack.isClosingTopModal;

export default modalStackSlice.reducer;

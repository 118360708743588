import { META_PIXEL_ID } from "constants/config";

let ReactPixelInstance: any = null;

// Move instance to a singleton from a dynamic import to avoid "window not defined" errors on
// server side.
async function getReactPixelInstance() {
  if (!ReactPixelInstance && META_PIXEL_ID) {
    const fbPixelModule = await import("react-facebook-pixel");
    ReactPixelInstance = fbPixelModule.default;
  }
  return ReactPixelInstance;
}

export async function initialize(): Promise<void> {
  const instance = await getReactPixelInstance();
  if (META_PIXEL_ID && instance) {
    instance.init(META_PIXEL_ID);
  }
}

export async function logPageViewEvent(page: string): Promise<void> {
  const instance = await getReactPixelInstance();
  if (instance) {
    instance.pageView();
  }
}

export async function logUserSignupEvent(signupMethod: string): Promise<void> {
  const instance = await getReactPixelInstance();
  if (instance) {
    instance.track("CompleteRegistration", { value: signupMethod });
  }
}

export async function logStudentDiscountEmailEvent(): Promise<void> {
  const instance = await getReactPixelInstance();
  if (instance) {
    instance.trackCustom("StudentDiscountEmail", {});
  }
}

export async function logSearchEvent(query: string): Promise<void> {
  const instance = await getReactPixelInstance();
  if (instance) {
    instance.track("Search", { search_string: query });
  }
}

import { useModalStack } from "hooks/useModalStack";
import useProToggle from "hooks/useProToggle";
import { useAppDispatch, useAppSelector } from "hooks/useStore";
import { useCallback } from "react";
import {
  CreditedFeature,
  setDisableProToggleOnPremiumModalClose,
  setPremiumModalFeature,
} from "store/slices/subscription";

export const PREMIUM_FEATURE_MODAL_ID = "premium-feature-modal";

export const usePremiumFeatureModal = () => {
  const dispatch = useAppDispatch();
  const {
    openModal,
    closeModalIfTop,
    isModalOpen,
    closeModal,
    closeAllModals,
  } = useModalStack(PREMIUM_FEATURE_MODAL_ID);
  const { changeProToggle } = useProToggle();

  /**
   * The feature that is currently being displayed in the modal if it is open
   */
  const currentFeature = useAppSelector(
    (state) => state.subscription.upgradeToPremiumModalFeature
  );

  const disableProToggleOnPremiumModalClose = useAppSelector(
    (state) => state.subscription.disableProToggleOnPremiumModalClose
  );

  /**
   * @description Callback to be called when the premium feature modal is closed.
   *              Toggles the pro toggle off if it is on and the disableProToggleOnPremiumModalClose is true.
   *              Resets the disableProToggleOnPremiumModalClose to false on every close.
   */
  const onClosePremiumFeatureModal = useCallback(() => {
    if (disableProToggleOnPremiumModalClose) {
      changeProToggle(false);
    }
    dispatch(setDisableProToggleOnPremiumModalClose(false));
  }, [changeProToggle, disableProToggleOnPremiumModalClose, dispatch]);

  /**
   * @description Opens the premium feature modal
   * @param feature - The feature to display in the modal
   */
  const openPremiumFeatureModal = useCallback(
    (feature: CreditedFeature, disableProToggle?: boolean) => {
      dispatch(setPremiumModalFeature(feature));

      if (disableProToggle) {
        dispatch(setDisableProToggleOnPremiumModalClose(disableProToggle));
      }

      openModal();
    },
    [dispatch, openModal]
  );

  /**
   * @description Closes the premium feature modal if it is the top modal on the modal stack
   * @param onCloseCallback - A callback to execute after the modal is closed
   */
  const closePremiumFeatureModalIfOnTopOfStack = useCallback(
    (onCloseCallback?: () => void) => {
      const closeCallback = () => {
        onClosePremiumFeatureModal();
        if (onCloseCallback) {
          onCloseCallback();
        }
      };
      closeModalIfTop(closeCallback);
    },
    [closeModalIfTop, onClosePremiumFeatureModal]
  );

  /**
   * @description Closes the premium feature modal regardless of its position in the modal stack
   */
  const forceClosePremiumFeatureModal = useCallback(
    (onCloseCallback?: () => void) => {
      const closeCallback = () => {
        onClosePremiumFeatureModal();
        if (onCloseCallback) {
          onCloseCallback();
        }
      };
      closeModal(closeCallback);
    },
    [closeModal, onClosePremiumFeatureModal]
  );

  return {
    currentFeature,
    openPremiumFeatureModal,
    closePremiumFeatureModalIfOnTopOfStack,
    forceClosePremiumFeatureModal,
    closeAllModals,
    onClosePremiumFeatureModal,
    isPremiumFeatureModalOpen: isModalOpen,
  };
};

import { BOOKMARK_LISTNAME_FAVORITE } from "constants/config";
import { BookmarkItemsType, SaveBookmarkState } from "store/slices/bookmark";

export enum BookmarkType {
  PAPER = "paper",
  SEARCH = "search",
  THREAD = "thread",
  UPLOADED_PAPER = "uploaded_paper",
}

export interface IBookmarkListItem {
  id: string;
  text_label: string;
  created_at: string;
  deleted_at: string;
}

export interface IBookmarkListResponse {
  clerk_user_id: string;
  bookmark_lists: IBookmarkListItem[];
}

export interface IBookmarkCreateListResponse {
  success: boolean;
  created_item: IBookmarkListItem;
}

export interface IBookmarkUpdateListResponse {
  success: boolean;
  updated_item: IBookmarkListItem;
}

export interface IPaperBookmark {
  paper_id: string;
}

export interface IUploadedPaperBookmark {
  uploaded_paper_id: string;
}

export interface ISearchBookmark {
  search_url: string;
}

export interface IThreadBookmark {
  interaction_count: number;
  is_shared: boolean;
  last_interaction_at: string;
  preview_text: string;
  thread_id: number;
  title?: string;
}

export interface IUploadedPaperBookmark {
  uploaded_paper_id: string;
}

export interface IBookmarkItem {
  id: number;
  clerk_user_id: string;
  bookmark_list_id: number;
  bookmark_type: BookmarkType;
  bookmark_data:
    | IPaperBookmark
    | ISearchBookmark
    | IThreadBookmark
    | IUploadedPaperBookmark;
  created_at: string;
  deleted_at?: string;
}

export interface IBookmarkItemsResponse {
  bookmark_items: { [key: number]: IBookmarkItem[] };
}

export interface IBookmarkCreateItemData {
  list_id: string;
  paper_id?: string;
  search_url?: string;
  thread_id?: number;
  uploaded_paper_id?: string;
}

export interface IBookmarkCreateItemsResponse {
  success: boolean;
  created_items: IBookmarkItem[];
}

export function sortBookmarkList(
  bookmarkLists: IBookmarkListItem[]
): IBookmarkListItem[] {
  bookmarkLists.sort((a: IBookmarkListItem, b: IBookmarkListItem) => {
    if (a.text_label == BOOKMARK_LISTNAME_FAVORITE) return -1;
    if (b.text_label == BOOKMARK_LISTNAME_FAVORITE) return 1;

    const dateA = new Date(a.created_at as string);
    const dateB = new Date(b.created_at as string);
    return dateA.getTime() < dateB.getTime() ? 1 : -1;
  });
  return bookmarkLists;
}

export function findBookmarkItem(
  saveBookmarkState: SaveBookmarkState,
  listId: string,
  bookmark_items: BookmarkItemsType
): IBookmarkItem | null {
  if (!bookmark_items || bookmark_items.hasOwnProperty(listId) == false)
    return null;

  const items: IBookmarkItem[] = bookmark_items[listId];
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    if (
      saveBookmarkState.bookmarkType == BookmarkType.PAPER &&
      item.bookmark_type == BookmarkType.PAPER
    ) {
      const data: IPaperBookmark = item.bookmark_data as IPaperBookmark;
      if (data.paper_id == saveBookmarkState.paperId) {
        return item;
      }
    } else if (
      saveBookmarkState.bookmarkType == BookmarkType.SEARCH &&
      item.bookmark_type == BookmarkType.SEARCH
    ) {
      const data: ISearchBookmark = item.bookmark_data as ISearchBookmark;
      if (data.search_url == saveBookmarkState.searchUrl) {
        return item;
      }
    } else if (
      saveBookmarkState.bookmarkType == BookmarkType.THREAD &&
      item.bookmark_type == BookmarkType.THREAD
    ) {
      const data: IThreadBookmark = item.bookmark_data as IThreadBookmark;
      if (data.thread_id == saveBookmarkState.threadId) {
        return item;
      }
    } else if (
      saveBookmarkState.bookmarkType == BookmarkType.UPLOADED_PAPER &&
      item.bookmark_type == BookmarkType.UPLOADED_PAPER
    ) {
      const data: IUploadedPaperBookmark =
        item.bookmark_data as IUploadedPaperBookmark;
      if (data.uploaded_paper_id == saveBookmarkState.uploadedPaperId) {
        return item;
      }
    }
  }
  return null;
}

export function isSearchUrlBookmarked(
  search_url: string,
  bookmark_items: BookmarkItemsType
): boolean {
  let isBookmarked = false;
  for (const key in bookmark_items) {
    const items: IBookmarkItem[] = bookmark_items[key];
    items.forEach((item) => {
      if (item.bookmark_type == BookmarkType.SEARCH) {
        const bookmarkData: ISearchBookmark =
          item.bookmark_data as ISearchBookmark;
        if (bookmarkData.search_url == search_url) {
          isBookmarked = true;
        }
      }
    });
  }

  return isBookmarked;
}

export function isPaperBookmarked(
  paper_id: string,
  bookmark_items: BookmarkItemsType
): boolean {
  let isBookmarked = false;
  for (const key in bookmark_items) {
    const items: IBookmarkItem[] = bookmark_items[key];
    items.forEach((item) => {
      if (item.bookmark_type == BookmarkType.PAPER) {
        const bookmarkData: IPaperBookmark =
          item.bookmark_data as IPaperBookmark;
        if (bookmarkData.paper_id == paper_id) {
          isBookmarked = true;
        }
      }
    });
  }

  return isBookmarked;
}

export function isThreadBookmarked(
  thread_id: number,
  bookmark_items: BookmarkItemsType
): boolean {
  let isBookmarked = false;
  for (const key in bookmark_items) {
    const items: IBookmarkItem[] = bookmark_items[key];
    items.forEach((item) => {
      if (item.bookmark_type == BookmarkType.THREAD) {
        const bookmarkData: IThreadBookmark =
          item.bookmark_data as IThreadBookmark;
        if (bookmarkData.thread_id == thread_id) {
          isBookmarked = true;
        }
      }
    });
  }

  return isBookmarked;
}

export function isUploadedPaperBookmarked(
  uploaded_paper_id: string,
  bookmark_items: BookmarkItemsType
): boolean {
  let isBookmarked = false;
  for (const key in bookmark_items) {
    const items: IBookmarkItem[] = bookmark_items[key];
    items.forEach((item) => {
      if (item.bookmark_type == BookmarkType.UPLOADED_PAPER) {
        const bookmarkData: IUploadedPaperBookmark =
          item.bookmark_data as IUploadedPaperBookmark;
        if (bookmarkData.uploaded_paper_id == uploaded_paper_id) {
          isBookmarked = true;
        }
      }
    });
  }

  return isBookmarked;
}

export function getBookmarkCountsByType(
  list_id: string,
  bookmark_items: BookmarkItemsType
): {
  paperBookmarkCount: number;
  searchBookmarkCount: number;
  threadBookmarkCount: number;
} {
  let paperBookmarkCount = 0;
  let searchBookmarkCount = 0;
  let threadBookmarkCount = 0;

  if (bookmark_items && bookmark_items.hasOwnProperty(list_id)) {
    const items: IBookmarkItem[] = bookmark_items[list_id];

    items.forEach((item) => {
      if (
        item.bookmark_type == BookmarkType.PAPER ||
        item.bookmark_type == BookmarkType.UPLOADED_PAPER
      ) {
        paperBookmarkCount++;
      } else if (item.bookmark_type == BookmarkType.SEARCH) {
        searchBookmarkCount++;
      } else if (item.bookmark_type == BookmarkType.THREAD) {
        threadBookmarkCount++;
      }
    });
  }

  return {
    paperBookmarkCount,
    searchBookmarkCount,
    threadBookmarkCount,
  };
}

export function getBookmarksByType(
  list_id: string,
  bookmark_items: BookmarkItemsType
): {
  paperBookmarks: IBookmarkItem[];
  searchBookmarks: IBookmarkItem[];
  threadBookmarks: IBookmarkItem[];
} {
  let paperBookmarks: IBookmarkItem[] = [];
  let searchBookmarks: IBookmarkItem[] = [];
  let threadBookmarks: IBookmarkItem[] = [];

  if (bookmark_items && bookmark_items.hasOwnProperty(list_id)) {
    const items: IBookmarkItem[] = bookmark_items[list_id];

    items.forEach((item) => {
      if (
        item.bookmark_type == BookmarkType.PAPER ||
        item.bookmark_type == BookmarkType.UPLOADED_PAPER
      ) {
        paperBookmarks.push(item);
      } else if (item.bookmark_type == BookmarkType.SEARCH) {
        searchBookmarks.push(item);
      } else if (item.bookmark_type == BookmarkType.THREAD) {
        threadBookmarks.push(item);
      }
    });
  }

  return {
    paperBookmarks,
    searchBookmarks,
    threadBookmarks,
  };
}

export function hasBookmarkListItemsDifference(
  bookmarkItems1: IBookmarkItem[],
  bookmarkItems2: IBookmarkItem[]
): boolean {
  let bookmarkPaperCount1 = bookmarkItems1.filter(
    (x) =>
      x.bookmark_type == BookmarkType.PAPER ||
      x.bookmark_type == BookmarkType.UPLOADED_PAPER
  ).length;
  let bookmarkPaperCount2 = bookmarkItems2.filter(
    (x) =>
      x.bookmark_type == BookmarkType.PAPER ||
      x.bookmark_type == BookmarkType.UPLOADED_PAPER
  ).length;

  if (bookmarkPaperCount1 != bookmarkPaperCount2) {
    return true;
  }

  for (let i = 0; i < bookmarkItems1.length; i++) {
    const bookmarkItem1: IBookmarkItem = bookmarkItems1[i];
    if (
      bookmarkItem1.bookmark_type == BookmarkType.PAPER ||
      bookmarkItem1.bookmark_type == BookmarkType.UPLOADED_PAPER
    ) {
      let isIncluded = false;
      for (let j = 0; j < bookmarkItems2.length; j++) {
        const bookmarkItem2: IBookmarkItem = bookmarkItems2[j];
        if (
          bookmarkItem2.bookmark_type == BookmarkType.PAPER ||
          bookmarkItem2.bookmark_type == BookmarkType.UPLOADED_PAPER
        ) {
          const bookmarkData1 = bookmarkItem1.bookmark_data as
            | IPaperBookmark
            | IUploadedPaperBookmark;
          const bookmarkData2 = bookmarkItem2.bookmark_data as
            | IPaperBookmark
            | IUploadedPaperBookmark;

          const id1 =
            "paper_id" in bookmarkData1
              ? bookmarkData1.paper_id
              : bookmarkData1.uploaded_paper_id;
          const id2 =
            "paper_id" in bookmarkData2
              ? bookmarkData2.paper_id
              : bookmarkData2.uploaded_paper_id;

          if (id1 == id2) {
            isIncluded = true;
            break;
          }
        }
      }
      if (isIncluded == false) {
        return true;
      }
    }
  }
  return false;
}

export function getBookmarkItemsCount(bookmark_items: {
  [key: number]: IBookmarkItem[];
}): number {
  let bookmarkItemNum = 0;
  for (let key in bookmark_items) {
    bookmarkItemNum += bookmark_items[key].length;
  }
  return bookmarkItemNum;
}

import { IconProps } from ".";

/**
 * @component CopyTextIcon
 * @description Component for showing the share SVG icon. Used instead of an <img> tag so that
 * we are able to change the color with css, e.g. on active. Use in conjunction with the <Icon>
 * component.
 * @example
 * return (
 *   <Icon
 *      className="fill-current"
 *      name="copy-text"
 *      local=
 *    />
 * )
 */
function CopyTextIcon({ className }: IconProps) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 45 45"
      fill="none"
      className={`${className} ${className.includes("h-") ? "" : "w-5 h-5"}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.11392 14.3667H14.2242V6.08859C14.2242 4.68791 14.7987 3.41498 15.7249 2.49332C16.6511 1.57166 17.9303 1 19.3381 1H37.8861C39.2937 1 40.573 1.57171 41.4992 2.49332C42.4255 3.41493 43 4.68783 43 6.08859V24.5447C43 25.9454 42.4241 27.2183 41.4992 28.14C40.573 29.0616 39.2938 29.6333 37.8861 29.6333H32.7758V37.9114C32.7758 39.3121 32.2013 40.585 31.2751 41.5067C30.3489 42.4283 29.0697 43 27.6619 43H9.11392C7.70627 43 6.427 42.4283 5.50075 41.5067C4.57451 40.5851 4 39.3122 4 37.9114V19.4553C4 18.0546 4.57456 16.7817 5.50075 15.86C6.42695 14.9384 7.70618 14.3667 9.11392 14.3667ZM17.0829 14.3667H27.6617C29.0693 14.3667 30.3486 14.9384 31.2748 15.86C32.2011 16.7816 32.7756 18.0545 32.7756 19.4553V26.7882H37.8858C38.5051 26.7882 39.0693 26.5349 39.4774 26.1275C39.8868 25.7201 40.1413 25.1588 40.1413 24.5438V6.0877C40.1413 5.47151 39.8868 4.91013 39.4774 4.50276C39.068 4.09539 38.5038 3.84208 37.8858 3.84208H19.3378C18.7186 3.84208 18.1544 4.0954 17.745 4.50276C17.3356 4.91013 17.081 5.47151 17.081 6.0877V14.3658L17.0829 14.3667ZM15.6606 17.2113H9.1123C8.49303 17.2113 7.92886 17.4646 7.52075 17.872C7.11135 18.2794 6.85678 18.8407 6.85678 19.4569V37.9131C6.85678 38.5293 7.11136 39.0906 7.52075 39.498C7.93014 39.9054 8.49431 40.1587 9.11358 40.1587H27.6616C28.2808 40.1587 28.845 39.9054 29.2544 39.498C29.6638 39.0906 29.9184 38.5293 29.9184 37.9131V19.4578C29.9184 18.8416 29.6638 18.2802 29.2544 17.8728C28.845 17.4655 28.2808 17.2122 27.6616 17.2122H15.6607L15.6606 17.2113Z" />
    </svg>
  );
}

export default CopyTextIcon;

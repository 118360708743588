import { CoButton } from "components/CoButton";
import { CoButtonPropsType } from "components/CoButton/CoButton";
import { BetaTag } from "components/Tag/BetaTag";
import { ReactNode } from "react";

type ChildrenOnlyPropType = {
  id?: string;
  children: ReactNode;
};

type HeaderPropsType =
  | {
      iconComponent?: ReactNode;
      title: string;
      hint?: string;
      isBeta?: boolean;
    }
  | ChildrenOnlyPropType;

type DescriptionPropsType =
  | {
      content: string;
    }
  | ChildrenOnlyPropType;

type HintPropsType =
  | {
      content: string;
    }
  | ChildrenOnlyPropType;

type InfoSectionPropsType = {
  infoText?: string;
  children?: ReactNode;
};

const CardTooltip = (props: ChildrenOnlyPropType) => {
  const { children } = props;
  return (
    <section className="flex flex-col" id={props.id}>
      {children}
    </section>
  );
};

const Header = (props: HeaderPropsType) => {
  if ("children" in props) {
    const { children } = props;
    return <>{children}</>;
  }

  const { title, iconComponent, hint, isBeta } = props;
  return (
    <div className="flex items-center gap-3 mb-2">
      <h2 className="flex items-center flex-1 gap-3 sm-bold">
        {iconComponent}
        {title}
      </h2>
      {hint && <span className="text-fg-muted tiny-normal">{hint}</span>}
      {isBeta && <BetaTag />}
    </div>
  );
};

const Description = (props: DescriptionPropsType) => {
  if ("children" in props) {
    const { children } = props;
    return <>{children}</>;
  }

  const { content } = props;
  return <p className="sm-normal">{content}</p>;
};

const Hint = (props: HintPropsType) => {
  if ("children" in props) {
    const { children } = props;
    return <>{children}</>;
  }

  const { content } = props;
  return <p className="tiny-normal text-fg-muted mt-4">{content}</p>;
};

const Button = (props: CoButtonPropsType) => {
  return <CoButton className="gap-x-2 mt-4" size="sm" {...props} />;
};

const InfoSection = ({ infoText, children }: InfoSectionPropsType) => {
  if (!infoText && !children) {
    return null;
  }

  if (children) {
    return <>{children}</>;
  }

  return (
    <div className="flex items-center gap-2 p-3 mt-3 bg-bgr-subtle rounded-xl">
      <i className="text-xl icon-info" />
      <span className="text-fg-base tiny-md">{infoText}</span>
    </div>
  );
};

CardTooltip.Header = Header;
CardTooltip.Description = Description;
CardTooltip.Hint = Hint;
CardTooltip.Button = Button;
CardTooltip.InfoSection = InfoSection;

export default CardTooltip;

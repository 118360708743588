import { ButtonVariant, CoButton } from "components/CoButton";
import { HIERARCHY_OF_EVIDENCE_URL } from "constants/config";
import React from "react";

interface StudyTypeTooltipProps {
  title: string;
  content: string;
  icon: string;
  iconPyramid?: "top" | "middle" | "bottom";
  showLink?: boolean;
  learnMoreLink?: string;
}

/**
 * @component StudyTypeTooltip
 * @description Tooltip for case study
 * @example
 * return (
 *   <StudyTypeTooltip />
 * )
 */
const StudyTypeTooltip = ({
  title,
  icon,
  content,
  iconPyramid,
  showLink = true,
  learnMoreLink,
}: StudyTypeTooltipProps) => {
  return (
    <div className="flex flex-col gap-3">
      <p className="flex items-center gap-3 sm-bold">
        <img className="w-6" alt={title} src={icon} />
        {title}
      </p>
      <p
        className="sm-normal"
        dangerouslySetInnerHTML={{ __html: content }}
      ></p>
      {showLink ? (
        <CoButton
          variant={ButtonVariant.Tertiary}
          onClick={(e) => {
            window.open(learnMoreLink || HIERARCHY_OF_EVIDENCE_URL, "_blank");
            e.preventDefault();
          }}
          icon={
            iconPyramid === "top"
              ? "/icons/pyramid1.svg"
              : iconPyramid === "middle"
              ? "/icons/pyramid2.svg"
              : "/icons/pyramid3.svg"
          }
          iconRight="icon-external-link"
          label="Learn more"
          size="sm"
        />
      ) : null}
    </div>
  );
};

export default StudyTypeTooltip;

import Popover from "components/Popover";
import StudyTypeTooltip from "components/Popover/StudyTypeTooltip/StudyTypeTooltip";
import { ANIMAL_STUDY_URL } from "constants/config";
import useLabels from "hooks/useLabels";
import React from "react";
import { Tag } from "./Tag";

interface AnimalStudyTagProps {
  pressable?: boolean;
}

function AnimalStudyTag(props?: AnimalStudyTagProps) {
  const [pageLabels] = useLabels("tooltips.animal-trial");

  return (
    <Popover
      interactive
      maxWidth={306}
      tooltipContent={
        <StudyTypeTooltip
          title={pageLabels.title}
          content={pageLabels.content}
          icon="/icons/animal-trial.svg"
          iconPyramid="bottom"
          learnMoreLink={ANIMAL_STUDY_URL}
        />
      }
    >
      <Tag
        variant="white"
        data-testid="animal-trial-tag"
        className="border-red-base"
        onClick={(e) => {
          if (!props?.pressable) {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
      >
        <img
          alt="Info"
          src="/icons/animal-trial.svg"
          width="20px"
          height="20px"
        />
        <span>{pageLabels["tag"]}</span>
      </Tag>
    </Popover>
  );
}

export default AnimalStudyTag;

import classNames from "classnames";
import Icon from "components/Icon";
import Popover from "components/Popover";
import { SearchHistory } from "helpers/api";
import {
  getFilterCountForSearchHistory,
  getSearchPathFromSearchHistory,
} from "helpers/searchHistories";
import { useAppSelector } from "hooks/useStore";
import Link from "next/link";
import { useRouter } from "next/router";
import { MouseEventHandler, useMemo, useState } from "react";
import SearchHistoryActionsModal from "./SearchHistoryActionsModal";

type SearchHistoryItemProps = {
  onDelete: (id: string) => void;
  onNavigate: () => void;
  onSave: (searchHistory: SearchHistory) => void;
  searchHistory: SearchHistory;
};

export const isUploadedPaperUrl = (url: string) => {
  return url.includes("papers/uploaded/") || url.includes("papers/u/");
};

function SearchHistoryItem({
  searchHistory,
  onNavigate,
  onSave,
  onDelete,
}: SearchHistoryItemProps) {
  const router = useRouter();
  const isMobile = useAppSelector((state) => state.setting.isMobile);
  const [isSelected, setIsSelected] = useState(false);

  const link = useMemo(() => {
    if (isUploadedPaperUrl(searchHistory.full_url)) {
      return searchHistory.full_url;
    }
    return getSearchPathFromSearchHistory(searchHistory, router.query);
  }, [searchHistory, router.query]);

  const filterCount = useMemo(() => {
    return getFilterCountForSearchHistory(searchHistory);
  }, [searchHistory]);

  const handleOnClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
    if (isMobile && !isSelected) {
      // Disable the link and toggle selected state upon first click on mobile
      e.preventDefault();
      setIsSelected(true);
    } else {
      // Allow the link to proceed
      onNavigate();
    }
  };

  const isUploadedPaperItem = useMemo(() => {
    return isUploadedPaperUrl(searchHistory.full_url);
  }, [searchHistory.full_url]);

  return (
    <li
      key={searchHistory.id}
      className="relative w-full overflow-hidden group"
    >
      <div
        className={classNames(
          "flex mx-3 gap-2 rounded-lg items-center",
          isSelected && "bg-gray-100"
        )}
      >
        <Link href={link} passHref legacyBehavior>
          <a
            onClick={handleOnClick}
            className="flex flex-1 p-2 text-fg-base sm-normal"
          >
            <span
              className={classNames(
                "flex-1 line-clamp-1 leading-[175%] pr-2 text-ellipsis",
                !isMobile ? "group-hover:pr-0" : isSelected && "pr-0"
              )}
            >
              {searchHistory.query}
            </span>
            {isUploadedPaperItem && (
              <i
                className={classNames(
                  "icon-file-arrow-up text-fg-muted text-gray-400",
                  !isMobile && !isUploadedPaperItem
                    ? "group-hover:hidden"
                    : !isSelected && "block"
                )}
              />
            )}
            {filterCount > 0 && (
              <div
                className={classNames(
                  "flex items-center",
                  !isMobile
                    ? "group-hover:hidden group-hover:pl-2"
                    : isSelected && "mr-0 pl-2"
                )}
              >
                <span className="flex items-center gap-[3px] px-1 text-[10px] text-white rounded-full bg-gray-550">
                  <Icon
                    className="w-[10px] h-[10px] text-white fill-current stroke-current stroke-0"
                    name="filter"
                    local
                  />
                  {filterCount}
                </span>
              </div>
            )}
          </a>
        </Link>
        <div>
          {!isUploadedPaperItem && (
            <Popover
              interactive
              placement="right"
              tooltipContent={
                <SearchHistoryActionsModal
                  onDelete={() => onDelete(searchHistory.id)}
                  onSave={() => onSave(searchHistory)}
                />
              }
              trigger="click"
            >
              <button
                className={classNames(
                  "px-2 mr-2",
                  !isMobile
                    ? "hidden group-hover:block"
                    : isSelected
                    ? "block"
                    : "hidden"
                )}
              >
                <i className="icon-ellipsis-vertical text-fg-muted" />
              </button>
            </Popover>
          )}
        </div>
      </div>
    </li>
  );
}

export default SearchHistoryItem;

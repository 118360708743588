/* eslint-disable react-hooks/exhaustive-deps */
import { useAuth, useUser } from "@clerk/nextjs";
import {
  extractSubscriptionUsageDataFromUserMetadata,
  getActiveSubscription,
  getCustomerUpcomingInvoice,
  isUserMetadataInSync,
} from "helpers/subscription";
import useClerkMetadata from "hooks/useClerkMetadata";
import { useAppDispatch, useAppSelector } from "hooks/useStore";
import { useEffect } from "react";
import {
  setIsSubscriptionLoaded,
  setSubscription,
  setUpcomingInvoice,
} from "store/slices/subscription";
import {
  setIsUsageDataLoaded,
  setShouldUpdateClerk,
  setUsageData,
  setUserSettings,
  updateIsIncognitoModeActive,
  updateResearchHubBannerHidden,
} from "store/slices/user";

/**
 * @component HandleSubscriptionAndUsage
 * @description Sets the subscription and usage data in redux upon each page load.
 * @example
 * return (
 *   <HandleSubscriptionAndUsage />
 * )
 */

const HandleSubscriptionAndUsage = () => {
  const { user } = useUser();
  const { userMetadata } = useClerkMetadata();

  const { isSignedIn, isLoaded } = useAuth();
  const dispatch = useAppDispatch();

  const subscription = useAppSelector(
    (state) => state.subscription.subscription
  );
  const isSubscriptionLoaded = useAppSelector(
    (state) => state.subscription.isSubscriptionLoaded
  );
  const isUserMetadataLoaded = useAppSelector(
    (state) => state.user.isUserMetadataLoaded
  );

  useEffect(() => {
    const fetchSubscriptionData = async () => {
      dispatch(setIsSubscriptionLoaded(false));
      const customerId = user?.publicMetadata.stripeCustomerId as string;
      const subscriptionRet = await getActiveSubscription({
        customerId,
        emailAddresses: user?.emailAddresses.map(
          (record) => record.emailAddress
        ),
      });
      const invoice = await getCustomerUpcomingInvoice(customerId);
      dispatch(setSubscription(subscriptionRet));
      dispatch(setUpcomingInvoice(invoice));
      dispatch(setIsSubscriptionLoaded(true));
    };

    if (isSignedIn && isLoaded && !isSubscriptionLoaded) {
      fetchSubscriptionData();
    }
  }, [isSignedIn, isLoaded]);

  // Sync the publicMetadata from the useUser hook into redux subscription usage data.
  useEffect(() => {
    if (!userMetadata) {
      dispatch(setIsUsageDataLoaded(false));
      return;
    }

    let subscriptionUsageData =
      extractSubscriptionUsageDataFromUserMetadata(userMetadata);
    dispatch(setUsageData(subscriptionUsageData));
    dispatch(setUserSettings(userMetadata.settings));
    dispatch(setIsUsageDataLoaded(true));
    dispatch(
      updateIsIncognitoModeActive(!!userMetadata.is_incognito_mode_active)
    );
    dispatch(
      updateResearchHubBannerHidden(
        !!userMetadata.is_research_hub_banner_hidden
      )
    );
  }, [userMetadata]);

  // Check for out-of-sync Clerk user metadata and mark a resync to Clerk if needed
  useEffect(() => {
    if (isSubscriptionLoaded && isUserMetadataLoaded && userMetadata) {
      const isMetadataInSyncWithSubscription = isUserMetadataInSync(
        userMetadata,
        subscription
      );
      if (!isMetadataInSyncWithSubscription) {
        dispatch(setShouldUpdateClerk(true));
      }
    }
  }, [userMetadata, subscription, isSubscriptionLoaded, isUserMetadataLoaded]);

  return null;
};

export default HandleSubscriptionAndUsage;

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  isChecked: boolean;
  onToggle?: () => void;
  label?: string;
  className?: string;
  checkboxSize?: "sm" | "md";
}

const Checkbox: React.FC<CheckboxProps> = ({
  isChecked,
  onToggle = () => {},
  label,
  className = "",
  checkboxSize = "md",
  ...props
}) => {
  const handleOnClick = (e: React.MouseEvent<HTMLLabelElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onToggle();
  };

  return (
    <label
      onClick={handleOnClick}
      className={`flex items-center gap-2 cursor-pointer ${className}`}
    >
      <input
        type="checkbox"
        checked={isChecked}
        className="hidden"
        readOnly
        {...props}
      />
      <span
        className={`cursor-pointer rounded border-border-base flex items-center justify-center ${
          isChecked ? "bg-accent-base" : "bg-white border"
        } ${checkboxSize === "sm" ? "w-4 h-4" : "w-5 h-5"}`}
      >
        {isChecked && (
          <i
            className={`text-white icon-check ${
              checkboxSize === "sm" && "text-sm"
            }`}
          />
        )}
      </span>
      {label && <span className="sm-md">{label}</span>}
    </label>
  );
};

export default Checkbox;

import classNames from "classnames";

type NumberInputProps = {
  value?: number;
  onChange: (value?: number) => void;
  placeholder?: string;
  min?: number;
  className?: string;
};

/**
 * @component NumberInput
 * @description A number input used on the filter drawer form.
 */
function NumberInput({
  value,
  onChange,
  placeholder = "Enter a number",
  min = 1,
  className,
}: NumberInputProps) {
  // Prevents invalid characters from being entered into the input field.
  // This ensures only positive integers can be input.
  const handlePositiveIntegerOnly = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (["e", ".", "-", " "].includes(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <input
      placeholder={placeholder}
      className={classNames("p-2 h-[50px] w-full border rounded-md", className)}
      type="number"
      min={min}
      value={value ?? ""}
      onChange={(e) =>
        onChange(e.target.value ? parseInt(e.target.value) : undefined)
      }
      onKeyDown={handlePositiveIntegerOnly}
    />
  );
}

export default NumberInput;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DEFAULT_LANG } from "constants/config";
import { LangCodeType } from "helpers/language";
import { ISubscriptionUsageData } from "helpers/subscription";

interface UserSettingsType {
  lang?: LangCodeType;
}

interface UserStateType {
  shouldUpdateClerk: boolean;
  settings: UserSettingsType;
  usage: ISubscriptionUsageData;
  isUserMetadataLoaded: boolean;
  isIncognitoModeActive: boolean;
  isResearchHubBannerHidden: boolean;
}

const initialState = {
  shouldUpdateClerk: false,
  isUserMetadataLoaded: false,
  settings: {},
  isIncognitoModeActive: false,
  isResearchHubBannerHidden: true,
  usage: {
    userCreatedDate: new Date().toISOString(),
    userLastResetDate: new Date().toISOString(),
    studySnapshotUsedPaperIds: [],
    consensusSnapshotUsedQueries: [],
    proAnalysisUsedQueries: [],
    askPaperMessagesPerPaperCount: {},
    isSet: false,
    stripeCustomerId: undefined,
  },
} as UserStateType;

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setSearchLanguage(
      state: UserStateType,
      { payload }: PayloadAction<LangCodeType>
    ) {
      state.shouldUpdateClerk = true;
      state.settings.lang = payload !== DEFAULT_LANG ? payload : undefined;
    },
    setShouldUpdateClerk(
      state: UserStateType,
      { payload }: PayloadAction<boolean>
    ) {
      state.shouldUpdateClerk = payload;
    },
    setUserSettings(
      state: UserStateType,
      { payload }: PayloadAction<UserSettingsType>
    ) {
      // Fix for bad language code push.
      if (payload && payload.lang === "ch") {
        state.settings = { ...payload, lang: "zh" };
      } else {
        state.settings = payload || {};
      }
    },
    setUsageData(
      state: UserStateType,
      { payload }: PayloadAction<ISubscriptionUsageData>
    ) {
      state.usage = payload;
    },
    setIsUsageDataLoaded(
      state: UserStateType,
      { payload }: PayloadAction<boolean>
    ) {
      state.isUserMetadataLoaded = payload;
    },
    appendStudyDetailsUsedPaperId(
      state: UserStateType,
      { payload }: PayloadAction<string>
    ) {
      if (!state.usage.studySnapshotUsedPaperIds.includes(payload)) {
        state.shouldUpdateClerk = true;
        state.usage.studySnapshotUsedPaperIds.push(payload);
      }
    },
    appendProAnalysisUsedQuery(
      state: UserStateType,
      { payload }: PayloadAction<string>
    ) {
      if (!state.usage.proAnalysisUsedQueries.includes(payload)) {
        state.shouldUpdateClerk = true;
        state.usage.proAnalysisUsedQueries.push(payload);
      }
    },
    appendConsensusSnapshotUsedQuery(
      state: UserStateType,
      { payload }: PayloadAction<string>
    ) {
      if (!state.usage.consensusSnapshotUsedQueries.includes(payload)) {
        state.shouldUpdateClerk = true;
        state.usage.consensusSnapshotUsedQueries.push(payload);
      }
    },
    updateAskPaperMessageCountForPaper(
      state: UserStateType,
      { payload }: PayloadAction<{ [key: string]: number }>
    ) {
      state.shouldUpdateClerk = true;
      state.usage.askPaperMessagesPerPaperCount = {
        ...state.usage.askPaperMessagesPerPaperCount,
        ...payload,
      };
    },
    updateIsIncognitoModeActive(
      state: UserStateType,
      { payload }: PayloadAction<boolean>
    ) {
      state.shouldUpdateClerk = true;
      state.isIncognitoModeActive = payload;
    },
    updateResearchHubBannerHidden(
      state: UserStateType,
      { payload }: PayloadAction<boolean>
    ) {
      state.shouldUpdateClerk = true;
      state.isResearchHubBannerHidden = payload;
    },
  },
});

export const {
  appendConsensusSnapshotUsedQuery,
  appendProAnalysisUsedQuery,
  appendStudyDetailsUsedPaperId,
  setIsUsageDataLoaded,
  setSearchLanguage,
  setShouldUpdateClerk,
  setUsageData,
  setUserSettings,
  updateAskPaperMessageCountForPaper,
  updateIsIncognitoModeActive,
  updateResearchHubBannerHidden,
} = userSlice.actions;

export default userSlice.reducer;

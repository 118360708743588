import { countries } from "helpers/sharedDataConstants";
import { Filter, Filters } from "store/slices/search";
import SearchableCheckboxList from "./SearchableCheckboxList";

type CountryInputProps = {
  changePendingFilter: <T extends Filter>(filter: T, value: Filters[T]) => void;
  pendingFilters: Filters;
};

/**
 * @component CountryInput
 * @description A searchable list of checkboxes to filter countries
 */
function CountryInput({
  changePendingFilter,
  pendingFilters,
}: CountryInputProps) {
  return (
    <SearchableCheckboxList
      items={countries}
      selectedItems={pendingFilters.countries ?? []}
      onChange={(updatedCountries) =>
        changePendingFilter("countries", updatedCountries)
      }
      getLabel={(country) => country.displayName}
    />
  );
}

export default CountryInput;

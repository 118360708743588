import Popover from "components/Popover";
import StudyTypeTooltip from "components/Popover/StudyTypeTooltip/StudyTypeTooltip";
import { OBSERVATIONAL_STUDY_URL } from "constants/config";
import useLabels from "hooks/useLabels";
import React from "react";
import { Tag } from "./Tag";

interface ObservationalStudyTagProps {
  pressable?: boolean;
}

function ObservationalStudyTag(props?: ObservationalStudyTagProps) {
  const [pageLabels] = useLabels("tooltips.observational-study");

  return (
    <Popover
      interactive
      maxWidth={306}
      tooltipContent={
        <StudyTypeTooltip
          title={pageLabels.title}
          content={pageLabels.content}
          icon="/icons/observational-study.svg"
          iconPyramid="middle"
          learnMoreLink={OBSERVATIONAL_STUDY_URL}
        />
      }
    >
      <Tag
        variant="white"
        data-testid="observational-study-tag"
        className="border-yellow-base"
        onClick={(e) => {
          if (!props?.pressable) {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
      >
        <img
          alt="Info"
          src="/icons/observational-study.svg"
          width="20px"
          height="20px"
        />
        <span>{pageLabels["tag"]}</span>
      </Tag>
    </Popover>
  );
}

export default ObservationalStudyTag;

import { FeatureFlag as FeatureFlagEnum } from "enums/feature-flag";
import { useAppDispatch, useAppSelector } from "hooks/useStore";
import { updateIsIncognitoModeActive } from "store/slices/user";
import useIsFeatureEnabled from "./useIsFeatureEnabled";

export const useIncognitoMode = () => {
  const isIncognitoModeFeatureEnabled = useIsFeatureEnabled(
    FeatureFlagEnum.INC_MODE
  );
  const isIncognitoModeActive = useAppSelector(
    (state) => state.user.isIncognitoModeActive
  );
  const dispatch = useAppDispatch();

  const toggleIncognitoMode = async () => {
    if (!isIncognitoModeFeatureEnabled) return;

    const newIncognitoMode = !isIncognitoModeActive;
    dispatch(updateIsIncognitoModeActive(newIncognitoMode));
  };

  return {
    isIncognitoModeActive:
      isIncognitoModeFeatureEnabled && isIncognitoModeActive,
    toggleIncognitoMode,
  };
};

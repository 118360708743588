import {
  useAuth,
  useOrganization,
  useOrganizationList,
  useUser,
} from "@clerk/nextjs";
import { SetActive } from "@clerk/types";
import { useQuery } from "@tanstack/react-query";
import { OrganizationRole } from "helpers/organization";
import { getTeamSubscriptionActiveStatus } from "helpers/subscription";
import { useEffect, useRef, useState } from "react";

const MAX_MEMBERS_COUNT = 200;

const useOrg = () => {
  const { isLoaded: isUserLoaded, user } = useUser();
  const { orgRole } = useAuth();
  const { organization } = useOrganization();
  const { setActive } = useOrganizationList();
  const [orgMembers, setOrgMembers] = useState<{
    isLoading: boolean;
    currentMembersCount?: number;
  }>({ isLoading: false });

  const orgIdRef = useRef<string>();

  const hasOrganization = !!organization;
  const getTeamSubscriptionStatusQuery = useQuery(
    ["organization-active-status", organization?.id],
    () => {
      if (organization) {
        return getTeamSubscriptionActiveStatus(organization?.id);
      }
    },
    {
      enabled: hasOrganization,
    }
  );

  useEffect(() => {
    const loadActiveOrgMembersState = async () => {
      if (isUserLoaded) {
        const activeOrganization =
          organization || user?.organizationMemberships?.[0]?.organization;
        const activeRole = orgRole || user?.organizationMemberships?.[0]?.role;
        if (activeOrganization && activeOrganization.id !== orgIdRef.current) {
          orgIdRef.current = activeOrganization.id;
          setOrgMembers({ isLoading: true });
          if (!organization) {
            await (setActive as SetActive)({
              organization: activeOrganization.id,
            });
          }
          if (
            [OrganizationRole.OWNER, OrganizationRole.ADMIN].includes(
              activeRole as OrganizationRole
            )
          ) {
            const { total_count: membersCount } =
              await activeOrganization?.getMemberships({
                pageSize: MAX_MEMBERS_COUNT,
              });
            const { total_count: invitationsCount } =
              await activeOrganization?.getInvitations({
                status: ["pending"],
                pageSize: MAX_MEMBERS_COUNT,
              });
            setOrgMembers({
              isLoading: false,
              currentMembersCount: membersCount + invitationsCount,
            });
          } else {
            setOrgMembers({ isLoading: false });
          }
        }
      }
    };
    loadActiveOrgMembersState();
  }, [
    organization,
    user?.organizationMemberships,
    setActive,
    orgRole,
    isUserLoaded,
  ]);

  const maxMemberships = organization?.maxAllowedMemberships as number;
  const availableSeats = maxMemberships - (orgMembers.currentMembersCount || 0);

  return {
    loading:
      !isUserLoaded ||
      orgMembers.isLoading ||
      (hasOrganization && getTeamSubscriptionStatusQuery.isLoading),
    currentMembersCount: orgMembers.currentMembersCount,
    purchasedSeats: maxMemberships,
    organization,
    availableSeats,
    orgRole: orgRole || user?.organizationMemberships?.[0]?.role,
    isPaused: !getTeamSubscriptionStatusQuery.data?.active,
  };
};

export default useOrg;

import { ButtonVariant, CoButton } from "components/CoButton";
import { SALES_EMAIL } from "constants/config";
import useLabels from "hooks/useLabels";
import React from "react";

function TeamsSubscriptionUpgradeCard() {
  const [upgradePlanLabels] = useLabels("upgrade-plan-card");

  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-col flex-1 gap-4">
        <h3 className="lg-bold">{upgradePlanLabels["title-teams"]}</h3>
        <span className="text-fg-muted">
          {upgradePlanLabels["subtitle-teams"]}
        </span>
      </div>
      <div className="flex gap-1">
        <CoButton
          className="flex-1"
          href={"mailto:" + SALES_EMAIL}
          label={upgradePlanLabels["contact-us"]}
          size="sm"
          variant={ButtonVariant.Tertiary}
        />
        <CoButton
          href="/pricing"
          label={upgradePlanLabels["see-plans"]}
          size="sm"
          variant={ButtonVariant.Flat}
        />
      </div>
    </div>
  );
}

export default TeamsSubscriptionUpgradeCard;

import { useUser } from "@clerk/nextjs";
import Tooltip, { Placement } from "components/Popover";
import useCredits from "hooks/useCredits";
import { useIncognitoMode } from "hooks/useIncognitoMode";
import React, { useState } from "react";
import IncognitoModeUserPicture from "./IncognitoModeUserPicture";
import UserMenuContents from "./UserMenuContents";

function UserButton({
  offset,
}: {
  offset?: [number, number];
  placement?: Placement;
}) {
  const { user } = useUser();
  const { subscriptionImgSrc, isPremium } = useCredits();
  const [isOpen, setIsOpen] = useState(false);
  const { isIncognitoModeActive } = useIncognitoMode();

  return (
    <Tooltip
      interactive
      tooltipContent={<UserMenuContents />}
      className="p-0 m-0"
      offset={offset}
      placement="top"
      trigger="click"
      onShow={() => setIsOpen(true)}
      onHide={() => setIsOpen(false)}
      contentWidthFull
    >
      <button
        data-testid="user-button"
        type="button"
        id="user-button"
        className="relative flex items-center rounded-full hover:shadow-button"
      >
        <div
          className={`flex items-center justify-center px-4 py-4 rounded-lg ${
            isOpen ? "bg-bgr-faint" : "hover:bg-bgr-faint"
          }`}
        >
          <div
            className={`relative flex items-center justify-center w-6 h-6 rounded-full hover:bg-bgr-strong ${
              isOpen ? "bg-bgr-strong" : "bg-bgr-faint"
            }`}
          >
            {isIncognitoModeActive ? (
              <IncognitoModeUserPicture />
            ) : user?.imageUrl ? (
              <img
                src={user.imageUrl}
                className="rounded-full"
                alt="user profile"
              />
            ) : (
              <span>
                <i className="icon-user" />
              </span>
            )}
            {isPremium && (
              <span className="absolute flex items-center justify-center w-4 h-4 bg-white rounded-full -top-1 -right-1">
                <img
                  src={subscriptionImgSrc}
                  alt="premium"
                  className="w-3 h-3"
                />
              </span>
            )}
          </div>
        </div>
      </button>
    </Tooltip>
  );
}

export default UserButton;

import classNames from "classnames";
import { FeatureFlag } from "enums/feature-flag";
import { SearchHistory } from "helpers/api";
import { useIncognitoMode } from "hooks/useIncognitoMode";
import useIsFeatureEnabled from "hooks/useIsFeatureEnabled";
import SearchHistoryItem, { isUploadedPaperUrl } from "./SearchHistoryItem";

type SearchHistoryGroupProps = {
  label: string;
  onDelete: (id: string) => void;
  onNavigate: () => void;
  onSave: (searchHistory: SearchHistory) => void;
  searchHistories: SearchHistory[];
};

function SearchHistoryGroup({
  label,
  searchHistories,
  onNavigate,
  onSave,
  onDelete,
}: SearchHistoryGroupProps) {
  const { isIncognitoModeActive } = useIncognitoMode();
  const isResearchHubEnabled = useIsFeatureEnabled(FeatureFlag.RESEARCH_HUB);

  const filteredSearchHistories = isResearchHubEnabled
    ? searchHistories
    : searchHistories.filter(
        (searchHistory) => !isUploadedPaperUrl(searchHistory.full_url)
      );

  if (filteredSearchHistories.length === 0) {
    return null;
  }

  return (
    <div className="w-full overflow-hidden">
      <h2
        className={classNames(
          "pl-4 mb-2 mt-4 tiny-md text-fg-muted",
          isIncognitoModeActive && "opacity-50"
        )}
      >
        {label}
      </h2>
      <ol className="w-full overflow-hidden">
        {filteredSearchHistories.map((searchHistory) => (
          <SearchHistoryItem
            key={searchHistory.id}
            onDelete={onDelete}
            onNavigate={onNavigate}
            onSave={onSave}
            searchHistory={searchHistory}
          />
        ))}
      </ol>
    </div>
  );
}

export default SearchHistoryGroup;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BookmarkType, IBookmarkItem } from "helpers/bookmark";

export interface SaveBookmarkState {
  bookmarkType: BookmarkType;
  paperId?: string;
  searchUrl?: string;
  threadId?: number;
  uploadedPaperId?: string;
}

export type BookmarkItemsType = {
  [key: string]: IBookmarkItem[];
};

interface BookmarkState {
  targetedListsForNewBookmarkItem: string[];
  saveBookmarkState: SaveBookmarkState;
  isSaveBookmarkModalOpen: boolean;
}

const initialState = {
  targetedListsForNewBookmarkItem: [],
  saveBookmarkState: {
    bookmarkType: BookmarkType.PAPER,
    paperId: undefined,
    searchUrl: undefined,
    threadId: undefined,
    uploadedPaperId: undefined,
  },
  isSaveBookmarkModalOpen: false,
} as BookmarkState;

const bookmarkSlice = createSlice({
  name: "bookmark",
  initialState,
  reducers: {
    clearListTargetsForNewBookmarkItem(state: BookmarkState) {
      state.targetedListsForNewBookmarkItem = [];
    },

    addListTargetForNewBookmarkItem(
      state: BookmarkState,
      { payload }: PayloadAction<string>
    ) {
      if (state.targetedListsForNewBookmarkItem.includes(payload) == false) {
        state.targetedListsForNewBookmarkItem.push(payload);
      }
    },

    removeListTargetForNewBookmarkItem(
      state: BookmarkState,
      { payload }: PayloadAction<string>
    ) {
      const index = state.targetedListsForNewBookmarkItem.indexOf(payload, 0);
      if (index > -1) {
        state.targetedListsForNewBookmarkItem.splice(index, 1);
      }
    },

    setSaveBookmarkState(
      state: BookmarkState,
      { payload }: PayloadAction<SaveBookmarkState>
    ) {
      state.saveBookmarkState = payload;
    },
    setIsSaveBookmarkModalOpen(
      state: BookmarkState,
      { payload }: PayloadAction<boolean>
    ) {
      state.isSaveBookmarkModalOpen = payload;
    },
  },
});

export const {
  addListTargetForNewBookmarkItem,
  clearListTargetsForNewBookmarkItem,
  setSaveBookmarkState,
  removeListTargetForNewBookmarkItem,
  setIsSaveBookmarkModalOpen,
} = bookmarkSlice.actions;
export default bookmarkSlice.reducer;

import { ALL_DOMAINS } from "constants/filters";
import { Filter, Filters } from "store/slices/search";
import SearchableCheckboxList from "./SearchableCheckboxList";

type DomainInputProps = {
  changePendingFilter: <T extends Filter>(filter: T, value: Filters[T]) => void;
  pendingFilters: Filters;
};

/**
 * @component DomainInput
 * @description A searchable list of checkboxes to filter by domain
 */
function DomainInput({
  changePendingFilter,
  pendingFilters,
}: DomainInputProps) {
  return (
    <SearchableCheckboxList
      items={ALL_DOMAINS}
      selectedItems={pendingFilters.domains ?? []}
      onChange={(updatedDomains) =>
        changePendingFilter("domains", updatedDomains)
      }
      getLabel={(domain) => domain} // Domains are already strings
    />
  );
}

export default DomainInput;

import { HIERARCHY_OF_EVIDENCE_URL } from "constants/config";
import useLabels from "hooks/useLabels";
import React from "react";

/**
 * @component StudyTypesFilterTooltip
 * @description Tooltip for case study
 * @example
 * return (
 *   <StudyTypesFilterTooltip />
 * )
 */
const StudyTypesFilterTooltip = () => {
  const [tooltipsLabels] = useLabels("tooltips.study-types");

  return (
    <div
      className="flex flex-col gap-3"
      data-testid="tooltip-study-types-filter"
    >
      <div className="flex items-center gap-3">
        <img className="w-6" alt="Sparkler" src="/icons/sparkler.svg" />
        <p className="sm-bold">{tooltipsLabels["title"]}</p>
      </div>
      <p
        className="sm-normal"
        dangerouslySetInnerHTML={{
          __html: tooltipsLabels["content"].replace(
            "HIERARCHY_OF_EVIDENCE_URL",
            HIERARCHY_OF_EVIDENCE_URL
          ),
        }}
      ></p>
      <p className="tiny-normal text-fg-muted">{tooltipsLabels["footer"]}</p>
      <div className="grid grid-cols-4 pt-3 border-t border-t-border-base">
        <span className="justify-self-center">
          <img src="/icons/pyramid-green-outline.svg" alt="green-pyramid" />
        </span>
        <span className="tiny-normal col-span-3 text-fg-faint text-right">
          {tooltipsLabels["green-indicator-desc"]}
        </span>
        <span className="justify-self-center">
          <img src="/icons/pyramid-yellow-outline.svg" alt="green-pyramid" />
        </span>
        <span className="tiny-normal col-span-3 text-fg-faint text-right">
          {tooltipsLabels["yellow-indicator-desc"]}
        </span>
        <span className="justify-self-center">
          <img src="/icons/pyramid-red-outline.svg" alt="green-pyramid" />
        </span>
        <span className="tiny-normal col-span-3 text-fg-faint text-right">
          {tooltipsLabels["red-indicator-desc"]}
        </span>
      </div>
    </div>
  );
};

export default StudyTypesFilterTooltip;

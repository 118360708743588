import AnimalStudyTag from "components/Tag/AnimalTrialTag";
import CaseStudyTag from "components/Tag/CaseStudyTag";
import InVitroStudyTag from "components/Tag/InVitroTrialTag";
import LiteratureReviewTag from "components/Tag/LiteratureReviewTag";
import MetaAnalysisTag from "components/Tag/MetaAnalysisTag";
import NonRctTag from "components/Tag/NonRctTrialTag";
import ObservationalStudyTag from "components/Tag/ObservationalStudyTag";
import RctTag from "components/Tag/RctTag";
import SystematicReviewTag from "components/Tag/SystematicReviewTag";
import { StudyType } from "./api";

export const StudyTypesFilterOptions = [
  {
    value: StudyType.META_ANALYSIS,
    elementTag: <MetaAnalysisTag pressable key={StudyType.META_ANALYSIS} />,
    label: "Meta-Analysis",
  },
  {
    value: StudyType.SYSTEMATIC_REVIEW,
    elementTag: (
      <SystematicReviewTag pressable key={StudyType.SYSTEMATIC_REVIEW} />
    ),
    label: "Systematic Review",
  },
  {
    value: StudyType.RCT,
    elementTag: <RctTag pressable key={StudyType.RCT} />,
    label: "RCT",
  },
  {
    value: StudyType.NON_RCT_TRIAL,
    elementTag: <NonRctTag pressable key={StudyType.NON_RCT_TRIAL} />,
    label: "Non-RCT",
  },
  {
    value: StudyType.OBSERVATIONAL_STUDY,
    elementTag: (
      <ObservationalStudyTag pressable key={StudyType.OBSERVATIONAL_STUDY} />
    ),
    label: "Observational",
  },
  {
    value: StudyType.LITERATURE_REVIEW,
    elementTag: (
      <LiteratureReviewTag pressable key={StudyType.LITERATURE_REVIEW} />
    ),
    label: "Literature Review",
  },
  {
    value: StudyType.CASE_STUDY,
    elementTag: <CaseStudyTag pressable key={StudyType.CASE_STUDY} />,
    label: "Case Report",
  },
  {
    value: StudyType.ANIMAL_STUDY,
    elementTag: <AnimalStudyTag pressable key={StudyType.ANIMAL_STUDY} />,
    label: "Animal Study",
  },
  {
    value: StudyType.IN_VITRO_TRIAL,
    elementTag: <InVitroStudyTag pressable key="in_vitro" />,
    label: "In Vitro Study",
  },
];

export const studyTypeBadges = [
  {
    value: StudyType.META_ANALYSIS,
    elementTag: <MetaAnalysisTag pressable key="meta_analysis" />,
    label: "Meta-Analysis",
  },
  {
    value: StudyType.SYSTEMATIC_REVIEW,
    elementTag: <SystematicReviewTag pressable key="systemic_review" />,
    label: "Systematic Review",
  },
  {
    value: StudyType.RCT,
    elementTag: <RctTag pressable key="rct" />,
    label: "RCT",
  },
  {
    value: StudyType.NON_RCT_TRIAL,
    elementTag: <NonRctTag pressable key="non_rct" />,
    label: "Non-RCT",
  },
  {
    value: StudyType.OBSERVATIONAL_STUDY,
    elementTag: <ObservationalStudyTag pressable key="observational" />,
    label: "Observational",
  },
  {
    value: StudyType.LITERATURE_REVIEW,
    elementTag: <LiteratureReviewTag pressable key="lit_review" />,
    label: "Literature Review",
  },
  {
    value: StudyType.CASE_STUDY,
    elementTag: <CaseStudyTag pressable key="case_study" />,
    label: "Case Report",
  },
  {
    value: "animal",
    elementTag: <AnimalStudyTag pressable key="animal" />,
    label: "Animal Study",
  },
  {
    value: StudyType.IN_VITRO_TRIAL,
    elementTag: <InVitroStudyTag pressable key="in_vitro" />,
    label: "In Vitro Study",
  },
];

import { Domain } from "constants/filters";

export const DomainToQueryParam: Record<Domain, string> = {
  [Domain.AgriculturalAndFoodSciences]: "agri",
  [Domain.Art]: "art",
  [Domain.Biology]: "bio",
  [Domain.Business]: "bus",
  [Domain.Chemistry]: "chem",
  [Domain.ComputerScience]: "cs",
  [Domain.Economics]: "econ",
  [Domain.Education]: "edu",
  [Domain.Engineering]: "eng",
  [Domain.EnvironmentalScience]: "env",
  [Domain.Geography]: "geog",
  [Domain.Geology]: "geol",
  [Domain.History]: "hist",
  [Domain.Law]: "law",
  [Domain.Linguistics]: "ling",
  [Domain.MaterialsScience]: "mat",
  [Domain.Mathematics]: "math",
  [Domain.Medicine]: "med",
  [Domain.Philosophy]: "philo",
  [Domain.Physics]: "phys",
  [Domain.PoliticalScience]: "poli",
  [Domain.Psychology]: "psych",
  [Domain.Sociology]: "soc",
};

export const getQueryParamsFromDomains = (domains?: Domain[]) => {
  if (domains === undefined || domains.length === 0) return undefined;
  return domains.map((domain) => DomainToQueryParam[domain]).join(",");
};

export const getDomainsFromQueryParams = (
  paramValue?: string
): Domain[] | undefined => {
  if (!paramValue) return undefined;

  return paramValue
    .split(",")
    .map(
      (shortCode) =>
        Object.entries(DomainToQueryParam).find(
          ([, code]) => code === shortCode
        )?.[0] as Domain
    )
    .filter(Boolean); // Remove undefined values
};

import { SubscriptionPlan } from "enums/subscription-plans";
import { formatCurrency } from "helpers/format";
import React, { useMemo } from "react";
import LoadingSubscriptionCard from "../LoadingSubscriptionCard/LoadingSubscriptionCard";
import EnterpriseSubscriptionUpgradeCard from "./EnterpriseSubscriptionUpgradeCard";
import FreeSubscriptionUpgradeCard from "./FreeSubscriptionUpgradeCard";
import PremiumSubscriptionUpgradeCard from "./PremiumSubscriptionUpgradeCard";
import TeamsSubscriptionUpgradeCard from "./TeamsSubscriptionUpgradeCard";

type Props = {
  currentPlan: SubscriptionPlan;
  priceToUpgrade: any;
  isLoading?: boolean;
  onClick?: () => void;
};

/**
 * @component SubscriptionUpgradeCard
 * @description designed for subscription upgrade card
 * @example
 * return (
 *   <SubscriptionUpgradeCard>Learn more</SubscriptionUpgradeCard>
 * )
 */
const SubscriptionUpgradeCard = ({
  currentPlan,
  priceToUpgrade,
  isLoading,
  onClick,
}: Props) => {
  const priceText = useMemo(() => {
    if (!priceToUpgrade) {
      return;
    }
    if (!priceToUpgrade.tiers_mode) {
      return formatCurrency(priceToUpgrade.unit_amount / 12 / 100);
    } else if (priceToUpgrade.tiers_mode == "volume") {
      const prices = priceToUpgrade.tiers
        ?.slice()
        .sort((a: any, b: any) => a.unit_amount - b.unit_amount);
      const priceAmount = prices[0].unit_amount / 12;

      return formatCurrency(priceAmount / 100);
    }
    return;
  }, [priceToUpgrade]);

  if (isLoading) {
    return <LoadingSubscriptionCard />;
  }

  if (currentPlan === SubscriptionPlan.ENTERPRISE)
    return <EnterpriseSubscriptionUpgradeCard />;
  else if (currentPlan === SubscriptionPlan.TEAM)
    return <TeamsSubscriptionUpgradeCard />;
  else if (currentPlan === SubscriptionPlan.PREMIUM)
    return (
      <PremiumSubscriptionUpgradeCard price={priceText} onClick={onClick!} />
    );
  else
    return <FreeSubscriptionUpgradeCard price={priceText} onClick={onClick!} />;
};

export default SubscriptionUpgradeCard;

import classNames from "classnames";
import React, { useEffect, useState } from "react";

type SwitchProps = {
  onChange?: (state: boolean) => void;
  active?: boolean;
  disabled?: boolean;
  className?: string;
};

function Switch({
  onChange = () => {},
  active = false,
  disabled = false,
  className,
}: SwitchProps) {
  const [isActive, setIsActive] = useState(active);

  const toggleIsActive = () => {
    if (!disabled) {
      setIsActive(!isActive);
      onChange(!isActive);
    }
  };

  useEffect(() => {
    // Update state if change made after initializatin of componenet
    setIsActive(active);
  }, [active]);

  return (
    <button
      className={classNames(
        "transition w-[34px] h-[20px] rounded-full",
        disabled
          ? isActive
            ? "bg-accent-faint shadow-switchOn"
            : "bg-bgr-subtle shadow-switchOff"
          : isActive
          ? "bg-accent-subtle shadow-switchOn"
          : "bg-border-emphasis shadow-switchOff",
        className || ""
      )}
      type="button"
      role="switch"
      aria-checked={isActive}
      aria-disabled={disabled}
      data-state={isActive ? "on" : "off"}
      data-testid="switch"
      disabled={disabled}
      onClick={toggleIsActive}
    >
      <div
        className={classNames(
          "w-full h-full flex items-center p-1",
          isActive ? "justify-end" : ""
        )}
      >
        <div
          className={classNames(
            "transition w-[12px] h-[12px] rounded-full",
            disabled
              ? isActive
                ? "bg-accent-subtle"
                : "bg-bgr-faint"
              : isActive
              ? "bg-accent-base"
              : "bg-white"
          )}
        />
      </div>
    </button>
  );
}

export default Switch;

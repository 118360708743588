import { UserResource } from "@clerk/types";
import { MIXPANEL_API_KEY, MIXPANEL_PROXY_API_HOST } from "constants/config";
import verifyIsUploadedPaper from "features/ResearchHub/utils/useUploadedPaper";
import { Paper, trackEventServerSide } from "helpers/api";
import mixpanel from "mixpanel-browser";
import { store } from "../../../store";
import {
  MixpanelActionSource,
  MixpanelCiteType,
  MixpanelEvent,
  MixpanelEventVersion,
  MixpanelExportFileType,
  MixpanelFullTextLinkType,
  MixpanelNavigationSource,
  MixpanelPaperSource,
  RelatedSearchClickEvent,
} from "./events";
import {
  buildPaperEventSearchProperties,
  buildPaperProperties,
  buildResultProperties,
  buildSearchResultsProperties,
  buildSearchSettingsProperties,
  getActionSource,
  getNavigationSource,
} from "./util";

let clientSideMixpanelInitialized = false;

export function getMajorVersion(): MixpanelEventVersion {
  return MixpanelEventVersion.PaperSearch;
}

/** Initializes mixpanel tracking. */
export function initialize(): void {
  if (MIXPANEL_API_KEY) {
    mixpanel.init(MIXPANEL_API_KEY, {
      debug: false,
      // Our privacy policy states that we do not follow DNT, so ok to disable here
      ignore_dnt: true,
      api_host: MIXPANEL_PROXY_API_HOST,
    });
    clientSideMixpanelInitialized = true;
  }
}

/** Associates all logged events with a custom user id. */
export function identifyUser(user: UserResource): void {
  if (!clientSideMixpanelInitialized) return;
  mixpanel.identify(user.id);
}

/** Adds a custom user and properties to the mixpanel system. */
export function registerUser(signupMethod: string): void {
  if (!clientSideMixpanelInitialized) return;
  const state = store.getState();
  const user = state.analytic.user;
  if (user) {
    // Alias should only be called once when a user signs up.
    mixpanel.alias(user.id);
    mixpanel.people.set_once({
      $name: user.id,
      signUpDate: user.createdAt
        ? new Date(user.createdAt).toISOString()
        : null,
      signUpAuthMethod: signupMethod,
    });
  }
}

/** User views a page. */
export function logPageViewEvent(data: { route: string; pageTitle: string }) {
  // Client side logging of page view (for debugging)
  if (clientSideMixpanelInitialized) {
    mixpanel.track(MixpanelEvent.PageViewBrowser, {
      pageType: data.route,
      pageTitle: data.pageTitle,
      version: getMajorVersion(),
    });
  }
}

/** User succesfully logs in. */
export function logUserLoginEvent(authMethod: string) {
  trackEventServerSide({
    event: MixpanelEvent.LogIn,
    authMethod,
    version: getMajorVersion(),
  });
}

/** User succesfully logs in. */
export function logUserLogoutEvent() {
  trackEventServerSide({
    event: MixpanelEvent.LogOut,
    version: getMajorVersion(),
  });
}

/** User searches from the search bar. */
export function logSearchEvent() {
  trackEventServerSide({
    event: MixpanelEvent.Search,
    version: getMajorVersion(),
    ...buildSearchSettingsProperties(),
    navigationSource: getNavigationSource(),
  });
}

export function logPaperViewEvent() {
  trackEventServerSide({
    event: MixpanelEvent.PaperView,
    version: getMajorVersion(),
    ...buildPaperProperties(),
    navigationSource: getNavigationSource(),
  });
}

const determinePaperSource = async (paperId: string) => {
  const isUploadedPaper = await verifyIsUploadedPaper(paperId);

  return isUploadedPaper
    ? MixpanelPaperSource.UserUpload
    : MixpanelPaperSource.ConsensusSearch;
};

export async function logAskPaperClickEvent(paperId: string) {
  const paperSource = await determinePaperSource(paperId);
  trackEventServerSide({
    event: MixpanelEvent.AskPaperClick,
    ...buildPaperProperties(),
    ...buildResultProperties(),
    ...buildPaperEventSearchProperties(),
    version: getMajorVersion(),
    actionSource: getActionSource(),
    navigationSource: getNavigationSource(),
    paperId,
    paperSource,
  });
}

export async function logAskPaperChatEvent(message: string, paperId: string) {
  const paperSource = await determinePaperSource(paperId);
  trackEventServerSide({
    event: MixpanelEvent.AskPaperChat,
    ...buildPaperProperties(),
    ...buildResultProperties(),
    ...buildPaperEventSearchProperties(),
    version: getMajorVersion(),
    actionSource: getActionSource(),
    navigationSource: getNavigationSource(),
    message: message,
    paperId: paperId,
    paperSource: paperSource,
  });
}

/** User clicks a result in the search results list. */
export function logClickResultItemEvent() {
  trackEventServerSide({
    event: MixpanelEvent.SearchResultsItemClick,
    ...buildPaperProperties(),
    ...buildResultProperties(),
    ...buildSearchResultsProperties(),
    ...buildSearchSettingsProperties(),
    version: getMajorVersion(),
    actionSource: getActionSource(),
  });
}

/** User clicks a result in the Pro analysis. */
export function logProAnalysisClickResultItemEvent(
  referenceLineNumber: number,
  numberOfReferenceLines: number,
  associatedSentence: string
) {
  trackEventServerSide({
    event: MixpanelEvent.SearchResultsItemClick,
    ...buildPaperProperties(),
    ...buildResultProperties(),
    ...buildSearchResultsProperties(),
    ...buildSearchSettingsProperties(),
    version: getMajorVersion(),
    actionSource: getActionSource(),
    referenceLineNumber,
    numberOfReferenceLines,
    associatedSentence,
  });
}

/** User clicks a related search. */
export function logClickRelatedSearchEvent(
  relatedQuery: string,
  resultNumber: number,
  pagePosition?: RelatedSearchClickEvent["pagePosition"]
) {
  trackEventServerSide({
    event: MixpanelEvent.RelatedSearchClick,
    ...buildSearchResultsProperties(),
    ...buildSearchSettingsProperties(),
    version: getMajorVersion(),
    relatedQuery: relatedQuery,
    resultNumber: resultNumber,
    pagePosition: pagePosition,
  });
}

/** User clicks one of the 4 share options. */
export function logExecuteSharePaperEvent(shareType: string) {
  trackEventServerSide({
    event: MixpanelEvent.PaperShare,
    shareType,
    actionSource: getActionSource(),
    ...buildPaperEventSearchProperties(),
    ...buildPaperProperties(),
    ...buildResultProperties(),
    version: getMajorVersion(),
  });
}

export function logFullTextClickEvent(linkType: MixpanelFullTextLinkType) {
  trackEventServerSide({
    event: MixpanelEvent.FullTextClick,
    linkType: linkType,
    ...buildPaperEventSearchProperties(),
    ...buildPaperProperties(),
    ...buildResultProperties(),
    version: getMajorVersion(),
  });
}

export function logSavePaperEvent() {
  trackEventServerSide({
    event: MixpanelEvent.PaperSave,
    ...buildPaperEventSearchProperties(),
    ...buildPaperProperties(),
    ...buildResultProperties(),
    version: getMajorVersion(),
    actionSource: getActionSource(),
  });
}

export function logStudySnapshotEvent() {
  trackEventServerSide({
    event: MixpanelEvent.StudySnapshot,
    ...buildPaperEventSearchProperties(),
    ...buildPaperProperties(),
    ...buildResultProperties(),
    version: getMajorVersion(),
    actionSource: getActionSource(),
  });
}

export function logSaveSearchEvent() {
  trackEventServerSide({
    event: MixpanelEvent.SaveSearch,
    ...buildSearchResultsProperties(),
    ...buildSearchSettingsProperties(),
    version: getMajorVersion(),
  });
}

export function logProAnalysisCopyEvent(citeType?: MixpanelCiteType) {
  trackEventServerSide({
    event: MixpanelEvent.ProAnalysisCopy,
    ...buildSearchResultsProperties(),
    ...buildSearchSettingsProperties(),
    version: getMajorVersion(),
    citeType,
  });
}

export function logCitePaperEvent(citeType: MixpanelCiteType) {
  trackEventServerSide({
    event: MixpanelEvent.PaperCite,
    citeType: citeType,
    ...buildPaperProperties(),
    ...buildResultProperties(),
    ...buildPaperEventSearchProperties(),
    version: getMajorVersion(),
    actionSource: getActionSource(),
  });
}

/** User shares results. */
export function logShareResultsEvent(shareType: string) {
  trackEventServerSide({
    event: MixpanelEvent.SearchResultsShare,
    ...buildSearchResultsProperties(),
    ...buildSearchSettingsProperties(),
    shareType,
    version: getMajorVersion(),
    actionSource: getActionSource(),
  });
}

export function logResultsViewEvent(
  items: Paper[],
  pageNumber: number,
  isLoadMore: boolean
) {
  trackEventServerSide({
    event: MixpanelEvent.SearchResultsView,
    ...buildSearchResultsProperties(),
    ...buildSearchSettingsProperties(),
    version: getMajorVersion(),
    navigationSource: getNavigationSource(),
    paperIds: items.map((item) => item.paper_id),
    pageNumber,
    isLoadMore,
  });
}

export function logCreateListEvent(listName: string) {
  trackEventServerSide({
    event: MixpanelEvent.CreateList,
    version: getMajorVersion(),
    listName: listName,
  });
}

export function logToggleProAnalysisEvent(value: boolean) {
  trackEventServerSide({
    event: MixpanelEvent.ToggleProAnalysis,
    ...buildPaperEventSearchProperties(),
    version: getMajorVersion(),
    value,
  });
}

export function logToggleYesNoSummaryTableEvent(value: boolean) {
  trackEventServerSide({
    event: MixpanelEvent.ToggleYesNoSummaryTable,
    ...buildPaperEventSearchProperties(),
    version: getMajorVersion(),
    value,
  });
}

export function logExportResultsEvent(
  actionSource?: MixpanelActionSource,
  fileType: MixpanelExportFileType = MixpanelExportFileType.CSV
) {
  trackEventServerSide({
    event: MixpanelEvent.ExportResults,
    ...buildSearchResultsProperties(),
    ...buildSearchSettingsProperties(),
    version: getMajorVersion(),
    actionSource: actionSource,
    fileType,
  });
}

/**
 * Track user clicking on announcement banner dismiss button
 */
export function logAnnouncementBannerDismiss() {
  trackEventServerSide({
    event: MixpanelEvent.DismissBanner,
    version: MixpanelEventVersion.ResearchHub,
    actionSource: MixpanelActionSource.ResearchHubAnnouncementBanner,
  });
}

/**
 * Track user clicking on announcement banner research hub link
 */
export function logAnnouncementBannerResearchHubLink() {
  trackEventServerSide({
    event: MixpanelEvent.ClickResearchHubLink,
    version: MixpanelEventVersion.ResearchHub,
    actionSource: MixpanelActionSource.ResearchHubAnnouncementBanner,
  });
}

/**
 * Track user deleting uploaded paper from research hub
 */
export function logDeleteUploadedPaperFromResearchHub(paperId: string) {
  trackEventServerSide({
    event: MixpanelEvent.DeleteUploadedPaper,
    version: MixpanelEventVersion.ResearchHub,
    actionSource: MixpanelActionSource.ResearchHub,
    navigationSource: MixpanelNavigationSource.External,
    paperId,
  });
}

/**
 * Track user deleting uploaded paper from details page
 */
export function logDeleteUploadedPaperFromDetailsPage(paperId: string) {
  trackEventServerSide({
    event: MixpanelEvent.DeleteUploadedPaper,
    version: MixpanelEventVersion.ResearchHub,
    actionSource: MixpanelActionSource.DetailsPage,
    navigationSource: MixpanelNavigationSource.External,
    paperId,
  });
}

/**
 * Tracking user downloading uploaded paper from research hub
 */
export function logDownloadUploadedPaper(paperId: string) {
  trackEventServerSide({
    event: MixpanelEvent.DownloadUploadedPaper,
    version: MixpanelEventVersion.ResearchHub,
    actionSource: MixpanelActionSource.ResearchHub,
    navigationSource: MixpanelNavigationSource.External,
    paperId,
  });
}

/**
 * Track user opening ask paper drawer via redirect
 */
export async function logAskPaperViaRedirect(paperId: string) {
  const paperSource = await determinePaperSource(paperId);
  trackEventServerSide({
    event: MixpanelEvent.AskPaperClick,
    version: getMajorVersion(),
    actionSource: MixpanelActionSource.Redirect,
    navigationSource: MixpanelNavigationSource.External,
    paperId,
    paperSource,
  });
}

/**
 * Track user opening ask paper drawer via details page
 */
export async function logAskPaperViaDetailsPage(paperId: string) {
  const paperSource = await determinePaperSource(paperId);
  trackEventServerSide({
    event: MixpanelEvent.AskPaperClick,
    version: getMajorVersion(),
    actionSource: MixpanelActionSource.DetailsPage,
    navigationSource: MixpanelNavigationSource.External,
    paperId,
    paperSource,
  });
}

/**
 * Track user opening ask paper drawer via ask paper result tag
 */
export async function logAskPaperViaResultTag(paperId: string) {
  const paperSource = await determinePaperSource(paperId);
  trackEventServerSide({
    event: MixpanelEvent.AskPaperClick,
    version: getMajorVersion(),
    actionSource: MixpanelActionSource.AskPaperResultTag,
    navigationSource: MixpanelNavigationSource.External,
    paperId,
    paperSource,
  });
}

/**
 * Track user opening ask paper drawer via uploaded paper details
 */
export async function logAskPaperViaUploadedPaperDetails(paperId: string) {
  const paperSource = await determinePaperSource(paperId);
  trackEventServerSide({
    event: MixpanelEvent.AskPaperClick,
    version: getMajorVersion(),
    actionSource: MixpanelActionSource.UploadedPaperDetails,
    navigationSource: MixpanelNavigationSource.External,
    paperId,
    paperSource,
  });
}

/**
 * Unified function to track paper upload events
 */
export function logUploadPaper(
  event:
    | MixpanelEvent.UploadPaperTriggered
    | MixpanelEvent.UploadPaperSuccess
    | MixpanelEvent.UploadPaperError,
  actionSource: MixpanelActionSource,
  errorMessage?: string
) {
  trackEventServerSide({
    event,
    version: MixpanelEventVersion.ResearchHub,
    actionSource,
    navigationSource: MixpanelNavigationSource.External,
    errorMessage,
  });
}

import { Combobox as Combo, Transition } from "@headlessui/react";
import classNames from "classnames";
import { Fragment, useEffect, useState } from "react";

export type OptionObj = { label: string; value: string; [key: string]: any };
export type OptionType = string | OptionObj;

type PropsType = {
  options: OptionType[];
  onChange: (item: OptionType) => void;
  defaultValue?: OptionType;
  placeholder?: string;
  onInputChange?: (value: string) => void;
  className?: string;
  inputFieldClassName?: string;
  key?: string;
};

function isString(x: any) {
  return typeof x === "string";
}

const getOptionDisplayValue = (option: OptionType) =>
  isString(option) ? (option as string) : (option as OptionObj).label;

export const Combobox = ({
  options,
  placeholder,
  className,
  inputFieldClassName,
  onChange,
  defaultValue,
  onInputChange,
  key,
}: PropsType) => {
  const [selected, setSelected] = useState<OptionType>(
    defaultValue || options[0]
  );

  const handleSelect = (item: OptionType) => {
    setSelected(item);
    onChange(item);
  };

  useEffect(() => {
    if (!isString(selected)) {
      const value = options.find(
        (option) =>
          (option as OptionObj).value === (selected as OptionObj).value
      );
      setSelected(value as OptionType);
    }
  }, [options, selected]);

  return (
    <Combo value={selected} onChange={handleSelect} key={key}>
      {({ open }) => (
        <div className={classNames("relative", className)}>
          <div className="relative">
            <Combo.Input
              className={classNames(
                "border border-border-base px-2 py-2 w-full rounded-lg",
                inputFieldClassName
              )}
              displayValue={(item: OptionType) => getOptionDisplayValue(item)}
              onChange={(event) =>
                onInputChange && onInputChange(event.target.value)
              }
              readOnly={!onInputChange}
              placeholder={placeholder}
            />
            <Combo.Button className="absolute bg-transparent left-0 right-0 h-full">
              <div className="flex justify-end mx-2">
                {open ? (
                  <i className="icon-chevron-up text-xl" />
                ) : (
                  <i className="icon-chevron-down text-xl" />
                )}
              </div>
            </Combo.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => onInputChange && onInputChange("")}
          >
            <Combo.Options
              className={classNames(
                "absolute mt-1 max-h-40 w-full overflow-auto p-1 rounded-xl bg-white py-1 ring-1 ring-black/5 focus:outline-none sm:text-sm z-50 scrollbar-hide border border-border-base co-bottom-shadow"
              )}
            >
              {options.map((option) => (
                <Combo.Option
                  key={getOptionDisplayValue(option)}
                  value={option}
                >
                  {({ active, selected }) => (
                    <div
                      className={classNames(
                        "px-2 py-2 flex gap-3 items-center tiny-md hover:bg-bgr-faint cursor-pointer",
                        { "bg-bgr-faint": active }
                      )}
                    >
                      {selected && (
                        <span>
                          <i className="icon-check text-xl" />
                        </span>
                      )}
                      <div>
                        <span>{getOptionDisplayValue(option)}</span>
                      </div>
                    </div>
                  )}
                </Combo.Option>
              ))}
            </Combo.Options>
          </Transition>
        </div>
      )}
    </Combo>
  );
};

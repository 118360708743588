import { EXAMPLE_SEARCH_URL } from "constants/config";
import useLabels from "hooks/useLabels";
import Link from "next/link";
import React from "react";

type SidebarBookmarksListEmptyProps = {
  onNavigate: () => void;
};

function SidebarBookmarksListEmpty({
  onNavigate,
}: SidebarBookmarksListEmptyProps) {
  const [labels] = useLabels("screens.sidebar.bookmarks-empty");

  return (
    <div className="mx-4 tracking-wide text-center text-gray-550 width-[268px]">
      <img
        src={"/icons/search-gray.svg"}
        className="w-[60px] h-[60px] mx-auto"
        alt="search icon"
      />
      <h2 data-testid="title" className="w-full mt-3 text-sm text-center">
        {labels["title"]}
      </h2>
      <p
        data-testid="try-searching"
        className="w-full mt-5 text-sm text-center"
        onClick={onNavigate}
      >
        {labels["try-searching"]}
      </p>

      <Link
        passHref
        href={EXAMPLE_SEARCH_URL}
        className="block mt-[16px] ml-auto mr-auto text-center text-sm text-fg-base px-[16px] py-[6px] border border-[#E4E9EC] rounded-full hover:shadow-button"
        onClick={onNavigate}
      >
        {labels["query-text"]}
      </Link>
    </div>
  );
}

export default SidebarBookmarksListEmpty;

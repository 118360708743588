import classNames from "classnames";
import { ButtonVariant, CoButton } from "components/CoButton";
import useLabels from "hooks/useLabels";
import { useRouter } from "next/router";
type NewSearchButtonProps = {
  isCollapsed?: boolean;
};

/**
 * @component NewSearchButton
 * @description The button at the top of the sidebar that triggers a new search
 */
function NewSearchButton({ isCollapsed = false }: NewSearchButtonProps) {
  const [labels] = useLabels("features.new-search-button");
  const router = useRouter();

  const handleSearchButtonClick = () => {
    if (!router.asPath.includes("/search")) {
      router.push("/search");
    }
  };

  return (
    <CoButton
      onClick={handleSearchButtonClick}
      icon="icon-search"
      label={isCollapsed ? null : labels["new-search"]}
      size="sm"
      className={classNames(isCollapsed ? "" : "w-full ml-4 mr-4")}
      variant={ButtonVariant.Secondary}
    />
  );
}

export default NewSearchButton;

// Required to allow PDFs to load on iOS
// https://github.com/wojtekmaj/react-pdf/issues/1811
// TODO(Jimmy): Remove once above issue is resolved.
if (typeof (Promise as any).withResolvers === "undefined") {
  if (typeof window !== "undefined") {
    (window.Promise as any).withResolvers = function () {
      let resolve, reject;
      const promise = new Promise((res, rej) => {
        resolve = res;
        reject = rej;
      });
      return { promise, resolve, reject };
    };
  } else {
    (global.Promise as any).withResolvers = function () {
      let resolve, reject;
      const promise = new Promise((res, rej) => {
        resolve = res;
        reject = rej;
      });
      return { promise, resolve, reject };
    };
  }
}

export {};

import Tippy from "@tippyjs/react";
import classNames from "classnames";
import { useAppSelector } from "hooks/useStore";
import React, {
  cloneElement,
  JSXElementConstructor,
  ReactElement,
  useLayoutEffect,
  useState,
} from "react";
import "tippy.js/animations/scale-subtle.css";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";

export type Placement =
  | "bottom"
  | "bottom-end"
  | "bottom-start"
  | "left"
  | "left-end"
  | "left-start"
  | "right"
  | "right-end"
  | "right-start"
  | "top"
  | "top-end"
  | "top-start";

type Props = {
  children: ReactElement<any, string | JSXElementConstructor<any>> | undefined;
  tooltipContent: ReactElement<any, string | JSXElementConstructor<any>>;
  theme?: string;
  placement?: Placement;
  [rest: string]: any;
  delay?: number;
  disabled?: boolean;
  widthFull?: boolean;
  show?: boolean; // Optionally control whether the tooltip is open externally
  className?: string;
  touch?: boolean; // Optionally disable touch events on mobile or other touchscreen devices. Defaults to true to allow touch events.
  onShow?: () => void;
  onHide?: () => void;
  childrenClassName?: string; // Optionally add a class to the children span wrapper
  size?: "small" | "default"; // Changes the padding a border radius of the tooltip. "small" has smaller padding and a smaller border radius.
  contentWidthFull?: boolean; // Controls if tooltip content should be full width or have padding
  zIndex?: number;
};

const useSSRLayoutEffect =
  typeof window === "undefined" ? Function.prototype : useLayoutEffect;

/**
 * @component Tooltip
 * @description Wrapper component for tooltips
 * @example
 * return (
 *   <Tooltip content={<ReactComponent />}>Learn more</Tooltip>
 * )
 */
const Tooltip = ({
  tooltipContent,
  children,
  placement = "top-end",
  theme = "light",
  disabled,
  widthFull,
  show,
  className,
  onShow,
  onHide,
  touch = true,
  childrenClassName,
  size = "default",
  contentWidthFull = false,
  zIndex,
  ...rest
}: Props) => {
  const [body, setBody] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [clickOutsideActive, setClickOutsideActive] = useState<boolean>(false);
  const isMobile = useAppSelector((state) => state.setting.isMobile);

  useSSRLayoutEffect(() => {
    setBody(document?.body);
  }, []);

  const handleShow = () => {
    if (onShow) {
      onShow();
    }

    if (isMobile) {
      setOpen(true);
      setTimeout(() => {
        setClickOutsideActive(true);
      }, 100);
    }
  };

  const handleHide = () => {
    if (onHide) {
      onHide();
    }
    setOpen(false);
    setClickOutsideActive(false);
  };

  if (!body) return null;

  if (disabled) {
    return <>{children}</>;
  }

  return (
    <>
      <Tippy
        content={cloneElement(tooltipContent)}
        animation="scale-subtle"
        theme={theme}
        placement={placement}
        offset={[5, 5]}
        appendTo={body}
        onShow={handleShow}
        visible={show}
        touch={touch}
        trigger={
          show !== undefined ? undefined : isMobile ? "click" : "mouseenter"
        }
        onHide={handleHide}
        zIndex={zIndex}
        className={classNames(
          !contentWidthFull &&
            (size === "small" ? "rounded-lg p-2" : "rounded-xl p-3"),
          className
        )}
        delay={[300, null]} // show delay is 300ms, hide delay is the default
        {...rest}
      >
        <span
          className={classNames(
            widthFull ? "w-full" : "w-fit",
            childrenClassName
          )}
          data-testid="tooltip"
        >
          {children}
          {open && (
            <div
              id="tippy-backdrop"
              data-testid="tooltip-backdrop"
              onClick={clickOutsideActive ? handleHide : undefined}
              className="fixed left-0 top-0 h-full w-full bg-red z-50 bg-transparent"
            />
          )}
        </span>
      </Tippy>
    </>
  );
};

export default Tooltip;

import { ButtonVariant, CoButton } from "components/CoButton";
import { SALES_EMAIL } from "constants/config";
import useLabels from "hooks/useLabels";
import React from "react";

function EnterpriseSubscriptionUpgradeCard() {
  const [upgradePlanLabels] = useLabels("upgrade-plan-card");

  return (
    <div className="flex flex-col gap-6 h-full">
      <div className="flex flex-col flex-1 gap-4">
        <div className="flex items-center gap-2">
          <img
            src={"/icons/enterprise.svg"}
            className="w-[21px] h-[21px]"
            alt="Enterprise"
          />
          <h3 className="lg-bold">{upgradePlanLabels["title-enterprise"]}</h3>
        </div>
        <span className="text-fg-muted">
          {upgradePlanLabels["subtitle-enterprise"]}
        </span>
      </div>
      <div className="flex">
        <CoButton
          className="flex-1"
          href={"mailto:" + SALES_EMAIL}
          label={upgradePlanLabels["contact-us"]}
          size="sm"
          variant={ButtonVariant.Tertiary}
        />
      </div>
    </div>
  );
}

export default EnterpriseSubscriptionUpgradeCard;

import { ButtonVariant, CoButton } from "components/CoButton";
import { useBookmarks } from "features/Bookmarks";
import { IBookmarkListItem } from "helpers/bookmark";
import useLabels from "hooks/useLabels";
import Link from "next/link";
import React from "react";
import SidebarBookmarksListEmpty from "./SidebarBookmarksListEmpty";
import SidebarContentLoading from "./SidebarContentLoading";

type SidebarBookmarksListProps = {
  onNavigate: () => void;
};

function SidebarBookmarksList({ onNavigate }: SidebarBookmarksListProps) {
  const [sidebarLabels] = useLabels("screens.sidebar");

  const { isBookmarkListLoaded, bookmarkLists } = useBookmarks();

  if (!isBookmarkListLoaded) return <SidebarContentLoading />;

  return (
    <div className="flex-1 my-5 overflow-y-auto h-full pb-[135px]">
      {bookmarkLists.length === 0 ? (
        <div className="flex items-center justify-center w-full h-full">
          <SidebarBookmarksListEmpty onNavigate={onNavigate} />
        </div>
      ) : (
        <>
          <ol>
            {bookmarkLists.map((bookmarkList: IBookmarkListItem) => (
              <li className="mx-2" key={bookmarkList.id}>
                <Link
                  onClick={onNavigate}
                  href={`/lists/${bookmarkList.id}`}
                  className="block px-6 py-2 text-sm rounded-lg hover:bg-gray-100"
                >
                  {bookmarkList.text_label}
                </Link>
              </li>
            ))}
          </ol>
          <div className="flex justify-center mt-4">
            <Link href="/lists/" onClick={onNavigate} passHref>
              <CoButton
                variant={ButtonVariant.Secondary}
                label={sidebarLabels["view-all-lists"]}
              />
            </Link>
          </div>
        </>
      )}
    </div>
  );
}

export default SidebarBookmarksList;

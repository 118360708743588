export enum OrganizationRole {
  OWNER = "org:owner",
  ADMIN = "org:admin",
  MEMBER = "org:member",
}

export function canOrgRoleAccessApi(orgRole?: string): boolean {
  const permittedRoles = [OrganizationRole.OWNER, OrganizationRole.ADMIN];
  return orgRole ? permittedRoles.includes(orgRole as OrganizationRole) : false;
}

// Example list of country codes, should be imported from shared constants
import { countries, CountryCode } from "helpers/sharedDataConstants";

export const getCountriesFromQueryParams = (
  paramValue?: string
): CountryCode[] | undefined => {
  if (!paramValue) return undefined;

  return paramValue
    .split(",")
    .map((code) =>
      countries.find(
        (country) => country.code.toLowerCase() === code.toLowerCase()
      )
    )
    .filter(Boolean) as CountryCode[];
};

export const getQueryParamsFromCountries = (countries?: CountryCode[]) => {
  if (countries === undefined || countries.length === 0) return undefined;
  return countries.map((country) => country.code.toLowerCase()).join(",");
};

import { ButtonVariant, CoButton } from "components/CoButton";
import useFilterDrawer from "features/FilterDrawer/hooks/useFilterDrawer";
import useFilters from "features/FilterDrawer/hooks/useFilters";
import pluralize from "helpers/pluralize";
import useLabels from "hooks/useLabels";
import React, { MouseEvent } from "react";

function FilterButton() {
  const [labels] = useLabels("features.filter-drawer.filter-button");

  const { openFilterDrawer } = useFilterDrawer();

  const handleOpenFilterDrawer = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    openFilterDrawer();
  };

  const { appliedFilterCount } = useFilters();

  return (
    <CoButton
      icon="icon-list-filter"
      data-testid="filter-button"
      label={pluralize(labels["filter"], {
        FILTER_COUNT: appliedFilterCount,
      })}
      onClick={handleOpenFilterDrawer}
      size="sm"
      variant={ButtonVariant.Flat}
    />
  );
}

export default FilterButton;

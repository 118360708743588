import { ButtonVariant, CoButton } from "components/CoButton";
import useLabels from "hooks/useLabels";
import { useAppSelector } from "hooks/useStore";
import React, { ReactNode } from "react";
import LoadingSubscriptionCard from "../LoadingSubscriptionCard/LoadingSubscriptionCard";

type Props = {
  children?: ReactNode;
  onUpgradePlan: () => void;
  isLoading?: boolean;
};

/**
 * @component SubscriptionUpgradeFeaturesCard
 * @description lists out the premium features for a user if they would upgrade
 * @example
 * return (
 *   <SubscriptionUpgradeFeaturesCard
 *    onUpgradePlan={clickedUpgradePlan}
 *    isLoading={isPageLoading}
 *  />
 * )
 */
const SubscriptionUpgradeFeaturesCard = ({
  onUpgradePlan,
  isLoading,
}: Props) => {
  const [upgradeFeaturesCardLabels] = useLabels("upgrade-features-card");
  const { premium } = useAppSelector((state) => state.subscription.pricingText);

  if (isLoading) {
    return <LoadingSubscriptionCard />;
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-2">
        <img src="/icons/premium.svg" alt="Premium" className="w-6 h-6" />
        <h3 className="flex-1 lg-bold">{upgradeFeaturesCardLabels["title"]}</h3>
      </div>
      <div className="flex-1">
        <ul className="flex flex-col gap-2">
          {premium.features.map((feature: any) => (
            <li key={`feature-${feature}`} className="flex gap-2">
              <i className="text-xl text-green-emphasis icon-check" />
              <span className="flex-1 sm-normal text-fg-base">{feature}</span>
            </li>
          ))}
        </ul>
      </div>
      <CoButton
        className="w-full"
        label={upgradeFeaturesCardLabels["upgrade-to-premium"]}
        onClick={onUpgradePlan}
        size="sm"
        variant={ButtonVariant.Primary}
      />
    </div>
  );
};

export default SubscriptionUpgradeFeaturesCard;

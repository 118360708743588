import { Filter, Filters, setAppliedFilters } from "store/slices/search";
import { useAppDispatch, useAppSelector } from "../../../hooks/useStore";
import { countFilters } from "../utils/filters";

/**
 * @component useFilters
 * @description A hook used to manage the currently applied filters, encapsulating global redux state.
 */
function useFilters() {
  const dispatch = useAppDispatch();

  const appliedFilters = useAppSelector((state) => state.search.appliedFilters);

  const appliedFilterCount = countFilters(appliedFilters);

  // Apply new filters, replacing the existing ones
  const applyFilters = (newFilters: Partial<Filters>) => {
    dispatch(setAppliedFilters(newFilters));
  };

  // Modify a single filter value without replacing the entire appliedFilters object
  const changeFilter = <T extends keyof Filters>(
    filter: T,
    value: Filters[T]
  ) => {
    dispatch(
      setAppliedFilters({
        ...appliedFilters,
        [filter]:
          Array.isArray(value) && value.length === 0 ? undefined : value,
      })
    );
  };

  // Remove a single filter by setting it to undefined or an empty value
  const removeFilter = (filter: Filter) => {
    dispatch(setAppliedFilters({ ...appliedFilters, [filter]: undefined }));
  };

  // Clear all filters by resetting them to default values
  const removeAllFilters = () => {
    dispatch(setAppliedFilters({}));
  };

  return {
    appliedFilterCount,
    appliedFilters,
    applyFilters,
    changeFilter,
    removeAllFilters,
    removeFilter,
  };
}

export default useFilters;

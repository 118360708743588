import useLabels from "hooks/useLabels";
import CardTooltip from "../CardTooltip";

/**
 * @component AskPaperFilterTooltip
 * @description Tooltip accompanying the "Ask paper" beta tag in the filter drawer
 */
const AskPaperFilterTooltip = () => {
  const [tooltipLabels] = useLabels("tooltips.ask-this-paper-filter");

  return (
    <CardTooltip>
      <CardTooltip.Header
        title={tooltipLabels["ask-paper"]}
        iconComponent={
          <img
            alt="ask paper"
            src="/icons/ask-paper.svg"
            width="24px"
            height="24px"
          />
        }
        isBeta
      />
      <CardTooltip.Description content={tooltipLabels["description"]} />
    </CardTooltip>
  );
};

export default AskPaperFilterTooltip;

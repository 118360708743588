import { StudyType } from "helpers/api";

const StudyTypeShortCodeMap: Record<StudyType, string> = {
  [StudyType.ANIMAL_STUDY]: "animal",
  [StudyType.CASE_STUDY]: "case",
  [StudyType.IN_VITRO_TRIAL]: "in_vitro",
  [StudyType.LITERATURE_REVIEW]: "lit_review",
  [StudyType.META_ANALYSIS]: "meta",
  [StudyType.NON_RCT_TRIAL]: "non_rct",
  [StudyType.OBSERVATIONAL_STUDY]: "observational",
  [StudyType.RCT]: "rct",
  [StudyType.SYSTEMATIC_REVIEW]: "systematic",
};

const ShortCodeToStudyTypeMap = Object.fromEntries(
  Object.entries(StudyTypeShortCodeMap).map(([key, value]) => [value, key])
) as Record<string, StudyType>;

export const getStudyTypesFromQueryParams = (
  paramValue?: string
): StudyType[] | undefined => {
  if (!paramValue) return undefined;

  return paramValue
    .split(",")
    .map((shortCode) => ShortCodeToStudyTypeMap[shortCode])
    .filter(Boolean) as StudyType[];
};

export const getQueryParamsFromStudyTypes = (studyTypes?: StudyType[]) => {
  if (!studyTypes || studyTypes.length === 0) return undefined;
  return studyTypes.map((type) => StudyTypeShortCodeMap[type]).join(",");
};

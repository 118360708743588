import pluralize from "helpers/pluralize";
import useLabels from "hooks/useLabels";
import { Tag } from "./Tag";

type BetaTagProps = {
  papersCount?: number;
  className?: string;
};

const BetaTag = ({ papersCount, className }: BetaTagProps) => {
  const [betaLabels] = useLabels("beta");
  return (
    <Tag size="sm" className={className}>
      {!!papersCount
        ? pluralize(betaLabels["beta-papers"], {
            count: papersCount,
          })
        : betaLabels["beta"]}
    </Tag>
  );
};

export { BetaTag };

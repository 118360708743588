import { ButtonVariant, CoButton } from "components/CoButton";
import { useSaveBookmarkModal } from "features/Bookmarks";
import useThreads from "features/Sidebar/hooks/useThreads";
import { ThreadHistory as ThreadHistoryType } from "helpers/api";
import { MixpanelActionSource } from "helpers/services/mixpanel/events";
import useAnalytics from "hooks/useAnalytics";
import useLabels from "hooks/useLabels";
import React, { useMemo } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import ThreadHistoryEmpty from "./components/ThreadHistoryEmpty";
import ThreadHistoryGroup from "./components/ThreadHistoryGroup";
import ThreadHistoryLoading from "./components/ThreadHistoryLoading";
import { groupThreadsByDate } from "./utils";

type ThreadHistoryProps = {
  onNavigate: () => void;
};

function ThreadHistory({ onNavigate }: ThreadHistoryProps) {
  const [threadHistoryLabels] = useLabels("screens.sidebar.thread-history");
  const { setAnalyticActionSource } = useAnalytics();

  const {
    fetchNextPage,
    hasNextPage,
    isError,
    isFetchingNextPage,
    isLoading,
    threads,
  } = useThreads();

  const { openSaveBookmarkModalForThread } = useSaveBookmarkModal();

  const groupedData = useMemo(
    () => groupThreadsByDate(threads || []),
    [threads]
  );

  const [infiniteRef] = useInfiniteScroll({
    loading: isFetchingNextPage,
    hasNextPage: !!hasNextPage,
    onLoadMore: fetchNextPage,
    disabled: isError,
    rootMargin: "0px 0px 400px 0px",
  });

  const handleDeleteThread = async (thread_id: number) => {
    // TODO(Jimmy): Migrate this to useThreads hook.
    // Delete the thread and remove it from the client-side thread list or invalidate and refetch the list.
  };

  const handleSaveThread = (thread: ThreadHistoryType) => {
    setAnalyticActionSource(MixpanelActionSource.Sidebar);
    openSaveBookmarkModalForThread(thread.thread_id);
  };

  if (isError || groupedData === undefined) {
    return (
      <div className="flex flex-col items-center gap-2 m-5 mx-8 text-center">
        {threadHistoryLabels["error"]}
        <CoButton
          variant={ButtonVariant.Secondary}
          onClick={() => fetchNextPage()}
          label={threadHistoryLabels["retry"]}
        />
      </div>
    );
  }

  if (isLoading) {
    return <ThreadHistoryLoading />;
  }

  return (
    <>
      <div className="flex-1 overflow-auto pb-[104px] bottom-fade-out">
        {groupedData.count === 0 ? (
          <div className="flex items-center justify-center w-full h-full pt-4">
            <ThreadHistoryEmpty onNavigate={onNavigate} />
          </div>
        ) : (
          <div className="flex flex-col w-full gap-6">
            {groupedData.today.length > 0 && (
              <ThreadHistoryGroup
                label={threadHistoryLabels["today"]}
                threads={groupedData.today}
                onNavigate={onNavigate}
                onDelete={handleDeleteThread}
                onSave={handleSaveThread}
              />
            )}
            {groupedData.lastSevenDays.length > 0 && (
              <ThreadHistoryGroup
                label={threadHistoryLabels["last-seven-days"]}
                threads={groupedData.lastSevenDays}
                onNavigate={onNavigate}
                onDelete={handleDeleteThread}
                onSave={handleSaveThread}
              />
            )}
            {groupedData.lastThirtyDays.length > 0 && (
              <ThreadHistoryGroup
                label={threadHistoryLabels["last-thirty-days"]}
                threads={groupedData.lastThirtyDays}
                onNavigate={onNavigate}
                onDelete={handleDeleteThread}
                onSave={handleSaveThread}
              />
            )}
            {groupedData.lastYear.length > 0 && (
              <ThreadHistoryGroup
                label={threadHistoryLabels["last-year"]}
                threads={groupedData.lastYear}
                onNavigate={onNavigate}
                onDelete={handleDeleteThread}
                onSave={handleSaveThread}
              />
            )}
          </div>
        )}
        {hasNextPage && (
          <div ref={infiniteRef} className="text-center">
            <ThreadHistoryLoading />
          </div>
        )}
      </div>
    </>
  );
}

export default ThreadHistory;

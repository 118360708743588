import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";

type DrawerProps = {
  open?: boolean;
  children?: React.ReactNode;
  onClose: (value: boolean) => void;
  fillY?: boolean | undefined;
};

function Drawer({ open, onClose, children, fillY }: DrawerProps) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[2147483004]" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-[#20202066] sm:bg-transparent transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div
              className={`pointer-events-none fixed xl ${
                fillY ? "inset-y-0" : "bottom-0 sm:inset-y-0"
              } right-0 flex max-w-full pt-[70px] sm:pt-0`}
            >
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-200 sm:duration-400"
                enterFrom="translate-y-full sm:translate-x-full sm:translate-y-0"
                enterTo="translate-y-0 sm:translate-x-0 sm:translate-y-0"
                leave="transform transition ease-in-out duration-200 sm:duration-400"
                leaveFrom="translate-y-0 sm:translate-x-0 sm:translate-y-0"
                leaveTo="translate-y-full sm:translate-x-full sm:translate-y-0"
              >
                <Dialog.Panel className="sm:w-[322px] relative w-screen pointer-events-auto max-w-screen">
                  <div
                    data-testid="drawer"
                    className="flex flex-col h-full overflow-y-auto bg-white shadow-xl rounded-t-[20px] sm:rounded-none "
                  >
                    <div className="relative flex-1 overflow-y-auto">
                      {children}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default Drawer;

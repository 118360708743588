import useLabels from "hooks/useLabels";
import React from "react";

/**
 * @component StudyDetailsFilterTooltip
 * @description Tooltip for case study
 * @example
 * return (
 *   <StudyDetailsFilterTooltip />
 * )
 */
const StudyDetailsFilterTooltip = () => {
  const [tooltipsLabels] = useLabels("tooltips.study-details-filter");

  return (
    <div
      className="flex flex-col gap-3"
      data-testid="tooltip-study-details-filter"
    >
      <div className="flex items-center gap-3">
        <img className="w-6" alt="Sparkler" src="/icons/sparkler.svg" />
        <p className="sm-bold">{tooltipsLabels["title"]}</p>
      </div>
      <p
        className="sm-normal"
        dangerouslySetInnerHTML={{ __html: tooltipsLabels["content"] }}
      ></p>

      <p className="tiny-normal text-fg-muted">{tooltipsLabels["footer"]}</p>
    </div>
  );
};

export default StudyDetailsFilterTooltip;
